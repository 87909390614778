import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import Page from "core-cmp/Page";
import Utils from "core-cmp/Utils";
import XlsxFormat from "core-cmp/util/XlsxFormat";
import {I18N} from "core-cmp/util/I18n";
import Grid from "core-cmp/Grid";
import UTILS from "core-cmp/Utils";
import {PREF} from "core-cmp/Preferences";
import ErsActivityInfoPanel from "thm/ui/common/ersActivity/ErsActivityInfoPanel";
import ErsDataComparePopupTpl from "thm/ui/common/ersData/comparePopup/ErsDataComparePopupTpl.stache";
import "thm/ui/common/ersData/comparePopup/ErsDataComparePopup.css";

/**
 * Compare ErsData Activities.
 */
let ErsDataComparePopup = Page.extend({
  id:              "ersDataComparePopup",
  template:        ErsDataComparePopupTpl,
  i18nPrefix:      "thm.ui.common.ersData.ErsDataComparePopup.",
  routerType:      "popup",
  popupHeight:     "100%",
  popupWidth:      "100%",
  modal:           true,
  activities:      null,
  autoBindActions: true,

  /**
   * @override
   */
  init: function (config) {
    let me = this;
    me._super(config);

    me.format = new XlsxFormat({
      encodeType: "blob",
      ordered:    true
    });

    me.customStyle = me.format.stylesheet.createFormat({
      font: {
        bold:  true,
        color: '000000'
      }
    }).id;

    me.addStyle = me.format.stylesheet.createFormat({
      font: {
        color: '000000'
      },
      fill: {
        type:        'pattern',
        patternType: 'solid',
        fgColor:     'd1ffb3'
      }
    }).id;

    me.modifyStyle = me.format.stylesheet.createFormat({
      font: {
        color: '000000'
      },
      fill: {
        type:        'pattern',
        patternType: 'solid',
        fgColor:     'fff0b3'
      }
    }).id;

    me.deleteStyle = me.format.stylesheet.createFormat({
      font: {
        color: '000000'
      },
      fill: {
        type:        'pattern',
        patternType: 'solid',
        fgColor:     'ffb3b3'
      }
    }).id;
  },

  /**
   * @override
   */
  doAfterEnhance: function () {
    let me = this;
    me._super();

    // Initialize the grid widget
    me.gridElement = new Grid(me.$findById("activities-container"), {
      columns:    me._getColumns(),
      grouping:   {
        contextMenuEnabled: true,
        autoExpandAll:      true,
        allowCollapsing:    true
      },
      noDataText: me.msg("noData")
    });
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this;

    me.filter = ["time", "position", "zoneFullName", "port", "catchDetail"];
    me._super();

    me.activities = me.context.activities;
    me.message = me.context.message;

    if (me.context.compareAll) {
      me.filter = null;
    }

    let matrices   = me.computeWeightMatrix(),
        activities = me.getDiffActivities(matrices);

    me.gridElement.option("dataSource", me.getDataSource(activities));
  },

  onExport: function () {
    let me               = this,
        file             = {
          fileName: (me.msg("title") + ' ' + PREF().format("dateTime", new Date())).replace(/ /g, '_')
        },
        columns          = me._getColumns(),
        headers          = [],
        // Generate a clone of grid dataSource without virtual scrolling and paging, using the same store (data)
        exportDataSource = new DevExpress.data.DataSource({
          paginate: false,
          store:    me.gridElement.gridinstance.getDataSource().store()
        }),
        datas            = [];

    // Add custom before grid
    datas.push(...me._getCustomRows());

    // Add headers
    columns.forEach((col) => {
      headers.push(me.format.getHeaderData(col));
    });
    datas.push(headers);

    // Generate rows
    exportDataSource.load().done((rowsOrGroups) => {
      let rows = _.cloneDeep(rowsOrGroups);
      rows.forEach((row) => {
        let formattedRow = [],
            metadata;

        switch (row.action) {
          case me.msg("add"):
            metadata = me.addStyle;
            break;
          case me.msg("delete"):
            metadata = me.deleteStyle;
            break;
          default:
            metadata = me.modifyStyle;
            break;
        }

        me._getColumns().forEach((col) => {
          let value = row[col.dataField];
          if (_.startsWith(col.dataType, "date") && !_.isNil(value)) {
            value = PREF().format(col.dataType === "datetime" ? "dateTime" : "date", new Date(value));
          }

          value = me.format.encodeValue(value);
          formattedRow.push({
            metadata: {style: metadata},
            value:    value
          });

        });
        datas.push(formattedRow);
      });

      file = _.extend(file, me.format.encode(datas, true));
      $.when(file.promise).then(function (content) {
        $.when(Utils.saveAs({
          content:  content,
          type:     file.type,
          fileName: file.fileName + "." + file.extension,
          timeout:  100
        }));
      });
    });
  },

  computeWeightMatrix: function () {
    let me             = this,
        countMatrix    = [],
        activityMatrix = [];
    me.activities.old.forEach((oldAct) => {
      let countLine    = [],
          activityLine = [];
      me.activities.new.forEach((newAct) => {
        let lineDiff,
            count    = null,
            activity = null;

        if (oldAct.kind !== newAct.kind) {
          lineDiff = null;
        } else {
          lineDiff = me.getCountModifiedActivities(_.clone(oldAct), _.clone(newAct))
        }

        // If count > 20 then activities aren't the same
        if (lineDiff && lineDiff[0] > 20) {
          lineDiff = null;
        }

        if (!_.isNil(lineDiff)) {
          count = lineDiff[0];
          activity = {
            diff:   lineDiff[1],
            refAct: newAct
          };
        }
        countLine.push(count);
        activityLine.push(activity);
      });
      countMatrix.push(countLine);
      activityMatrix.push(activityLine);
    });
    return [countMatrix, activityMatrix];
  },

  getDiffActivities: function (matrices) {
    let me             = this,
        activities     = {
          add:    [],
          modify: [],
          delete: []
        },
        weightMatrix   = matrices[0],
        activityMatrix = matrices[1],
        validMatrix    = [];

    weightMatrix.forEach((weightLine) => {
      let validLine        = [],
          currentBestValue = null,
          reorderLineIndex = null;

      weightLine.forEach((weight, weightIndex) => {
        validLine.push(null);
        if (_.isNil(weight)) {
          return true;
        }

        if (_.isNil(currentBestValue) || (currentBestValue > weight)) {
          let columnPrevValue = me._getColumnBestValue(weightIndex, validMatrix);
          if (!columnPrevValue || columnPrevValue.value > weight) {
            // Reset all line
            validLine = validLine.map(() => null);
            validLine[weightIndex] = weight;
            currentBestValue = weight;

            if (columnPrevValue && columnPrevValue.value > weight) {
              reorderLineIndex = columnPrevValue.index;
            }
          }
        }
      });

      if (!_.isNil(reorderLineIndex)) {
        me._reorderLine(reorderLineIndex, weightMatrix, validMatrix);
      }

      validMatrix.push(validLine);
    });

    let columnIsNull = new Array(me.activities.new.length).fill(true, 0, me.activities.new.length);
    validMatrix.forEach((line, lineIndex) => {
      let lineIsNull = true;
      line.forEach((value, valueIndex) => {
        // On ne test pas le 0 car si 0 activité non modifiée
        if (value) {
          activities.modify.push(activityMatrix[lineIndex][valueIndex]);
          lineIsNull = false;
          columnIsNull[valueIndex] = false;
          return false;
        }
      });

      if (lineIsNull) {
        activities.delete.push({
          diff:   {
            from: me.activities.old[lineIndex],
            to:   {}
          },
          refAct: me.activities.old[lineIndex]
        });
      }
    });

    columnIsNull.forEach((isNull, columnIndex) => {
      if (isNull) {
        activities.add.push({
          diff:   {
            from: {},
            to:   me.activities.new[columnIndex]
          },
          refAct: me.activities.new[columnIndex]
        });
      }
    });

    return activities;
  },

  getCountModifiedActivities: function (oldAct, newAct) {
    let me           = this,
        removeFields = ["modifTime", "modifTimeMillis", "modifUser"];

    // Remove all ids fields (recursively)
    me.cleanActivityBeforeDiff(oldAct, removeFields);
    me.cleanActivityBeforeDiff(newAct, removeFields);

    let result = UTILS.diff(oldAct, newAct);

    if (result.diff.timeMillis && _.isNumber(result.diff.timeMillis.from) && _.isNumber(result.diff.timeMillis.to)) {
      // Each 60 minutes diff add 1 count
      result.count += Math.ceil(Math.abs(result.diff.timeMillis.from - result.diff.timeMillis.to) / (60 * 60 * 1000));
    }

    return [result.count, result.diff];
  },

  cleanActivityBeforeDiff: function (activity, removeFields) {
    let me               = this,
        activityIdFields = Object.keys(activity).filter((field) => !!field.toLowerCase().includes('id'));

    activityIdFields.concat(removeFields).forEach((field) => {
      delete activity[field];
    });

    _.each(activity, (value, key) => {
      if (_.isObject(value)) {
        activity[key] = me.cleanActivityBeforeDiff(value, []);
      }
    });

    return activity;
  },

  getDataSource: function (activities) {
    let me         = this,
        dataSource = [];

    Object.keys(activities).forEach((key) => {
      activities[key].forEach((data) => {
        let baseItem = {
          action:     me.msg(key),
          timeMillis: data.refAct.timeMillis,
          kind:       I18N.msg("uda.ersActivity.field.kind." + data.refAct.kind)
        };

        dataSource = dataSource.concat(me.computeDataSource(baseItem, ErsActivityInfoPanel.prototype.authorizedFieldsByActivityType[data.refAct.kind], data.diff, data.refAct, key));
      });
    });

    return dataSource;
  },

  computeDataSource: function (baseObject, authorizedFields, diff, refAct, action) {
    let me         = this,
        source     = [],
        isAddOrDel = (action === "add") || (action === "delete"),
        properties = diff;

    if (!authorizedFields) {
      return source;
    }

    if (isAddOrDel) {
      properties = refAct;
    }

    Object.keys(properties).forEach((property) => {
      if (property.toLowerCase().includes("id") || property.toLowerCase().includes("millis")) {
        return true;
      }

      if (me.filter) {
        let filterFound = me.filter.find((item) => {
          return item.toLowerCase().includes(property.toLowerCase()) || property.toLowerCase().includes(item.toLowerCase());
        });

        if (!filterFound) {
          return true;
        }
      }

      let from = isAddOrDel ? diff.from[property] : diff[property].from,
          to   = isAddOrDel ? diff.to[property] : diff[property].to;

      // Delete undefined
      if (from === "undefined") {
        from = "";
      }

      if (to === "undefined") {
        to = "";
      }

      if (_.isArray(from) || _.isArray(to)) {
        let hasGrid = _.find(authorizedFields.grids, {place: property});
        if (hasGrid) {
          let grid = ErsActivityInfoPanel.prototype[hasGrid.gridName + "Config"];
          source = source.concat(me.computeArraySource(baseObject, {
            from,
            to
          }, property, grid, hasGrid.gridName));
        }
        return true;
      } else if (_.isObject(from) || _.isObject(to)) {
        if (_.isDate(from) || _.isDate(to)) {
          source.push(Object.assign({}, baseObject, {
            property:     I18N.msg("uda.ersActivity.field." + property),
            initialValue: from ? PREF().format("dateTime", new Date(from.getTime())) : '',
            finalValue:   to ? PREF().format("dateTime", new Date(to.getTime())) : ''
          }));
        }
      } else if (property.includes(".")) {
        let splitProp = property.split("."),
            hasGrid   = _.find(authorizedFields.grids, {place: splitProp[0]});
        if ((splitProp.length === 3) && hasGrid) {
          let grid  = ErsActivityInfoPanel.prototype[hasGrid.gridName + "Config"],
              label = me.handleSplitProperty(property, refAct, grid, hasGrid.gridName);
          if (label) {
            source.push(Object.assign({}, baseObject, {
              property:     label,
              initialValue: from,
              finalValue:   to
            }));
          }
        }
      } else {
        source.push(Object.assign({}, baseObject, {
          property:     I18N.msg("uda.ersActivity.field." + property),
          initialValue: from,
          finalValue:   to
        }));
      }
    });

    return source;
  },

  handleSplitProperty: function (property, refAct, grid, gridName) {
    let me        = this,
        splitProp = property.split("."),
        columns   = me._getErsActivityGridColumns(grid.columns),
        column    = _.find(columns, {dataField: splitProp[2]});

    if (!columns.length || !column) {
      return null;
    }

    return splitProp[0] + " > " + refAct[splitProp[0]][splitProp[1]][columns[0].dataField] + " > " + I18N.msg("thm.ui.common.ersActivity.ErsActivityInfoPanel." + gridName + "." + column.id);
  },

  computeArraySource: function (baseObject, diff, property, grid, gridName) {
    let me             = this,
        arrayToIterate = diff.from ? diff.from : diff.to,
        columns        = me._getErsActivityGridColumns(grid.columns),
        finalArray     = [];

    if (!columns.length) {
      return [];
    }

    arrayToIterate.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        let column = _.find(columns, {dataField: key});
        if (column) {
          finalArray.push(Object.assign({}, baseObject, {
            property:     property + " > " + (diff.from ? diff.from[index][columns[0].dataField] : diff.to[index][columns[0].dataField])
                            + " > " + I18N.msg("thm.ui.common.ersActivity.ErsActivityInfoPanel." + gridName + "." + column.id),
            initialValue: diff.from ? diff.from[index][key] : "undefined",
            finalValue:   diff.to ? diff.to[index][key] : "undefined"
          }));
        }
      });
    });

    return finalArray;
  },

  _getColumnBestValue: function (columnIndex, matrix, ignoreIndex) {
    let lineIndex = matrix.length,
        value,
        indexLine;

    for (let i = 0; i < lineIndex; i++) {
      if (!_.isNil(ignoreIndex) && ignoreIndex === lineIndex) {
        continue;
      }
      if (!_.isNil(matrix[i][columnIndex])) {
        value = matrix[i][columnIndex];
        indexLine = i;
      }
    }

    return !_.isNil(value) ? {
      value,
      index: indexLine
    } : null;
  },

  _reorderLine: function (lineIndex, weightMatrix, validMatrix) {
    let me               = this,
        currentBestValue = null,
        reorderLineIndex = null,
        newLine          = [];

    weightMatrix[lineIndex].forEach((weight, index) => {
      newLine.push(null);
      if (_.isNil(weight)) {
        return true;
      }

      if (_.isNil(currentBestValue) || (currentBestValue > weight)) {
        let columnPrevValue = me._getColumnBestValue(index, validMatrix, lineIndex);
        if (!columnPrevValue || columnPrevValue.value > weight) {
          // Reset all line
          newLine = newLine.map(() => null);
          newLine[index] = weight;
          currentBestValue = weight;

          if (columnPrevValue && columnPrevValue.value > weight) {
            reorderLineIndex = columnPrevValue.index;
          }
        }
      }
    });

    if (!_.isNil(reorderLineIndex)) {
      me._reorderLine(reorderLineIndex, weightMatrix, validMatrix);
    }

    validMatrix[lineIndex] = newLine;
  },

  _getColumns: function () {
    let me = this;
    return [
      {
        id:             "action",
        dataField:      "action",
        caption:        me.msg("action"),
        width:          120,
        dataType:       "string",
        alignment:      "center",
        allowFiltering: true,
        allowSorting:   true,
        visible:        true,
        allowGrouping:  true
      },
      {
        id:             "date",
        dataField:      "timeMillis",
        caption:        me.msg("date"),
        width:          170,
        dataType:       "datetime",
        alignment:      "center",
        allowFiltering: true,
        allowSorting:   true,
        visible:        true,
        allowGrouping:  true
      },
      {
        id:             "kind",
        dataField:      "kind",
        caption:        me.msg("kind"),
        width:          140,
        dataType:       "string",
        alignment:      "center",
        allowFiltering: true,
        allowSorting:   true,
        visible:        true,
        allowGrouping:  true
      },
      {
        id:             "property",
        dataField:      "property",
        caption:        me.msg("property"),
        width:          180,
        dataType:       "string",
        alignment:      "center",
        allowFiltering: true,
        allowSorting:   true,
        visible:        true,
        allowGrouping:  false
      },
      {
        id:             "initialValue",
        dataField:      "initialValue",
        caption:        me.msg("initialValue"),
        width:          200,
        dataType:       "string",
        alignment:      "center",
        allowFiltering: true,
        allowSorting:   true,
        visible:        true,
        allowGrouping:  false
      },
      {
        id:             "finalValue",
        dataField:      "finalValue",
        caption:        me.msg("finalValue"),
        width:          200,
        dataType:       "string",
        alignment:      "center",
        allowFiltering: true,
        allowSorting:   true,
        visible:        true,
        allowGrouping:  false
      }
    ]
  },

  _getErsActivityGridColumns: function (columns) {
    let me            = this,
        sourceColumns = [];

    columns.forEach((column) => {
      if (column.columns) {
        sourceColumns = sourceColumns.concat(me._getErsActivityGridColumns(column.columns));
      } else {
        sourceColumns.push(column);
      }
    });

    return sourceColumns;
  },

  _getCustomRows: function () {
    let me    = this,
        datas = [];

    datas.push([I18N.msg("uda.ersData.field.time"), {
      metadata: {style: me.customStyle},
      value:    PREF().format("dateTime", new Date(me.message.timeMillis))
    }]);
    datas.push([I18N.msg("uda.licence.field.vesselName"), {
      metadata: {style: me.customStyle},
      value:    me.message.vesselName
    }]);
    datas.push([I18N.msg("uda.mobile.field.registry"), {
      metadata: {style: me.customStyle},
      value:    me.message.vesselRegistry
    }]);
    datas.push([I18N.msg("callSign"), {
      metadata: {style: me.customStyle},
      value:    me.message.vesselCallSign
    }]);
    datas.push([I18N.msg("uda.mobile.field.ref"), {
      metadata: {style: me.customStyle},
      value:    me.message.vesselRef
    }]);

    // 3 lines for margin
    datas.push({});
    datas.push({});
    datas.push({});
    return datas;
  }
});


export default ErsDataComparePopup;
