import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";

/**
 * @class AlertDefinitionTripProgressionInfoPanel
 * Alert Definition "TripProgression" Form.
 */
let AlertDefinitionTripProgressionInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'alertDefinitionTripProgressionInfoPanel',
  timingAllowed: true
});
export default AlertDefinitionTripProgressionInfoPanel;
