<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
      <field-text name="name" max-length="60" required="true" col="12 12"></field-text>
      <field-textarea name="description" max-length="240" col="12 12"></field-textarea>
      <field-text name="toAddress" max-length="240" col="6 12"></field-text>
      <field-text name="fromAddress" max-length="240" col="6 12"></field-text>
      <field-text name="prefix" max-length="100" col="6 12"></field-text>
      <field-text name="suffix" max-length="100" col="6 12"></field-text>
      <field-switch name="cmdInSubject" text-on-key="subject" text-off-key="body"></field-switch>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
