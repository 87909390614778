<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content">
    <field-text name="code" max-length="30" required="true" format="uppercase" col="6 12"></field-text>
    <field-text name="name" max-length="100" required="true" col="6 12"></field-text>
    <field-checkbox name="enabled" col="6 12"></field-checkbox>
  </field-item>

  <ui-submit-cancel class="L-panel-footer-btn" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
