import Page from "core-cmp/Page";
import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {PREF} from "core-cmp/Preferences";
import Toastr from "core-cmp/Toastr";
import EditActivitySpeciesPopupTplStache from "thm/ui/common/ersActivity/EditActivitySpeciesPopupTpl.stache";
import SpeciesTplStache from "thm/ui/common/species/SpeciesTpl.stache";
import SpeciesCat from "core-uda/model/species/SpeciesCat";

/**
 * Fenêtre d'édition de la prise.
 */
let EditActivitySpeciesPopup = Page.extend({
  id:              "editActivitySpeciesPopup",
  routerType:      'popup',
  template:        EditActivitySpeciesPopupTplStache,
  modal:           true,
  i18nPrefix:      ['thm.ui.common.ersActivity.EditActivitySpeciesPopup.'],
  autoBindActions: true,
  popupWidth:      500,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();
    me.viewModel.attr('catch', {
      id:           '',
      speciesId:    '',
      weightFished: undefined,
      speciesCatId: '',
      numberFished: undefined
    });

    me.viewModel.attr('title', me.msg('title'));
    me.viewModel.attr('speciesTemplate', SpeciesTplStache);
    me.viewModel.attr('speciesItems', APP('species').getData());

    if (_.isNil(APP('speciesCat'))) {
      SpeciesCat.findAll();
    }

    me.viewModel.on('catch.speciesId', me.proxy(me.onChangeSpecies));
  },

  doBeforeOpen: function () {
    let me         = this,
        catchModel = me.context.item;

    if (!catchModel) {
      catchModel = {
        id:           '',
        speciesId:    '',
        weightFished: undefined,
        speciesCatId: '',
        numberFished: undefined
      };
    }

    me.viewModel.attr('catch').attr({
      id:           catchModel.id,
      speciesId:    catchModel.speciesId,
      weightFished: catchModel.weightFished,
      speciesCatId: catchModel.speciesCatId,
      numberFished: catchModel.numberFished
    }, true);

    me.$el.find('.field-text.error').removeClass('error');

    me.resizePopup();
  },

  onChangeSpecies: function (ev, id) {
    let me                = this,
        currentSpeciesId  = me.viewModel.attr('catch.speciesCatId'),
        speciesCatOptions = [];

    if (!!APP('speciesCat')) {
      speciesCatOptions = _.filter(APP('speciesCat').getData(), function (speCat) {
        return speCat.speciesId === id;
      });
      speciesCatOptions.sort((a, b) => a.code.localeCompare(b.code));
    }

    me.viewModel.attr('catch.speciesCatId', !!_.find(speciesCatOptions, {id: currentSpeciesId}) ? currentSpeciesId : '');
    me.viewModel.attr('speciesCatOptions', speciesCatOptions);
  },

  onSave: function (event) {
    let me              = this,
        catchModel      = me.viewModel.attr('catch').attr(),
        locale          = PREF('locale'),
        selectedSpecies = APP('species', catchModel.speciesId),
        ctx             = me.context,
        item;

    if (!selectedSpecies) {
      Toastr.showError(me.msg('error.noSpeciesSelected'), 5000);
      return;
    }
    catchModel.speciesCode = selectedSpecies.code;
    catchModel.speciesI18n = selectedSpecies.i18n[locale];

    if (!!catchModel.speciesCatId) {
      let selectedSpeciesCat = APP('speciesCat', catchModel.speciesCatId);
      catchModel.speciesCatCode = selectedSpeciesCat.code;
      catchModel.speciesCatI18n = selectedSpeciesCat.i18n[locale];
    }

    switch (ctx.action) {
      case "create" : {
        item = catchModel;
        break;
      }
      case "update" : {

        // IMPORTANT : Modify context instance, do not create a new item
        item = ctx.item;
        item.attr(catchModel);
        break;
      }
    }

    if (_.isNil(catchModel.id)) {
      Toastr.showToastr('error', 'emptyFields');
      return;
    }

    ctx.onSave(ctx.action, item);
    me.close();
  }
});

export default EditActivitySpeciesPopup;
