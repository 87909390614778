import ResourceUI from "core-uda/ui/ResourceUI";
import ExceptionReport from "core-uda/model/exceptionReport/ExceptionReport";
import CheckboxesCriteria from "core-criteria/listCheckbox/CheckboxesCriteria";
import ListCheckboxCriteria from "core-criteria/listCheckbox/ListCheckboxCriteria";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";

/**
 * User Interface elements for ErsQuery
 */
let ExceptionReportUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: ExceptionReport.itemId,
      icon:       "beaconAlert",
      i18nPrefix: "thm.ui.common.ersActivity.ExceptionReportUI."
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "type": I18N_FIELD("uda.exceptionReport.field.kind.")
    }
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'kind':       {
        cmpClass: CheckboxesCriteria,
        resourceName: "exceptionReport",
        optionsKeyPrefix: "uda.exceptionReport.field.kind.",
        validateMandatory: false
      }//,
      // 'technology': {
      //   cmpClass:   ListCheckboxCriteria,
      //   i18nPrefix: 'uda.exceptionReport.technology.'
      // }
    }
  }

});

const EXCEPTION_REPORT_UI = new ExceptionReportUI();

export default ExceptionReportUI;
