import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {THM_PREF} from "thm/ThmPreferences";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import AlertDefinitionSensorStateMonitoringInfoPanelTplStache from "thm/ui/common/alertDefinition/AlertDefinitionSensorStateMonitoringInfoPanelTpl.stache";

/**
 * @class thm.ui.common.alertDefinition.AlertDefinitionSensorStateMonitoringInfoPanel
 * @constructor
 * Afficher les infos d'une alerte de type sensor state monitoring
 * @param {Object} options
 */
let AlertDefinitionSensorStateMonitoringInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'alertDefinitionSensorStateMonitoringInfoPanel',
  child2Tpl:     AlertDefinitionSensorStateMonitoringInfoPanelTplStache,
  timingAllowed: true,

  onSave: function (forceClose) {
    var me        = this,
        dataModel = me.getDataModel();

    if (_.isUndefined(dataModel.watchingDefects)) me.viewModel.attr('dataModel').attr('watchingDefects', false);
    if (_.isUndefined(dataModel.watchingIntrusions)) me.viewModel.attr('dataModel').attr('watchingIntrusions', false);

    me._super(forceClose);
  }

});
export default AlertDefinitionSensorStateMonitoringInfoPanel;
