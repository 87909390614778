<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
    {{#if showClient}}
      <uda-select of="customers" label="" name="customerId" col="6 12" {enabled}="_cmp.creation" {hidden}="isListEditPage"></uda-select>
    {{/if}}
    <field-text name="name" label="" max-length="30" required="true" col="6 12"></field-text>
    <field-textarea name="description" max-length="240" col="12 12"></field-textarea>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
