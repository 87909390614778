<div id="{{id}}" class="page">
    <div class="header-group">
        <h3>
            {{msg 'titleSourceCustomer'}}
        </h3>
    </div>
    <field-item  item="sourceCustomer" class="inlineGroup fields">
        <field-select name="sourceId" {options}="synchonizedSourceOptions" options-value="id" options-text="sourceId" required="true"></field-select>
        <field-list-select name="sourceCustomerId" {items}="sourceCustomersOptions" {item-template}="sourceCustomerTpl"
                           search-fields="company" options-value="id" options-text="company" {enabled}="hasSource" required="true">
            <span>{{value.company}}</span>
        </field-list-select>
        <field-list-select name="sourceLogin" {items}="usersFromCustomerOptions" {item-template}="sourceUserTpl"
                           id-field="login" {enabled}="hasSourceCustomers" required="true">
            <span>{{value.login}}{{#if value.isMain}} / {{msg 'mainUser'}}{{/if}}</span>
        </field-list-select>
        <field-checkbox name="activated"></field-checkbox>
    </field-item>

    <div class="inline-2btn btn-footer">
        <a cls-action="save" class="btn btn-ok">{{msg '_Save'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>