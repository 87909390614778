<div id="{{id}}" class="page L-scrollY has-header">
  <div class="L-scrollY-header header-page">
    <h3>{{msg 'title'}}</h3>
    <div cls-action="close" class="btn btn-header-left ui-icon-arrow-l"></div>
  </div>
  <div id="loadingMetoc" class="{{#unless loadingMetoc}}hidden{{/unless}}">
    {{msg 'loading'}}
    <ui-loading {visible}="loadingMetoc" anchor="bottom-center"/>
  </div>
  <div class="L-scrollY-content {{#if loadingMetoc}}hidden{{/if}}">
    <div class="header-group icon-left icon-m ui-icon-calendar">
      <h2>{{msg 'METOCDate'}}</h2>
    </div>

    <field-item item="metocDate">
      <div class="inlineGroup fields">
        <field-hcombo name="dateType" label="" options="metocDate.dateTypeSelectOptions"
                      col="12 12"></field-hcombo>
        <field-date name="date" format="dateTime" min="{{dateMin}}" max="{{dateMax}}" label="" col="12 12"
                    {visible}="flagShowDateTime"></field-date>
      </div>
    </field-item>

    {{#each families}}
      <div class="header-group icon-left icon-m ui-icon-{{icon}}">
        <h2>{{title}}</h2>
        <field-toggle {(field)}="legendVisible" label="" icon="label" title-key="_ToggleMetocLegend" class="header-right"></field-toggle>
      </div>
      <div class="fields inlineGroup">
        <field-select {(field)}="currentDataId" options="data{{id}}Options"></field-select>
        {{#if currentDataId}}
          {{#if currentData.showDepth}}
            <field-select {(field)}="currentDataDepth" {options}="depthData{{id}}Options" format="pnum" options-sort="false" {visible}="currentData.hasDepth"></field-select>
          {{/if}}
        {{/if}}
      </div>
    {{/each}}
  </div>
</div>
