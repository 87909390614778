<field-text {(field)}="code" format="pint" required="true" required-default-value="1000"></field-text>
<field-checkbox {(field)}="normal" col="12 12"></field-checkbox>
{{#if normal}}
  <field-text {(field)}="normal-trackingFrequencyRate" label-key="trackingFrequencyRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="normal-trackingFrequencyRate-sel" label="" {options}="selectOptionsMin" col="3 3"></field-select>

  <field-text {(field)}="normal-gpsAcquisitionRate" label-key="gpsAcquisitionRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="normal-gpsAcquisitionRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>

  <field-text {(field)}="normal-accelerationSampleRate" label-key="accelerationSampleRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="normal-accelerationSampleRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
{{/if}}

<field-checkbox {(field)}="movement" col="12 12"></field-checkbox>
{{#if movement}}
  <field-text {(field)}="movement-trackingFrequencyRate" label-key="trackingFrequencyRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="movement-trackingFrequencyRate-sel" label="" {options}="selectOptionsMin" col="3 3"></field-select>
  <field-text {(field)}="movement-gpsAcquisitionRate" label-key="gpsAcquisitionRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="movement-gpsAcquisitionRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
  <field-text {(field)}="movement-accelerationSampleRate" label-key="accelerationSampleRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="movement-accelerationSampleRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
{{/if}}

<field-checkbox {(field)}="nogps" col="12 12"></field-checkbox>
{{#if nogps}}
  <field-text {(field)}="nogps-trackingFrequencyRate" label-key="trackingFrequencyRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="nogps-trackingFrequencyRate-sel" label="" {options}="selectOptionsMin" col="3 3"></field-select>
  <field-text {(field)}="nogps-gpsAcquisitionRate" label-key="gpsAcquisitionRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="nogps-gpsAcquisitionRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
  <field-text {(field)}="nogps-accelerationSampleRate" label-key="accelerationSampleRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="nogps-accelerationSampleRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
{{/if}}

<field-checkbox {(field)}="lowbattery" col="12 12"></field-checkbox>
{{#if lowbattery}}
  <field-text {(field)}="lowbattery-trackingFrequencyRate" label-key="trackingFrequencyRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="lowbattery-trackingFrequencyRate-sel" label="" {options}="selectOptionsMin" col="3 3"></field-select>
  <field-text {(field)}="lowbattery-gpsAcquisitionRate" label-key="gpsAcquisitionRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="lowbattery-gpsAcquisitionRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
  <field-text {(field)}="lowbattery-accelerationSampleRate" label-key="accelerationSampleRate" format="pint" col="9 9" class="l70-f30"></field-text>
  <field-select {(field)}="lowbattery-accelerationSampleRate-sel" label="" {options}="selectOptionsSec" col="3 3"></field-select>
{{/if}}
