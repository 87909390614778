<div id="{{id}}" class="page">

    <div class="header-group">
        <h3>{{msg 'title'}}</h3>
    </div>

    {{#each datas}}
        <div>
            <span style="font-weight: bold;">{{key}} :</span> <span style="font-stylet: italic;">{{value}}</span>
        </div>
    {{/each}}

    <ui-submit-cancel {status}="saveStatus" submit-label-key="_Apply" cancel-action="close"></ui-submit-cancel>
</div>