import _ from "core-cmp/lodash";
import Toastr from "core-cmp/Toastr";
import InfoPanel from "core-uda/ui/InfoPanel";
import {APP} from "core-uda/model/Resource";
import Cpc from "core-uda/model/cpc/Cpc";
import CpcInfoPanelTplStache from "thm/ui/common/cpc/CpcInfoPanelTpl.stache";
import "thm/ui/common/cpc/CpcInfoPanel.css";
import CpcResultPopup from "thm/ui/common/cpc/CpcResultPopup";

/**
 * @class CpcInfoPanel
 * Afficher les infos d'un cpc
 */
let CpcInfoPanel = InfoPanel.extend({
  template:        CpcInfoPanelTplStache,
  i18nPrefix:      ['uda.cpc.', 'thm.ui.common.cpc.CpcInfoPanel.'],
  id:              'cpcInfoPanel',
  entityId:        'cpc',
  autoBindActions: true,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr("flagOptions", []);
    me.viewModel.attr("cpcProtocolOptions", []);
    me.viewModel.attr("cpcFormatOptions", []);
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr("flagOptions", _.map(APP("flags").getData(), (flag) => {
      return {
        value: flag.id,
        text:  flag.countryCode
      }
    }));

    me.viewModel.attr("cpcProtocolOptions", _.map(APP("cpcProtocols").getData(), (protocol) => {
      return {
        value: protocol.id,
        text:  protocol.name
      }
    }));

    me.viewModel.attr("cpcFormatOptions", _.map(APP("cpcFormats").getData(), (format) => {
      return {
        value: format.id,
        text:  format.name
      }
    }));

    if (_.isNil(me.data.nature)) {
      me.viewModel.attr("dataModel.nature", "VMS");
    }
  },

  onTestConnection: function () {
    let me       = this,
        protocol = APP("cpcProtocols", me.data.cpcProtocolId);

    if (protocol) {
      Cpc.test(me.data.id, protocol).done((result) => {
        CpcResultPopup.openSingleton({
          title:        me.data.name,
          protocolKind: protocol.kind,
          returnCode:   result.code || '',
          response:     result.response || '',
          error:        result.error || '',
          success:      result.success,
          buttons:  [
            {action: "cancel", label: me.msg('close'), style: "btn-cancel"}
          ],
          onAction: function (action) {
            me.close();
          }
        });
        if (result.success) {
          Toastr.showSuccess(me.msg("testSuccess"), 5000);
        } else {
          Toastr.showError(me.msg("testError"), 5000);
        }
      });
    } else {
      console.error("Error protocol on Cpc, request not send");
      Toastr.showError(me.msg("testError"), 5000);
    }
  }
});

export default CpcInfoPanel;
