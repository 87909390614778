<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

    <field-item item="dataModel" class="L-panel-content inlineGroup fields">
        <field-text name="name" label="" placeholder-key="Name" required="true" max-length="60" col="8"></field-text>
        <field-checkbox name="shared" col="4"></field-checkbox>
      {{#if showUserGroupList}}
          <field-checkbox name="sharedWithGroup" col="6 12"></field-checkbox>
          <field-checkbox name="editableWithinGroup" col="6 12"></field-checkbox>
      {{/if}}
      {{#if hasNumber}}
          <field-text name="number" required="true" col="6 12"></field-text>
      {{/if}}
      {{#if showClient}}
          <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
      {{/if}}
      {{#if hasAnimals}}
          <field-select name="domestication"
                        options="domesticationOptions"
                        option-none="false"
                        col="6 12"></field-select>
          <field-text name="breed"
                      required="false"
                      col="6 12"></field-text>
          <field-select name="migration"
                        options="migrationOptions"
                        option-none="false"
                        col="6 12"></field-select>
          <field-select name="environment"
                        options="environmentOptions"
                        option-none="false"
                        col="6 12"></field-select>
          <field-select name="purpose"
                        options="purposeOptions"
                        option-none="false"
                        col="6 12"></field-select>
          <field-text name="size"
                      required="false"
                      col="6 12"
                      enabled="false"></field-text>
          <field-text name="equiped"
                      required="false"
                      col="6 12"
                      enabled="false"></field-text>
          <field-text name="maleEquiped"
                      required="false"
                      col="6 12"
                      enabled="false"></field-text>
          <field-text name="femaleEquiped"
                      required="false"
                      col="6 12"
                      enabled="false"></field-text>
      {{/if}}

      {{#if hasDescription}}
          <field-textarea name="description" required="false" max-length="200" col="12 12"></field-textarea>
      {{/if}}

        <uda-list of="mobiles"
                  style="height:196px;"
                  field="dataModel.mobiles"
                  label-key="uda.fleet.field.kind.mobileList"
                  format="array"
                  {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId"
                  toolbar-size="3"
                  {visible}="hasMobiles"
                  class="allow-delete-item">
        </uda-list>

        <field-list {(field)}="dataModel.criteria"
                    style="height:196px;"
                    header-icon="search"
                    label-key="uda.fleet.field.kind.criteria"
                    format="items"
                    index-as-id="true"
                    edit-page="{{criteriaEditPageId}}"
                    toolbar-size="3"
                    {toolbar-actions}="criteriaToolbarActions"
                    {item-template}="criteriaTpl"
                    {item-handler}="criteriaHandler"
                    {visible}="hasCriteria"
                    (itemdelete)="_cmp.onDeleteCriteria"></field-list>

        <field-list id="animalList"
                    style="height:196px;"
                    counter="true"
                    search-fields="name"
                    {(field)}="dataModel.mobiles"
                    {items}="animals"
                    format="array"
                    {item-template}="animalTemplate"
                    {item-handler}="animalHandler"
                    label-key="uda.fleet.field.animalList"
                    (itemsrefresh)="onAnimalsRefresh"
                    edit-mode="R"
                    header-icon="paw"
                    {visible}="hasAnimals"></field-list>

        <field-list name="userGroups"
                    style="height:196px;"
                    counter="true"
                    format="items"
                    {(field)}="dataModel.userGroups"
                    {scrollable}="scrollable"
                    {item-template}="userGroupTemplate"
                    edit-mode=""
                    header-icon="user"
                    {visible}="showUserGroupList"></field-list>
    </field-item>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>


