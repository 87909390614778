<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-top-content inlineGroup fields">
    <uda-select of="customers" name="customerId" col="12 12"></uda-select>
    <field-date name="validStartDate" format="date" required="false" col="6 12"></field-date>
    <field-date name="validEndDate" format="date" required="false" col="6 12"></field-date>
  </field-item>

  <uda-list class="inlineGroup L-panel-content"
            of="mobiles"
            field="dataModel.mobiles"
            label-key="uda.sharedVessel.field.mobilesList"
            format="array"
            toolbar-size="3">
  </uda-list>

  <uda-list class="inlineGroup L-panel-content"
            of="zones"
            field="dataModel.zones"
            label-key="uda.sharedVessel.field.zonesList"
            format="array"
            toolbar-size="3">
  </uda-list>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus"></ui-submit-cancel>
</div>


