import Page from "core-cmp/Page";
import _ from "core-cmp/lodash";
import {canMap} from "core-cmp/can";
import EditRestrictionPopupTplStache from "thm/ui/common/customer/EditRestrictionPopupTpl.stache";
import SourceTplStache from "thm/ui/common/customer/SourceTpl.stache";
import MaskFormat from "core-cmp/format/MaskFormat";
import Toastr from "core-cmp/Toastr";

/**
 * Fenêtre d'édition des restrictions.
 */
let EditRestrictionPopup = Page.extend({
  id:                "editRestrictionPopup",
  routerType:        'popup',
  template:          EditRestrictionPopupTplStache,
  i18nPrefix:        ['thm.ui.common.customer.EditRestrictionPopup.', 'thm.ui.common.customer.CustomerInfoPanel.', 'uda.customer.'],
  autoBindActions:   true,
  popupWidth:        450,
  popupHeightMargin: 20,

  /**
   * @override
   */
  initViewModel: function (config) {
    let me = this;

    me._super();

    me.viewModel.attr('restriction', {
      id: '',
      from: undefined,
      to: undefined,
      lastxdays: undefined,
      sources: undefined,
      zones: undefined
    });
    me.viewModel.attr('dateTypeOptions', me.loadDateTypeOptions());
    me.viewModel.attr('dateType', 'noDate');
    me.viewModel.attr('sourceTpl', SourceTplStache);
    me.viewModel.attr('sourceDatas', APP('source').getData());
    me.viewModel.attr('sourcesEditParams', {
      hasListHeader: false,
      title: me.msg('source')
    });
    me.viewModel.on('dateType', function(vm, newValue){
      if (newValue === 'noDate'){
        this.attr('hasBetweenDate', false);
        this.attr('hasLastDaysDate', false);
        this.attr('restriction.from', undefined);
        this.attr('restriction.to', undefined);
        this.attr('restriction.lastxdays', undefined);
      }else if (newValue === 'between'){
        this.attr('hasBetweenDate', true);
        this.attr('hasLastDaysDate', false);
        this.attr('restriction.lastxdays', undefined);
      }else {
        this.attr('hasBetweenDate', false);
        this.attr('hasLastDaysDate', true);
        this.attr('restriction.from', undefined);
        this.attr('restriction.to', undefined);
      }
    });

    MaskFormat.RESTRICTIONZONES_FORMAT = new MaskFormat({
      id:        'restrictionZone',
      mask:      /[\[\]\.\-0-9a-z]/i,
      maskVerif: /^\[((\[(\-)?\d{1,3}(\.\d*)?,(\-)?\d{1,3}(\.\d*)?\],?)+)?\[(\-)?\d{1,3}(\.\d*)?,(\-)?\d{1,3}(\.\d*)?\]\]$/,
      formatType: "string",
      i18nKey: "thm.ui.common.customer.EditRestrictionPopup.error.mask-restrictionZone"
    });
  },

  loadDateTypeOptions: function(){
    let me = this;
    return [{value: "noDate", text: me.msg("noDateOption")},
            {value: "between", text: me.msg("betweenOption")},
            {value: "xLastDays", text: me.msg("xLastDaysOption")}];
  },

  setContext: function (context) {
    let me = this;

    me._super(context);

    // update
    if (context.item){
      me.viewModel.attr('restriction.id', context.item.id);
      me.viewModel.attr('restriction.from', context.item.from);
      me.viewModel.attr('restriction.to', context.item.to);
      me.viewModel.attr('restriction.lastxdays', context.item.lastxdays);
      me.viewModel.attr('restriction.sources', context.item.sources);
      me.viewModel.attr('restriction.zones', context.item.zones);

      if (context.item.from){
        me.viewModel.attr('hasBetweenDate', true);
        me.viewModel.attr('hasLastDaysDate', false);
        me.viewModel.attr('dateType', 'between');
      }else if (context.item.xlastdays){
        me.viewModel.attr('hasBetweenDate', false);
        me.viewModel.attr('hasLastDaysDate', true);
        me.viewModel.attr('dateType', 'xLastDays');
      }else {
        me.viewModel.attr('hasBetweenDate', false);
        me.viewModel.attr('hasLastDaysDate', false);
        me.viewModel.attr('dateType', 'noDate');
      }
    }else { // create
      me.viewModel.attr('restriction.from', undefined);
      me.viewModel.attr('restriction.to', undefined);
      me.viewModel.attr('restriction.lastxdays', undefined);
      me.viewModel.attr('restriction.sources', undefined);
      me.viewModel.attr('restriction.zones', undefined);
      me.viewModel.attr('dateType', 'noDate');
    }
  },

  onSave: function (event) {
    let me            = this,
      restriction = me.viewModel.attr('restriction').attr();

    if (restriction.zones){
      let checkFormat = restriction.zones.match(MaskFormat.RESTRICTIONZONES_FORMAT.maskVerif);
      if (!checkFormat){
        Toastr.showError(me.msg('error.mask-restrictionZone'), 5000);
        return;
      }
    }

    // update
    if (me.context.onSave) {
      me.context.onSave(restriction);
    }

    // create
    if (me.context.setItems) {
      let items = [],
          lastRestriction = _.last(me.context.items),
          newId = _.isUndefined(lastRestriction) ? '0' : (parseInt(lastRestriction.id) +1) + '' ;

      restriction.id = newId;
      items.push(new canMap(restriction));
      me.context.setItems(items);
    }
    me.close();
  }
});

export default EditRestrictionPopup;
