import _ from "core-cmp/lodash";
import Criteria from "core-criteria/Criteria";
import SwitchStache from "thm/ui/common/position/SwitchCriteria.stache";

/**
 * @class core-criteria.checkboxes.SwitchCriteria
 * @parent core-criteria.checkboxes
 * @constructor
 * Gestion de la configuration de la grille.
 * @param {Object} options
 */
let SwitchCriteria = Criteria.extend({}, {
  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;
    me.stache = SwitchStache;
    me._super(config);

    me.criteriaFormModel = {
      switch: "true"
    }

    me.label = me.I18N.msg(me.i18nPrefix + me.name);

  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me        = this,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda = {};

    paramsUda[resourceName + me.name] = me.criteriaFormModel.switch.attr ? me.criteriaFormModel.switch.attr() : me.criteriaFormModel.switch;

    return paramsUda;
  }

});

export default SwitchCriteria;
