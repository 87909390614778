import Page from "core-cmp/Page";
import _ from "core-cmp/lodash";
import Toastr from "core-cmp/Toastr";
import EditSpeciesPopupTplStache from "thm/ui/common/licence/EditSpeciesPopupTpl.stache";

/**
 * Fenêtre d'édition des espèces.
 */
let EditSpeciesPopup = Page.extend({
  id:              "editSpeciesPopup",
  routerType:      'popup',
  template:        EditSpeciesPopupTplStache,
  i18nPrefix:      ['thm.ui.common.licence.EditSpeciesPopup.', 'uda.licence.'],
  autoBindActions: true,
  popupWidth:      300,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    // customerId required for species creation / selection
    me.viewModel.attr('dataModel', {id: '', code: '', name: '', amount: 0, unit: '', customerId:''});
    me.viewModel.attr('family', '');
    me.viewModel.on('dataModel.id', function () {
      // this variable is the viewModel here
      let selectedSpecies = APP('species', this.attr('dataModel.id'));
      if (_.isNil(selectedSpecies)) {
        selectedSpecies = {};
      }
      me.viewModel.attr('dataModel').attr({
        code:   selectedSpecies.code || "",
        name:   selectedSpecies.name || "",
        family: selectedSpecies.family || ""
      });
    });
  },

  doBeforeOpen: function () {
    let me      = this,
        species = me.context.item;

    if (!species && !!APP('species')) {
      species = APP('species').first();
    }

    if (!species) {
      species = {
        id:     '',
        code:   '',
        name:   '',
        family: ''
      };
    }

     me.viewModel.attr("title", me.msg(me.context.params.titleKey));

    me.viewModel.attr('dataModel').attr({
      id:     species.id,
      code:   species.code || "",
      name:   species.name || "",
      amount: species.amount || 0,
      family: species.family || 0,
      unit:   species.unit || ""
    }, true);

    // customerId required for species creation / selection
    me.viewModel.attr("dataModel.customerId", me.context.params.customerId);

    me.$el.find('.field-text.error').removeClass('error');

    me.resizePopup();
  },

  onSave: function (event) {
    let me        = this,
        ctx       = me.context,
        dataModel = me.viewModel.attr('dataModel').attr(), // .attr() important (prevent canjs event error)
        item;

    // customerId required for species creation / selection
    delete dataModel.customerId;

    switch (ctx.action) {
      case "create" : {
        item = dataModel;
        break;
      }
      case "update" : {

        // IMPORTANT : Modify context instance, do not create a new item
        item = ctx.item;
        item.attr(dataModel, true);
        break;
      }
    }

    if (_.isNil(dataModel.id)) {
      Toastr.showToastr('error', 'emptyFields');
      return;
    }

    ctx.onSave(ctx.action, item);
    me.close();
  }
});

export default EditSpeciesPopup;
