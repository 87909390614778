{{pref 'core-uda.ui.view.PrintPage.'}}
<style>
    .print-header-psdkp {
        margin: 0;
        border: 1px solid #ddd;
        text-align: left;
        height: 68px;
    }

    .print-header-psdkp h5 {
        float: left;
        margin: 0;
        line-height: 12px;
    }

    .print-header-psdkp h3 {
        float: left;
        margin: 0;
        line-height: 24px;
        width: 100%;
        padding-top: 10px;
    }

    .print-header {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .rowLeft {
        width: 30%;
    }

    .rowRight {
        width: 70%;
    }

    th {
        font-size: 14px;
        font-weight: normal;
        text-align: left;
    }

    .table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }

    .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
        border: 1px solid #ddd;
    }

    .sign-legend {
        width: 100%;
        height: 20px;
        font-size: 12px;
        text-align: center;
        border-bottom: 1px solid #ddd;
    }

    .sign-content {
        width: 100%;
        height: 60px;
        font-size: 12px;
    }

    .sign-content p {
        line-height: 12px;
        min-height: 12px !important;
        margin: 0;
        position: relative;
        top: 44px;
        text-align: center;
    }

    body {
        max-height: 100%;
        overflow-y: auto;
    }

    .header-left {
        width: 80%;
        height: 100%;
        float: left;
        padding-left: 8px;
        box-sizing: border-box;
    }

    .header-right {
        width: 20%;
        height: 100%;
        float: right;
        padding-right: 8px;
        box-sizing: border-box;
    }

    .header-right #logo {
        background-size: 64px 64px;
        width: 64px;
        float: right;
        background-color: rgba(255, 255, 255, 0.3);
        z-index: 1029;
        height: 64px;
    }

    @media print {
        .print-header-psdkp h3 {
            font-size: 14px !important;
            font-weight: bold !important;
        }

        .print-header-psdkp h5 {
            font-size: 12px !important;
        }
    }
</style>

<div id="{{id}}-container">
    <div class="print-header-psdkp">
        <div class="header-left">
            <h3>FMC</h3>
            <h5>PUSAT PEMANTAUAN PERIKANAN DITJEN PSDKP-KEMENTRIAN KELAUTAN & PERIKANAN</h5>
        </div>
        <div class="header-right">
            <div id="logo" class="{{#unless hideForm}}hidden{{/unless}}">
            </div>
        </div>
    </div>
    <h4 class="print-header" data-ref="print_title"
        data-value="{{#if user.agency}} {{user.agency}} {{/if}}">{{print_title}}{{#if user.agency}}
        - {{user.agency}} {{/if}}</h4>

    <div id="{{id}}-infos" class="{{#unless hideForm}}hidden{{/unless}} ">

        <table class="table table-bordered">
            <tbody>
            <tr>
                <th class="rowLeft">NOMOR :</th>
                <th class="rowRight" data-ref="print_reference">{{print_reference}}</th>
            </tr>
            <tr>
                <th class="rowLeft">TANGGAL :</th>
                <th class="rowRight" data-ref="date">{{date}}</th>
            </tr>
            <tr>
                <th class="rowLeft">OBYEK :</th>
                <th class="rowRight" data-ref="print_type">{{print_type}}</th>
            </tr>
            <tr>
                <th class="rowLeft">SITUASI :</th>
                <th class="rowRight" data-ref="print_reference">{{print_situation}}</th>
            </tr>
            </tbody>
        </table>
        <table class="table table-bordered">
            <tbody>
            <tr>
                <th style="border-top-width:0px;" class="rowCenter"><img id="{{id}}View" src="{{imgSrc}}" style="height:auto;width:100%;"/></th>
            </tr>
            <tr style="height:300px;">
                <th class="rowCenter printContents" data-ref="print_contents">
                    {{#each print_contents}}
                        <p>{{value}}</p>
                    {{/each}}
                </th>
            </tr>
            </tbody>
        </table>
        <table class="table table-bordered">
            <tbody>
            <tr style="height:80px;">
                <th style="border-top-width:0px; width:40%;"></th>
                <th style="border-top-width:0px; width:20%;">
                    <div class="sign-legend">
                        ANALISIS
                    </div>
                    <div class="sign-content">
                        <p data-ref="print_analyst">{{print_analyst}}</p>
                    </div>
                </th>
                <th style="border-top-width:0px; width:20%;">
                    <div class="sign-legend">
                        VERIFIKASI
                    </div>
                    <div class="sign-content">
                        <p data-ref="print_verification">{{print_verification}}</p>
                    </div>
                </th>
                <th style="border-top-width:0px; width:20%;">
                    <div class="sign-legend">
                        MENGETAHUI
                    </div>
                    <div class="sign-content">
                        <p data-ref="print_cognizant">{{print_cognizant}}</p>
                    </div>
                </th>
            </tr>
            </tbody>
        </table>

        <div class="inline-1btn no-print">
          <a id="print-button" class="btn">{{msg '_Print'}}</a>
        </div>
    </div>


    <field-item id="{{id}}-fields" item="dataModel" class="no-print" {hidden}="hideForm">
        <img id="{{id}}-formView" src="{{imgSrc}}" style="display:block;height:35%;margin-left:auto;margin-right:auto;">
        <field-text name="title"></field-text>
        <field-text name="reference"></field-text>
        <field-text name="type"></field-text>
        <field-text name="situation"></field-text>
        <field-textarea name="content"></field-textarea>
        <field-text name="analyst"></field-text>
        <field-text name="verification"></field-text>
        <field-text name="cognizant"></field-text>
        <div class="inline-2btn no-print">
            <a id="{{id}}-save" cls-action="save" class="btn">{{msg '_Save'}}</a>
            <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
        </div>
    </field-item>
</div>
