import _ from "core-cmp/lodash";
import {PREF} from "core-cmp/Preferences";
import InfoPanel from "core-uda/ui/InfoPanel";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import {APP} from "core-uda/model/Resource";
import ErsTripInfoPanelTplStache from "thm/ui/common/ersTrip/ErsTripInfoPanelTpl.stache";
import ExportLogbookPage from "thm/ui/common/ersTrip/ExportLogbookPage";

/**
 * @class ErsTripInfoPanel
 * Afficher les infos d'un voyage
 */
let ErsTripInfoPanel = InfoPanel.extend({
  template:   ErsTripInfoPanelTplStache,
  i18nPrefix: ['uda.ersTrip.', 'thm.ui.common.ersTrip.ErsTripInfoPanel.'],
  id:         'ersTripInfoPanel',
  entityId:   'ersTrip',

  /**
   * @override
   */
  getToolbarActions: function () {
    let me      = this,
        actions = me._super();

    me.addToolbarAction(actions, {
      action:     "logbook",
      icon:       "export",
      label:      me.msg("_Logbook")
    });

    if (RIGHTS('flag') === 'SYC'){
      me.addToolbarAction(actions, {
        action:     "wellplan",
        icon:       "export",
        label:      me.msg("_Wellplan")
      });
    }

    if (RIGHTS('flag') === 'SYC' || RIGHTS('flag') === 'MAU' || RIGHTS('flag') === 'MUS'){
      me.addToolbarAction(actions, {
        action:     "purseseine",
        icon:       "export",
        label:      me.msg("_Purseseine")
      });
    }

    if (RIGHTS('flag') === 'SYC' || (RIGHTS('flag') === 'MUS')){
      me.addToolbarAction(actions, {
        action:     "longline",
        icon:       "export",
        label:      me.msg("_Longline")
      });
    }

    if (RIGHTS('flag') === 'MAU' || RIGHTS('flag') === 'MUS'){
      me.addToolbarAction(actions, {
        action:     "bankfishing",
        icon:       "export",
        label:      me.msg("_Bankfishing")
      });
    }

    return actions;
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.dataModel.attr('disabled', !me.managementMode);
  },

  /**
   * @override
   */
  getTitle: function () {
    let me = this;
    return me.msg('label');
  },

  onLogbook: function () {
    let me = this;
    ExportLogbookPage.openPage({
      tripId: me.viewModel.dataModel.id
    });
  },

  onWellplan: function () {
    let me = this;
    me.downloadXlsxReport("wellplan");
  },

  onPurseseine: function () {
    let me = this;
    me.downloadXlsxReport("purseseine");
  },

  onLongline: function (){
    let me = this;
    if (RIGHTS('flag') === 'SYC') {
      me.downloadXlsxReport("longlinesyc");
    }
    if (RIGHTS('flag') === 'MUS') {
      me.downloadXlsxReport("longlinemus");
    }

  },

  onBankfishing: function () {
    let me = this;
    me.downloadXlsxReport("bankfishing");
  },

  downloadXlsxReport: function (type) {
    let me      = this,
        tripId  = me.viewModel.dataModel.id,
        request = new XMLHttpRequest(),
        params  = 'application=umv' +
          '&format=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    request.open("POST", UDA_URL + "ersTrip/" + tripId + "/" + type, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.setRequestHeader(APP().useJwtToken ? 'jwt' : 'token', APP().user.token);
    request.responseType = 'blob';

    me.viewModel.attr('saveStatus', 0);

    request.onload = function (e) {
      if (this.status === 200) {
        let blob     = this.response,
            fileName = "Logbook_" + _.upperFirst(type) + "-" + PREF().format("date", new Date()).replace(/\//g, "-") + ".xlsx";
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          let downloadLink      = window.document.createElement('a'),
              contentTypeHeader = request.getResponseHeader("Content-Type");

          downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentTypeHeader}));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    };
    request.send(params);
  }
});

export default ErsTripInfoPanel;
