import $ from "thm/thm-jquery";
import {APP} from "core-uda/model/Resource";
import ThmZoneInfoPanel from "thm/ui/common/zone/ThmZoneInfoPanel";
import ZoneItineraryInfoPanelTplStache from "thm/ui/common/zone/ZoneItineraryInfoPanelTpl.stache";

/**
 * @class ZoneItineraryInfoPanel
 * Itinerary form.
 */
let ZoneItineraryInfoPanel = ThmZoneInfoPanel.extend({
  id:       'zoneItineraryInfoPanel',
  childTpl: ZoneItineraryInfoPanelTplStache,

  /**
   * @override
   */
  updateViewModel: function () {
    let me    = this,
        ports = [];
    me._super();
    $.each(APP("zones").filterByKind('port'), function (i, val) {
      ports.push({value: me.getPortId(val.id), text: val.name});
    });

    me.viewModel.attr('ports', ports);
  },

  getPortId: function (val) {
    return val.split('_')[1];
  }
});
export default ZoneItineraryInfoPanel;
