<div id="{{id}}" class="page">
    <div class="header-group icon-left icon-l ui-icon-star">
        <h3>{{msg 'duplicateTitle'}}</h3>
    </div>

    <field-item item="dataModel" class="inlineGroup fields">

        <uda-list id="{{id}}-mobileList"
                    of="mobiles"
                    name="duplicateMobileTo"
                    label-key="_Mobiles"
                    {update-for}="dataModel.context"
                    update-function="filterMobileByCustomerId"
                    class="inlineGroup"></uda-list>

    </field-item>


    <ui-submit-cancel {status}="saveStatus" cancel-action="close"></ui-submit-cancel>

</div>
