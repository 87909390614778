<field-checkbox name="global" {visible}="globalZoneAuthorized"></field-checkbox>
{{#unless dataModel.global}}
    <uda-select of="userZoneTypes"
                name="typeId"
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                create-function="createForCustomer"
                col="6 12"
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                item-none="true"></uda-select>
{{/unless}}

{{#if dataModel.global}}
    <uda-select of="globalZoneTypes"
                name="globalZoneTypeId"
                col="6 12"
                item-none="true"></uda-select>
{{/if}}

<field-switch name="seasonal" text-model="yesNo" col="6 12"></field-switch>

<div class="{{#unless dataModel.seasonal}}hidden{{/unless}} inlineGroup">
    <field-date name="beginDate" max="{{maxDate}}" updatemax="true"
                period="seasonalPeriod" role="start" format="dateTime" col="6 12"></field-date>
    <field-date name="endDate" max="{{maxDate}}" updatemax="true"
                period="seasonalPeriod" role="end" format="dateTime" col="6 12"></field-date>
</div>
