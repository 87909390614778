import _ from "core-cmp/lodash";
import PointUI from "core-uda/ui/PointUI";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import {THM_ALERT_DEFINITION_UI} from "thm/ui/common/alertDefinition/ThmAlertDefinitionUI";
import CreateSarAlertPopup from "core-sar/ui/sarAlert/CreateSarAlertPopup";

/**
 * User Interface elements for Point
 */
let ThmPointUI = PointUI.extend({

  /**
   * @override
   */
  getContextMenuActions: function (context) {
    let me        = this,
        actions = me._super(context);

    actions.push(
      me.getAddSarAlertAction(context),
      THM_ALERT_DEFINITION_UI.getCreateManualAlertAction(context)
    );

    return actions;
  },

  /**
   * Return addSarAlert action.
   * @param {object} context - context object
   * @param {object} context.event - click event
   * @param {string} context.resourceId - resource identifier
   * @param {string} context.from - use case of event (Ex: map, grid, ....)
   * @param {Resource|object} context.data - data (Resource) linked to the clicked element.
   * @param {number} [context.dataIndex] - index of the data in the list of data (Ex: position of trajectory)
   * @param {number} [context.mapXY] - coordinate of data (for geo data only)
   * @param {Page} [context.page] - Page of the clicked element.
   * @param {object} [config] - action config
   * @returns {Array|null} null if no actions defined for resource, empty array if no actions authorized, array of actions otherwise
   */
  getAddSarAlertAction: function (context, config) {
    let me        = this,
        isFromMap = context.from === 'map';

    return _.assign({
      id:         "addSarAlert",
      label:      me.msg("core-sar.ui.CreateSarAlertPopup.title"),
      icon:       "plus",
      authorized: isFromMap && DATA_AUTHORIZED('searchAndRescue.create') && RIGHTS("RCC"),
      handler:    function (ctx, button, ev) {
        CreateSarAlertPopup.openPage(ctx);
      }
    }, config);
  }

});

const THM_POINT_UI = new ThmPointUI();
export default ThmPointUI;
