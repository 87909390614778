{{#if displayMetarSpec}}
  <span class="col-xs-12 text-center" style="white-space: normal;">{{meteoStationRecordMetar.recordRaw}}</span>
  <span class="col-xs-3 icon-m icon-left ui-icon-metoc">{{__format 'temperature' recordData.metar.temperatureSI}}</span>
  <span class="col-xs-3 icon-m icon-left ui-icon-oil">{{__format 'temperature' recordData.metar.dewPointSI}}</span>
  <span class="col-xs-3 icon-m icon-left ui-icon-wind">{{recordData.metar.wind.directionDegrees}}° {{__format 'speed' recordData.metar.wind.speedSI}}</span>
  <span class="col-xs-3 icon-m icon-left ui-icon-view">{{recordData.metar.visibility.mainVisibility}}</span>
{{else}
  <span class="col-xs-12 text-center" style="white-space: normal;">{{msg '_NoData'}}</span>
{{/if}}
{{#if displayTafSpec}}
  <span class="col-xs-12" style="border-top: 1px solid #555;margin-top: 5px;margin-bottom: 5px;"></span>
  <span class="col-xs-12 text-center" style="white-space: normal;padding-top: 10px">{{meteoStationRecordTaf.recordRaw}}</span>
  <span class="col-xs-6 icon-m icon-left ui-icon-wind">{{recordData.taf.wind.directionDegrees}}° {{__format 'speed' recordData.taf.wind.speedSI}}</span>
  <span class="col-xs-6 icon-m icon-left ui-icon-view">{{recordData.taf.visibility.mainVisibility}}</span>
  {{#each recordData.taf.clouds}}
    <span class="col-xs-6 icon-m icon-left ui-icon-cloud">{{cloudsView}}, {{__format 'distance' heightSI}}</span>
  {{else}}
  {{/each}}
  {{else}}
  <span class="col-xs-12 text-center" style="white-space: normal;">{{msg '_NoData'}}</span>
{{/if}}
