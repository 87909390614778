import InfoPanel from "core-uda/ui/InfoPanel";
import {RIGHTS} from "core-uda/Rights";
import Template from "thm/ui/common/fishingProtocol/FishingProtocolInfoPanelTpl.stache";

/**
 * Fishing prtocol form.
 * @class FishingProtocolInfoPanel
 */
let FishingProtocolInfoPanel = InfoPanel.extend({
  template:   Template,
  i18nPrefix: ['uda.fishingProtocol.', 'thm.ui.common.fishingProtocol.FishingProtocolInfoPanel.'],
  id:         'fishingProtocolInfoPanel',
  childTpl:   null,
  entityId:   'fishingProtocol',
  allowWidth: true,
  allowClone: true,
  titleKey:   "title",

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function(dataModel){
    let me = this;

    dataModel.attr({
      name:    me.msg("Clone") + " - " + dataModel.name
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr('tabs', "identification");
    me.viewModel.attr('tabsOptions', me.loadTabsOptions());
    me.viewModel.attr("showClient", RIGHTS("showClient"));
  },

  loadTabsOptions: function () {
    let me = this;
    return [{value: 'identification', text: me.msg('identificationOption'), icon: 'info'},
      {value: 'selection', text: me.msg('selectionOption'), icon: 'edit'}];
  }
});

export default FishingProtocolInfoPanel;
