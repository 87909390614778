import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import SelectStache from "thm/ui/common/position/ThmDateLastPositionsCriteria.stache";
import Criteria from "core-criteria/Criteria";
import DateCriteria from "core-criteria/date/DateCriteria";

/**
 * @class ThmDateMobileCriteria
 */
let ThmDateMobileCriteria = DateCriteria.extend({
  /**
   * @override
   */
  readParameters: function (resource, criteriaConfig, criteriaName) {
    return Criteria.readDefaultParameters(resource, criteriaConfig, criteriaName);
  }
}, {
  dateParamsNoStatistical: "boolean",
  noStatisticalAuthorized: "boolean",
  alwaysLastBetween: "boolean",

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;
    me.stache = SelectStache;
    me._super(config);

    me.dateParams.push({value: 'noStatistical', text: I18N.msg('thm.ui.common.mobile.ThmDateMobileCriteria.noStatistical')});
    me.dateParams[0].text = I18N.msg('thm.ui.common.mobile.ThmDateMobileCriteria.lastDays');
    me.dateParams[1].text = I18N.msg('thm.ui.common.mobile.ThmDateMobileCriteria.between');

    me.criteriaFormModel.noStatistical = "";

    me.criteriaFormModel.on("dateParams", me.proxy(me.changeDateParams));
    me.on("lastDaysAuthorized", me.proxy(me.changeLastDaysDateParams));
    me.on("mostRecentDataAuthorized", me.proxy(me.changeLastDaysDateParams));
    me.on("noStatisticalAuthorized", me.proxy(me.changeLastDaysDateParams));
    me.changeDateParams();
    me.changeLastDaysDateParams();
    me.validateCriteria = true;
    me.alwaysLastBetween = !!config?.alwaysLastBetween;
  },

  /**
   * @override
   */
  setOptions: function () {
    let me = this;
    me._super();
    me.lastPositionsAuthorized = me.options.lastPositions;
  },

  /**
   * @override
   */
  changeLastDaysDateParams: function () {
    let me                = this,
        lastDaysAuth      = me.lastDaysAuthorized,
        mostRecentAuth    = me.mostRecentDataAuthorized,
        noStatisticalAuth = me.noStatisticalAuthorized,
        choices           = ["between"];

    choices = lastDaysAuth ? ["lastDays"].concat(choices) : choices;
    choices = mostRecentAuth ? choices.concat(["mostRecentData"]) : choices;
    choices = noStatisticalAuth ? choices.concat(["noStatistical"]) : choices;
    me.dateParams = choices;
    me.criteriaFormModel.dateParams = lastDaysAuth ? "lastDays" : "between";
  },

  /**
   * @override
   */
  changeDateParams: function () {
    let me        = this,
        from      = me.criteriaFormModel.from,
        to        = me.criteriaFormModel.to,
        dateParam = me.criteriaFormModel.dateParams;


    switch (dateParam) {
      case "lastDays":
        me.dateParamsLastDays = true;
        me.dateParamsBetween = false;
        me.dateParamsNoStatistical = false;
        me.lastType = ["positions", "fishing"];
        break;
      case "between":
        if (from == " ") {
          from = new Date();
        }
        if (to == " ") {
          to = new Date();
        }
        me.criteriaFormModel.from = from;
        me.criteriaFormModel.to = to;
        me.dateParamsLastDays = false;
        me.dateParamsBetween = true;
        me.dateParamsNoStatistical = false;
        me.lastType = ["positions"];
        me.criteriaFormModel.lastType = "positions";
        break;
      case "mostRecentData":
        me.dateParamsLastDays = false;
        me.dateParamsBetween = false;
        me.dateParamsNoStatistical = false;
        me.lastType = ["positions"];
        me.criteriaFormModel.lastType = "positions";
        break;
      case "noStatistical":
        me.dateParamsLastDays = false;
        me.dateParamsBetween = false;
        me.dateParamsNoStatistical = true;
        me.lastType = ["positions"];
        me.criteriaFormModel.noStatistical = true;


        break;
    }

    if (me.allCriteria) {
      let statisticSourcesCriteria = me.allCriteria["statisticSources"];
      if (statisticSourcesCriteria) {
        statisticSourcesCriteria.setVisible(dateParam !== "noStatistical");
        statisticSourcesCriteria.hideCriteriaFirstTime = false;
      }
    }

  },

  /**
   * @override
   */
  getUDAParamsNames: function () {
    let me    = this,
        names = ["since","alwaysLastPos","lastFishingTrips","noStatistical","from","to","lastFishingTrips"];

    if (me.getUDAParamsNamesCustom) {
      names = me.getUDAParamsNamesCustom(names);
    }
    return names;
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this;

    if (params.since) {

      me.dateParamsLastDays = true;
      me.dateParamsBetween = false;
      me.dateParamsNoStatistical = false;
      me.criteriaFormModel.dateParams = "lastDays";
      let hours = (params.since / 3600);
      params.lastDays = hours / 24;
      params.lastHours = hours - params.lastDays * 24;
      me.criteriaFormModel.lastDays = params.lastDays || '';
      me.criteriaFormModel.lastHours = params.lastHours || '';

    } else if (params.from && params.to) {

      me.dateParamsLastDays = false;
      me.dateParamsBetween = true;
      me.dateParamsNoStatistical = false;
      me.criteriaFormModel.dateParams = "between";
      me.criteriaFormModel.from = PREF().fromServerDate(params.from);
      me.criteriaFormModel.to = PREF().fromServerDate(params.to);

    } else if (params.noStatistical){

      me.dateParamsLastDays = false;
      me.dateParamsBetween = false;
      me.dateParamsNoStatistical = true;
      me.criteriaFormModel.dateParams = "noStatistical";

    } else {

      me.dateParamsLastDays = false;
      me.dateParamsBetween = false;
      me.dateParamsNoStatistical = false;
      me.criteriaFormModel.dateParams = "mostRecentData";

    }
  }
})
export default ThmDateMobileCriteria;
