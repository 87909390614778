import _ from "core-cmp/lodash";
import AlertUI from "core-alert/ui/alert/AlertUI";
import {APP} from "core-uda/model/Resource";
import ThmListCriteria from "thm/ui/common/criteria/ThmListCriteria";
import CheckboxesCriteria from "core-criteria/listCheckbox/CheckboxesCriteria";
import AlertImage from "core-alert/model/AlertImage";
import Mobile from "core-uda/model/mobile/Mobile";
import Alert from "core-alert/model/Alert";
import {canList} from "core-cmp/can";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";
import {I18N_OBJECT_FIELD} from "core-uda/ui/ResourceUI";
import TextCriteria from "core-criteria/text/TextCriteria";


/**
 * User Interface elements for Alert
 */
let ThmAlertUI = AlertUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;
    me._super({
      i18nPrefix: "thm.ui.common.alert.ThmAlertUI",
      resourceId: Alert.itemId,
      icon:       "alert"
    });
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "text": I18N_OBJECT_FIELD("uda.ersAlertReport.field.text.")
    }
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    let me = this;
    return _.extend({}, me._super(), {
      "selectBy":   {
        cmpClass: ThmSelectByCriteria,
        options:  ['mobile', 'fleet']
      },
      'kind':       {
        cmpClass:              ThmListCriteria,
        i18nPrefix:            "uda.alertType.",
        resourceName:          "alertTypes",
        kindOptions:           APP("alertTypes").getKindOptions(),
        alertTypeKinds:        APP("alertTypes").kindList.join(","),
        optionsUpdateFunction: "findByKinds"
      },
      "counters":   {
        cmpClass:     CheckboxesCriteria,
        resourceName: "alert"
      },
      "alertZones": {
        cmpClass:          TextCriteria,
        labelKey:          "uda.alert.field.alertZone",
        validateMandatory: false
      }
    });
  },

  /**
   * @override
   *
   * @param data
   * @returns {{}}
   */
  loadForEdition: function (data) {

    let me       = this,
        deferred = new $.Deferred();

    data.images = [];

    AlertImage.findBy({selectBy: "alert:" + data.id}).done((res) => {

      let images = new canList(res);

      images.each(i => i.attr('status', 'database'));


      data.images = images;

      if (data.mobileId) {
        Mobile.findOne(data.mobileId, {}, true).done((mobile) => {
          data.mobile = mobile;
          deferred.resolve(data);
        }).fail((error) => {
          console.error(error);
          data.mobile = undefined;
          deferred.resolve(data);
        });
      } else {
        data.mobile = undefined;
        deferred.resolve(data);
      }


    }).fail((error) => {
      deferred.reject(error);
    });

    return deferred;
  }
});

const THM_ALERT_UI = new ThmAlertUI();

export {THM_ALERT_UI};
export default ThmAlertUI;
