import ResourceUI from "core-uda/ui/ResourceUI";
import HistoricalPosition from "core-uda/model/position/HistoricalPosition";
import ThmDateLastPositionsCriteria from "thm/ui/common/position/ThmDateLastPositionsCriteria";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";
import ThmSelectCpcCriteria from "thm/ui/common/cpcPostData/ThmSelectCpcCriteria";
import Toastr from "core-cmp/Toastr";


/**
 * Position history UI for position history report
 */
let HistoricalPositionUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: HistoricalPosition.itemId,
      i18nPrefix: "thm.ui.common.position.HistoricalPosition.",
      icon:       "position"
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      "date":       {
        udaNames:      ['from', 'to'],
        extraUdaNames: ['since', "lastPositions"],
        cmpClass:      ThmDateLastPositionsCriteria
      },
      "selectBy":   {
        cmpClass:         ThmSelectByCriteria,
        excludeAllOption: true,
        options:          ['mobile']
      },
      "cpcFromIds": {
        cmpClass:    ThmSelectCpcCriteria,
        cmpResource: "cpcFrom"
      }
    };
  },

  isCriteriaValid: function (params) {
    const me = this;

    if (params['selectBy']) {
      const mobiles = params['selectBy'].split(':')[1];

      if (mobiles.split(',').length > 5) {
        Toastr.showError(me.msg('errorMobileNumber'), 5000);
        return false;
      }

      return true;
    } else if (Object.keys(params).length) {
      Toastr.showError(me.msg('errorSelectMobile'), 5000);
      return false;
    }

    return false;
  }

});

const HISTORICAL_POSITION_UI = new HistoricalPositionUI();

export default HistoricalPositionUI;
