<li data-id="{{item.id}}" data-index="{{index}}" class="list-item btn {{stateClass}}">
    <div>
        <div class="dataCustomDisplay">
            {{item.sourceName}} - {{__format 'dateTime' item.lastSynchroDate ''}}
        </div>
        <div class="dataCustomDisplay">
            {{item.sourceCustomerCompany}}
            <div class="btn btn-tool-s icon-l ui-icon-check disabled btn-synchronized {{#if item.activated}}active opacityDisabledButton {{/if}}"></div>
        </div>
        <div class="dataCustomDisplay">
            {{item.sourceLogin}}
        </div>
    </div>
</li>