<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
    <div class="list-field col-sm-12 col-size-xs-12">
        <label class="annotation inlineGroup">{{msg 'distanceToZoneDescription'}} : </label>
    </div>
    <field-select name="operator" options="operatorOptions"></field-select>
    <field-text name="distanceThreshold" format="distance" required="true"></field-text>
</div>
