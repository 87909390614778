<div id="{{id}}" class="page">
    <div id="{{contentId}}" class="msg-mode-read has-header has-footer">

        <div class="L-scrollY-header header-group">
            <h1>{{title}}</h1>
            <a cls-action="back" class="btn btn-header-left ui-icon-arrow-l" title="{{msg '_Close'}}"></a>
            <a cls-action="delete" class="btn btn-header-right ui-icon-delete" title="{{msg '_Delete'}}"></a>
        </div>

        <div class="msg-delete-options fields">
            <field-item item="bindModel">
                <field-checkbox name="deleteAll" label-key="_CheckUncheck"></field-checkbox>
            </field-item>
        </div>

        <div class="L-scrollY-content">
            {{#each messages}}
                {{>MessageTpl}}
            {{/each}}
        </div>

        <div id="{{id}}-footer" class="L-scrollY-footer">
            {{>MessagePageFooterTpl}}
        </div>

        {{#if showDeleteFooter}}
            <div id="{{id}}-deleteFooter" class="L-scrollY-footer inline-2btn">
                <a cls-action="confirmDelete" class="btn btn-ok">{{msg '_Delete'}}</a>
                <a cls-action="cancelDelete" class="btn btn-cancel">{{msg '_Cancel'}}</a>
            </div>
        {{/if}}
    </div>

</div>