<li data-id="{{item.id}}" class="list-item btn zone-ui" {{allowedTitle item "name"}}>
    <div class="icon-l icon-left {{item.getKindIcon()}}">
        <div {{#if item.isFavoriteZone()}}style="position: relative" class="ui-icon-star2 icon-l icon-right"{{/if}}>
            <h3>
              {{#if item.reference}}
                {{item.reference}} -
              {{/if}}
              {{item.name}}
            </h3>

            <p class="icon-s icon-right {{item.getGeometryIcon()}}">
              {{item.getKindLabel()}} - {{item.getGeometryLabel()}}
            </p>
        </div>
    </div>
</li>
