<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-item item="form">
      <field-hcombo name="currentTab" label="" options="tabs" col="12 12"
                    always-editable="true" class="hcombo-tab"></field-hcombo>
    </field-item>
  </ui-page-header>

  <field-item item="dataModel" class="L-panel-content">

    {{! TAB : Identification }}
    <div id="{{id}}-tab-identification" class="tab">

      {{! TAB : Identification / General information}}

      <div id="{{id}}-general" class="inlineGroup fields">
        <field-text name="name"
                    required="true"
                    format="uppercase"
                    col="6 12"></field-text>
        <uda-select of="customers"
                    name="customerId"
                    col="6 12"
                    {enabled}="_cmp.creation"></uda-select>
        <field-checkbox name="active"
                        required="false"
                        col="6 6"></field-checkbox>
        <field-checkbox name="unknown"
                        required="false"
                        col="6 6"></field-checkbox>
        <field-select name="sex"
                      option-none="false"
                      {options}="genderOptions"
                      col="6 12"></field-select>
        <field-select name="importance"
                      option-none="false"
                      {options}="importanceOptions"
                      col="6 12"></field-select>
        <uda-select of="species"
                    name="speciesId"
                    required="true"
                    col="6 12"></uda-select>
        {{#if gregarious}}
          <field-text {(field)}="domestication"
                      format="string"
                      required="false"
                      col="6 12"
                      enabled="false"></field-text>
        {{/if}}
        <uda-select of="externalImages"
                    name="externalImage"
                    option-none="true"></uda-select>
      </div>

      {{! TAB : Identification / Identification}}

      <div class="header-group inline icon-left icon-m ui-icon-help">
        <h2>{{msg 'identification'}}</h2>
      </div>

      <div id="{{id}}-identification" class="inlineGroup fields l50-f50">
        <field-text name="breed"
                    required="false"
                    col="6 12"></field-text>
        <field-date name="birthDate"
                    format="date"
                    required="false"
                    col="6 12"></field-date>
        <field-select name="maturity"
                      option-none="false"
                      {options}="maturityOptions"></field-select>
        <field-text {(field)}="age"
                    format="pnum"
                    required="false"
                    col="6 12"
                    enabled="false"></field-text>
        <field-textarea name="description"
                        label-align="top"
                        required="false"
                        col="12 12"></field-textarea>
      </div>

      {{! TAB : Identification / Herd}}

      <div class="header-group inline icon-left icon-m ui-icon-fleet">
        <h2>{{msg 'herd'}}</h2>
      </div>
      <uda-select of="fleets"
                  name="fleetId"
                  required="true"></uda-select>

      {{! TAB : Identification / Representativity}}

      <div class="header-group inline icon-left icon-m ui-icon-label">
        <h2>{{msg 'representativity'}}</h2>
      </div>

      <field-text name="representativity"
                  format="pnum"
                  required="true"
                  col="6 12"></field-text>

      {{! TAB : Identification / Beacons}}

      <uda-list of="beacons"
                name="beacons"
                scrollable="false"
                counter="true"
                {update-for}="dataModel.id"
                update-function="findAvailableForMobile"
                create-function="createForCustomer"
                {item-template}="beaconsTemplate"
                {edit-page-item-template}="beaconEditTemplate"
                {toolbar-actions}="beaconListToolbarActions"
                toolbar-size="3"
                (itemclick)="onChangeActiveBeacon"
                (itemdelete)="onDeleteBeacon"
                (itemsupdated)="onBeaconUpdated"
                scrollable="false"
                edit-icon="add">
      </uda-list>
      {{! TAB : Identification / Pictures}}

      <div class="header-group inline icon-left icon-m ui-icon-label">
        <h2>{{msg 'pictures'}}</h2>
      </div>

      <div id="{{id}}-image">
        <a id="{{id}}-image-a" class="image-view {{#unless imgSrc}}hidden{{/unless}}" href="{{imgSrc}}"
           data-lightbox="mobileImage">
          <img id="{{id}}-image" src="{{imgSrc}}" alt=""/>
        </a>
        {{#unless imgSrc}}
          {{#if imgName}}
          <label class="inlineGroup" style="padding-left: 2%" name="imgNameNoPic">{{imgName}}</label>
          {{/if}}
        {{/unless}}
      </div>
    </div>

    {{! TAB : Contact}}
    <div id="{{id}}-tab-contacts" class="tab" style="display:none;">
      <div id="{{id}}-specifications" class="inlineGroup fields">
        <field-text name="contactName" required="false" col="12 12"></field-text>
        <field-text name="contactAddress" required="false" col="12 12"></field-text>
        <field-text format="phone-inter" name="telephone" required="false" col="6 12"></field-text>
        <field-text format="phone-inter" name="fax" required="false" col="6 12"></field-text>
        <field-text format="email" name="email" required="false" col="12 12"></field-text>
      </div>
    </div>


    {{! TAB : Events}}
    <div id="{{id}}-tab-events" class="tab L-fit L-panel" style="display:none;">
      <field-list {(field)}="dataModel.events"
                  format="items"
                  edit-page="{{eventEditPage}}"
                  {edit-page-params}="dataModel"
                  {item-template}="eventTpl"
                  header-icon="mobileEvent"
                  edit-icon="add"
                  item-size="xl"
                  toolbar-size="4"
                  export-fields="eventDate,eventTypeName,eventComment"
                  {export-options}="eventExportOptions"
                  class="L-panel-content"></field-list>
    </div>

  </field-item>
  <form id="{{id}}-request-form" class="hidden" action="{{certificateFormModel.url}}" target="_blank" method="post">
    <input type="text" name="token" value="{{certificateFormModel.token}}">
    <input type="text" name="application" value="{{certificateFormModel.application}}">
    <input type="text" name="format" value="{{certificateFormModel.format}}">
    <input type="text" name="template" value="{{certificateFormModel.template}}">
    <input type="text" name="data" value="{{certificateFormModel.data}}">
    <button type='button' id='submitButton'></button>
  </form>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
