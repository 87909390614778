import Toastr from "core-cmp/Toastr";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionZoneInOutInfoPanelTpl.stache";

/**
 * @class AlertDefinitionZoneInOutInfoPanel
 * Alert Definition "Zone In Out" Form.
 */
let AlertDefinitionZoneInOutInfoPanel = AlertDefinitionInfoPanel.extend({
  id:       'alertDefinitionZoneInOutInfoPanel',
  childTpl: ChildStache,

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;

    me.viewModel.attr('periodicPollingAuthorized', RIGHTS("data.alert.periodicPolling"));
    me._super();

  },

  /**
   * @override
   */
  forceDefaultValues: function () {
    let me        = this,
        dataModel = me.getDataModel();

    if (_.isUndefined(dataModel.monitorIn)) {
      me.viewModel.attr('dataModel.monitorIn', false);
    }
    if (_.isUndefined(dataModel.monitorOut)) {
      me.viewModel.attr('dataModel.monitorOut', false);
    }
  },

  /**
   * @override
   */
  checkErrors: function (dataModel) {
    let me = this;

    if (me._super(dataModel)) {
      return true;
    }

    if (!dataModel.zones || dataModel.zones.length === 0) {
      Toastr.showToastr('error', 'alertDefinitionNoZone');
      return true;
    }

    return false;
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me = this;

    let dataModel = me._super();

    if (!dataModel.ppActivated) {
      delete dataModel.ppFrequency;
      delete dataModel.ppNbOfRetry;
      delete dataModel.ppChannel;
    }

    return dataModel;

  }
});
export default AlertDefinitionZoneInOutInfoPanel;
