<li class="list-item btn {{stateClass}}">
  <h3>{{item.speciesCode}} - {{item.speciesI18n}} ({{item.speciesCatI18n}})</h3>
  <p>{{#if item.weightFished}}
        {{__format "weight" item.weightFished true}}
      {{/if}}

      {{#if item.numberFished}}
        / {{item.numberFished}}
      {{/if}}

    {{#if item.areaFullName}}
      / {{item.areaFullName}}
    {{/if}}</p>
</li>
