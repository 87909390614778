<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content-noScroll L-panel">
    <div class="L-panel-header inlineGroup fields">
      <field-text name="name" max-length="128" required="true" col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
      {{#if showClient}}
        <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
      {{/if}}
    </div>
    <uda-list of="zones"
              name="offZoneIds"
              {update-for}="dataModel.customerId"
              update-function="findCustomerOfficialZones"
              class="L-panel-content inlineGroup"></uda-list>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
