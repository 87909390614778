import {THM_PREF} from "thm/ThmPreferences";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionSpeedAnomalyInfoPanelTpl.stache";
import {ALERT_DEFINITION_CONST} from "core-alert/model/AlertDefinition";

/**
 * @class AlertDefinitionSpeedAnomalyInfoPanel
 * Alert Definition "Speed Anomaly" Form.
 */
let AlertDefinitionSpeedAnomalyInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'AlertDefinitionSpeedAnomalyInfoPanel',
  childTpl:      ChildStache,
  timingAllowed: true,

  bindEvents: function () {
    let me = this;
    me._super();
    THM_PREF.bind('unitChanged', function (ev, newVal, oldVal) {
      me.updateViewModel();
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('operatorOptions', ALERT_DEFINITION_CONST.operator);
  }
});
export default AlertDefinitionSpeedAnomalyInfoPanel;
