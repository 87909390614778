<field-select name="typeName" options="typeOptions" col="6 12"></field-select>
<field-switch name="seasonal" text-model="yesNo" col="6 12"></field-switch>

{{#if item.seasonal}}
    <div class="inlineGroup">
        <field-date name="beginDate" updatemax="true"
                    period="seasonalPeriod" role="start" format="dateTime" col="6 12"></field-date>
        <field-date name="endDate" updatemax="true"
                    period="seasonalPeriod" role="end" format="dateTime" col="6 12"></field-date>
    </div>
{{/if}}