<div id="{{id}}" class="{{#if hideCriteriaFirstTime}}hidden{{/if}}">
  <uda-select of="{{name}}"
              field="value"
              label="{{label}}"
              label-align="{{labelAlign}}"
              item-none="{{#if mandatory}}false{{else}}true{{/if}}"
              class="inlineGroup"
              update-function="{{optionsDetails.updateFunction}}">
  </uda-select>
</div>
