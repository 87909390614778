import InfoPanel from "core-uda/ui/InfoPanel";
import SatProvider from "core-uda/model/satProvider/SatProvider";
import template from "thm/ui/common/satProvider/SatProviderInfoPanelTpl.stache";
import "thm/ui/common/satProvider/SatProviderInfoPanel.css";

/**
 * Satellite provider form
 * @class SatProviderInfoPanel
 */
let SatProviderInfoPanel = InfoPanel.extend({
  template:   template,
  i18nPrefix: ['uda.satProvider.', 'thm.ui.common.satProvider.SatProviderInfoPanel.'],
  id:         'satProviderInfoPanel',
  entityId:   SatProvider.itemId,
  allowWidth: true,
  allowClone: true,
  titleKey:   "title",

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function(dataModel){
    let me = this;

    dataModel.attr({
      name:    me.msg("Clone") + " - " + dataModel.name
    });
  }
});

export default SatProviderInfoPanel;
