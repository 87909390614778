<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>
    <field-item item="dataModel" class="L-scrollY-content">
        <div class="inlineGroup fields">
            <field-text name="tripNumber" required="true" max-length="10"></field-text>
            <uda-select of="mobiles"
                        name="mobileId"
                        required="true"
                        {update-for}="dataModel.customerId"
                        update-function="filterByCustomerId"
                        class="inlineGroup"></uda-select>
            <uda-select of="zones"
                        name="itineraryId"
                        label-key="field.itineraryName"
                        icon="route"
                        required="true"
                        {update-for}="dataModel.customerId"
                        update-function="findCustomerItineraryZones"></uda-select>
            <field-date name="estimatedDepartureDate" period="myPeriod" role="start" format="dateTime"></field-date>
            <field-date name="estimatedArrivalDate"  period="myPeriod" role="end" format="dateTime"></field-date>
            <field-text name="speed" format="speed"></field-text>
            <field-text name="speedTolerance" format="speed"></field-text>
            <field-text name="breadth" format="length"></field-text>
            <field-switch name="routeMonitoring"></field-switch>
            <field-switch name="speedMonitoring"></field-switch>
            <field-switch name="progressMonitoring"></field-switch>
            <field-select name="tripState" {options}="tripStateOptions"></field-select>
            <field-text name="idleAtDestinationPort" format="pint"></field-text>
            <field-switch name="cycleTrip"></field-switch>
            <field-date name="realDepartureDate" format="dateTime" disabled="true"></field-date>
            <field-date name="realArrivalDate" format="dateTime" disabled="true"></field-date>
            <field-date name="lastLocationDate" format="dateTime" disabled="true"></field-date>
            <field-text name="distancePercentage" format="%%" disabled="true"></field-text>
            <field-text name="durationPercentage" format="%%" disabled="true"></field-text>
        </div>
    </field-item>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
