<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

    <field-item item="dataModel" class="L-panel-content">

      {{! Status }}

        <div class="header-group icon-left icon-m ui-icon-database hidden-listEditPage">
            <h2>{{msg 'Status'}}</h2>
        </div>
        <div class="inlineGroup fields">
            <field-like label="" title-key="_Mobile" class="icon-l icon-left ui-icon-mobile hidden-listEditPage"
                        col="6 12">
              {{dataModel.mobileName}}
            </field-like>
            <field-like label="" title-key="uda.beacon.field.satelliteProviderName"
                        class="icon-l icon-left ui-icon-vesselDetection hidden-listEditPage" col="6 12">
              {{dataModel.satelliteProviderName}}
            </field-like>
          {{#if showClient}}
            {{#if isNew}}
                <uda-select of="customers"
                            name="customerId"
                            label=""
                            title-key="uda.customer.one"
                            col="6 12"
                            {enabled}="_cmp.creation"
                            {hidden}="isListEditPage"></uda-select>
            {{else}}
                <field-text name="customerCompany" col="6 12" editor-icon="edit" editor-title-key="customer"
                            (editor)="_cmp.onEditionCustomer"></field-text>
            {{/if}}
          {{/if}}
          {{#if contractNumbReq}}
              <field-text name="contractNumber" {visible}="showClient" max-length="30" col="6 12"
                          editor-icon="annotation" editor-title-key="duplicateCustomerContractNumber"
                          (editor)="_cmp.onCustomerContractNumber" required="true"></field-text>
          {{else}}
              <field-text name="contractNumber" {visible}="showClient" max-length="30" col="6 12"
                          editor-icon="annotation" editor-title-key="duplicateCustomerContractNumber"
                          (editor)="_cmp.onCustomerContractNumber"></field-text>
          {{/if}}

        </div>

      {{! Information }}

        <div class="header-group icon-left icon-m ui-icon-info">
            <h2>{{msg 'info'}}</h2>
        </div>
        <div class="inlineGroup fields">
            <field-text name="reference" {disabled}="editRefDisabled" required="true" col="6 12"
                        {min-length}="minLengthReference" {max-length}="maxLengthReference"
                        {format}="formatReference"></field-text>
            <uda-select of="beaconTypes"
                        name="beaconTypeId"
                        update-function="filterBySatelliteProviderName"
                        {update-for}="dataModel.satelliteProviderName"
                        col="6 12"
                        required="true"></uda-select>

            <!-- Inmarsat -->

            <field-text name="inmarsatSerialNumber" {visible}="inmarsatPart" max-length="256" col="6 12"></field-text>
            <field-switch name="inmarsatSubAddress" format="int" required="true" {visible}="inmarsatPart" col="6 12"
                          value-type="number" value-on="1" value-off="0" text-on="1" text-off="0"></field-switch>
            <uda-select of="lesInmcs" name="lesInmcId" {visible}="inmcPart" col="6 12" required="true"></uda-select>
            <field-text name="inmarsatNumber" {visible}="inmarsatPart" format="pint" min-length="9" max-length="9"
                        col="6 12"></field-text>

            <!-- Varmac -->

            <field-text name="password" {visible}="varmacPart" max-length="32" col="6 12"></field-text>
            <field-textarea name="description" max-length="240" col="12 12"></field-textarea>

            <!-- FISHING CONTEXT -->

            <uda-select of="zones" name="portId" item-none="true" icon="port" {visible}="fishingContext"
                        {update-for}="dataModel.customerId"
                        update-function="findCustomerPorts"></uda-select>
            <field-textarea name="installer" {visible}="fishingContext" max-length="30" col="12 12"></field-textarea>
        </div>

      {{! Inmarsat }}

        <div class="header-group icon-left icon-m ui-icon-station {{#unless inmarsatPart}}hidden{{/unless}}">
            <h2>{{msg 'lesInmcSubTitle'}}</h2>
        </div>
        <div class="inlineGroup fields {{#unless inmarsatPart}}hidden{{/unless}}">
            <field-text name="inmarsatDnid" format="int" min="1" max="65535" col="3 12" {visible}="inmarsatPart"
                        required="true" class="l50-f50"></field-text>
            <field-text name="inmarsatMember" format="int" min="1" max="255" col="3 12" {visible}="inmarsatPart"
                        required="true" class="l50-f50"></field-text>
            <field-select name="inmarsatOcean" {options}="inmarsatOceanOptions" options-name="oceanRegion"
                          option-none="true" {visible}="inmarsatPart" required="true" col="6 12"></field-select>
            <field-checkbox {(field)}="inmarsat2" label-key="addInmarsat2" col="12"></field-checkbox>
            <field-text name="inmarsatDnid2" format="int" min="1" max="65535" col="3 12" class="l50-f50"
                        {visible}="inmarsat2"></field-text>
            <field-text name="inmarsatMember2" format="int" min="1" max="255" col="3 12" class="l50-f50"
                        {visible}="inmarsat2"></field-text>
            <field-select name="inmarsatOcean2" {options}="inmarsatOceanOptions" options-name="oceanRegion"
                          option-none="true" col="6 12" {visible}="inmarsat2"></field-select>
        </div>
    </field-item>

    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
