import ThmZoneInfoPanel from "thm/ui/common/zone/ThmZoneInfoPanel";
import ZoneAnnotationInfoPanelTplStache from "thm/ui/common/zone/ZoneAnnotationInfoPanelTpl.stache";

/**
 * @class ZoneAnnotationInfoPanel
 * Annotation form.
 */
let ZoneAnnotationInfoPanel = ThmZoneInfoPanel.extend({
  id:         'zoneAnnotationInfoPanel',
  childTpl:   ZoneAnnotationInfoPanelTplStache
});

export default ZoneAnnotationInfoPanel;
