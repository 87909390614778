import TrajectoryUI from "core-mobile/ui/trajectory/TrajectoryUI";
import { THM_ALERT_DEFINITION_UI } from "thm/ui/common/alertDefinition/ThmAlertDefinitionUI";
import { THM_SENSOR_UI } from "thm/ui/common/sensor/ThmSensorUI";
import TrajectoryTplStache from "core-mobile/ui/trajectory/TrajectoryTpl.stache";
import { I18N } from "core-cmp/util/I18n";
import MobileListCmp from "core-mobile/ui/MobileListCmp";
import { RIGHTS } from "core-uda/Rights";
import { APP } from "core-uda/model/Resource";
import TrajectoryPage from "core-mobile/ui/trajectory/TrajectoryPage"; // Do not delete
import Toastr from "core-cmp/Toastr";
import RightPanel from "core-uda/ui/rightPanel/RightPanel";

/**
 * User Interface elements for ThmTrajectory
 */
let ThmTrajectoryUI = TrajectoryUI.extend({

  /**
   * @override
   */
  init: function (config) {
    let me = this;

    me._super(_.extend({
      template: TrajectoryTplStache
      // handler: me.getItemHandler()
    }, config));
  },

  /**
   * @override
   */
  getContextMenuActions: function (context) {
    let me = this,
      trajectory = context.data,
      customerId = trajectory.mobile.customerId,
      hideGetStatus = context.data.positions && !context.data.positions.length;

    if (hideGetStatus) {
      Toastr.showError(me.msg("_noPositionForStatus"), 2000);
    }

    return me._super(context, hideGetStatus)
      .concat(THM_ALERT_DEFINITION_UI.getContextMenuActionsForType("trajectories", customerId, context))
      .concat([THM_SENSOR_UI.getOpenTimeLineAction(context, trajectory), me.getAddToOpenSaaAction(context)]);
  },

  getAddToOpenSaaAction: function (context) {
    let me = this,
      isAuthorized = false,
      pageId = RightPanel.getEditedResourceId(),
      isOpen = RightPanel.get().isOpen;

    if (isOpen && pageId === 'sarSacs') {
      isAuthorized = true;
    }

    return {
      id: "addToOpenSac",
      label: me.msg("_AddToOpenSac"),
      icon: "route",
      authorized: isAuthorized,
      handler: function (ctx, button, ev) {
        RightPanel.get().getChild('sarSac').addVesselAsSru(ctx.data.mobile);
      }
    }
  },

  /**
   * @override
   */
  getListSelectionPageParams: function () {
    let me = this;
    return _.assign(me._super(), {
      title: I18N.msg('_Mobiles')
    });
  },

  openListSelectionPage: function (config = { editPage: 'trajectoryPage' }) {
    let me = this;

    me._super(config);
  },

  getItemHandler: function () {
    return {
      getItemViewModel: function (item, index) {
        let isTraj = !!item.mobile,
          mobile = item.mobile || item,
          viewModel = MobileListCmp.getMobileViewModel(null, mobile, index),
          count,
          hasPositions,
          hasCollectedDatas,
          hasSensorMessages,
          itemState = "last"/*selection.getItemState(item)*/,
          hasAlerts = false; // TODO : Ajouter les alertes sur la trajectoire

        item = isTraj ? item : APP('trajectory', mobile.id);
        hasPositions = item && item.positions && item.positions.length > 0;
        hasCollectedDatas = item && item.collectedDatas && item.collectedDatas.length > 0;
        hasSensorMessages = item && item.sensorMessages && item.sensorMessages.length > 0;

        if (hasPositions) {
          switch (itemState) {
            case "hide":
              count = 0;
              break;
            case "last":
              count = 1;
              break;
            case "all":
              count = item.positions.length;
              break;
          }
        }

        viewModel.stateClass = /*(item && selection) ? selection.getItemStateClass(item) :*/ "";
        viewModel.alerts = hasAlerts;
        viewModel.collectedDatas = hasCollectedDatas;
        viewModel.sensorMessages = hasSensorMessages;
        viewModel.positions = hasPositions;
        viewModel.count = count;
        viewModel.splitButtonClass = (hasPositions ? "ui-icon-position" : "");
        if (itemState === "none" || !hasPositions) {
          viewModel.icon = "hideVessel";
          viewModel.hasState = false;
        } else {
          viewModel.icon = "center";
          viewModel.hasState = true;
        }

        viewModel.allowTooltip = !!RIGHTS('map.allowTooltip');

        return viewModel;
      }
    }
  }
});

const THM_TRAJECTORY_UI = new ThmTrajectoryUI();
export default ThmTrajectoryUI;
