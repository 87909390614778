<div id="{{id}}" class="{{#if hideCriteriaFirstTime}}hidden{{/if}}">
    <field-item item="criteriaFormModel">
        <field-hcombo id="criteria-selectByType" name="type" label="" {options}="typeOptions"></field-hcombo>

        <uda-list {of}="udaListOf" {name}="udaListName" label-key="uda.cpcPostData.cpc.severalSelected" {visible}="states.cpc"
                  class="inlineGroup"></uda-list>
        <uda-list {of}="udaListOf" {name}="udaListName" label-key="uda.cpcPostData.cpcFrom.severalSelected" {visible}="states.cpcFrom"
                  class="inlineGroup"></uda-list>
    </field-item>
</div>
