import _ from "core-cmp/lodash";
import {RIGHTS} from "core-uda/Rights";
import MetocPage from "core-metoc/ui/MetocPage";
import ThmMetocPageTpl from "thm/ui/umv/metoc/ThmMetocPageTpl.stache";

let ThmMetocPage = MetocPage.extend({
  template:   ThmMetocPageTpl,
  i18nPrefix: ["core-metoc.ui.MetocPage.", "thm.ui.umv.metoc.ThmMetocPage."],


  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this;

    me._super();
    me.viewModel.attr('families', me.metocModel.getFamilyModelList());
  },

  /**
   * @override
   */
  loadFamilies: function () {
    let me = this;

    me.metocModel.families.each(function (familyModel) {
      let options  = [{
            value: null,
            text:  "------------"
          }],
          familyId = _.upperFirst(familyModel.id);
      familyModel.title = me.msg("METOC" + familyId);
      _.each(familyModel.dataList, function (data) {
        options.push({
          value: data.id,
          text:  data.name
        });
      });

      me.viewModel.attr("data" + familyModel.id + "Options", options);
      me.viewModel.attr("depthData" + familyModel.id + "Options", RIGHTS("metoc.authorized.depths") || me.metocModel.defaultDepths);
    });
    me.viewModel.attr('families', me.metocModel.getFamilyModelList());
  }
});

export default ThmMetocPage;
