import $ from "core-cmp/cmp-jquery";
import Page from "core-cmp/Page";
import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {DATA_AUTHORIZED, RIGHTS} from "core-uda/Rights";
import Toastr from "core-cmp/Toastr";
import {TO_ICON_CLASS} from "core-alert/model/AlertTypeModel";
import GeneralAlertBroadcastPageTpl from "thm/ui/common/generalAlertBroadcast/GeneralAlertBroadcastPageTpl.stache";
import GeneralAlertBroadcast from "core-uda/model/generalAlertBroadcast/GeneralAlertBroadcast";
import {UTILS} from "core-cmp/Utils";

const KIND_TO_ICON_CLASS = TO_ICON_CLASS;

let GeneralAlertBroadcastPage = Page.extend({
  template:        GeneralAlertBroadcastPageTpl,
  i18nPrefix:      ['thm.ui.common.generalAlertBroadcast.GeneralAlertBroadcastPage.'],
  id:              'generalAlertBroadcastPage',
  widthPage:       680,
  tabWidth:        2,
  data:            null,
  routerType:      'tab',
  autoBindActions: true,
  initialModel:    {},

  initViewModel: function () {
    let me = this;

    me._super();
    me.viewModel.attr("dataModel", {});
    me.viewModel.attr("existingAlerts", {});
  },

  updateViewModel: function () {
    let me      = this,
        types   = [],
        options = [];

    GeneralAlertBroadcast.getNotifyOptions().forEach((op) => {
      options.push({
        text:  me.msg(op),
        value: op
      });
    });

    //RIGHTS('alertTypes') ne renvoi pas les types d'alertes mais les types de définition d'alerte
    //qui ne sont pas compatibles avec la création d'alerte (ex: ua_zoneInOut et ua_portInOut)
    //on doit donc aller les récupérer sur la ressource UDA alerts/types
    GeneralAlertBroadcast.findAllAlertsTypes().done(res => {
      types = res;
      types = types.filter((type) => {
          return type.match('^(ga_|sa_)((?!ers).)*$'); // return types that are ga_* except ers and ua_
      });
      types = types.map((type) => {
        return {
          name:    type,
          label:   me.msg('uda.alert.field.kind.' + type),
          icon:    "icon-left icon-l ui-icon-" + KIND_TO_ICON_CLASS[type.substring(0, 2)],
          options: options
        };
      });

      types.forEach((t) => {
        me.setOptions(t, APP("generalAlertBroadcast").find((g) => g.alertKind === t.name));
      });
      me.viewModel.attr('alertTypes', types);
      me.initialModel = _.extend({}, me.viewModel.attr('dataModel')._data);
    });
  },

  setOptions: function (params, generalAlertBroadcast) {
    let me            = this,
        options       = [],
        activeOptions = GeneralAlertBroadcast.getActiveOptions(generalAlertBroadcast),
        versionNum    = GeneralAlertBroadcast.getVersionNum(generalAlertBroadcast);

    options = _.map(params.options, (o) => {
      let opt = {value: o.value, text: o.text};
      return opt;
    });

    me.viewModel.attr(params.name, options);
    me.viewModel.attr("dataModel." + params.name, activeOptions);

    if (!_.isNil(versionNum)) {
      me.viewModel.attr("existingAlerts." + params.name, versionNum);
    }
  },

  doAfterOpen: function () {
    let me = this;
    me._super();
    me.updateViewModel();
  },

  onCancel: function () {
    let me = this;
    me.close();
  },

  onValidate: function () {
    let me             = this,
        params         = me.viewModel.attr('dataModel'),
        alertTypes     = Object.keys(params.attr()),
        diff           = UTILS.diff(me.initialModel, me.viewModel.attr('dataModel')._data, {
          numberAsString: true,
          nilAsFalse:     true
        }),
        differences    = _.map(diff.diff, (value, key) => value = key.toString().split('.')[0]),
        existingAlerts = me.viewModel.attr('existingAlerts')._data,
        alertsToCreate = [],
        alertsToUdpate = [],
        deferreds      = [];

    if (diff.count > 0) {
      alertTypes.forEach((type) => {
        if (differences.indexOf(type) !== -1) {
          let alert = _.find(APP("generalAlertBroadcast").getData(), (x) => x.alertKind === type);
          if (!_.isNil(existingAlerts[type])) {
            alert = _.extend(alert, GeneralAlertBroadcast.createGeneralAlertBroadcast(type, params[type]));
            alert.versionNum = existingAlerts[type];
            alertsToUdpate.push(alert);
          } else {
            alert = GeneralAlertBroadcast.createGeneralAlertBroadcast(type, params[type]);
            alertsToCreate.push(alert);
          }
        }
      });

      let deferred;
      alertsToCreate.forEach((alert) => {
        deferred = GeneralAlertBroadcast.create(alert).done((res) => {
          APP("generalAlertBroadcast").add(res);
        });
        deferreds.push(deferred);
      });

      if (alertsToUdpate.length > 0) {
        deferred = GeneralAlertBroadcast.updateAllGeneralAlertBroadcast(alertsToUdpate).done((res) => {
          for (const alertData of res.data) {
            APP("generalAlertBroadcast").set(new GeneralAlertBroadcast(alertData));
          }
        });
        deferreds.push(deferred);
      }

      $.when(...deferreds).done((res) => {
        Toastr.showToastr('success', 'generalAlertUpdateSuccess');
        me.updateViewModel();
      }).fail((err) => {
        Toastr.showToastr('error', 'generalAlertUpdateFail');
      });
    } else {
      Toastr.showToastr('info', 'noChanges');
    }
  }
});

export default GeneralAlertBroadcastPage;
