import ResourceUI from "core-uda/ui/ResourceUI";
import ErsMissingDeclarationReport from "core-uda/model/ersMissingDeclarationReport/ErsMissingDeclarationReport";

import BooleanCriteria from "core-criteria/boolean/BooleanCriteria";

import {I18N} from "core-cmp/util/I18n";

/**
 * User Interface elements for ErsMissingDeclarationReport
 */
let ErsMissingDeclarationReportUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: ErsMissingDeclarationReport.itemId,
      icon: "estimatedPosition",
      i18nPrefix: "thm.ui.common.ersMissingDeclarationReport.ErsMissingDeclarationReportUI.",
      onlyDisplayResultColumns:   [
        'tripId',
        'tripNumber',
        'tripOpenDate',
        'tripOpenDateMillis',
        'tripCloseDate',
        'tripCloseDateMillis'
      ],
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'addTripDetail': {
        cmpClass: BooleanCriteria,
        labelKey: "thm.ui.common.ers.field.addTripDetail"
      }
    }
  }

});

const ERS_MISSING_DECLARATION_REPORT_UI = new ErsMissingDeclarationReportUI();

export default ErsMissingDeclarationReportUI;
