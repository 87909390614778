import Page from "core-cmp/Page";
import _ from "core-cmp/lodash";
import {canMap} from "core-cmp/can";
import {APP} from "core-uda/model/Resource";
import {DATA_CONTEXT} from "core-uda/model/DataContext";
import Toastr from "core-cmp/Toastr";
import Position from "core-uda/model/position/Position";
import CpcPostData from "core-uda/model/cpcPostData/CpcPostData";
import template from "thm/ui/common/position/ThmPositionForwardPopupTpl.stache";

/**
 * Edit mobile life event.
 */
let ThmPositionForwardPopup = Page.extend({
  id:              "thmPositionForwardPopup",
  routerType:      'popup',
  template:        template,
  i18nPrefix:      ['thm.ui.common.position.ThmPositionForwardPopup.', 'uda.position.'],
  autoBindActions: true,
  popupWidth:      400,
  popupHeight:     160,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me   = this;

    me.viewModel.attr('title', me.msg('forwardPositionTitle'));

  },


  onSend: function (evt) {
    let me   = this,
        item = me.context.positions,
        positions = [],
        datas = {};

    datas.cpcId = me.viewModel.attr('dataModel.cpcId');

    _.forEach(item, (pos) => {
      let positionInfo = {};

      positionInfo.locationId = pos.id;
      positionInfo.mobileId = pos.mobileId;

      positions.push(positionInfo);
    });

    datas.locations = positions;

    CpcPostData.transmitPositions(datas).done(() => {
      Toastr.showSuccess(me.msg('forwardSuccess'), 3000);
    }).fail((err) => {
      Toastr.showError(me.msg('fowardFailed', err.statusText, err.status), 3000)
    });

    me.close();
  },


});

export default ThmPositionForwardPopup;
