import _ from "core-cmp/lodash";
import Species from "core-uda/model/species/Species";
import {APP} from "core-uda/model/Resource";
import {PREF} from "core-cmp/Preferences";
import {RIGHTS, DATA_AUTHORIZED} from "core-uda/Rights";
import InfoPanel from "core-uda/ui/InfoPanel";
import {I18N} from "core-cmp/util/I18n";
import template from "thm/ui/common/species/SpeciesInfoPanelTpl.stache";

/**
 * Species form
 * @class SpeciesInfoPanel
 */
let SpeciesInfoPanel = InfoPanel.extend({
  template:   template,
  i18nPrefix: ['uda.species.', 'thm.ui.common.species.SpeciesInfoPanel.'],
  id:         'speciesInfoPanel',
  entityId:   Species.itemId,
  allowWidth: true,
  allowClone: true,
  titleKey:   "title",

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function(dataModel){
    let me = this;

    dataModel.attr({
      name:    me.msg("Clone") + " - " + dataModel.name
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me      = this,
        locales = [];
    me._super();

    me.viewModel.attr("showClient", RIGHTS("showClient"));
    _.each(RIGHTS('locales').split(","), function (locale) {
      locales.push({
        text:  I18N.msg("core-uda.ui.settings.PreferencesPage." + locale),
        value: locale
      });
    });

    me.viewModel.attr("locales", locales);
  },

  /**
   * @override
   */
  loadData: function (data, creation) {
    let me = this;

    if (!data.i18n) {
      data.attr("i18n", {});
    }
    if (!data.gregarious) {
      data.attr("gregarious", false)
    }
    me._super(data, creation);
  }

});

export default SpeciesInfoPanel;
