{{pref 'core-uda.ui.view.PrintPage.'}}
<style>
    @media print {
        html, body {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        @page {
            size: A4 landscape;
            max-height: 100%;
            max-width: 100%;
        }
    }
</style>
<div id="{{id}}-container">
    <div class="header-group">
        <h3>{{user.label}} - {{date}} {{#if user.agency}} - {{user.agency}} {{/if}} {{#user.customerName}} -
            {{user.customerName}} {{/user.customerName}}</h3>
    </div>

    <img id="{{id}}View" src="{{imgSrc}}" class="printedView"/>

    <div id="{{id}}-infos" class="{{#unless hideForm}}hidden{{/unless}} ">
        <h5 id="{{id}}-printTitle" data-ref="print_title">{{print_title}}</h5>
        <hr style="margin:0;">
        <div id="{{id}}-printContents" class="printContents" data-ref="print_contents">
            {{#each print_contents}}
                <p>{{value}}</p>
            {{/each}}
        </div>
        <div class="inline-1btn no-print">
          <a id="print-button" class="btn">{{msg '_Print'}}</a>
        </div>
    </div>
    <field-item id="{{id}}-fields" item="dataModel" class="no-print" {hidden}="hideForm">
        <field-text name="title"></field-text>
        <field-textarea name="content"></field-textarea>
        <div class="inline-2btn no-print">
            <a id="{{id}}-save" cls-action="save" class="btn">{{msg '_Save'}}</a>
            <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
        </div>
    </field-item>
    <div id="imgContainer">
        <div id="logo" class="{{#unless hideForm}}hidden{{/unless}}">
        </div>
    </div>
</div>
