<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-state {(field)}="tabs" options="tabsOptions" options-sort="false" options-col="4" class="tab"></field-state>
  </ui-page-header>

  <field-item item="dataModel" class="L-panel-content">

    <!---------------- TAB identification --------------------->

    <div class="{{#is tabs "identification"}}{{else}}hidden{{/is}}">
      <div class="header-group icon-left icon-m ui-icon-info">
        <h2>{{msg 'identificationOption'}}</h2>
      </div>
      <div class="inlineGroup fields">
        <field-text name="reference" max-length="60" required="true" col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
        {{#if showClient}}
          <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
        {{/if}}
        <field-select name="type" {options}="typeOptions" use-i18n="false" col="6 12"></field-select>
        <uda-select of="flags" label-key="uda.licence.field.flagId" name="flagId" col="6 12"></uda-select>
        <field-text name="issueDelegation" max-length="200" col="6 12"></field-text>
        <field-date name="issueDate" format="date" col="6 12"></field-date>
        <field-date name="beginDate" role="start" period="licenceValidity" format="dateTime" col="6 12"></field-date>
        <field-date name="endDate" role="end" period="licenceValidity" format="dateTime" col="6 12"></field-date>
        <uda-select of="fishingProtocols"
                    name="fishingProtocolId"
                    label-key="uda.fishingProtocol.field.fishingProtocolId" c
                    ol="6 12"
                    {update-for}="dataModel.customerId"
                    update-function="filterByCustomerId"></uda-select>
        <uda-select of="zones"
                    name="portId"
                    col="6 12"
                    item-none="true"
                    {update-for}="dataModel.customerId"
                    update-function="findCustomerPorts"
                    icon="port"></uda-select>
        <field-textarea name="description" max-length="240" col="12 12"></field-textarea>
      </div>
    </div>

    <!---------------- TAB Zones --------------------->

    <div class="L-fit {{#is tabs "zones"}}{{else}}hidden{{/is}}">
      <uda-list of="zones"
                name="zonesIds"
                {update-for}="dataModel.customerId"
                update-function="findCustomerOfficialZones"
                class="L-fit"></uda-list>
    </div>

    <!---------------- TAB Species --------------------->

    <div class="L-fit {{#is tabs "species"}}{{else}}hidden{{/is}}">
      <div class="inlineGroup">
        <field-list name="authorizedSpecies"
                    format="items"
                    edit-mode="CUD"
                    {item-template}="speciesTpl"
                    header-icon="circle-ok"
                    edit-icon="ui-icon-add"
                    {edit-page}="authSpeciesEditPage"
                    {edit-page-params}="editAuthorizedParams"></field-list>

        <field-list name="forbiddenSpecies"
                    format="items"
                    edit-mode="CUD"
                    {item-template}="speciesTpl"
                    header-icon="circle-ko"
                    edit-icon="ui-icon-add"
                    {edit-page}="authSpeciesEditPage"
                    {edit-page-params}="editForbiddenParams"></field-list>

        <field-list name="accessorySpecies"
                    format="items"
                    edit-mode="CUD"
                    {item-template}="speciesTpl"
                    (itemclick)="onClickAuthSpecies"
                    header-icon="grid"
                    edit-icon="ui-icon-add"
                    {edit-page}="authSpeciesEditPage"
                    {edit-page-params}="editAccessoryParams"></field-list>
      </div>
    </div>

    <!---------------- TAB Fishing Gears --------------------->

    <div class="L-fit {{#is tabs "fishingGears"}}{{else}}hidden{{/is}}">
      <uda-list of="fishingGears"
                name="fishingGearsIds"
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                create-function="createForCustomer"
                class="L-fit"></uda-list>
    </div>

  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
