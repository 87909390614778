import _ from "lodash";
import $ from "core-cmp/cmp-jquery";
import Page from "core-cmp/Page";
import Toastr from "core-cmp/Toastr";
import Grid from "core-cmp/Grid";
import {I18N} from "core-cmp/util/I18n";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";
import Mobile from "core-uda/model/mobile/Mobile";
import FishingGear from "core-uda/model/mobile/FishingGear";
import Flag from "core-uda/model/flag/Flag";
import MobileType from "core-uda/model/mobile/MobileType";
import "thm/ui/common/mobileImport/MobileImportPopup.css"
import MobileImportPopupTpl from "thm/ui/common/mobileImport/MobileImportPopupTpl.stache";

let MobileImportPopup = Page.extend({
  id:                      "mobileImportPopup",
  template:                MobileImportPopupTpl,
  routerType:              'popup',
  modal:                   true,
  popupHeight:             '75%',
  popupWidth:              '88%',
  i18nPrefix:              ['thm.ui.common.mobileImport.MobileImportPopup.'],
  autoBindActions:         true,
  useComponentAsViewModel: true,
  csvColumns:              ['internalRef', 'callSign', 'registry', 'name', 'flagCountryCode', 'lengthOverall',
    'tonnage', 'mobileTypeName', 'fishingGearsCodeList'],
  gridConfig:              {
    rowAlternationEnabled: false,
    columnHidingEnabled:   true,
    columnResizingMode:    'nextColumn',
    showBorders:           true,
    allowColumnResizing:   true,
    columnWidth:           160,
    headerFilter:          {
      visible: true
    },
    editing:               {
      mode:          "cell",
      allowUpdating: true
    },
    selection:             {
      allowSelectAll:     true,
      mode:               'multiple',
      selectAllMode:      'allPages',
      showCheckBoxesMode: 'always'
    }
  },
  mobilesList:             [],
  mobilesData:             [],
  mobilesGrid:             undefined,
  editGrid:                undefined,
  lastSelectedIndex:       0,

  initViewModel: function () {
    let me = this;
    me._super();

    me.attr('idField', {});
  },

  doAfterRender: function () {
    let me = this;

    me._super();
  },

  doAfterOpen: function () {
    let me = this;

    me._super();
    me.initMobilesGrid();
    me.initMobiles();
    me.initEditGrid();
  },

  doAfterClose: function (){
    let me = this;

    me.mobilesData = [];
  },

  /**
   * @override
   */
  bindEvents: function () {
    let me = this;
    me._super();

    me.on("idFile", me.onFileSelected.bind(me));

  },

  getMobileGridColumns: function () {
    let me = this;

    return [
      {
        dataField:    'name',
        caption:      I18N.msg('_Name'),
        alignment:    'center',
        dataType:     'string',
        allowEditing: false
      },
      {
        dataField:    'isNew',
        caption:      I18N.msg('thm.ui.common.mobileImport.MobileImportPopup.isNew'),
        alignment:    'center',
        dataType:     'boolean',
        allowEditing: false
      }
    ]
  },

  getEditGridColumns: function () {
    let me = this;

    return [
      {
        dataField:          'update',
        caption:            I18N.msg('thm.ui.common.mobileImport.MobileImportPopup.update'),
        alignment:          'center',
        dataType:           'boolean',
        calculateCellValue: function (rowData) {
          //We preselect internalRef and name because we cannot create a mobile without these fields
          if ((rowData.fieldName === 'internalRef' || rowData.fieldName === 'name') && !rowData.oldValue) {
            rowData.update = true;
          }
          return rowData.update;
        }
      },
      {
        dataField:    'fieldName',
        caption:      I18N.msg('thm.ui.common.mobileImport.MobileImportPopup.fieldName'),
        alignment:    'center',
        dataType:     'string',
        allowEditing: false
      },
      {
        dataField:    'newValue',
        caption:      I18N.msg('thm.ui.common.mobileImport.MobileImportPopup.newValue'),
        alignment:    'center',
        dataType:     'string',
        allowEditing: false
      },
      {
        dataField:    'oldValue',
        caption:      I18N.msg('thm.ui.common.mobileImport.MobileImportPopup.oldValue'),
        alignment:    'center',
        dataType:     'string',
        allowEditing: false
      }
    ]
  },

  initMobilesGrid: function () {
    let me   = this,
        grid = $('#mobiles-grid');

    me.mobilesGrid = new Grid(grid, me.gridConfig);
    me.mobilesGrid.setColumns(me.getMobileGridColumns());
    me.bindGridEvents();
  },

  initMobiles: function () {
    let me   = this,
      deferred  = new $.Deferred();

    APP().trigger('showLoader', [true]);
    Mobile.findWithFishingGears().done(function (data) {
      if (!_.isNil(data)) {
        _.forEach(data, function (mobile) {
          me.mobilesData.push(mobile);
        });
      }
      APP().trigger('showLoader', [false]);
      deferred.resolve();
    });
    return deferred;
  },

  initEditGrid: function () {
    let me     = this,
        grid   = $('#edit-grid'),
        config = _.cloneDeep(me.gridConfig);

    config.selection = {};

    //Fields with different values are highlighted
    config.onRowPrepared = function (e) {
      if (e.rowType !== 'data' || (e.rowType === 'data' && !e.data.newValue)) {
        return
      }

      if (e.data.newValue !== e.data.oldValue) {
        e.rowElement.css('background', 'yellow');
      }
    };

    me.editGrid = new Grid(grid, config);
    me.editGrid.setColumns(me.getEditGridColumns());
  },

  bindGridEvents: function () {
    let me = this;

    me.mobilesGrid.on('rowClick', (canEv, grid, ev) => {
      me.selectMobileRow(ev.rowIndex);
      me.onGridRowClick(grid, ev);
    });

    /**
     * Calls selectMobileRow when a line is selected to be updated when the user applies or saves
     * We have to add the css class another time to the edited line because the library deletes it on line selection
     */
    me.mobilesGrid.option('onSelectionChanged', (canEv) => {
      _.delay(me.selectMobileRow.bind(me), 500)
    });
  },

  /**
   *  Adds a css class to a line so the user understands this one is selected and edited in the right panel
   *
   * @param rowIndex
   */
  selectMobileRow: function (rowIndex) {
    let me  = this,
        index = _.isUndefined(rowIndex) ? me.lastSelectedIndex : rowIndex,
        row = me.mobilesGrid.gridinstance.getRowElement(index);

    if (!_.isUndefined(me.lastSelectedIndex) && me.lastSelectedIndex !== index) {
      me.mobilesGrid.gridinstance.getRowElement(me.lastSelectedIndex).removeClass('selected');
    }

    me.lastSelectedIndex = index;


    row.addClass('selected');
  },

  onGridRowClick: function (grid, ev) {
    let me = this;

    me.editGrid.setDataSource(ev.data.fields);
  },

  onFileSelected: function (ev, files) {
    let me = this;

    // Ignore reset value
    if (files === "") {
      return;
    }

    if (_.isEmpty(files)) {
      Toastr.showError(me.msg("helpMsg"), 4000);
      return;
    }
    me.attr("idFileStatus", 0);

    me.handleFile(files[0])
    .always(me.reset.bind(me))
    .done(me.createMobileList.bind(me))
    .fail((msgKey) => {
      if (_.isString(msgKey)) {
        Toastr.showError(me.msg(msgKey));
      }
    })
  },

  handleFile: function (file) {
    let me = this,
        deferred,
        reader,
        fileReader;

    deferred = new $.Deferred();
    reader = new $.Deferred();
    fileReader = new FileReader();

    fileReader.onload = (evt) => {
      reader.resolve(evt.target.result);
    };
    fileReader.onerror = (evt) => {
      reader.reject();
    };

    reader.fail(() => deferred.reject());

    if (_.endsWith(file.name, ".csv")) {
      fileReader.readAsText(file, "UTF-8");
      reader.then((text) => {
        deferred.resolve(me.fromCsv(text))
      });
      return deferred;
    }

    return deferred.reject("unsupportedFileType");
  },

  fromCsv: function (csvText) {
    let me      = this,
        lines   = csvText.split('\n'),
        mobiles = [];

    _.remove(lines, c => c.match(/^\s*[\r\n]/gm)); // remove empty lines

    lines.forEach((line) => {
      let currentMobile = {},
          lineValues    = line.split(';');

      for (let i = 0; i < me.csvColumns.length; i++) {
        currentMobile[me.csvColumns[i]] = lineValues[i];
      }

      mobiles.push(currentMobile);
    });

    return mobiles;
  },

  reset: function () {
    let me = this;

    me.lastSelectedIndex = 0;
    me.attr("idFileStatus", 1);
    me.attr("idFile", "");
  },

  createMobileList: function (mobileList) {
    let me      = this,
        mobiles = [],
        mergedMobiles     = [],
        mobilesFromApp    = APP('mobiles').getData();

    // fusion des deux tableaux APP(mobiles) et les données des fishingGears en se basant sur l'id des mobiles
    for(let i = 0; i < me.mobilesData.length; i++) {
      mergedMobiles.push({
        ...me.mobilesData[i],
        ...(mobilesFromApp.find((itmInner) => itmInner.id === me.mobilesData[i].id))}
      );
    }

    mobileList.forEach((csvMobile) => {
      let existingMobile     = mergedMobiles.find(m =>
        m.internalRef && m.internalRef.toUpperCase() === csvMobile.internalRef.toUpperCase()),
          alreadyAddedMobile = mobiles.find(m => m.internalRef === csvMobile.internalRef.toUpperCase()),
          currentMobile      = {},
          isDifferent        = !existingMobile;

      currentMobile.fields = [];
      currentMobile.name = existingMobile ? existingMobile.name : csvMobile.name;
      currentMobile.internalRef = existingMobile ? existingMobile.internalRef : csvMobile.internalRef

      currentMobile.isNew = !existingMobile;

      for (let i = 0; i < me.csvColumns.length; i++) {
        let fieldName = me.csvColumns[i],
            field     = csvMobile[fieldName] && csvMobile[fieldName].trim().toUpperCase();

        if (fieldName === 'callSign') {
          field = field.replace(/[^\w\s]/gi, '');
          field = field.replace(/\s/g, '')
        }

        currentMobile.fields[i] = {};
        currentMobile.fields[i].fieldName = fieldName;
        currentMobile.fields[i].newValue = field;

        if (fieldName === 'fishingGearsCodeList') {
          currentMobile.fields[i].oldValue = existingMobile && existingMobile[fieldName]
            ? existingMobile[fieldName].attr()[0] : undefined;
        } else {
          currentMobile.fields[i].oldValue = existingMobile ? existingMobile[fieldName] + '' : undefined;
        }

        currentMobile.fields[i].oldValue =
          currentMobile.fields[i].oldValue === 'undefined' ? '' : currentMobile.fields[i].oldValue;
        currentMobile.fields[i].update = false;

        if (!currentMobile.isNew && currentMobile.fields[i].newValue) {
          isDifferent = isDifferent || currentMobile.fields[i].newValue !== (currentMobile.fields[i].oldValue);
        }
      }

      if (isDifferent && !alreadyAddedMobile) {
        mobiles.push(currentMobile);
      } else if (!!alreadyAddedMobile) {
        mobiles[mobiles.indexOf(alreadyAddedMobile)] = currentMobile;
      }
    });


    me.mobilesList = _.cloneDeep(mobiles);
    me.mobilesGrid.setDataSource(mobiles);
    me.editGrid.setDataSource(mobiles[0].fields);
    _.delay(me.selectMobileRow.bind(me), 1000)
    me.attr('mobiles', mobiles);
  },

  /**
   *  Returns mobiles that have to be updated or created from the selected ones
   *
   * @param selectedMobilesOnly
   * @returns {*[]}
   */
  getUpdatedMobilesList: function (selectedMobilesOnly) {
    let me              = this,
        selectedMobiles = selectedMobilesOnly ? me.mobilesGrid.getSelectedRowsData() : me.mobilesList,
        res             = [];

    selectedMobiles.forEach((selected) => {
      let mobile  = {},
          updated = false;
      for (let i = 0; i < me.csvColumns.length; i++) {
        let fieldName = me.csvColumns[i];

        if (((selected.fields[i].update && selected.fields[i].newValue)
          || (!selected.fields[i].update && selected.fields[i].oldValue)) || !selectedMobilesOnly) {
          mobile[fieldName] = selected.fields[i].update || !selectedMobilesOnly ? selected.fields[i].newValue : selected.fields[i].oldValue;
        }

        updated = selected.fields[i].update || updated;
      }

      // Cannot create a mobile without an internalRef and name
      if (((selected.isNew && mobile.internalRef && mobile.name) || (!selected.isNew && updated)) || !selectedMobilesOnly) {
        res.push(mobile);
      }
    });

    return res;
  },

  /**
   * Delete updated mobiles from the displayed list
   *
   * @param mobiles
   */
  deleteUpdatedMobiles: function (mobiles) {
    let me   = this,
        list = [];

    mobiles.forEach((mobile) => {
      let mobileToDelete = me.mobilesList.find((m) =>
        m.fields[0].update ? m.fields[0].newValue === mobile.internalRef : m.fields[0].oldValue === mobile.internalRef);

      me.mobilesList.splice(me.mobilesList.indexOf(mobileToDelete), 1);
    });

    list = _.cloneDeep(me.mobilesList);
    me.mobilesGrid.setDataSource(list);
    if(list.length > 0){
      me.editGrid.setDataSource(list[0].fields);
      me.selectMobileRow(0);
      me.mobilesGrid.setSelectRowById(0);
    }
  },

  /**
   *  Cancels modification in the displayed list
   */
  onCancel: function () {
    let me = this;

    me.close();
  },

  cancelChanges: function () {
    let me   = this,
        list = _.cloneDeep(me.mobilesList);

    me.mobilesGrid.setDataSource(list);
    me.editGrid.setDataSource(list[0].fields);
    me.selectMobileRow( 0);

    me.mobilesGrid.setSelectRowById(0);
  },

  /**
   *  Apply changes to the import list
   */
  onApply: function () {
    let me              = this,
        mobilesToUpdate = me.getUpdatedMobilesList(true);

    me.importMobiles(mobilesToUpdate).done(() => {
      me.lastSelectedIndex = 0;
    });
  },

  onValidate: function () {
    let me              = this,
        mobilesToUpdate = me.getUpdatedMobilesList(true);

    me.importMobiles(mobilesToUpdate).done(() => {

      if (me.mobilesList.length) {
        ConfirmationPopup.openSingleton({
          title:    me.msg('titleConfirmation'),
          text:     me.msg('textConfirmation'),
          buttons:  [
            {action: "yes"},
            {action: "no"}
          ],
          onAction: function (action) {
            if (action === "yes") {
              mobilesToUpdate = me.getUpdatedMobilesList(false);

              me.importMobiles(mobilesToUpdate, true).done(() => {
                me.close();
              });
            } else {
              me.close();
            }
          }
        });
      } else {
        me.close();
      }

    });

  },

  importMobiles: function (mobilesToUpdate, importAll) {
    let me       = this,
        deferred = new $.Deferred();

    if (mobilesToUpdate.length) {
      APP().trigger('showLoader', [true]);

      me.formatMobiles(mobilesToUpdate);
      console.log(mobilesToUpdate);

      Mobile.importMobiles(mobilesToUpdate).done(() => {
        me.refreshAllData().done(() => {
          APP().trigger('showLoader', [false]);

          me.deleteUpdatedMobiles(mobilesToUpdate);

          Toastr.showSuccess(me.msg('selectedMobilesImported'), 3000);
          deferred.resolve();
        });
      }).fail((err) => {
        APP().trigger('showLoader', [false]);
        console.error('Mobiles import error ::: ' + err);
        Toastr.showError(me.msg('importingError'), 3000);
        me.onCancel();
        deferred.reject();
      });

    } else {
      Toastr.showWarn(me.msg('noMobilesSelected'), 3000);
      deferred.resolve();
    }

    return deferred;
  },

  refreshAllData: function () {
    let me        = this,
        deferred  = new $.Deferred(),
        deferreds = [];

    deferreds.push(Mobile.refreshAll());
    deferreds.push(Flag.refreshAll());
    deferreds.push(FishingGear.refreshAll());
    deferreds.push(MobileType.refreshAll());

    $.when(...deferreds).always(() => {
      APP().trigger('refreshGrid');
      deferred.resolve();
    });

    return deferred;
  },

  formatMobiles: function (mobiles) {
    let me = this;

    mobiles.forEach((m) => {
      m.kind = 'vessel';
      m.broadcastMode = 0;

      if (m.flagCountryCode) {
        m.flag = m.flagCountryCode;
      }

      if (m.fishingGearsCodeList) {
        m.fishingGearsNameList = [m.fishingGearsCodeList];
        delete m.fishingGearsCodeList;
      }
    });
  }

});

export default MobileImportPopup;
