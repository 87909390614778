<div id="{{id}}" class="page">

    <div class="header-group">
        <h1>{{title}}</h1>
    </div>
    <div class="inlineGroup fields">
      <field-file {(field)}="file" {status}="fileStatus" accept-extentions="dat,xml"></field-file>
    </div>

    <ui-submit-cancel {status}="saveStatus" cancel-action="close" submit-label="{{msg '_Import'}}"></ui-submit-cancel>

</div>
