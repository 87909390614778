import _ from "core-cmp/lodash";
import {I18N} from "core-cmp/util/I18n";
import Criteria from "core-criteria/Criteria";
import RecipientCriteriaStache from "thm/ui/common/criteria/ThmRecipientCriteria.stache";
import "core-criteria/selectBy/SelectByCriteria.css";

/**
 * @class ThmRecipientCriteria
 */
let ThmRecipientCriteria = Criteria.extend({}, {

  typeOptions: "array",
  states:      "observable",
  resourceMap: "observable",

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;

    if (!me.stache) {
      me.stache = RecipientCriteriaStache;
    }

    me._super(config);

    me.validateMandatory = false;

    me.criteriaFormModel = {
      type:    'all',
      ersTrip: []
    };

    me.typeOptions = [{
      value: "all",
      text:  I18N.msg("uda.recipient.all")
    },{
      value: "recipients",
      text:  I18N.msg("uda.recipient.selectionRecipient")
    }];

    me.states = {};
    me.criteriaFormModel.on("type", me.proxy(me.changeType));
    me.changeType();
  },


  changeType: function () {
    let me   = this,
        type = me.criteriaFormModel.type;

    me.states.set("recipients", type === "recipients");
  },

  /**
   * @override
   * Au moment de l'envoie de la requete, il faut determiner comment la construire
   */
  formatModelToUDA: function () {
    let me = this,
        params    = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda = {},
        type      = params.type;

    if (me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(params);
    } else {
      if (type !== "all" && !_.isEmpty(me.criteriaFormModel[type])) {
        let ids = me.criteriaFormModel[type].join(",");
        paramsUda[resourceName + me.name] = ids;
      }
    }

    return paramsUda;
  },


  /**
   * @override
   * Si des paramettres ont été rentré precedemment il faut les remettre.
   */
  setParams: function (params) {
    let me = this;

    me.criteriaFormModel.type = 'all';
    if (params[me.name]) {
      if (!_.isNil(me.states[me.name])) {
        me.criteriaFormModel.type = me.name;
        me.criteriaFormModel[me.name] = [params[me.name]];
      }
    }
  }

});

export default ThmRecipientCriteria;
