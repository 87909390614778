import InfoPanel from "core-uda/ui/InfoPanel";
import MobileTypeInfoPanelTplStache from "thm/ui/common/mobile/MobileTypeInfoPanelTpl.stache";
import MobileType from "core-uda/model/mobile/MobileType";

/**
 * Mobile type form.
 * @class MobileTypeInfoPanel
 */
let MobileTypeInfoPanel = InfoPanel.extend({
  template:   MobileTypeInfoPanelTplStache,
  i18nPrefix: ['uda.mobileType.', 'thm.ui.common.mobile.MobileTypeInfoPanel.'],
  id:         'mobileTypeInfoPanel',
  entityId:   MobileType.itemId,
  titleKey:   "title",

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    let me = this;
    return me.data.customerCompany + ' - ' + me.data.type;
  }
});

export default MobileTypeInfoPanel;
