import _ from "core-cmp/lodash";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import $ from "thm/thm-jquery";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionReportOverdueInfoPanelTpl.stache";

/**
 * @class AlertDefinitionReportOverdueInfoPanel
 *Alert Definition "Report overdue" Form.
 */
let AlertDefinitionReportOverdueInfoPanel = AlertDefinitionInfoPanel.extend({
  id:       'alertDefinitionReportOverdueInfoPanel',
  childTpl: ChildStache,

  updateViewModel: function () {
    let me = this,
        dataModel;

    me._super();

    dataModel = me.getDataModel();
    if (!!dataModel.triggerThresholds && dataModel.triggerThresholds.length > 0) {
      //console.log(dataModel.triggerThresholds);
      _.each(dataModel.triggerThresholds, function (val, i) {
        me.viewModel.attr('dataModel.trigger' + (i + 1), val);
      });
    }

    me.updateOriginalValue();
  },

  //override
  getDataModelForSave: function () {
    let me        = this;
    let dataModel = me._super();

    //TRIGGER ONE TWO THREE
    dataModel.triggerThresholds = [$.isTrue(dataModel.trigger1) + "", $.isTrue(dataModel.trigger2) + "", $.isTrue(dataModel.trigger3) + ""];
    delete dataModel.trigger1;
    delete dataModel.trigger2;
    delete dataModel.trigger3;
    delete dataModel.zoneId;

    return dataModel;
  }
});
export default AlertDefinitionReportOverdueInfoPanel;
