<div id="{{id}}" class="page ">
   <div class="header-group">
      <h1>{{msg 'title'}}</h1>
      <a cls-action="close" class="btn btn-header-right ui-icon-close" title="{{msg '_Close'}}"></a>
   </div>
   <div class="fields inlineGroup">
      <field-select label="" {(field)}="reportTemplate" {options}="reportTemplateOptions" col="12"></field-select>
      <field-file label="" {(field)}="file" accept-extentions="docx" min="1" max="1"></field-file>
   </div>
   <div class="inline-btn">
      <ui-submit {status}="submitStatus"></ui-submit>
   </div>
</div>