import ResourceUI from "core-uda/ui/ResourceUI";
import ErsTrip from "core-uda/model/ersTrip/ErsTrip";
import ExportLogbookPage from "thm/ui/common/ersTrip/ExportLogbookPage";
import {I18N} from "core-cmp/util/I18n";
import template from "thm/ui/common/ersTrip/ThmErsTripUITpl.stache";
const EXPORT_LOGBOOK_ACTION = (context, button) => {
  ExportLogbookPage.openPage({
    tripId: context.data.id
  });
};

/**
 * User Interface elements for ErsQuery
 */
let ErsTripUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: ErsTrip.itemId,
      icon:       "route",
      template:   template,
      i18nPrefix: "thm.ui.common.ersActivity.ErsTripUI."
    })
  },

  /**
   * @override
   */
  getUreContextMenuActions: function(context){
    const isRowClick =  context.type === "data";

    return [
      {id: "logbook", icon: "export", labelKey: "_Logbook", isAuthorized : isRowClick, handler : EXPORT_LOGBOOK_ACTION}
    ]
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {}
  },

  /**
   * @override
   */
  getUreMasterDetailResources: function () {
    return [
      "ersOperationReport",
      "ersActivity",
      "ersAlertReport"
    ]
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    let me       = this;
    return {
      "ersAlertTypes": {
        format: (values) => {
          if(values != undefined){
            let alerts = [];
            values.forEach(e => alerts.push(me.msg('uda.alert.field.kind.' + e)));
            return alerts.join('<br/>');
          }
          return values;
        },
        dataType: 'string',
        encodeHtml: false
      }
    }
  }

});

const ERS_TRIP_UI = new ErsTripUI();

export default ErsTripUI;
