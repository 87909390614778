<li data-id="{{item.id}}" data-index="{{index}}" class="list-item btn {{stateClass}}">
    <div>
        <div class="item-custom">{{__format 'dateTime' item.eventDateMillis true}} - {{item.modificationUser}} </div>
        <div class="item-custom">
            {{item.eventTypeName}}
        </div>
        <div class="item-custom {{#if item.eventComment}}disabled{{/if}}">
            {{item.eventComment}}
        </div>
    </div>
</li>
