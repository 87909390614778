import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import _ from "core-cmp/lodash";

/**
 * @class AlertDefinitionPortInOutInfoPanel
 * Alert Definition "Exception" Form.
 */
let AlertDefinitionExceptionInfoPanel = AlertDefinitionInfoPanel.extend({
  id:         'alertDefinitionExceptionInfoPanel',
  i18nPrefix: ['thm.ui.common.alertDefinition.AlertDefinitionInfoPanel.', 'uda.alertDefinition.',
    'thm.ui.common.beaconModel.CommandPeriodicPolling.', 'uda.alert.field.kind.'],
  childTpl:   null,

  updateViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('beaconEventsOptions', me.prepareBeaconEventTypesOptions(RIGHTS('beaconEventTypes').split(',')));
  },

  onSelectAll: function() {
    let me = this,
        nbSelected = 0,
        beaconEvents = {},
        newValue = true;
    _.each(me.viewModel.attr('beaconEventsOptions'), (cur) => {
      nbSelected += me.viewModel.attr("dataModel.beaconEvents." + cur.value) ? 1 : 0;
    });
    newValue =( nbSelected != me.viewModel.attr('beaconEventsOptions').length);
    _.each(me.viewModel.attr('beaconEventsOptions'), (cur) => {
      beaconEvents[cur.value]= newValue;
    });
    me.viewModel.attr("dataModel.beaconEvents", beaconEvents);
  },

  prepareBeaconEventTypesOptions: function (beaconEventTypes) {
    let me = this;

    return _.sortBy(beaconEventTypes.map((eventType) => {
      return {
        value: eventType,
        text:  me.msg(eventType)
      }
    }), "text");
  }
});
export default AlertDefinitionExceptionInfoPanel;
