import _ from "core-cmp/lodash";

import {PREF} from "core-cmp/Preferences";
import {APP} from "core-uda/model/Resource";
import {RIGHTS} from "core-uda/Rights";
import ResourceUI from "core-uda/ui/ResourceUI";
import MobileType from "core-uda/model/mobile/MobileType";
import MobileData from "core-uda/model/mobile/MobileData";


let ThmMobileData = MobileData.extend({

  /**
   * @override
   **/
  getMobileLabelForData: function (data) {
    let me     = this,
        mobileName = data[me.getMobileToData('name')];

    if (RIGHTS("data.positionsCriteria.historic")
      && PREF().historicVesselDetails
      && mobileName
      && mobileName.length > 0) {
      return mobileName;
    }

    return me._super(data);
  },

  /**
   * @override
   * Retourne le libellé du mobile.
   * @param {Object} mobile - mobile.
   * @return libellé.
   **/
  getMobileLabel: function (mobile, mobileName) {
    let me = this;

    if (RIGHTS("data.positionsCriteria.historic")
      && PREF().historicVesselDetails
      && mobileName !== undefined
      && mobileName.length > 0) {
      return mobileName.replace('<', '').replace('>', '');
    }

    if (!mobile) {
      return "???";
    }
    return mobile[me._getMobilePref(1)]
      || mobile[me._getMobilePref(2)]
      || mobile[me._getMobilePref(3)]
      || mobile["name"]
      || "???";
  }
});

const THM_MOBILE_DATA = new ThmMobileData();

export {THM_MOBILE_DATA};
export default ThmMobileData;
