import _ from "core-cmp/lodash";
import UdaPlugin from "core-uda/UdaPlugin";
import MeteoStationRecordMapUpdater from "thm/service/map/MeteoStationRecordMapUpdater";
import MeteoStationRecordHandler from "thm/service/info/meteoStationRecord/MeteoStationRecordHandler";
import MeteoStationRecordStyleEngine from "thm/service/style/meteoStationRecord/MeteoStationRecordStyleEngine";
import MeteoStationRecordModel from "thm/model/meteoStationRecord/MeteoStationRecordModel";

/**
 * Add support of driftMat.
 */
let MeteoStationRecordPlugin = UdaPlugin.extend({
  id: "meteoStationRecord",

  /**
   * Constructor.
   * @param config
   */
  init: function (config) {
    let me = this;

    APP().setModel(new MeteoStationRecordModel());

    me._super(_.extend({
      styleModels:          [MeteoStationRecordStyleEngine],
      mapUpdaters:          [MeteoStationRecordMapUpdater],
      infoHandlers:         [MeteoStationRecordHandler]
    }, config));
  }

});

export default MeteoStationRecordPlugin;
