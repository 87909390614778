import ResourceUI from "core-uda/ui/ResourceUI";
import AuditLog from "core-uda/model/auditLogs/AuditLog";
import CheckboxesCriteria from "core-criteria/listCheckbox/CheckboxesCriteria";
import TextCriteria from "core-criteria/text/TextCriteria";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";



/**
 * Audit Log Interface elements for AuditLog
 */
let AuditLogUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: AuditLog.itemId,
      i18nPrefix: "thm.ui.common.auditLog.AuditLogUI.",
      icon:       "user"
    })
  },

   /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "entityAttribute": I18N_FIELD("uda.auditLog.field.resourceField."),
      "message": I18N_FIELD("uda.auditLog.field.message."),
      "entityKind": I18N_FIELD("uda.auditLog.field.entityKind.")
    };
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
        "entities": {
          cmpClass: CheckboxesCriteria,
          resourceName: "auditLog",
          optionsKeyPrefix: "uda.auditLog.field.entityName.",
          validateMandatory: false
        },
        "changedBy": {
          cmpClass: TextCriteria,
          labelKey: "uda.auditLog.changedBy.label",
          validateMandatory: false
        }
    };
  },


});

const AUDIT_LOG_UI = new AuditLogUI();

export default AuditLogUI;
