import _ from "core-cmp/lodash";
import {RESOURCE_CLASS} from "core-uda/model/Resource";
import Page from "core-cmp/Page";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";
import "core-ure/ui/favorite/AddFavoritePopup.css";
import MobileDuplicatePopupTplStache from "thm/ui/common/mobile/MobileDuplicateSsasContactPopup.stache";
import SsasVessel from "core-uda/model/ssas/SsasVessel";
import Toastr from "core-cmp/Toastr";

/**
 * @class MobileDuplicateSsasContactPopup
 */
let MobileDuplicateSsasContactPopup = Page.extend({
  id:                  "mobileDuplicatePopup",
  routerType:          'popup',
  popupWidth:          600,
  modal:               true,
  template:            MobileDuplicatePopupTplStache,
  i18nPrefix:          "thm.ui.common.mobile.MobileDuplicateSsasContactPopup.",
  authorizedMode:      [],
  autoBindActions:     true,
  localValuesLocation: {
    "mobiles": '#mobileDuplicatePopup-mobileList field-list'
  },

  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this;
    if (!!me.context) {
      me.viewModel.attr('dataModel.context', me.context.vessel.customerId + "/" + me.context.vessel.id);
    }
  },

  /**
   * @override
   */
  doBeforeClose: function () {
    let me = this;
    me._super();
    me.viewModel.attr('dataModel.duplicateMobileTo', []);
  },

  onSubmit: function (event) {
    let me = this;

    ConfirmationPopup.openPage({
      title:    me.msg('alert'),
      text:     me.msg('duplicateInfo', me.context.vessel.name),
      buttons:  [
        {action: "apply"},
        {action: "cancel"}
      ],
      onAction: function (action) {
        let tasks = [];
        if (action === 'cancel') {
          me.close();
        } else {
          me.viewModel.attr('saveStatus', 0);
          _.forEach(me.viewModel.attr('dataModel.duplicateMobileTo'), (mobileId) => {
            tasks.push(me.createTask(mobileId));
          });

          // we wait the end of all tasks, if any to
          $.when(...tasks).done(() => {
            me.viewModel.attr('saveStatus', 1);
            me.close();
          }).fail(() => {
            me.viewModel.attr('saveStatus', -1);
            Toastr.showError(me.msg('failureDuplicate'), 5000);
            me.close();
          });
        }
      }
    });
  },

  createTask: function(mobileId) {
    let me = this,
    deferred = new $.Deferred();

    SsasVessel.findOne(mobileId).done((ssasVesselData) => {
      let ssasVesselInfo = me.compareSsasVessel(me.context.vessel.ssasVessel.attr(), ssasVesselData.attr());
      if (ssasVesselInfo.changes) {
        me.save(ssasVesselInfo.ssasVessel).done(() => {
          deferred.resolve();
        }).fail(() => {
          deferred.reject();
        });
      } else {
        deferred.resolve();
      }
    }).fail(() => {
      let mobile = me.getLocal("mobiles", mobileId);
      Toastr.showError(me.msg('noSsasVessel', mobile.name), 5000);
      me.viewModel.attr('saveStatus', -1);
      deferred.reject();
    });

    return deferred;
  },

  compareSsasVessel: function (ssasVesselFrom, ssasVesselTo) {
    let me                = this,
        changes           = false,
        keyList           = ["contact1Id", "contact2Id", "contact3Id"],
        ssasVesselRequest = {id: ssasVesselTo.id, themisVesselId: ssasVesselTo.themisVesselId};

    _.forEach(keyList, (key) => {
      if (ssasVesselTo[key] !== ssasVesselFrom[key]) {
        ssasVesselRequest[key] = ssasVesselFrom[key];
        changes = true;
      } else {
        ssasVesselRequest[key] = ssasVesselTo[key]
      }
    });

    //We want to keep vessel data, useful if you want to save
    Object.keys(ssasVesselTo).forEach(function (key) {
      if (!(key in ssasVesselRequest)) {
        ssasVesselRequest[key] = ssasVesselTo[key];
      }
    });

    return {changes: changes, ssasVessel: ssasVesselRequest}
  },

  save: function (data) {
    let me   = this,
        item = me.createSaveItem(data);

    return me.saveItem(item);
  },

  createSaveItem: function (data) {
    let me = this;
    return new RESOURCE_CLASS["ssasVessel"](data);
  },

  saveItem: function (ssasVessel) {
    let me = this,
        deferred;

    deferred = new $.Deferred();
    me.saveSsasVessel(ssasVessel).done((ssasVesselData) => {
      deferred.resolve();
    }).fail(() => {
      deferred.reject();
    });

    return deferred;
  },


  saveSsasVessel: function (ssasVessel) {
    let me = this;

    return ssasVessel.save().done(() => {
      Toastr.showSuccess(me.msg('successDuplicate'), 5000);
    }).fail(() => {
      Toastr.showError(me.msg('failureDuplicate'), 5000);
    });

  },

  onCancel: function () {
    let me = this;
    me.close();
  }

});

export default MobileDuplicateSsasContactPopup;
