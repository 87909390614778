<li data-id="{{item.value}}" class="list-item btn">
    <div class="icon-l icon-left">
        <h3>
            {{item.text}}
        </h3>

        <p class="icon-s icon-right">
            {{item.msg}}
        </p>
    </div>
</li>
