import _ from "core-cmp/lodash";
import Page from "core-cmp/Page";
import ExportLogbookTplStache from "thm/ui/common/ersTrip/ExportLogbookTpl.stache";
import {I18N} from "core-cmp/util/I18n";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import "thm/ui/common/quota/Quota.css";

/**
 * @class thm.ui.common.ersTrip.ExportLogbookPage
 * @parent thm.ui
 * @constructor
 * Page d'export pour le logbook papier d'un voyage
 * @param {Object} options
 */
let ExportLogbookPage = Page.extend({
  template:          ExportLogbookTplStache,
  i18nPrefix:        ['thm.ui.common.ersTrip.ExportLogbook.'],
  id:                'ersTripLogbookExport',
  routerType:        'popup',
  popupWidth:        500,
  popupHeightMargin: 50,
  tripId:            null,
  autoBindActions:   true,

  open: function (context, skipAnimation) {
    let me = this;

    me.tripId = context.tripId;
    me._super(context, skipAnimation);
  },

  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr('speciesDisabled', true);
    me.viewModel.attr('exportModeOptions', me.loadExportModeOptions());
    me.viewModel.attr('exportModel', {
      exportMode: '1'
    });
    me.viewModel.on('exportModel.exportMode', function (ev, newValue, oldValue) {
      this.attr('speciesDisabled', newValue === '1');
    })
  },

  loadExportModeOptions: function () {
    let me = this;

    return [
      {value: '1', text: me.msg('speciesByColumn')},
      {value: '2', text: me.msg('otherSpecies')}
    ];
  },

  onCancel: function () {
    let me = this;
    me.close();
  },

  onSubmit: function () {
    const me = this;
    const request = new XMLHttpRequest();
    const paramsFilter = me.constructParamsToSend();

    const params = {
      application:      'umv',
      fields:           'all',
      includes:         me.tripId,
      format:           'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      filter:           JSON.stringify(paramsFilter),
      exportDateFormat: PREF('dateFormat'),
      exportTimeFormat: PREF('timeFormat'),
      exportTimeZone:   PREF('timeZone') === 'system' ? window.Intl.DateTimeFormat().resolvedOptions().timeZone : null
    };

    request.open('POST', UDA_URL + 'ersTrip/' + me.tripId + '/logbook', true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.setRequestHeader(APP().useJwtToken ? 'jwt' : 'token', APP().user.token);
    request.responseType = 'blob';

    me.viewModel.attr('saveStatus', 0);

    request.onload = function (e) {

      if (this.status === 200) {
        me.viewModel.attr('saveStatus', 1);
        let blob = this.response;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'Logbook - ' + PREF().format('date', new Date()).replace(/\//g, '-') + '.xlsx');
        }
        else {
          let downloadLink      = window.document.createElement('a'),
              contentTypeHeader = request.getResponseHeader("Content-Type");

          downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentTypeHeader}));
          downloadLink.download = 'Logbook - ' + PREF().format('date', new Date()).replace(/\//g, '-') + '.xlsx';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        me.viewModel.attr('saveStatus', -1);
      }
    };
    request.onError = function (e) {
      me.viewModel.attr('saveStatus', -1);
    };
    const paramsStr = Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&');
    request.send(paramsStr);
  },

  constructParamsToSend: function () {
    let me           = this,
        speciesParam = [],
        params       = {
          locale: PREF('locale')
        },
        species      = [];

    if (!_.isEmpty(APP("species").filterByIdList(me.viewModel.attr('exportModel.species')))) {
      species = APP("species").filterByIdList(me.viewModel.attr('exportModel.species'));
    }

    params.exportMode = me.viewModel.attr('exportModel.exportMode');
    _.forEach(species, function (s) {
      speciesParam.push({code: s.code, name: s.name});
    });
    params.speciesCodeName = speciesParam;

    return params;
  }
});

export default ExportLogbookPage;
