import {canStache} from "core-cmp/can";
import _ from "core-cmp/lodash";
import ResourceUI from "core-uda/ui/ResourceUI";
import UTILS from "core-cmp/Utils";
import {COLOR_UTIL} from "core-cmp/util/ColorUtil";
import {ICON_SVG_MANAGER} from "core-cmp/service/IconSVGManager";
import {ICON_FACTORY} from "core-cmp/service/IconFactory";
import {APP} from "core-uda/model/Resource";
import TrajectoryStyleInfoPanel from "core-mobile/ui/style/TrajectoryStyleInfoPanel";

import breakrouteTemplate from "core-mobile/ui/style/TrajectoryStyleInfoPanelBreakrouteTpl.stache";
import estimationTemplate from "core-mobile/ui/style/TrajectoryStyleInfoPanelEstimationTpl.stache";
import mobileColorTemplate from "core-mobile/ui/style/TrajectoryStyleInfoPanelMobileColorTpl.stache";
import positionsTemplate from "core-mobile/ui/style/TrajectoryStyleInfoPanelPositionsTpl.stache";
import trajectoryTemplate from "core-mobile/ui/style/TrajectoryStyleInfoPanelTrajectoryTpl.stache";
import mobileSymbolTemplate from "core-mobile/ui/style/TrajectoryStyleInfoPanelMobileSymbolTpl.stache";
import mobileTrajectoryTemplate from "thm/ui/style/MobileTrajectoryTtpl.stache";

canStache.registerPartial("TrajectoryStyleInfoPanelMobileSymbolTpl", mobileSymbolTemplate);
canStache.registerPartial("TrajectoryStyleInfoPanelBreakrouteTpl", breakrouteTemplate);
canStache.registerPartial("TrajectoryStyleInfoPanelEstimationTpl", estimationTemplate);
canStache.registerPartial("TrajectoryStyleInfoPanelMobileColorTpl", mobileColorTemplate);
canStache.registerPartial("TrajectoryStyleInfoPanelPositionsTpl", positionsTemplate);
canStache.registerPartial("TrajectoryStyleInfoPanelTrajectoryTpl", trajectoryTemplate);
canStache.registerPartial("MobileTrajectoryTpl", mobileTrajectoryTemplate);

/**
 * ThmTrajectoryStyleInfoPanel
 */
let ThmTrajectoryStyleInfoPanel = TrajectoryStyleInfoPanel.extend({
  /**
   * Return preferences keys for radar product.
   * @returns {Array}
   */
  getPrefKeys: function () {
    return ['trajectoryStyleV2'];
  }
}, {
  id:            'thmTrajectoryStyleInfoPanel',
  styleType:     "trajectory",
  i18nPrefix:    ['core-uda.ui.style.StyleInfoPanel.', 'uda.style.', 'uda.style.trajectory.'],
  childTpl:      null,
  dynamicFields: ['mobileDynamicTypes', 'mobileDynamicColors'],

  /**
   * Constructor
   * @param config
   */
  init: function (config) {
    let me = this;

    me._super(config);
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;
    me._super();
    me.fillPointLabelFontSize();
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.on("dataModel.pointLabel.fontSize", () => me.fillPointLabelTextFont());
  },

  fillPointLabelFontSize: function () {
    let me        = this,
        dataModel = me.getDataModel(),
        textFont  = dataModel.pointLabel && dataModel.pointLabel.textFont || '8',
        textSize  = textFont.match(/\d+/g)[0];

    me.viewModel.attr('dataModel.pointLabel.fontSize', textSize);
  },

  fillPointLabelTextFont: function () {
    let me        = this,
        dataModel = me.getDataModel(),
        fontSize  = dataModel.pointLabel.fontSize || 8;
    me.viewModel.attr('dataModel.pointLabel.textFont', `bold ${fontSize}px tahoma,arial,verdana,sans-serif`);
  }

});
export default ThmTrajectoryStyleInfoPanel;
