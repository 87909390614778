import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import ResourceDAO from "core-uda/model/ResourceDAO";
import {APP} from "core-uda/model/Resource";
import {RIGHTS} from "core-uda/Rights";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import Report from "thm/model/report/Report";
import {RESOURCE_CLASS} from "core-uda/model/Resource";

/**
 * Report DAO
 */

let ReportDAO = ResourceDAO.extend({
  resource:      "report",
  resourceClass: Report,
  dataRequest:   null,
  customMethods: ["generateReport"],

  /**
   * Find data for widget.
   * @param config
   */
  generateReport: function (params) {
    let me       = this,
        deferred = new $.Deferred();

    let args = {
      path:        '',
      contentType: 'application/json',
      type:        'POST',
      data:        _.extend({}, params)
    };

    me._ajaxUDA(args).done((res) => {
      deferred.resolve(res);
    }).fail(function (e) {
      deferred.reject('error');
      me.onError('get', e);
    });

    return deferred;
  },

  findAll: function (params) {
    let me              = this,
        deferred        = new $.Deferred(),
        report,
        detailsRequests = [];

    me._super(params).done((res) => {
      let reports = res.getData();
      let resourceTypes = _.uniq(_.map(reports, 'resourceName'));
      _.each(resourceTypes, (resourceType) => {
        let idsList = _.map(_.filter(reports, {resourceName: resourceType}), 'resourceId');
        let request = RESOURCE_CLASS[resourceType].findBy({includes: idsList, fields: me.getFields(resourceType)});
        detailsRequests.push(request);
        request.done((additionnalInfos) => {
          _.each(_.filter(reports, {resourceName: resourceType}), (report) => {
            report.details = _.find(additionnalInfos, {id: report.resourceId});
          });
        }).fail(() => {
          deferred.resolve(reports);
        });
      });
      $.when(...detailsRequests).done(() => {
        deferred.resolve(reports)
      });
    }).fail(function (e) {
      deferred.reject('error');
      me.onError('get', e);
    });

    return deferred;
  },

  getFields: function (resourceName) {
    let me = this;

    switch (resourceName) {
      case 'mobile' :
        return 'id, name, flagCountryCode';
      case 'alertHistory' :
        return 'id, raiseTimeMillis, surveillanceName';
      case 'zone' :
        return 'id, name';
      case 'radarEcho' :
        return 'id';
      default:
        return 'id';
    }
  }
});

// Instance unique
let REPORT_DAO = new ReportDAO();

export {REPORT_DAO};
export default ReportDAO;
