import Page from "core-cmp/Page";
import EditSsasTestLogPopupTpl from "thm/ui/common/ssas/ssasTestLog/EditSsasTestLogPopupTpl.stache";

/**
 * @class EditSsasTestLogPopup
 *
 * Popup to chose the type of export
 */
let EditSsasTestLogPopup = Page.extend({
  id:              "editSsasTestLogPopup",
  routerType:      'popup',
  template:        EditSsasTestLogPopupTpl,
  i18nPrefix:      ['thm.ui.common.popup.editSsasTestLogPopup.'],
  autoBindActions: true,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('dataFormModel', {});
    me.viewModel.attr('dataFormModel').attr('comment', '');
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me      = this,
        context = me.context;

    me._super();

    if (context.testLog) {
      me.viewModel.attr('dataFormModel').attr('comment', context.testLog.comment);
      me.viewModel.attr('dataFormModel').attr('id', context.testLog.id);
    }
  },

  onSave: function () {
    let me     = this,
        data = me.viewModel.attr('dataFormModel'),
        ctx    = me.context; // The context will be destroy by close, save it to use it after close

    me.close().done(() => {
      ctx.save(data);
    });
  }
});

export default EditSsasTestLogPopup;
