import {PREF} from "core-cmp/Preferences";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionDistanceToZoneInfoPanelTpl.stache";
import {ALERT_DEFINITION_CONST} from "core-alert/model/AlertDefinition";

/**
 * @class AlertDefinitionDistanceToZoneInfoPanel
 * Alert Definition "Distance To Zone" Form.
 */
let AlertDefinitionDistanceToZoneInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'AlertDefinitionDistanceToZoneInfoPanel',
  childTpl:      ChildStache,
  timingAllowed: true,

  bindEvents: function () {
    let me = this;
    me._super();
    PREF.bind('unitChanged', function (ev, newVal, oldVal) {
      me.updateViewModel();
    });
  },

  /**
   * @override
   */
  forceDefaultValues: function () {
    let me        = this,
        dataModel = me.getDataModel();

    if (_.isUndefined(dataModel.monitorIn)) {
      me.viewModel.attr('dataModel.monitorIn', false);
    }
    if (_.isUndefined(dataModel.monitorOut)) {
      me.viewModel.attr('dataModel.monitorOut', false);
    }
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();
    me.viewModel.attr('operatorOptions', ALERT_DEFINITION_CONST.operator);
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('zoneAllowed', true); //check quand UDA ready si c'est nécéssaire
  }

});
export default AlertDefinitionDistanceToZoneInfoPanel;
