<div id="{{id}}" class="page">

  <div class="header-group">
    <h3>{{title}}</h3>
    {{#if allowClose}}
      <div cls-action="close" class="btn btn-header-right ui-icon-close"></div>
    {{/if}}
  </div>


    {{#if success}}
      <div class="msg inlineGroup" {{#longText}}style="white-space: pre-wrap" {{/longText}}>
        {{msg('testSuccess')}} {{protocolKind}}
      </div>
    {{#else}}
      <div class="msg inlineGroup" {{#longText}}style="white-space: pre-wrap" {{/longText}}>
        {{msg('testError')}} {{protocolKind}}
      </div>
    {{/if}}
    {{#hasReturnCode}}
      <div class="msg inlineGroup" {{#longText}}style="white-space: pre-wrap" {{/longText}}>
        {{msg('returnCode')}} : {{returnCode}}
      </div>
    {{/hasReturnCode}}

    {{#hasResponse}}
      <div class="msg inlineGroup" {{#longText}}style="white-space: pre-wrap" {{/longText}}>
        {{msg('response')}} : {{response}}
      </div>
    {{/hasResponse}}

    {{#hasError}}
      <div class="msg inlineGroup" {{#longText}}style="white-space: pre-wrap" {{/longText}}>
        {{msg('error')}} : {{error}}
      </div>
    {{/hasError}}
    <ui-loading {visible}="loading" anchor="bottom-center"/>
  </div>

  <div class="inline-{{buttonCount}}btn">
    {{#each buttons}}
      <a cls-action="action" {{data '.'}} class="btn {{style}}">{{label}}</a>
    {{/each}}
  </div>
</div>
