<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-scrollY-content inlineGroup fields">
    <field-text name="countryCode" min-length="3" max-length="3" required="true" format="uppercase" col="5 12"></field-text>
    <field-text name="countryName" max-length="60" format="uppercase" col="7 12"></field-text>
    <field-textarea name="webserviceUrl" max-length="512" col="12 12"></field-textarea>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
