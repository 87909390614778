<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
      <field-text name="reference" max-length="10" required="true" col="6 12"></field-text>
      <field-text name="name" max-length="60" required="true" col="6 12"></field-text>
      <field-text name="period" max-length="12" format="int" required="true" col="6 12"></field-text>
      <field-checkbox name="reserved" col="12 12"></field-checkbox>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
