<div class="header-group inline icon-left icon-m ui-icon-user">
    <h2>{{msg 'vesselContact'}}</h2>
</div>
<div id="{{id}}-specifications" class="inlineGroup fields">
    {{#if domainFishing}}
        <field-text name="captainName" required="false" col="12 12"></field-text>
        <field-text name="captainAddress" required="false" col="12 12"></field-text>
    {{/if}}
    <field-text format="phone-inter" name="telephone" required="false" col="6 12"></field-text>
    <field-text format="phone-inter" name="fax" required="false" col="6 12"></field-text>
    <field-text format="email" name="email" required="false" col="12 12"></field-text>
    <field-select name="broadcastMode" {options}="alertBroadcastModes" col="12 12"></field-select>
</div>
