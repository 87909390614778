import _ from "core-cmp/lodash";
import BeaconType from "core-uda/model/beacon/BeaconType";
import {canAssign} from "core-cmp/can";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import {APP} from "core-uda/model/Resource";

// List of command definitions
const COMMAND_DEFINITIONS = {
  "poll":                 {},
  "administratorPolling": {},
  "reportingInterval":    {hasParams: true},
  "piratFreeForm":        {hasParams: true, group: "pirat"},
  "piratTimers":          {hasParams: true, group: "pirat"},
  "piratLogicalOutput":   {hasParams: true, group: "pirat"},
  "email":                {},
  "restart":              {},
  "stop":                 {},
  "unload_dnid":          {},
  "load_dnid":            {},
  "hrdr":                 {hasParams: true},
  "polling_gprs":         {},
  "periodic_polling":     {hasParams: true, excludeFromGroups: true},
  "warning":              {hasParams: true}
};

// List of command names
const COMMANDS = _.keys(COMMAND_DEFINITIONS);

// Map :
// - key : group name
// - value : command name array
const COMMAND_GROUPS = _.reduce(COMMAND_DEFINITIONS, (map, definition, command) => {
  if (!definition.excludeFromGroups) {
    let group = definition.group || command;
    map[group] = map[group] || [];
    map[group].push(command);
  }
  return map;
}, {});

// List of command names
const GROUPS = _.keys(COMMAND_GROUPS);

// class (static) override
_.assign(BeaconType, {

  /**
   * Return authorized command list
   * @returns {string[]}
   */
  getReadableCommands: function () {
    if (!DATA_AUTHORIZED('command')) {
      return [];
    }
    // List of commands authorized for Themis center
    // note : RIGHTS("commands.availableCommandsList") est une string. ex : 'poll,bla,bla'
    // centerCommands : array of strings
    const centerCommands = RIGHTS("commands.availableCommandsList").split(',');
    const roleCommands = RIGHTS('data.command.read', []);
    const authorizedCommands = _.intersection(centerCommands, roleCommands);
    return _.filter(COMMANDS, (cmd) => authorizedCommands.includes(cmd));
  },

  /**
   * Return authorized command list
   * @returns {string[]}
   */
  getCreatableCommands: function () {
    if (!DATA_AUTHORIZED("command.create")) {
      return [];
    }
    // List of commands authorized for Themis center
    const centerCommands = RIGHTS("commands.availableCommandsList").split(',');
    const roleCommands = RIGHTS('data.command.create', []);
    const authorizedCommands = _.intersection(centerCommands, roleCommands);

    // ???
    if (RIGHTS("data.ppp.create")) {
      authorizedCommands.push('periodic_polling');
    }

    return _.filter(COMMANDS, (cmd) => authorizedCommands.includes(cmd));
  },

  /**
   * Return command groups of given commands.
   * @params {string[]} commands - list of commands
   * @returns {string[]} list og command groups
   */
  commandsToGroups: function (commands) {
    let groups = new Set(),
        group;

    _.each(commands, (cmd) => {
      group = _.findKey(COMMAND_GROUPS, (commands) => commands.includes(cmd));
      if (group) {
        groups.add(group)
      }
    });

    return Array.from(groups);
  },

  /**
   * Return authorized command groups.
   * @returns {string[]}
   */
  getReadableCommandGroups: function () {
    let me = this;

    return me.commandsToGroups(me.getReadableCommands());
  },

  /**
   * Return creatable command groups.
   * @returns {string[]}
   */
  getCreatableCommandGroups: function () {
    let me = this;

    return me.commandsToGroups(me.getCreatableCommands());
  },

  /**
   * Return creatable command groups.
   * @params {BeaconType[]} [beaconTypes] - if set, only commands active for these types are returned
   * @returns {string[]}
   */
  getCreatableCommandsForBeaconTypes: function (beaconTypes) {
    let me             = this,
        rightsCommands = me.getCreatableCommands(),
        commands       = new Set();

    if (rightsCommands.length === 0) {
      return [];
    }

    if (_.isNil(beaconTypes)) {
      beaconTypes = APP('beaconTypes').getData();
    }

    const piratCommands = rightsCommands.filter(com => com.startsWith('pirat'));

    _.each(beaconTypes, (type) => {
      _.each(type.getActiveCommands(), (command) => {

        // si commande "pirat", on ajoute les 3 commande de ce type
        if (command === 'pirat') {
          piratCommands.forEach(pirateCommand => {
            commands.add(pirateCommand);
          });
        }

        if (rightsCommands.includes(command)) {
          commands.add(command);
          if (command === 'poll' && rightsCommands.includes('periodic_polling')) {
            commands.add('periodic_polling');
          }
        }
      });
    });

    return Array.from(commands);
  },

  /**
   * Return command status if is compliant with all beaconType.
   * @params {BeaconType[]} [beaconTypes] - if set, only commands active for these types are returned
   * @returns {object} compliant boolean and values (if exists)
   */
  getCommandCompatibilityForBeaconTypes: function (command, beaconTypes) {
    let me                = this,
        creatableCommands = me.getCreatableCommandsForBeaconTypes(beaconTypes),
        declaration,
        values            = undefined,
        compliant;

    if (!creatableCommands.includes(command)) {
      return false;
    }

    if (_.isNil(APP('beaconTypes'))) {
      beaconTypes = APP('beaconTypes').getData();
    }

    compliant = !_.find(beaconTypes, (type) => {
      declaration = type.getCommandDeclaration(command);

      // find declaration with values not equals to previous declaration values
      if (declaration && declaration.values) {
        values = values || declaration.values;
        return (values !== declaration.values);
      }
      return false;
    });

    return {
      name:      command,
      compliant: compliant,
      values:    values
    };
  },

  /**
   * Return true if command has params.
   * @param {string} - command
   * @returns {boolean}
   */
  commandHasParams: function (command) {
    return !!(COMMAND_DEFINITIONS[command] || {}).hasParams;
  }
});

// Instance override
canAssign(BeaconType.prototype, {

  /**
   * Return active commands.
   * @returns {string[]} - commands
   */
  getActiveCommands: function () {
    const me = this;
    return _.map(_.filter(me.commands, "activeValue"), "name");
  },

  /**
   * Return  command declaration for given name.
   * @param {string} name - name.
   * @returns {object} - commands declaration
   */
  getCommandDeclaration: function (name) {
    const me = this;
    return _.find(me.commands, {name: name});
  }
});

export {COMMANDS};
export {COMMAND_GROUPS};
export {GROUPS};
export {COMMAND_DEFINITIONS};
export default BeaconType;
