<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>
    <field-item item="dataModel" class="L-scrollY-content">
        <div class="inlineGroup fields">
            <field-text name="name"></field-text>
            <field-checkbox name="enabled"></field-checkbox>
        </div>

        <div class="inlineGroup">
            <uda-list of="zones" name="zones"
                      {update-for}="dataModel.customerId"
                      update-function="findForCpcCondition"></uda-list>
        </div>

        <div class="inlineGroup">
            <field-switch label="" {(field)}="mobileOrFleet" text-on-key="fleets" text-off-key="mobiles"></field-switch>
            <uda-list of="fleets" name="fleets" {visible}="mobileOrFleet"></uda-list>
            <uda-list of="mobiles" name="mobiles" {hidden}="mobileOrFleet"></uda-list>
        </div>

        <div class="inlineGroup">
            <field-hcombo {(field)}="restrictionSourceFlag" label="" options="restrictionSourceOptions"></field-hcombo>
            <field-checkboxes name="sources" label="" options="sourcesOptions"
                              format="array" {visible}="sourcesList"></field-checkboxes>
        </div>
    </field-item>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
