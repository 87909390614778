import _ from "core-cmp/lodash";
import GIS from "core-gis/Default";
import {APP} from "core-uda/model/Resource";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import {FEATURE_TYPE, FEATURE_FAMILY_TYPE} from "core-gis/FeatureConstants";
import UdaMapUpdater from "core-uda/UdaMapUpdater";
import MeteoStationRecord from "thm/model/meteoStationRecord/MeteoStationRecord";
import {STYLE_BUILDER_REPOSITORY} from "core-gis/service/style/StyleBuilderRepository";

let MeteoStationRecordMapUpdater = UdaMapUpdater.extend({
  layerId: "meteoStationRecord",
  id:      "meteoStationRecord",

  /**
   * @override
   */
  init: function (config) {
    let me = this;
    me._super(config);

    STYLE_BUILDER_REPOSITORY.setFamilyStyleBuilder(FEATURE_FAMILY_TYPE.METEO_STATION, "MeteoStationRecord", "MeteoStationRecord");

    APP().whenMapLoaded().done(() => {
      if (RIGHTS('map.overlayLayers').includes('metarTaf')) {
        if (PREF('overlayLayer-metarTaf')) {
          me.getAllMetarTaf();
        }
        PREF().bind("overlayLayer-metarTaf", () => me.getAllMetarTaf());
      }
    });

  },

  getAllMetarTaf: function () {
    let me = this;

    if (!PREF('overlayLayer-metarTaf')) {
      GIS.removeFeaturesByFilter(me.layerId, (f, data) => data.type === FEATURE_TYPE.METEO_STATION_AERO);
      return;
    }

    MeteoStationRecord.findAll().done((data) => {
      if (_.isNil(data)) {
        return;
      }

      if (_.isNil(data.datas)) {
        return;
      }

      let features   = [],
          aero       = data.datas,
          aeroUniqId = _.uniqBy(aero, 'meteoStationId'),
          uniqId = aeroUniqId.map((v) => v.meteoStationId);

      if (uniqId.length < 1) {
        return;
      }

      _.each(uniqId, (id) => {
        let meteo = aero.filter((v) => v.meteoStationId === id);

        let metar = meteo.filter((v) => v.recordType === 'METAR')[0],
            taf = meteo.filter((v) => v.recordType === 'TAF')[0],
            location;

        if (metar) {
          location = metar.meteoStationLocation;
        } else {
          location = taf.meteoStationLocation;
        }

        let data = {
          metar: metar ? JSON.parse(metar.recordData) : {},
          taf: taf ? JSON.parse(taf.recordData) : {}
        };

        features.push(GIS.createGeoJsonFeature({
          type:        "Circle",
          coordinates: location,
          radius:      800
        }, {
          id:         id,
          type:       FEATURE_TYPE.METEO_STATION_AERO,
          onlyLabel:  false,
          visibility: data.metar.visibility,
          meteoStationRecordData: data,
          meteoStationRecordMetar: metar,
          meteoStationRecordTaf: taf
        }));
      })
      GIS.addFeatures(me.layerId, features);
    });
  }
});

export default MeteoStationRecordMapUpdater;
