import Resource from "core-uda/model/Resource";
import MeteoStationRecordModel from "thm/model/meteoStationRecord/MeteoStationRecordModel";

/**
 * MeteoStationRecord.
 */
let MeteoStationRecord = Resource.extend({
  modelClazz: MeteoStationRecordModel
}, {});

export default MeteoStationRecord;
