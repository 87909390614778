import {I18N} from "core-cmp/util/I18n";
import ResourceUI from "core-uda/ui/ResourceUI";
import UserAction from "core-uda/model/userAction/UserAction";
import ThmSelectUserCriteria from "thm/ui/common/userAction/ThmSelectUserCriteria";
import ThmSelectActionCriteria from "thm/ui/common/userAction/ThmSelectActionCriteria";

/**
 * User Interface elements for UserAction
 */
let UserActionUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: UserAction.itemId,
      i18nPrefix: "thm.ui.common.userAction.UserActionUI.",
      icon:       "user"
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "action": I18N_ACTION_FIELD("uda.userAction.action.")
    };
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      "user":     {
        cmpClass: ThmSelectUserCriteria
      },
      "action": {
        cmpClass: ThmSelectActionCriteria
      }
    };
  }
});

const I18N_ACTION_FIELD = (prefix) => {
  return {
    format:   (value) => {
      let values = value.split('_');
      return value ? I18N.msg(prefix + (values[0].toLowerCase() + values[1].toLocaleUpperCase())) || "" : "";
    },
    dataType: 'string'
  }
};

const USER_ACTION_UI = new UserActionUI();

export default UserActionUI;
