import _ from "core-cmp/lodash";
import Page from "core-cmp/Page";
import EnumFilter from "core-cmp/wc/util/EnumFilter";
import template from "thm/ui/common/zone/ZoneListSearchPageTpl.stache";
import {APP} from "core-uda/model/Resource";
import Zone from "core-zone/model/Zone";

/**
 * Edit filter options of zones list.
 */
let ZoneListSearchPage = Page.extend({
  id:              "zoneListSearchPage",
  routerType:      'popup',
  positionMode:    "mouse",
  popupWidth:      350,
  popupFloat:      true,
  template:        template,
  autoBindActions: true,
  modal:           false,
  titleKey:        "_Search",
  geometryFilter : null,
  kindFilter : null,

  /**
   * @override
   */
  init: function (config) {
    let me                  = this,
        zoneGeometries      = Zone.getGeometryTypesAuthorized(),
        zoneKindOptions,
        zoneKinds           = Zone.getKindsAuthorized(),
        zoneGeometryOptions;

    zoneKindOptions = _.map(zoneKinds, (kind) => {
      return {
        value: kind,
        icon:  Zone.getKindIconId(kind),
        text:  Zone.getKindLabel(kind)
      };
    });
    zoneKindOptions.sort((a, b) => a.text > b.text ? 1 : -1);
    me.kindOptions = zoneKindOptions;

    zoneGeometries.push("None");
    zoneGeometryOptions = _.map(zoneGeometries, (type) => {
      return {
        value: type,
        icon:  Zone.getGeometryIconId(type),
        text:  Zone.getGeometryLabel(type)
      };
    });
    zoneGeometryOptions.sort((a, b) => a.text > b.text ? 1 : -1);
    me.geometryOptions = zoneGeometryOptions;

    me.geometryFilter = new EnumFilter({
      field:        "geometry.type",
      css:          "geometryType-match",
      defaultValue: "None",
      pattern:      zoneGeometries,
      clearPattern: zoneGeometries
    });
    me.kindFilter = new EnumFilter({
      field:        "kind",
      css:          "kind-match",
      score:        600, // partial match : prior kind then type
      pattern:      zoneKinds,
      clearPattern: zoneKinds
    });

    me._super(config);
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.on("geometry", me._onGeometryChange.bind(me));
    me.viewModel.on("kind", me._onKindChange.bind(me));
    me.viewModel.on("searchQuery", me._onSearchQueryChange.bind(me));
    me.viewModel.attr('kindOptions', me.kindOptions);
    me.viewModel.attr('geometryOptions', me.geometryOptions);
  },

  doSearch: function () {
    let me = this;

    if (me.notDoSearch) {
      return
    }
    me.context.headerModel.doSearch();
  },

  _onGeometryChange: function () {
    let me = this;

    me.geometryFilter.setPattern(me.viewModel.attr('geometry'));
    me.doSearch();
  },

  _onKindChange: function () {
    let me = this;

    me.kindFilter.setPattern(me.viewModel.attr('kind'));
    me.doSearch();
  },

  _onSearchQueryChange: function () {
    let me = this;

    me.context.headerModel.searchQuery = me.viewModel.attr('searchQuery');
    me.doSearch();
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this;

    me._super();
    me.notDoSearch = true;

    me.context.itemFilter.addFilter(me.kindFilter, true);
    me.context.itemFilter.addFilter(me.geometryFilter, true);

    me.viewModel.attr('kind', me.kindFilter.pattern);
    me.viewModel.attr('geometry', me.geometryFilter.pattern);
    me.viewModel.attr('searchQuery', me.context.headerModel.searchQuery);

    me.notDoSearch = false;
  },

  onClear: function () {
    let me = this;

    me.notDoSearch = true;
    me.context.itemFilter.clear();
    me.viewModel.attr('kind', me.kindFilter.pattern);
    me.viewModel.attr('geometry', me.geometryFilter.pattern);
    me.notDoSearch = false;

    me.context.headerModel.searchQuery = "";
    me.context.headerModel.searchByString(null);
  }
});

export default ZoneListSearchPage;
