import {canDefineMap} from "core-cmp/can";
import ResourceUI from "core-uda/ui/ResourceUI";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import FishingTime from "core-uda/model/fishing/FishingTime";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import SearchByCriteria from "core-criteria/searchBy/SearchByCriteria";
import PeriodCriteria from "core-criteria/period/PeriodCriteria";

import {I18N} from "core-cmp/util/I18n";

/**
 * User Interface elements for ErsQuery
 */
let FishingTimeUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: FishingTime.itemId,
      icon:       "duration",
      i18nPrefix: "thm.ui.common.fishingTime.FishingTimeUI."
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'sensorThreshold': {
        cmpClass:       SearchByCriteria,
        optionsDetails: {
          format: 'int'
        }
      },
      'kind':            {
        cmpClass: PeriodCriteria
      }
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "kind": I18N_FIELD("uda.fishingTime.field.kind.")
    }
  }

});

const ERS_FISHING_TIME_UI = new FishingTimeUI();

export default FishingTimeUI;
