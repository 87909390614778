import GridPositions from "core-mobile/model/GridPositions";
import _ from "core-cmp/lodash";
import {THM_MOBILE_DATA} from "thm/model/mobile/ThmMobileData";
import {MOBILE_DAO} from "core-uda/model/mobile/MobileDAO";
import GridDefinition from "core-uda/model/GridDefinition";
import {MAIN_PAGE} from "core-uda/ui/UdaMainPage";

GridDefinition.MEMORY_CELL_VALUE = function (rowData) {
  let me  = this,
      res = rowData[me.memory.dataField],
      value;
  if ((!RIGHTS("data.positionsCriteria.historic")
    && !PREF().historicVesselDetails)) {

    if (me.memory.resourceDataField) {
      // A row data field contains the required object => get it
      res = rowData[me.memory.resourceDataField];
    } else if (me.memory.resourceIdDataField) {
      // A row data field contains the identifier of required object => load object using APP()
      res = APP(me.memory.resource, rowData[me.memory.resourceIdDataField]);
    }
    value = res ? res[me.memory.field] : "";
    return _.isNil(value) ? "" : value;
  }
  return rowData[me.dataField] || '';
};

let ThmGridPositions = GridPositions.extend({

  summaryGroupStart: function (options) {
    let me = this;

    MAIN_PAGE().gridPanel.getActiveChild().grid.gridinstance.option('groupPanel.visible', options.groupIndex === 0);

    me._super(options);
  },

  createPositionConfig: function () {
    let me               = this,
        columnManagement = GridPositions.createPositionConfig();
    if (RIGHTS("data.positions.displayStatisticZonesForPositions")) { // THM-9871 - don't display zoneFao and zoneIces if the displayStatisticZonesForPositions is off
      let col     = [{
            id:             "zone1",
            dataField:      "zone1",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone2",
            dataField:      "zone2",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone3",
            dataField:      "zone3",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone4",
            dataField:      "zone4",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone5",
            dataField:      "zone5",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone6",
            dataField:      "zone6",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone7",
            dataField:      "zone7",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }, {
            id:             "zone8",
            dataField:      "zone8",
            width:          200,
            dataType:       "string",
            allowFiltering: true,
            allowSorting:   true,
            visible:        true,
            allowGrouping:  false
          }],
          colName = JSON.parse(RIGHTS("data.positions.zones"));


      _.forEach(col, (c) => {
        if(!_.isNil(colName[c.id])){
          c.caption = colName[c.id].label;
          //c.dataField = colName[c.id].type;
          columnManagement.columns.push(c);
        }
      });

    }
    return columnManagement;
  },


  /**
   * @override
   */
  onRowPrepared: function (row) {
    let me     = this,
        column = _.find(me.columns, 'colorBackground'),
        colors = ["#F6BDC0", "#F1959B", "#F07470", "#EA4C46", "#DC1C13"],
        defaultColor = "#FFFFFF";

    if (column && row.rowType == "data") {
      let min,
          max,
          range,
          bgColor = '',
          order   = column.colorBackgroundOrder;

      _.forEach(me.datas, value => {
        if (_.isNil(min)) {
          min = value[column.id];
        } else {
          min = value[column.id] < min ? value[column.id] : min;
        }
        if (_.isNil(max)) {
          max = value[column.id];
        } else {
          max = value[column.id] > max ? value[column.id] : max;
        }
      });

      if (!_.isNil(row.data) && !_.isNil(row.data[column.id]) && !_.isNil(max) && !_.isNil(min)) {
        range = max - min;
        if (max === min) {
          //cas où on n'a qu'une seule valeur, on colore tout pour differencier les lignes avec valeurs des lignes sans
          bgColor = colors[4];
        } else if (row.data[column.id] <= min + 0.2 * range) {
          bgColor = order == "max" ? colors[0] : colors[4];
        } else if (row.data[column.id] <= min + 0.4 * range) {
          bgColor = order == "max" ? colors[1] : colors[3];
        } else if (row.data[column.id] <= min + 0.6 * range) {
          bgColor = colors[2];
        } else if (row.data[column.id] <= min + 0.8 * range) {
          bgColor = order == "max" ? colors[3] : colors[1];
        } else {
          bgColor = order == "max" ? colors[4] : colors[0];
        }
        //dx-row-alt colore en gris une ligne sur 2 au niveau td et tr, ce qui override la couleur au niveau ligne
        row.rowElement.removeClass('dx-row-alt');
        row.rowElement.css('background-color', bgColor);
        if (bgColor === colors[3] || bgColor === colors[4]) {
          //texte en blanc pour les fonds plus foncés
          row.rowElement.css('color', defaultColor);
        }
      } else {
        row.rowElement.removeClass('dx-row-alt');
        row.rowElement.css('background-color', defaultColor);
      }
    }
  },

  /**
   * Retourne la liste des définitions de colonnes et groupes à utiliser
   * @returns {Array} liste.
   */
  getConfigList: function () {
    let me           = this,
        dataConfig   = GridPositions.createDataConfig(),
        mobileColumn = dataConfig.columns.find(x => x.id === 'mobile');
    mobileColumn.calculateCellValue = function (rowData) {
      if (_.isNaN(parseInt(THM_MOBILE_DATA.getMobileLabel(rowData.mobile, rowData.mobileName)))) {
        return THM_MOBILE_DATA.getMobileLabel(rowData.mobile, rowData.mobileName).replace('<', '').replace('>', '');
      } else {
        return THM_MOBILE_DATA.getMobileLabel(rowData.mobile, rowData.mobileName);
      }
    };

    return [
      dataConfig,
      me.createPositionConfig()
    ];
  }
});

export default ThmGridPositions;
