<div id="{{id}}" class="page">

    <div class="header-group">
        <h3>{{msg 'title'}}</h3>
    </div>

    <field-item  item="dataFormModel" class="inlineGroup fields l30-f70" style="    padding-bottom: 48px;">
        <field-date label-key="from" {(field)}="from"
                    format="dateTime" col="6 12"
                    max-date="now"
                    period="exportPeriod" role="start"
                    required="true"></field-date>
        <field-date label-key="to" {(field)}="to"
                    format="dateTime" col="6 12"
                    max-date="now"
                    period="exportPeriod" role="end"
                    required="true"></field-date>
    </field-item>

    <div class="inline-2btn btn-footer">
        <a cls-action="submit" class="btn btn-ok">{{msg '_Send'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
