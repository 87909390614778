import ThmTrajectoryStyleInfoPanel from "thm/ui/style/ThmTrajectoryStyleInfoPanel";
import BourbonStyleInfoPanelTplStache from "thm/ui/style/ThmBourbonStyleInfoPanelTpl.stache";
import BourbonStyleEngine from "core-mobile/service/style/BourbonStyleEngine";

/**
 * BourbonStyleInfoPanel
 */
let ThmBourbonStyleInfoPanel = ThmTrajectoryStyleInfoPanel.extend({
  ID:     "trajectory-Bourbon",
  ENGINE: BourbonStyleEngine.INSTANCE
}, {
  id:       'bourbonStyleInfoPanel',
  childTpl: BourbonStyleInfoPanelTplStache,

  defautModel: {},

  /**
   * @override
   */
  getDefaultRules: function () {
    return BourbonStyleEngine.INSTANCE.rules;
  },

  initDefaultModel: function () {
    var me                = this,
        defaultStyleModel = me.getDefaultRules();

    me.defaultModel = {
      defaultPoint:        {
        pointRadius: defaultStyleModel.defaultPoint.pointRadius
      },
      base:                {
        strokeColor: defaultStyleModel.base.strokeColor
      },
      bourbon:             {
        strokeColor: defaultStyleModel.bourbon.strokeColor
      },
      pointLabel:          {
        fontColor: defaultStyleModel.pointLabel.fontColor,
        textFont:  defaultStyleModel.pointLabel.textFont
      },
      lineDefault:         {
        strokeWidth: defaultStyleModel.lineDefault.strokeWidth,
        strokeColor: defaultStyleModel.lineDefault.strokeColor
      },
      selected:            {
        pointRadius: defaultStyleModel.selected.pointRadius,
        strokeColor: defaultStyleModel.selected.strokeColor,
        fillColor:   defaultStyleModel.selected.fillColor
      },
      backLine:            {
        strokeWidth: defaultStyleModel.backLine.strokeWidth,
        strokeColor: defaultStyleModel.backLine.strokeColor
      },
      lineLevel:           {
        number: 2
      },
      lineLevel1:          {
        speed:       defaultStyleModel.lineLevel1.speed,
        strokeColor: defaultStyleModel.lineLevel1.strokeColor
      },
      lineLevel2:          {
        speed:       defaultStyleModel.lineLevel2.speed,
        strokeColor: defaultStyleModel.lineLevel2.strokeColor
      },
      estimationPoint:     {
        fillColor: defaultStyleModel.estimationPoint.fillColor
      },
      estimationLine:      {
        strokeWidth: defaultStyleModel.estimationLine.strokeWidth
      },
      lineBreakroute:      {
        strokeDashstyle: defaultStyleModel.lineBreakroute.strokeDashstyle
      },
      mobileDynamicTypes:  {
        select:    defaultStyleModel.mobileDynamicTypes.select,
        "default": defaultStyleModel.mobileDynamicTypes["default"]
      },
      mobileDynamicColors: {
        select:    defaultStyleModel.mobileDynamicColors.select,
        "default": defaultStyleModel.mobileDynamicColors["default"]
      }
    };
  },


  //override
  initViewModel: function () {
    var me = this;
    me._super();
    me.viewModel.attr('bourbon', true);
    me.viewModel.attr('fishing', true);
    me.viewModel.attr('number2', true);
  }
});
export default ThmBourbonStyleInfoPanel;
