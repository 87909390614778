import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import InfoPanel from "core-uda/ui/InfoPanel";
import UdaGrid from "core-uda/ui/UdaGrid";
import {I18N} from "core-cmp/util/I18n";
import {PREF} from "core-cmp/Preferences";
import Grid from "core-cmp/Grid";
import Utils from "core-cmp/Utils";
import ErsActivityInfoPanelTplStache from "thm/ui/common/ersActivity/ErsActivityInfoPanelTpl.stache";
import ErsActivityEditPage from "thm/ui/common/ersActivity/ErsActivityEditPage";
import {RIGHTS, DATA_AUTHORIZED} from "core-uda/Rights";
import "thm/ui/common/ersActivity/ErsActivityInfoPanel.css";
import ErsFormUI from "core-uda/model/ersFormUI/ErsFormUI";


const FN = {
  subActivityCOE:     (item) => {
    return _.find(item.subActivities, {kind: "COE"})
  },
  subActivityCOX:     (item) => {
    return _.find(item.subActivities, {kind: "COX"})
  },
  subActivityCVT:     (item) => {
    return _.find(item.subActivities, {kind: "CVT"})
  },
  subActivityCVOList: (item) => {
    return _.filter(item.subActivities, {kind: "CVO"})
  },
  subActivityJCI:     (item) => {
    return _.find(item.subActivities, {kind: "JCI"})
  },
  subActivityBTI:     (item) => {
    return _.find(item.subActivities, {kind: "BTI"})
  },
  isMarlinMauritanie: (item) => {
    return item.origin == "MARLIN" && RIGHTS('flag') === 'MRT';
  },
  isNonFishingActivity: (item) => {
    return item.origin == "MARLIN" && RIGHTS('flag') === 'MRT' && !!item.detail?.Reason && item.kind === 'FAR';
  }
};

const ZONE_CELL_TEMPLATE = (cellElement, {data: {areaNameZEE, areaNameFAO}}) => {
  if (areaNameZEE) {
    cellElement.append(areaNameFAO ? `${areaNameZEE}-${areaNameFAO}` : areaNameZEE);
  }
};

// THM-14566 - on masque le champ area
const DETAIL_COLUMN_EXCLUDED_KEYS = ['area'];
const DETAIL_COLUMN_VALUE_FORMATTERS = [
  {
    canFormat: ({value}) => _.isPlainObject(value),
    format:    ({value}) => Object.keys(value)[0]
  },
  {
    canFormat: ({key, value}) => key.toLowerCase().includes('date') && !_.isNaN(_.toNumber(value)),
    format:    ({value}) => {
      // FIXME : Fix moche en 8.5
      // Champs date dans les details => sont renvoyés en secondes depuis 2010 et pas en millis
      // On doit donc les transformer en millisecondes et les ajouter à la date depuis 2010
      // pour obtenir la bonne date..
      // modification pour afficher une date classique en fonction de la longueur du champs pour
      // gérer les deux cas en attendant de confirmer que l'on utilise que l'un des deux formats
      let dateMillis;
      if (value.length <= 9) {
        const dateSecSince2010 = _.toNumber(value);
        const dateMillisSince2010 = dateSecSince2010 * 1000;
        dateMillis = new Date('01/01/2010').addMilliseconds(dateMillisSince2010);
      } else {
        const valueMillis = _.toNumber(value);
        dateMillis = new Date(valueMillis);
      }
      return PREF().format('dateTime', dateMillis);
    }
  },
  {
    canFormat: ({value}) => _.isString(value),
    format:    ({key, value}) => {
      const translatedValue = I18N.msg('thm.ui.common.ersActivity.ErsActivityInfoPanel.' + _.camelCase(key) + '.' + value);
      return translatedValue.includes('?') && translatedValue != 'On' ? value : translatedValue;
    }
  }
];

/**
 * @class thm.ui.common.ersActivity.ErsActivityInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un voyage
 * @param {Object} options
 */
let ErsActivityInfoPanel = InfoPanel.extend({
  template:                       ErsActivityInfoPanelTplStache,
  i18nPrefix:                     ['uda.ersActivity.', 'thm.ui.common.ersActivity.ErsActivityInfoPanel.'],
  id:                             'ersActivityInfoPanel',
  entityId:                       'ersActivity',
  catchesGridConfig:              {
    width:              '100%',
    height:             300,
    wordWrapEnabled:    true,
    filterRow:          null,
    sorting:            {
      mode: "single"
    },
    export:             {
      enabled: false
    },
    columnResizingMode: "widget",
    columns:            [
      {
        caption: "uda.species.one",
        columns: [{
          id:           "code",
          dataField:    "speciesCode",
          width:        50,
          dataType:     "string",
          alignment:    "center",
          ////allowFiltering: true,
          allowSorting: true
        }, {
          id:           "name",
          dataField:    "speciesI18n",
          width:        150,
          dataType:     "string",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true
        }, {
          id:           "speciesCat",
          dataField:    "speciesCatI18n",
          width:        130,
          dataType:     "string",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true
        }]
      },
      {
        caption: "catchesGrid.quantity",
        columns: [{
          id:           "weightFished",
          dataField:    "weightFished",
          width:        65,
          dataType:     "number",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true,
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(PREF().format('weight', cellInfo.value, true));
          }
        }, {
          id:           "numberFished",
          dataField:    "numberFished",
          width:        65,
          dataType:     "number",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true
        }]
      },
      {
        id:           "zone",
        dataField:    "areaFullName",
        width:        115,
        dataType:     "string",
        alignment:    "center",
        cellTemplate: ZONE_CELL_TEMPLATE,
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "fateCode",
        dataField:    "fateCode",
        width:        120,
        dataType:     "string",
        alignment:    "center",
        ////allowFiltering: true,
        allowSorting: true,
        format:       (code) => {
          return code ? I18N.msg("uda.ersActivity.field.fateCode." + code) : "";
        }
      },
      {
        id:           "detail",
        dataField:    "detail",
        width:        150,
        encodeHtml:   false,
        dataType:     "string",
        alignment:    "center",
        ////allowFiltering: true,
        allowSorting: true,
        format:       (values) => {
          if (!values) {
            return '';
          }
          let entries = Object.entries(JSON.parse(values)).filter(([key]) => !DETAIL_COLUMN_EXCLUDED_KEYS.includes(key.toLowerCase()));
          let processingEntry = _.remove(entries, entry => entry[0] == "Processing");
          if (processingEntry.length){
            processingEntry.forEach(entry => {
              entries.push(...Object.entries(entry[1]))
            });
          }
          return entries.map(([key, value]) => {
            const translatedKey = I18N.msg('thm.ui.common.ersActivity.ErsActivityInfoPanel.' + _.camelCase(key));
            const formattedKey = translatedKey.includes('?') ? key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()) : translatedKey;
            const matchingFormatter = DETAIL_COLUMN_VALUE_FORMATTERS.find(({canFormat}) => canFormat({key, value}));
            const formattedValue = matchingFormatter ? matchingFormatter.format({key, value}) : value;
            return `${formattedKey}: ${formattedValue}`;
          }).join('<br />');
        }
      }
    ]
  },
  cvtCatchesGridConfig:           {
    width:              '100%',
    filterRow:          null,
    sorting:            {
      mode: "single"
    },
    export:             {
      enabled: false
    },
    columnResizingMode: "widget",
    columns:            [
      {
        id:           "code",
        dataField:    "speciesCode",
        width:        60,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "weight",
        dataField:    "weightFished",
        width:        80,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "numberFished",
        dataField:    "numberFished",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "numberLanded",
        dataField:    "numberLanded",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "weightInNets",
        dataField:    "weightInNets",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "numberInNets",
        dataField:    "numberInNets",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "gearType",
        dataField:    "gearType",
        width:        90,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "meansOfWeightMeasureType",
        dataField:    "meansOfWeightMeasureType",
        width:        90,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      }
    ]
  },
  pfpVesselGridConfig:            {
    width:              '100%',
    filterRow:          null,
    sorting:            {
      mode: "single"
    },
    export:             {
      enabled: false
    },
    columnResizingMode: "widget",
    columns:            [
      {
        id:           "name",
        dataField:    "VesselName",
        width:        150,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "callsign",
        dataField:    "RadioCallsign",
        width:        150,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "externalId",
        dataField:    "ExternalId",
        width:        150,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "identificationRegister",
        dataField:    "IdentificationRegister",
        width:        150,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "flagState",
        dataField:    "flagState",
        width:        150,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "masterName",
        dataField:    "MasterName",
        width:        150,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      }
    ]
  },
  landedCatchesGridConfig:        {
    width:              '100%',
    height:             '100%',
    filterRow:          null,
    sorting:            {
      mode: "single"
    },
    export:             {
      enabled: false
    },
    columnResizingMode: "widget",
    columns:            [
      {
        caption: "uda.species.one",
        columns: [{
          id:           "code",
          dataField:    "speciesCode",
          width:        50,
          dataType:     "string",
          alignment:    "center",
          ////allowFiltering: true,
          allowSorting: true
        }, {
          id:           "name",
          dataField:    "speciesI18n",
          width:        150,
          dataType:     "string",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true
        }, {
          id:           "speciesCat",
          dataField:    "speciesCatI18n",
          width:        130,
          dataType:     "string",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true
        }]
      },
      {
        caption: "catchesGrid.quantity",
        columns: [{
          id:           "weightFished",
          dataField:    "weightFished",
          width:        65,
          dataType:     "number",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true,
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(PREF().format('weight', cellInfo.value, true));
          }
        }, {
          id:           "numberFished",
          dataField:    "numberFished",
          width:        65,
          dataType:     "number",
          alignment:    "center",
          //allowFiltering: true,
          allowSorting: true
        }]
      },
      {
        id:           "zone",
        dataField:    "areaFullName",
        width:        115,
        dataType:     "string",
        alignment:    "center",
        cellTemplate: ZONE_CELL_TEMPLATE,
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "catchesGrid.fateCode",
        dataField:    "fateCode",
        width:        120,
        dataType:     "string",
        alignment:    "center",
        ////allowFiltering: true,
        allowSorting: true,
        format:       (code) => {
          return code ? I18N.msg("uda.ersActivity.field.fateCode." + code) : "";
        }
      }
    ]
  },
  gearsGridConfig:                {
    width:              '100%',
    filterRow:          null,
    sorting:            {
      mode: "single"
    },
    export:             {
      enabled: false
    },
    columnResizingMode: "widget",
    columns:            [
      {
        id:           "type",
        dataField:    "detail.Type",
        width:        60,
        dataType:     "string",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "mesh",
        dataField:    "detail.Mesh",
        width:        80,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "hooks",
        dataField:    "detail.Hooks",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "averageHeight",
        dataField:    "detail.AverageHeight",
        width:        145,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "capacity",
        dataField:    "detail.Capacity",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "quantity",
        dataField:    "detail.NetsQuantity",
        width:        90,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      },
      {
        id:           "totalLenght",
        dataField:    "detail.TotalLength",
        width:        120,
        dataType:     "number",
        alignment:    "center",
        //allowFiltering: true,
        allowSorting: true
      }
    ]
  },
  authorizedFieldsByActivityType: {
    TRA: {
      fields: [
        {field: "position", place: "detail.Position", group: "global"},
        {field: "portName", place: ["detail.PortName", "portName"], group: "global"},
        {field: "portReference", place: ["detail.PortRef", "portRef"], group: "global"},
        {field: "hole", place: "detail.Hole", hideFn: "isMarlinMauritanie", group: "global"},
        {field: "reason", place: "reason", group: "global"},
        {field: "mobileCaptainName", place: "detail.Trip.CaptainName", hideFn: "isMarlinMauritanie", group: "global"},
        {field: "mobileDonnorName", place: "mobileDonnorName", group: "donnor"},
        {field: "mobileDonnorCallSign", place: ["mobileDonnorCallSign", "detail.donorCallSign"], group: "donnor"},
        {
          field:  "mobileDonnorFlagCountryName",
          place:  "mobileDonnorFlagName",
          hideFn: "isMarlinMauritanie",
          group:  "donnor"
        },
        {field: "mobileDonnorRef", place: "mobileDonnorRef", hideFn: "isMarlinMauritanie", group: "donnor"},
        {field: "mobileDonnorRegistry", place: "mobileDonnorRegistry", hideFn: "isMarlinMauritanie", group: "donnor"},
        {field: "mobileDonnorImo", place: ["mobileDonnorImo", "detail.donorIMO"], group: "donnor"},
        {field: "mobileRecieverName", place: "mobileRecieverName", group: "reciever"},
        {field: "mobileRecieverCallSign", place: ["mobileRecieverCallSign", "detail.receiverCallSign"], group: "reciever"},
        {
          field:  "mobileRecieverFlagCountryName",
          place:  "mobileRecieverFlagName",
          hideFn: "isMarlinMauritanie",
          group:  "reciever"
        },
        {field: "mobileRecieverRef", place: "mobileRecieverRef", hideFn: "isMarlinMauritanie", group: "reciever"},
        {
          field:  "mobileRecieverRegistry",
          place:  "mobileRecieverRegistry",
          hideFn: "isMarlinMauritanie",
          group:  "reciever"
        },
        {field: "mobileRecieverImo", place: ["mobileRecieverImo", "detail.receiverIMO"], group: "reciever"},
        {
          field:  "mobileRecieverActivity",
          place:  "detail.ReceiverActivity",
          showFn: "isMarlinMauritanie",
          group:  "reciever"
        }
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    DIS: {
      fields: [
        {field: "position", place: "position", group: "global"},
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    FAR: {
      fields: [
        {field: "endDate", place: ["detail.EndTimeMillis", "detail.GearRetrieved.TimeMillis"], group: "global"},
        {field: "position", place: "position", group: "global"},
        {field: "positionStart", place: "detail.GearShoot.Position", group: "global"},
        {field: "positionEnd", place: "detail.GearRetrieved.Position", group: "global"},
        {field: "reason", place: "reason", group: "global"},
        {field: "totalWeight", place: "detail.TotalWeight", group: "global"},
        {field: "containerNumber", place: "detail.ContainerNumber", group: "global"},
        {field: "statisticZone", place: "detail.StatisticZone", group: "global"},
        {
          field:       "fadBeaconType",
          place:       "detail.FadData.BeaconType",
          hideIfNil:   true,
          group:       "dcp",
          i18nFishLog: "uda.ersActivity.field.fadBeaconType."
        },
        {field: "fadOperation", place: "detail.FadData.Operation", hideIfNil: true, group: "dcp"},
        {
          field:       "fadSchoolAssocCode",
          place:       "detail.FadData.SchoolAssocCode",
          group:       "dcp",
          hideIfNil:   true,
          i18nFishLog: "uda.ersActivity.field.fadSchoolAssocCode."
        },
        {field: "fadBeaconId", place: "detail.FadData.BeaconId", hideIfNil: true, group: "dcp"},
        {
          field:       "fadType",
          place:       "detail.FadData.FadType",
          hideIfNil:   true,
          group:       "dcp",
          i18nFishLog: "uda.ersActivity.field.fadType."
        },
        {
          field:       "fadVisitType",
          place:       "detail.FadData.VisitType",
          hideIfNil:   true,
          group:       "dcp",
          i18nFishLog: "uda.ersActivity.field.fadVisitType."
        },
        {field: "fadBrand", place: "detail.FadData.DcpBrand", hideIfNil: true, group: "dcp"},
        {
          field:         "fadWaterTemperature",
          place:         "detail.FadData.WaterTemperature",
          hideIfNil:     true,
          group:         "meteoCondition",
          formatFishLog: "temperature"
        },
        {
          field:         "fadWindSpeed",
          place:         "detail.FadData.WindSpeed",
          hideIfNil:     true,
          group:         "meteoCondition",
          formatFishLog: "speed"
        },
        {field: "fadWindDirection", place: "detail.FadData.WindDirection", hideIfNil: true, group: "meteoCondition"},
        {
          field:     "fadCurrentDirection",
          place:     "detail.FadData.CurrentDirection",
          hideIfNil: true,
          group:     "meteoCondition"
        },
        {
          field:         "fadCurrentSpeed",
          place:         "detail.FadData.CurrentSpeed",
          hideIfNil:     true,
          group:         "meteoCondition",
          formatFishLog: "speed"
        },
        {field: "cvt_name", placeFn: "subActivityCVT", place: "mobileDonnorName", group: "cvt"},
        {field: "cvt_iccat", placeFn: "subActivityCVT", place: "mobileDonnorIccat", group: "cvt"},
        {field: "cvt_imo", placeFn: "subActivityCVT", place: "mobileDonnorImo", group: "cvt"},
        {field: "cvt_callSign", placeFn: "subActivityCVT", place: "mobileDonnorCallSign", group: "cvt"},
        {field: "cvt_registry", placeFn: "subActivityCVT", place: "mobileDonnorRegistry", group: "cvt"},
        {field: "cvo_subItems", placeFn: "subActivityCVOList", group: "cvo"}, //not displayed, use to generate the stache
        {field: "cvo_name", placeFn: "subActivityCVOList", place: "mobileDonnorName", isItter: true, group: "cvo"},
        {field: "cvo_iccat", placeFn: "subActivityCVOList", place: "mobileDonnorIccat", isItter: true, group: "cvo"},
        {field: "cvo_imo", placeFn: "subActivityCVOList", place: "mobileDonnorImo", isItter: true, group: "cvo"},
        {
          field:   "cvo_callSign",
          placeFn: "subActivityCVOList",
          place:   "mobileDonnorCallSign",
          isItter: true,
          group:   "cvo"
        },
        {
          field:   "cvo_registry",
          placeFn: "subActivityCVOList",
          place:   "mobileDonnorRegistry",
          isItter: true,
          group:   "cvo"
        },
        {field: "jci_time", placeFn: "subActivityJCI", place: "timeMillis", group: "jci"},
        {field: "jci_position", placeFn: "subActivityJCI", place: "position", group: "jci"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridName: "gearsGrid", place: "subActivities"},
        {gridNameFishlog: "catches", place: "catchDetail"},
        {gridName: "pfp_vesselGrid", gridConfig: "pfpVesselGrid", place: "detail.FishingPartners", hideIfEmpty: true},
        {gridName: "cvt_catchesGrid", gridConfig: "cvtCatchesGrid", placeFn: "subActivityCVT", place: "catchDetail"},
        {
          gridName:   "cvo_catchesGrid",
          gridConfig: "cvtCatchesGrid",
          placeFn:    "subActivityCVOList",
          place:      "catchDetail",
          isItter:    true
        },
        {gridName: "jci_catchesGrid", gridConfig: "cvtCatchesGrid", placeFn: "subActivityJCI", place: "catchDetail"}
      ]
    },
    RLC: {
      fields: [
        {field: "position", place: "detail.Position", group: "global"},
        {field: "relocatedTo", place: "detail.RelocatedTo", group: "global"},
        {field: "reason", place: "reason", group: "global"},
        {field: "mobileDonnorName", place: "detail.DonnorVessels.VesselName", group: "donnor"},
        {field: "mobileDonnorCallSign", place: "detail.DonnorVessels.RadioCallsign", group: "donnor"},
        {field: "mobileDonnorFlagCountryName", place: "detail.DonnorVessels.FlagState", group: "donnor"},
        {field: "mobileDonnorRef", place: "detail.DonnorVessels.ExternalId", group: "donnor"},
        {field: "mobileDonnorRegistry", place: "detail.DonnorVessels.IdentificationRegister", group: "donnor"},
        {field: "mobileRecieverName", place: "detail.ReceiverVessel.VesselName", group: "reciever"},
        {field: "mobileRecieverCallSign", place: "detail.ReceiverVessel.RadioCallsign", group: "reciever"},
        {field: "mobileRecieverFlagCountryName", place: "detail.ReceiverVessel.FlagState", group: "reciever"},
        {field: "mobileRecieverRef", place: "detail.ReceiverVessel.ExternalId", group: "reciever"},
        {field: "mobileRecieverRegistry", place: "detail.ReceiverVessel.IdentificationRegister", group: "reciever"},
        {field: "bti_position", placeFn: "subActivityBTI", place: "position", group: "bti"},
        {
          field:   "bti_tugTowingVesselName",
          placeFn: "subActivityBTI",
          place:   "detail.TugTowingVesselName",
          group:   "bti"
        },
        {
          field:   "bti_tugTowingVesselIccat",
          placeFn: "subActivityBTI",
          place:   "detail.TugTowingVesselICCAT",
          group:   "bti"
        },
        {field: "bti_farmName", placeFn: "subActivityBTI", place: "detail.FarmName", group: "bti"},
        {field: "bti_farmIccat", placeFn: "subActivityBTI", place: "detail.FarmICCAT", group: "bti"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    PNO: {
      fields: [
        {field: "portName", place: "portName", group: "global"},
        {field: "portReference", place: "detail.PortRef", group: "global"},
        {field: "endPlannedDate", place: "detail.ArrivalTimeMillis", group: "global"},
        {field: "landingTime", place: "detail.LandingTimeMillis", group: "global"},
        {
          field: "purposeCall",
          place: "detail.PurposeCall",
          group: "global",
          i18n:  "uda.ersActivity.field.purposeCall."
        },
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"},
        {gridNameFishlog: "landedCatches", place: "landedDetail"}
      ]
    },
    RTP: {
      fields: [
        {field: "loch", place: "detail.LOCH", hideIfNil: true, group: "global"},
        {field: "portName", place: "portName", group: "global"},
        {field: "portReference", place: "detail.PortRef", group: "global"},
        {
          field: "reasonReturn",
          place: "detail.ReasonReturn",
          group: "global",
          i18n:  "uda.ersActivity.field.reasonReturn."
        },
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  []
    },
    DEP: {
      fields: [
        {field: "loch", place: "detail.LOCH", hideIfNil: true, group: "global"},
        {field: "portName", place: "portName", group: "global"},
        {field: "portReference", place: "detail.PortRef", group: "global"},
        {field: "anticipatedActivity", place: "detail.Activity", group: "global"},
        {field: "mobileCaptainName", place: "detail.Trip.CaptainName", showFn: "isMarlinMauritanie", group: "global"},
        {field: "reason", place: "reason", group: "global"},
        {field: "mobileName", place: "detail.vesselName", showFn: "isMarlinMauritanie", group: "mobileInfo"},
        {field: "mobileImo", place: "detail.imo", showFn: "isMarlinMauritanie", group: "mobileInfo"},
        {field: "mobileCallSign", place: "detail.radioCallSign", showFn: "isMarlinMauritanie", group: "mobileInfo"},
        {field: "nationalCrewNumber", place: "detail.NationalCrewNumber", group: "crew"},
        {field: "foreignCrewNumber", place: "detail.ForeignCrewNumber", group: "crew"},
        {
          field:     "dealershipFishingZone",
          place:     "detail.Dealership.FishingZone",
          group:     "dealership",
          hideIfNil: true,
          i18n:      "uda.ersActivity.field.dealershipFishingZone."
        },
        {
          field:     "dealershipType",
          place:     "detail.Dealership.Type",
          group:     "dealership",
          hideIfNil: true,
          i18n:      "uda.ersActivity.field.dealershipType."
        },
        {field: "dealershipName", place: "detail.Dealership.Name", group: "dealership", hideIfNil: true},
        {field: "dealershipSignatureDate", place: "detail.Dealership.SignatureDateMillis", group: "dealership"},
        {field: "dealershipExpirationDate", place: "detail.Dealership.ExpirationDateMillis", group: "dealership"},
        {field: "dealershipValidityYears", place: "detail.Dealership.ValidityYears", group: "dealership"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridName: "gearsGrid", place: "subActivities"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    COE: {
      fields: [
        {field: "targetSpecies", place: "detail.TargetSpecies", group: "global"},
        {field: "position", place: "detail.Position", group: "global"},
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    LAN: {
      fields: [
        {field: "portName", place: "portName", group: "global"},
        {field: "portReference", place: "portRef", group: "global"},
        {field: "senderKind", place: "detail.SenderType", group: "global"},
        {field: "portAgent", place: "detail.PortAgent", group: "global"},
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    INS: {
      fields: [
        {field: "position", place: "detail.Position", group: "global"},
        {field: "inspectorName", place: "detail.Inspector", group: "global"},
        {field: "inspectorFlagCode", place: "detail.InspectorFlagCode", group: "global"},
        {field: "countryCode", place: "detail.CountryCode", group: "global"},
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  []
    },
    COX: {
      fields: [
        {field: "position", place: "detail.Position", group: "global"},
        {field: "targetSpecies", place: "detail.TargetSpecies", group: "global"},
        {field: "reason", place: "reason", group: "global"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    PNT: {
      fields: [
        {field: "position", place: "detail.Position", group: "global"},
        {field: "reason", place: "reason", group: "global"},
        {field: "registerName", place: "detail.RegisterName", group: "register"},
        {field: "registerIccat", place: "detail.RegisterIccat", group: "register"},
        {field: "cagesNumber", place: "detail.CagesNumber", group: "register"}
      ],
      grids:  [
        {gridName: "catchesGrid", place: "catchDetail"},
        {gridNameFishlog: "catches", place: "catchDetail"}
      ]
    },
    EOF: {
      fields: [],
      grids:  []
    },
    CRO: {
      fields: [
        {field: "reason", place: "reason", group: "global"},
        {field: "coe_startDate", placeFn: "subActivityCOE", place: "timeMillis", group: "coe"},
        {field: "coe_position", placeFn: "subActivityCOE", place: "position", group: "coe"},
        {field: "coe_zoneFullName", placeFn: "subActivityCOE", place: "zoneFullName", group: "coe"},
        {field: "coe_targetSpecies", placeFn: "subActivityCOE", place: "detail.TargetSpecies", group: "coe"},
        {field: "cox_startDate", placeFn: "subActivityCOX", place: "timeMillis", group: "cox"},
        {field: "cox_position", placeFn: "subActivityCOX", place: "position", group: "cox"},
        {field: "cox_zoneFullName", placeFn: "subActivityCOX", place: "zoneFullName", group: "cox"},
        {field: "cox_targetSpecies", placeFn: "subActivityCOX", place: "detail.TargetSpecies", group: "cox"}
      ],
      grids:  [
        {gridName: "coe_catchesGrid", gridConfig: "catchesGrid", placeFn: "subActivityCOE", place: "catchDetail"},
        {gridName: "cox_catchesGrid", gridConfig: "catchesGrid", placeFn: "subActivityCOX", place: "catchDetail"}
      ]
    },
    TRZ: {
      fields: [
        {field: "reason", place: "reason", group: "global"},
        {field: "coe_startDate", placeFn: "subActivityCOE", place: "timeMillis", group: "coe"},
        {field: "coe_position", placeFn: "subActivityCOE", place: "position", group: "coe"},
        {field: "coe_zoneFullName", placeFn: "subActivityCOE", place: "zoneFullName", group: "coe"},
        {field: "coe_targetSpecies", placeFn: "subActivityCOE", place: "detail.TargetSpecies", group: "coe"},
        {field: "cox_startDate", placeFn: "subActivityCOX", place: "timeMillis", group: "cox"},
        {field: "cox_position", placeFn: "subActivityCOX", place: "position", group: "cox"},
        {field: "cox_zoneFullName", placeFn: "subActivityCOX", place: "zoneFullName", group: "cox"},
        {field: "cox_targetSpecies", placeFn: "subActivityCOX", place: "detail.TargetSpecies", group: "cox"}
      ],
      grids:  [
        {gridName: "coe_catchesGrid", gridConfig: "catchesGrid", placeFn: "subActivityCOE", place: "catchDetail"},
        {gridName: "cox_catchesGrid", gridConfig: "catchesGrid", placeFn: "subActivityCOX", place: "catchDetail"}
      ]
    },
    NONPECHE: {
      fields:[
        {
          field: "nonFishingReason",
          place: "detail.Reason",
          i18n: "thm.ui.common.ersActivity.ErsActivityInfoPanel.nonFishingReason.",
          group: "global"
        },
        {field: "reason", place: "reason", group: "global"}
      ]
    }
  },
  fishlogConf:                    {},
  logbook:                        {},
  onNormalConfig:                 true,

  /**
   * @override
   */
  init: function (config) {
    let me = this;
    me.attr('id', 'ersActivityInfoPanel-' + config.childId.replaceAll('\\.','-'));
    me._super(config);
  },

  /**
   * @override
   */
  getToolbarActions: function () {
    let me      = this,
        actions = me._super();

    me.addToolbarAction(actions, {
      action:     "partialEdit",
      icon:       "edit",
      label:      me.msg("_Edit"),
      authorized: RIGHTS('ers.activityModifications') &&
                    DATA_AUTHORIZED("ersActivity.edit") &&
                    me.data.status === 'VALIDATED'
    });

    return actions;
  },

  onPartialEdit: function () {
    let me = this;

    ErsActivityEditPage.openPage({
      activity:         me.data,
      groups:           me.viewModel.groups,
      authorizedFields: me.authorizedFieldsByActivityType
    });
  },

  updateColumnOption: function (grid, col) {
    let me           = this,
        gridConfigId = grid.gridConfig || grid.gridName || grid.gridNameFishlog + "Grid";

    col.caption = me.msg(gridConfigId + '.' + col.id);
    if (!_.isUndefined(grid.gridName))
      col.id = grid.gridName + "_" + col.id;
    else
      col.id = grid.gridNameFishlog + "Grid_" + col.id;
    Grid.updateColumnOptionFormat(col);
  },

  addCaptionToColumn: function (configColumns, grid) {
    let me = this;
    _.each(configColumns, function (col) {
      if (col.caption) {
        col.caption = me.msg(col.caption);
        _.each(col.columns, (subCol) => {
          me.updateColumnOption(grid, subCol)
        })
      } else {
        me.updateColumnOption(grid, col)
      }
    });
  },

  /**
   * @override
   */
  doAfterRender: function () {
    let me = this;

    me._super();
    if (me.onNormalConfig) {
      me.updateGrids();
      me.onNormalConfig = false;
    }
  },

  updateGrids: function (force) {
    let me = this,
        gridConfigId,
        config;

    _.each(me.authorizedFieldsByActivityType, (type) => {
      _.each(type.grids, (grid) => {
        if (!me[grid.gridName] || force) {
          let origin = me.viewModel.attr('dataModel.origin') === "IFIMS" ? "-ifims" : "-fishlog"; //me.getItemOrigin(me.viewModel.attr('dataModel.origin'));

          gridConfigId = grid.gridConfig || grid.gridName || grid.gridNameFishlog + "Grid";
          let gridNameFishlog = ('grid-' + grid.gridNameFishlog + '-' + me.viewModel.attr('dataModel.kind') + origin).toLowerCase(),
              gridFishlogDiv  = $('#' + gridNameFishlog);
          // attr() : => create copy
          config = me[gridConfigId + 'Config'].attr();
          me.addCaptionToColumn(config.columns, grid);
          if (!_.isUndefined(grid.gridName) && !grid.isItter)
            me[grid.gridName] = new UdaGrid(me.$findById(grid.gridName), config);
          if (!_.isEmpty(gridFishlogDiv))
            me[gridNameFishlog] = new UdaGrid(gridFishlogDiv, config);
        }
      });
    });
  },

  updateFishlogGrid: function (activity) {
    let me = this,
        gridConfigId,
        config;

    _.each(me.authorizedFieldsByActivityType[activity].grids, (grid) => {
      if (!me[grid.gridNameFishlog]) {
        let origin;
        switch (me.viewModel.attr('dataModel.origin')) {
          case "IFIMS":
            origin = "-ifims";
            break;
          case "FSHLOG":
            origin = "-fishlog";
            break;
          case "GFCM":
            origin = "-gfcm";
            break;
          case "MANUAL":
            if (me.viewModel.attr('dataModel.actOldOrigin') == "FSHLOG")
              origin = "-fishlog";
            break;
        }
        let gridNameFishlog = (origin === "-gfcm" && !_.isNil(grid.GFCMLabel)) ?
          ('grid-' + grid.GFCMLabel + '-' + me.viewModel.attr('dataModel.kind') + origin).toLowerCase() :
          ('grid-' + grid.gridNameFishlog + '-' + me.viewModel.attr('dataModel.kind') + origin).toLowerCase(),
            gridFishlogDiv  = $('#' + gridNameFishlog);
        let gridConfigId = grid.gridConfig || grid.gridName || grid.gridNameFishlog + "Grid";
        config = me[gridConfigId + 'Config'].attr();
        _.each(config.columns, function (col) {
          if (col.caption) {
            col.caption = me.msg(col.caption);
            _.each(col.columns, (subCol) => {
              me.updateColumnOption(grid, subCol)
            })
          } else {
            me.updateColumnOption(grid, col)
          }
        });
        if (!_.isEmpty(gridFishlogDiv))
          me[gridNameFishlog] = new UdaGrid(gridFishlogDiv, config);
      }
    });
  },


  /**
   * @override
   */
  updateViewModel: function () {
    let me          = this,
        item        = me.data.attr ? me.data.attr() : me.data,
        ersFormUiId = _.get(item, ['detail', 'appId']) ? item.detail.appId + '_' + item.detail.versionId : undefined,
        deferred    = new $.Deferred(),
        fishlogConf;

    me._super();

    if (item.origin === "IFIMS") {
      ersFormUiId = "IFims-purse-seiner-logbook_1"
    } else if (item.origin === "GFCM") {
      ersFormUiId = "GFCM-logbook_1"
    }

    if (_.isNil(item.landedDetail)) {
      item.landedDetail = [];
    }

    if (!FN.isMarlinMauritanie(item)){
      DETAIL_COLUMN_EXCLUDED_KEYS.push("processing");
    } else {
      _.remove(DETAIL_COLUMN_EXCLUDED_KEYS, val => val == 'processing');
    }

    me.viewModel.attr('loadingScreen', true);
    if ((item.origin === "FSHLOG" || item.actOldOrigin === "FSHLOG" || item.origin === "IFIMS" || item.origin === "GFCM") && ersFormUiId) {
      me.viewModel.attr('fshlogOrigin', true);
      fishlogConf = me.fishlogConf[ersFormUiId];
      if (fishlogConf) {
        me.fishlogRender(fishlogConf, item);
        deferred.resolve();
      } else {

        ErsFormUI.findOne(ersFormUiId).done((config) => {
          // console.log(config);
          me.fishlogConf[ersFormUiId] = config;
          me.fishlogRender(config, item);
          deferred.resolve();
        }).fail(() => {
          me.normalRender(item);
          deferred.resolve();
        })
        /*
         me.loadLogbook(ersFormUiId).done((data) => {
         me.fishlogConf[ersFormUiId] = data;
         me.fishlogRender(data, item);
         }).fail(() => me.normalRender(item));
         */
      }
    } else {
      me.normalRender(item);
      deferred.resolve();
    }

    if (!FN.isNonFishingActivity(item) && (item.kind === 'COE' || item.kind === 'FAR' || item.kind === 'TRA' || item.kind === 'COX')) {
      let zoneFullName = item.zoneZeeName || "";
      zoneFullName += (item.zoneZeeName && item.zoneFaoName) ? "-" : "";
      zoneFullName += item.zoneFaoName || "";

      if (item.zoneZeeName || item.zoneFaoName) {
        me.viewModel.dataModel.attr('zoneFullName', zoneFullName);
      }
      deferred.done(() => {
        if (!_.isUndefined(me.viewModel.attr('visibles'))) {
          me.viewModel.attr('visibles').attr("zoneFullName", true);
        }
      });
    }
  },

  /**
   * Function to test the conf files
   * @param id
   * @returns {*}
   */
  loadLogbook: function (id) {
    let me = this;
    return $.getJSON('../web/logbookConf/' + 'UI_' + id + ".json").done((data) => {
      //me.logbook = data;
    });
  },

  fishlogRender: function (config, item) {
    let me = this,
        conf,
        renderer,
        toGenerate,
        idActivity/* = item.origin === "IFIMS" ? "-ifims" : "-fishlog"*/;

    switch (item.origin) {
      case "IFIMS":
        idActivity = "-ifims";
        break;
      case "FSHLOG":
        idActivity = "-fishlog";
        break;
      case "GFCM":
        idActivity = "-gfcm";
        break;
      case "MANUAL":
        if (item.actOldOrigin == "FSHLOG")
          idActivity = "-fishlog";
        break;
    }

    conf = config[item.kind.toLowerCase()];
    if (!!conf) {
      toGenerate = _.sortBy(conf.toGenerate, (field) => field.pos);
      if (me.$container[0].lastElementChild.id === me.$all[0].id) {
        me.$container[0].lastElementChild.remove();
      }
      me.viewModel.attr('stacheAutoGenerated', undefined);
      me.viewModel.attr('loadingScreen', true);
      _.delay(() => {
        renderer = Utils.generateFieldsStache(toGenerate, me.viewModel, true, '4 6 12', 'dataModel', item.kind.toLowerCase() + idActivity);
        if ($('#'+ me.id).length > 0) {
          $('#'+ me.id).remove();
        }
        me.viewModel.attr('stacheAutoGenerated', renderer(me.viewModel));
        me.render(me.$container);
        me.updateFishlogGrid(item.kind);
        me.updateProcess();
        if (!!item.position) {
          me.viewModel.attr('dataModel.longitude', item.position[0]);
          me.viewModel.attr('dataModel.latitude', item.position[1]);
        }

        if (item.origin === "GFCM") {
          let msgCatches  = "",
              speciesList = [];

          for (let i = 0; i < item.catchDetail.length; i++) {
            speciesList.push(item.catchDetail[i].speciesCode);
            if (i === 0) {
              msgCatches += item.catchDetail[i].speciesI18n + ' ' + item.catchDetail[i].weightFished;
            } else {
              msgCatches += ' ' + item.catchDetail[i].speciesI18n + ' ' + item.catchDetail[i].weightFished;
            }
            if (i == item.catchDetail.length - 1) {
              msgCatches += "//";
            }

          }
        }

        me.setFieldsForActivityType(item.kind, toGenerate);
        me.handleFishlogConditionalFields(item.kind, toGenerate);
        me.viewModel.attr('loadingScreen', false);
        me.lastRender = "fishlogRender";
      }, 50);

    } else {
      me.normalRender(item);
    }
  },

  normalRender: function (item) {
    let me = this;

    me.updateProcess();
    me.updateGrids(me.lastRender == "fishlogRender");
    me.setFieldsForActivityType(item.kind);
    me.viewModel.attr('loadingScreen', false);
    me.viewModel.attr('fshlogOrigin', false);
    me.lastRender = "normalRender";
  },

  updateProcess: function () {
    let me    = this,
        item  = me.data.attr ? me.data.attr() : me.data,
        isDep = item.kind === "DEP";

    if (_.get(me.data, "detail.Comment") && !me.data.reason) {
      me.data.reason = me.data.detail.Comment;
      if (me.data.attr) {
        me.data.attr("reason", me.data.detail.Comment);
      }
    }

    if (_.get(me.data, "detail.Dealership.SignatureDateMillis") && _.get(me.data, "detail.Dealership.ExpirationDateMillis")) {
      let diff = me.data.detail.Dealership.ExpirationDateMillis - me.data.detail.Dealership.SignatureDateMillis;
      let res;
      if (diff < 0) {
        res = me.msg("errorDealershipDates");
      } else {
        diff /= 1000 * 3600 * 24 * 30; // diff in months (30 days)
        res = Math.floor(diff / 6) / 2;
      }

      if (me.data.attr) {
        me.data.attr("detail.Dealership.ValidityYears", res);
      } else {
        _.set(me.data, "detail.Dealership.ValidityYears", res);
      }
    }

    me.setTitle(me.msg('field.kind.' + item.kind));
    me.setSubTitle(me.data.origin);

    // me.viewModel.attr('hasSpecies', hasSpecies);
    me.viewModel.attr('isDep', isDep);

    me.viewModel.dataModel.attr('disabled', !me.managementMode);

    if (item.kind === 'PNO' && item.detail.DateFishingStart) {
      me.viewModel.dataModel.attr('startDate', PREF().format('date', new Date(item.detail.DateFishingStart)));
    } else if (item.kind !== 'PNO') {
      me.viewModel.dataModel.attr('startDate', PREF().format('dateTime', new Date(item.timeMillis)));
    }
  },

  setFieldsForActivityType: function (type, conf) {
    let me                = this,
        mainItem          = me.data.attr ? me.data.attr() : me.data,
        authorizedForType = me.authorizedFieldsByActivityType[type],
        value,
        item,
        fieldName;

    me.viewModel.attr("visibles", {});
    me.viewModel.attr("groups", {});
    me.viewModel.attr("grids", {});
    me.viewModel.attr("formats", {});

    if(FN.isNonFishingActivity(mainItem)) {
      authorizedForType = me.authorizedFieldsByActivityType['NONPECHE'];
    }
    if (!!authorizedForType) {

      if (!conf) {
        _.each(authorizedForType.fields, (field) => {
          item = mainItem;
          value = undefined;
          fieldName = undefined;

          if (field.hideFn && FN[field.hideFn](item)) {
            return;
          }
          if (field.showFn && !FN[field.showFn](item)) {
            return;
          }

          if (field.placeFn) {
            item = FN[field.placeFn](item);
          }

          if (_.isArray(item)) {
            if (field.isItter) {
              _.forEach(item, (subActivity, index) => {
                value = _.get(subActivity, field.place, undefined)
                me.viewModel.dataModel.attr(`${index}_${field.field}`, value);
              });
            } else {
              value = item;
              fieldName = field.field;
            }
          } else {
            if (typeof field.place == 'string') {
              value = _.get(item, field.place, undefined);
              fieldName = field.place;
            } else {
              _.each(field.place, current => {
                if (!fieldName && _.get(item, current, undefined)) {
                  value = _.get(item, current, undefined);
                  fieldName = current;
                }
              })
            }

            if (_.isNil(value) && field.hideIfNil) {
              return;
            }

            if (!_.isNil(value) && _.endsWith(fieldName, "Millis")) {
              value = PREF().format("dateTime", typeof value === 'string' ? value : new Date(value));
            }

            if (field.formatFishLog && !_.isNil(value)) {
              me.viewModel.formats.attr(field.field, item.origin === "FSHLOG" || item.actOldOrigin === "FSHLOG" ? field.formatFishLog : "text");
            }

            // Translate
            if (field.i18n && !_.isNil(value)) {
              value = I18N.msg(field.i18n + value);
            }

            // Translate only for FishLog messages
            if (field.i18nFishLog && !_.isNil(value) && (item.origin === "FSHLOG" || item.actOldOrigin === "FSHLOG")) {
              value = I18N.msg(field.i18nFishLog + value);
            }
          }

          if (!field.isItter) {
            me.viewModel.dataModel.attr(field.field, value);
          }
          me.viewModel.groups.attr(field.group, me.viewModel.groups.attr(field.group) || !_.isNil(value));
          me.viewModel.attr('visibles').attr(field.field, true);
        });
      } else {
        _.each(conf, (field) => {
          //La date est reçue en millis depuis 1970 dans un string
          if (field.type === 'date' && field.udaField && field.udaField.includes('detail.') && !!me.viewModel.dataModel.attr(field.udaField)) {
            me.viewModel.dataModel.attr(field.udaField, new Date(parseInt(me.viewModel.dataModel.attr(field.udaField))));
          }
        })
      }

      _.each(authorizedForType.grids, (grid) => {
        let isCatchDetailGrid = grid.place === 'catchDetail' || grid.place === "landedDetail",
            origin;
        switch (me.viewModel.attr('dataModel.origin')) {
          case "IFIMS":
            origin = "-ifims";
            break;
          case "FSHLOG":
            origin = "-fishlog";
            break;
          case "GFCM":
            origin = "-gfcm";
            break;
          case "MANUAL":
            if (me.viewModel.attr('dataModel.actOldOrigin') == "FSHLOG")
            origin = "-fishlog";
            break;
        }
        let isFishlogCatchesGrid = ('grid-' + grid.gridNameFishlog + '-' + me.viewModel.attr('dataModel.kind') + origin).toLowerCase(),
            realItem             = grid.placeFn ? FN[grid.placeFn](mainItem) : mainItem,
            listDetails          = !!realItem && !!grid.place ? _.get(realItem, grid.place, undefined) : realItem,
            meGrid               = (mainItem.origin === "FSHLOG" || mainItem.origin === "IFIMS" || mainItem.origin === "GFCM" || mainItem.actOldOrigin === "FSHLOG") && !!me[isFishlogCatchesGrid] ? me[isFishlogCatchesGrid] : me[grid.gridName];

        if (_.isArray(realItem)) {
          let config = me[grid.gridConfig + 'Config'].attr();
          me.addCaptionToColumn(config.columns, grid);

          _.forEach(realItem, (subActivity, index) => {
            let gridName = `${index}_${grid.gridName}`;

            if (grid.isItter) {
              //for some reason, if we try to access the UdaGrid we previously created in the case of the #each
              //it doesn't appear, so we create a new UdaGrid every time.
              me[gridName] = new UdaGrid(me.$findById(gridName), config);
            }

            let currentGrid        = me[gridName],
                currentListDetails = !!subActivity && !!grid.place ? _.get(subActivity, grid.place, undefined) : subActivity;

            if (!_.isUndefined(currentGrid)) {
              currentGrid.beginUpdate();
              currentGrid.option('height', 'auto');
              currentGrid.option('dataSource', currentListDetails || []);
              currentGrid.endUpdate();
            }

            if (grid.hideIfEmpty) {
              me.viewModel.grids.attr(gridName, !!currentListDetails);
            } else {
              me.viewModel.grids.attr(gridName, true);
            }
          });
        } else {
          if (!_.isUndefined(meGrid)) {
            meGrid.beginUpdate();
            meGrid.option('height', 'auto');
            meGrid.option('dataSource', listDetails || []);
            meGrid.endUpdate();
          }

          if (grid.hideIfEmpty) {
            me.viewModel.grids.attr(grid.gridName, !!listDetails);
          } else {
            me.viewModel.grids.attr(grid.gridName, true);
          }
        }
      });
    }
  },

  handleFishlogConditionalFields: function (type, conf) {
    let me = this,
        conditionalFields = conf.filter((f) => !!f.activationCondition);

    if (conditionalFields.length) {
      let conditionTemplate = {},
          conditionParentFieldId = [];

      conditionalFields.forEach((f) => {
        const { key, value } = f.activationCondition,
              fieldId = f.labelKey || f.label;

        if (!conditionTemplate[key]) {
          conditionTemplate[key] = [];
          conditionParentFieldId.push(conf.find((f) => f.label === key));
        }

        conditionTemplate[key].push({ value, fieldId });
      });

      conditionParentFieldId.forEach((parentField) => {
        conditionTemplate[parentField.label].forEach(({ value, fieldId }) => {
          let realValue = value.attr ? value.attr(): value,
              conditionValue = me.viewModel.attr("dataModel." + parentField.udaField),
              isVisibleField = true;

          if (_.isArray(realValue)){
            isVisibleField = _.map(realValue, (c) => "" + c).includes(conditionValue);
          } else {
            isVisibleField = ("" + realValue) == ("" + conditionValue);
          }

          if (isVisibleField) {
            $(`[cmp-id="${fieldId}"]`).show();
          } else {
            $(`[cmp-id="${fieldId}"]`).hide();
          }
        });
      });
    }
  }
});

export default ErsActivityInfoPanel;
