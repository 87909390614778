import _ from "core-cmp/lodash";
import Zone from "core-zone/model/Zone";
import SaveZonePopup from "core-zone/ui/SaveZonePopup";

/**
 * Choose save option (create zone / Append zone geometry / replace zone geometry).
 * This class add special behaviour for zone creation.
 */
let ThmSaveZonePopup = SaveZonePopup.extend({

  /**
   * @override
   */
  createZone: function (params) {
    let data = {name: params.name, kind: params.kind, shared: params.kind === 'official'};

    switch (data.kind) {
      case 'official' : {
        data.typeName = 'ZEE';
        data.reference = "REF_" + data.name.toUpperCase().split(' ').join('_').substring(0, 6);
        break;
      }
      case "port" :
      case "landmark" :
      case "workstation": {
        data.reference = data.name.substr(0, 10);
        break;
      }
    }

    return new Zone(data);
  }
});

export default ThmSaveZonePopup;
