import ThmTrajectoryStyleInfoPanel from "thm/ui/style/ThmTrajectoryStyleInfoPanel";
import TrackingStyleInfoPanelTplStache from "thm/ui/style/ThmTrackingStyleInfoPanelTpl.stache";
import TrackingStyleEngine from "core-mobile/service/style/TrackingStyleEngine";

/**
 * ThmTrackingStyleInfoPanel
 */
let ThmTrackingStyleInfoPanel = ThmTrajectoryStyleInfoPanel.extend({
  ID: "trajectory-Tracking"
}, {
  id:          'trackingStyleInfoPanel',
  childTpl:    TrackingStyleInfoPanelTplStache,
  defautModel: {},

  /**
   * @override
   */
  getDefaultRules: function () {
    return TrackingStyleEngine.INSTANCE.rules;
  },

  initDefaultModel: function () {
    let me                = this,
        defaultStyleModel = me.getDefaultRules();

    me.defaultModel = {
      defaultPoint:        {
        pointRadius: defaultStyleModel.defaultPoint.pointRadius,
        strokeColor: defaultStyleModel.defaultPoint.strokeColor,
        fillColor:   defaultStyleModel.defaultPoint.fillColor
      },
      selected:            {
        pointRadius: defaultStyleModel.selected.pointRadius,
        strokeColor: defaultStyleModel.selected.strokeColor,
        fillColor:   defaultStyleModel.selected.fillColor
      },
      pointLabel:          {
        fontColor: defaultStyleModel.pointLabel.fontColor,
        textFont:  defaultStyleModel.pointLabel.textFont
      },
      lineDefault:         {
        strokeWidth: defaultStyleModel.lineDefault.strokeWidth,
        strokeColor: defaultStyleModel.lineDefault.strokeColor
      },
      backLine:            {
        strokeWidth: defaultStyleModel.backLine.strokeWidth,
        strokeColor: defaultStyleModel.backLine.strokeColor
      },
      estimationPoint:     {
        fillColor: defaultStyleModel.estimationPoint.fillColor
      },
      estimationLine:      {
        strokeWidth: defaultStyleModel.estimationLine.strokeWidth
      },
      lineBreakroute:      {
        strokeDashstyle: defaultStyleModel.lineBreakroute.strokeDashstyle
      },
      mobileDynamicTypes:  {
        select:    defaultStyleModel.mobileDynamicTypes.select,
        "default": defaultStyleModel.mobileDynamicTypes["default"]
      },
      mobileDynamicColors: {
        select:    defaultStyleModel.mobileDynamicColors.select,
        "default": defaultStyleModel.mobileDynamicColors["default"]
      }
    };
  }
});
export default ThmTrackingStyleInfoPanel;
