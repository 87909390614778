import ResourceUI from "core-uda/ui/ResourceUI";
import SsasContact from "core-uda/model/ssas/SsasContact";
import template from "thm/ui/common/ssas/SsasUITpl.stache";

/**
 * User Interface elements for SsasContacts
 */
let SsasContactUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   SsasContact.itemId,
      i18nPrefix:   "thm.ui.common.ssas.SsasUI.",
      icon:         "mail",
      template:     template,
      isListEditable: true
    })
  },


});

const SSAS_CONTACT_UI = new SsasContactUI();

export {SSAS_CONTACT_UI};
export default SsasContactUI;
