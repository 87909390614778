<field-text name="address" col="6 12"></field-text>
<field-text name="phone" format="phone-inter" col="6 12"></field-text>
<field-text name="emergencyPhone" format="phone-inter" col="6 12"></field-text>
<field-text name="fax" format="phone-inter" col="6 12"></field-text>
<field-text name="email" format="email" col="6 12"></field-text>
<field-text name="contact" col="6 12"></field-text>
<field-select name="direction" col="6 12" options="agencies"></field-select>
<field-text name="navyZone" col="6 12"></field-text>
<div class="inlineGroup">
    <field-text name="uhf1" col="3 6"></field-text>
    <field-text name="uhf2" col="3 6"></field-text>
    <field-text name="vhf1" col="3 6"></field-text>
    <field-text name="vhf2" col="3 6"></field-text>
</div>
