import ResourceUI from "core-uda/ui/ResourceUI";
import FishingGear from "core-uda/model/mobile/FishingGear";
import template from "core-mobile/ui/fishingGear/FishingGearTpl.stache";

/**
 * User Interface elements for FishingGear
 */
let FishingGearUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   FishingGear.itemId,
      i18nPrefix:   "thm.ui.common.fishingGear.FishingGearUI.",
      icon:         "fishHook",
      searchFields: ["name", "code"],
      template:     template,
      isListEditable: true
    })
  },

  /**
   * @override
   */
  getLabel: function (item) {
    return `${item.name} (${item.code})`;
  },

  /**
   *  @override
   *
   * @param error
   * @param defaultKey
   * @returns {*}
   */
  getItemDeletionErrorLabel: function (error, defaultKey) {
    let me     = this,
        msgKey = me.i18nPrefix,
        errorType = error.args[0].split(' ')[1];

    if (error.exception !== "INTERNAL_ERROR") {
      msgKey += _.camelCase(errorType);
    } else {
      msgKey = defaultKey;
    }

    return msgKey;
  }

});

const FISHING_GEAR_UI = new FishingGearUI();

export default FishingGearUI;
