<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
    <label class="annotation inlineGroup">{{msg 'alertTriggered' }} :</label>

    <div class="col-xs-4" style="padding:0">
        <field-text name="min" label="" format="speed" required="true"></field-text>
    </div>
    <div class="col-xs-4" style="text-align: center;">
        <label style="margin: 0;line-height: 44px;text-align: center;"> < {{msg '_SpeedLight'}} < </label>
    </div>
    <div class="col-xs-4" style="padding:0">
        <field-text name="max" label="" format="speed" required="true"></field-text>
    </div>
    <field-checkbox name="portsIncluded" col="12 12"></field-checkbox>
</div>
