<div id="{{id}}" class="page">
  <div class="header-group">
    <h3>
      {{msg 'title'}}
    </h3>
  </div>
  <field-item item="activity">
    <div class="inlineGroup fields">
      <field-date name="startDate" format="dateTime" required="true"></field-date>
      <field-date name="endDate" format="dateTime" {visible}="hasEndDate"></field-date>
      <field-textarea name="reason" no-resize="true" max-length="100"></field-textarea>
    </div>
    <div class="header-group icon-left icon-m ui-icon-user {{#unless groups.dealership}}hidden{{/unless}}" style="width: 98%; margin-left: 1%">
      <h2>{{msg 'dealership'}}</h2>
    </div>
    <div class="inlineGroup fields">
      <field-date name="dealershipSignatureDate" format="dateTime" {visible}="groups.dealership"></field-date>
      <field-date name="dealershipExpirationDate" format="dateTime" {visible}="groups.dealership"></field-date>

      <field-list name="species"
                  format="items"
                  counter="true"
                  {item-template}="speciesTemplate"
                  edit-mode="CUD"
                  {edit-page}="ersActivitySpeciesEditPage"
                  {edit-page-params}="ersActivitySpeciesEditParams"
                  header-icon="config"
                  {visible}="hasSpecies">
      </field-list>
    </div>
  </field-item>

  <ui-submit-cancel {status}="saveStatus"></ui-submit-cancel>
</div>
