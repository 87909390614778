<div class="L-scrollY-header header-page">
    {{#if subTitleInfo}}
        <h4>{{titleInfo}}</h4>
        <h5>{{subTitleInfo}}</h5>
    {{else}}
        <h3>{{titleInfo}}</h3>
    {{/if}}

    {{#if allowClose}}
        <a cls-action="close"
           class="btn btn-header-{{direction}} ui-icon-{{iconClose}} visible-page-rpanel"
           title="{{msg '_Close'}}"></a>
        {{#if allowMenu}}
            <a cls-action="menu" class="btn ui-icon-bars btn-header-right-2 visible-page-rpanel"></a>
            <a cls-action="menu" class="btn ui-icon-bars btn-header-right visible-tab"></a>
        {{/if}}
    {{else}}
        {{#if allowMenu}}
            <a cls-action="menu" class="btn btn-header-right ui-icon-bars"></a>
        {{/if}}
    {{/if}}
    {{#if allowWidth}}
        <a cls-action="width"
           class="btn btn-header-left {{iconClass}} visible-rpanel" title="{{msg '_Width'}}"></a>
    {{/if}}
</div>