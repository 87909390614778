<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>
  <field-item item="dataModel" class="L-scrollY-content">
    <div class="inlineGroup fields">
      <field-text name="name"></field-text>
      <field-switch name="nature" text-on-key="field.nature.vms" text-off-key="field.nature.ers" value-off="ERS"
                    value-on="VMS"></field-switch>
      <field-checkbox name="saveSentMessages"></field-checkbox>
      <field-select name="flagId" {options}="flagOptions"></field-select>
      <field-select name="cpcProtocolId" {options}="cpcProtocolOptions"></field-select>
      <a id="test-button" cls-action="testConnection" class="btn">{{msg "testConnection"}}</a>
      <field-select name="cpcFormatId" {options}="cpcFormatOptions"></field-select>
      <field-text name="accord"></field-text>
      <field-date name="startDate" period="myPeriod" role="start" format="dateTime" col="6 12"></field-date>
      <field-date name="endDate" period="myPeriod" role="end" format="dateTime" col="6 12"></field-date>
      <field-textarea name="description"></field-textarea>
      <field-checkbox class="activated" name="activated"></field-checkbox>
      <uda-list of="cpcConditions" name="cpcConditions" format="string"
                {visible}="dataModel.activated"></uda-list>
    </div>
  </field-item>
  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
