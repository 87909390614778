import ResourceUI from "core-uda/ui/ResourceUI";
import UserGroup from "core-uda/model/userGroup/UserGroup";
import template from "core-zone/ui/ZoneUserGroupTpl.stache"

/**
 * User Interface elements for UserGroup
 */
let UserGroupUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: UserGroup.itemId,
      i18nPrefix: "thm.ui.common.userGroup.UserGroupUI.",
      icon:       "user2",
      template:   template
    })
  }
});

const USER_GROUP_UI = new UserGroupUI();

export default UserGroupUI;
