import _ from "core-cmp/lodash";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import {APP} from "core-uda/model/Resource";
import FamilyModelPrefDAO from "core-metoc/model/FamilyModelPrefDAO";
import MultiModel from "core-metoc/model/multiLayer/MultiModel";
import DefaultModel from "core-metoc/model/multiLayer/DefaultModel";

/**
 * Modèle de données Metoc gérant 3 couches de données :
 * - image : Donnée représentée sous la forme d'une image en dégardé de couleur.
 * - vector : Donnée vestorielle (vent, courant,...)
 * - isoligne : Donnée représentée sous la forme d'isolignes
 */
let UMVMetocModel = MultiModel.extend({
  families:                {},
  modelClazz:              DefaultModel,
  config:                  null,
  models:                  null,

  /**
   * @override
   */
  init: function () {
    let me = this;
    me.familyDao = new FamilyModelPrefDAO();
    me.attr("legends", []);
    me.model = {};
    me.config = {};
    me._super();
  },

  loadConfig: function (context) {
    const me = this,
          config = {
            models: {
              metoc: {
                families: [{
                  id:     "image",
                  index:  1,
                  family: "image",
                  icon:   "griddedData",
                  title: {
                    "en": "Picture",
                    "fr": "Image"
                  }
                }, {
                  id:     "vector",
                  index:  2,
                  family: "vector",
                  icon:   "vectorData",
                  title: {
                    "en": "Vector data",
                    "fr": "Données Vectorielles"
                  }
                }, {
                  id:     "isoline",
                  index:  3,
                  family: "isoline",
                  icon:   "isoline",
                  title: {
                    "en": "Isoline",
                    "fr": "Isoligne"
                  }
                }],
                groups: {},
                services: [{
                }]
              }
            }
          };

    // Generate uda proxy url
    _.each(context.wmsServices, (wmsService) => {
      wmsService.url = UDA_URL + 'map/metoc/' + wmsService.id;
      wmsService.httpHeaders = {
        jwt:         context.token,
        application: context.application
      }
    });

    config.models.metoc.services = context.wmsServices;

    me.setConfig(config);
  },

  getModelClass: function (id) {
    let me    = this,
        clazz = me._super(id);

    if (id === "others") {
      clazz = me.modelClazz;
    }

    return clazz;
  },
});

export default UMVMetocModel;
