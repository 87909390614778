import Mobile from "core-uda/model/mobile/Mobile";
import Animal from "core-uda/model/mobile/Animal";
import _ from "core-cmp/lodash";
import {RIGHTS} from "core-uda/Rights";
import {MAIN_PAGE} from "core-uda/ui/UdaMainPage";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import Toastr from "core-cmp/Toastr";
import {APP} from "core-uda/model/Resource";
import MobileInfoPanel from "thm/ui/common/mobile/MobileInfoPanel";
import BeaconLogPopup from "core-mobile/ui/beacon/BeaconLogPopup";
import BeaconLog from "core-uda/model/beacon/BeaconLog";
import Beacon from "core-uda/model/beacon/Beacon";
import $ from "thm/thm-jquery";
import InformationPopup from "core-cmp/msg/InformationPopup";
import AnimalInfoPanelTplStache from "thm/ui/common/mobile/AnimalInfoPanelTpl.stache";
import BeaconTplStache from "thm/ui/common/mobile/BeaconTpl.stache";
import BeaconEditTplStache from "core-mobile/ui/beacon/BeaconTpl.stache";
import MobileLifeEvent from "core-uda/model/mobileLifeEvent/MobileLifeEvent";


/**
 * @class thm.ui.common.mobile.AnimalInfoPanel
 * @parent thm.ui.themis.mobile
 * @constructor
 * Afficher les infos d'un mobile sous themis
 * @param {Object} options
 */
let AnimalInfoPanel = MobileInfoPanel.extend({
  template:       AnimalInfoPanelTplStache,
  i18nPrefix:     [
    'thm.ui.common.mobile.MobileInfoPanel.',
    'uda.animal.',
    'uda.animal.field.'
  ],
  allowEdit:      true,
  allowClone:     true,
  entityId:       'animals',
  disabledFields: ['disabledFields'],


  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this;

    dataModel.attr({
      imo:                      "",
      mmsi:                     "",
      name:                     me.msg("_Clone").toUpperCase() + " - " + dataModel.attr("name"),
      beacons:                  "",
      activeBeaconRef:          "",
      activeBeaconId:           "",
      activeBeaconDnid:         "",
      activeBeaconMember:       "",
      activeBeaconSubAddress:   "",
      activeBeaconSerialNumber: "",
      activeBeaconProviderName: "",
      activeBeaconProviderType: "",
      activeBeaconModelId:      "",
      activeBeaconModelName:    ""
    });
  },

  /**
   * @override
   */
  bindEvents: function () {
    let me        = this,
        dataModel = me.viewModel.dataModel;

    me._super();
    APP().on("beaconStateChanged", function (ev, state, item) {
      // On supprime l'item de la liste
      if (state === "false") {
        APP('beacons', item.id).removeAttr('mobileId');
        if (dataModel.activeBeaconId === item.id) {
          APP('beacons', item.id).attr('active', false);
          dataModel.attr('activeBeaconId', undefined);
        }
      }
    });
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr('dataModel').on('active', function (ev, newVal, oldVal) {
      // newVal is undefined if form is rest
      if (!_.isNil(newVal) && oldVal) {
        InformationPopup.openPage({
          titleKey: '_Caution',
          textKey:  'mobileActiveChanged'
        });
      }
    });

    me.viewModel.attr('dataModel').on('ssasTerminal', function () {
      me.viewModel.attr('showTerminalLevel', $.isTrue(me.viewModel.attr('dataModel.ssasTerminal')));
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me   = this,
        tabs = [];

    me._super();

    tabs.push({
      value: "identification",
      text:  me.msg('titleIdentification')
    }, {
      value: "contacts",
      text:  me.msg('contact')
    }, {
      value: "events",
      text:  me.msg('titleEvents')
    });
    me.tabs = tabs;
    me.viewModel.attr('tabs', me.tabs);

    // Beacons
    me.viewModel.attr('beaconsTemplate', BeaconTplStache);
    me.viewModel.attr('beaconEditTemplate', BeaconEditTplStache);
    me.viewModel.attr('onChangeActiveBeacon', me.proxy(me.changeActiveBeacon));
    me.viewModel.attr('onDeleteBeacon', me.proxy(me.deleteBeacon));
    me.viewModel.attr('onBeaconUpdated', me.proxy(me.onBeaconUpdated));
    me.viewModel.attr("beaconListToolbarActions", [{
      id:      "log",
      icon:    "grid",
      label:   me.msg("core-mobile.ui.beacon.BeaconLogPopup.title"),
      handler: me.onLog.bind(me)
    }]);

    // Animals Specific fields
    me.viewModel.attr('domesticationOptions', APP("animal").getDomesticationOptions());
    me.viewModel.attr('genderOptions', APP("animal").getGenderOptions());
    me.viewModel.attr('importanceOptions', APP("animal").getImportanceOptions());
    me.viewModel.attr('maturityOptions', APP("animal").getMaturityOptions());
    me.viewModel.attr("age", undefined);
    me.viewModel.attr("dataModel").on("externalImage", (wc, newVal, oldVal) => {
      me.updateExternalImage(newVal);
    });
    me.viewModel.attr("dataModel").on("birthDate", (wc, newVal, oldVal) => {
      if (newVal) {
        me.updateAge(newVal);
      } else {
        me.viewModel.attr("age", undefined);
      }
    });

    me.viewModel.attr('dataModel').on('speciesId', (wc, newVal, oldVal) => {
      me.updateSpecies(newVal);
    });
  },

  //Override
  updateViewModel: function () {
    let me         = this,
        isNew      = _.isNil(me.data.id),
        extImgList = APP('externalImages').getData();

    // On ajoute pour chaque image le nom à afficher
    extImgList.filter(img => {
      img.name = img.id;
    });

    me._super();

    me.updateExternalImage(me.data.externalImage);
    if (me.data.birthDate) {
      me.updateAge(me.data.birthDate);
    }

    me.updateBeaconsViewModel();

    if (isNew) {
      me.viewModel.dataModel.attr("customerId", APP().user.customerId);
    }

    me.viewModel.attr("imagesToDisplay", _.map(extImgList, (val) => {
      return {src: me.externalImageToSrcUrl(val), imgName: val.id};
    }));

    me.updateSpecies(me.data.speciesId);
  },

  /**
   * @override
   */
  loadForEdition: function (data) {
    let deferred = new $.Deferred();

    Mobile.loadForEdition(data.id, data).done((mobile) => {
      let args = [];

      if (DATA_AUTHORIZED("beacon")) {
        args.push(Beacon.refreshAll());
      }
      if (args.length === 0) {
        deferred.resolve(mobile);
      }

      $.when(...args).done(() => {
        // Passage des champs de animal dans mobile
        deferred.resolve(Object.assign(mobile, data));
      })
    }).fail((error) => {
      deferred.reject(error);
    });
    return deferred;
  },

  isValidGregarious: function () {
    let me           = this,
        isValid      = true,
        speciesId    = me.viewModel.dataModel.attr("speciesId"),
        fleetId      = me.viewModel.dataModel.attr("fleetId"),
        species      = APP('species', speciesId),
        isGregarious = !_.isNil(speciesId) && species.gregarious;

    if (isGregarious && _.isNil(fleetId)) {
      isValid = false;
      Toastr.showToastError("noFleetForGregariousSpecies", 3000, species.name);
    }

    return isValid;
  },

  /**
   * Function which allow to check if the mobileEvents have been updated or not
   * @param animal
   * @returns {boolean}
   */
  checkAnimalEventsAreUpdated: function(animal){
    let me = this,
        oldEvents,
        animalEvents,
        diff;

    oldEvents = me.data.events ? me.data.events.attr() : [];
    animalEvents = animal.events ? animal.events.attr() : [];

    diff = UTILS.diff(oldEvents, animalEvents, {
      numberAsString: true,
      nilAsFalse:     true
    });

    return diff && diff.count > 0;
  },

  /**
   * @override
   */
  onSave: function (forceClose) {
    let me           = this,
        modelRequest = me.getDataModel(),
        events       = [],
        eventsUpdated;

    if (me.checkErrors()) {
      me.showErrorFields();
      return;
    }

    if (!me.isValidGregarious()) {
      return;
    }

    let animal = new Animal(modelRequest);
    if (!RIGHTS('mobile.allowBeaconLink')) {
      delete animal.beacons;
    }
    eventsUpdated = me.checkAnimalEventsAreUpdated(animal);

    Object.assign(events, animal.events);
    animal.removeAttr('events');
    me.listenStatus(animal.save(), "saveStatus").done(function (data) {
      if(eventsUpdated) {
        me.saveEvents(events, data.id).done((dataEvents) => {
          data.attr("events", dataEvents);
          if (data.active) {
            Toastr.showToastr("success", "animalUpdated");
          } else {
            Toastr.showToastr("success", "animalDisactivated");
          }
          APP().trigger("animalsLoaded");
          me.onSaveSuccess(data, forceClose);
        }).fail((errors) => {
          me.onSaveError(errors);
        });
      }else{
        if (data.active) {
          Toastr.showToastr("success", "animalUpdated");
        } else {
          Toastr.showToastr("success", "animalDisactivated");
        }
        APP().trigger("animalsLoaded");
        me.onSaveSuccess(data, forceClose);
      }
    });
  },

  saveEvents: function (events, mobileId) {
    let me       = this,
        deferred = new $.Deferred();

    const eventsToSend = new Array();

    if (events && events.length) {
      events.forEach(item => {
        item.attr("mobileId", mobileId);
        eventsToSend.push(item._data);
      });

      return MobileLifeEvent.updateList(eventsToSend, mobileId).done((data) => {
        deferred.resolve(data);
      });
    } else {
      if (me.data.events && me.data.events.length) { //cas où on n'a plus d'events mais on en avait avant
        return MobileLifeEvent.updateList(eventsToSend, mobileId).done((data) => {
          deferred.resolve(data);
        });
      }
      deferred.resolve(true);
    }
    return deferred;
  },

  /**
   * @override
   */
  onSaveSuccess: function (data, forceClose) {
    let me = this;

    if (data.active) {
      me._super(data, forceClose);
      me.updateBeaconModel(data.id, data.beacons);
    } else {
      Toastr.showToastr('success', "mobileDisactivated");
      APP().setById(me.entityId, data.id, null);
      if (me.managementMode) {
        setTimeout(function () {
          me.close();
        }, 500);
      }
    }
  },

  /****************** BEACONS ******************/

  updateBeaconModel: function (id, beacons) {
    let me = this,
        beacon;

    if (!beacons || beacons.length === 0 || APP().getModelSize('beacons') === 0) {
      return;
    }

    _.each(beacons, function (beaconId) {
      beacon = APP('beacons', beaconId);
      if (beacon) {
        beacon.attr('mobileId', id);
      }
    });
  },

  updateBeaconsViewModel: function () {
    let me     = this,
        beacon = APP('beacons', me.viewModel.dataModel.activeBeaconId);

    if (beacon) {
      beacon.attr("active", true);
    }
  },

  deleteBeacon: function (wc, dom, wcEvent, wcList, item) {
    let me = this,
        beacon,
        beacons;

    if (!item) {
      return;
    }

    beacon = APP("beacons", item.id);
    beacons = me.viewModel.attr("dataModel.beacons") || [];

    beacon.removeAttr("mobileId");

    if (beacon.active) {
      beacon.attr("active", false);
      if (beacons.length > 0) {
        let beaconTmp = _.find(wcList.itemOptions, {id: beacons[0]});
        me.viewModel.attr("dataModel.activeBeaconId", beaconTmp.id);
        beaconTmp.attr("active", true);
      } else {
        me.viewModel.attr("dataModel.activeBeaconId", "");
      }
    }
  },

  changeActiveBeacon: function (wc, dom, wcEvent, wcList, item) {
    let me = this,
        activeBeaconId,
        beacons;

    if (!item) {
      return;
    }

    activeBeaconId = item.id;
    beacons = me.viewModel.attr("dataModel.beacons") || [];

    _.each(beacons, (beacon) => {
      let beaconTmp = _.find(wcList.itemOptions, {id: beacon});
      beaconTmp.attr('active', activeBeaconId === beaconTmp.id);
    });

    // Request model
    me.viewModel.attr('dataModel.activeBeaconId', activeBeaconId);

  },

  onBeaconUpdated: function (wc, dom, wcEvent, wcList, item) {
    let me = this,
        state,
        beacons;

    if (!item) {
      return;
    }

    beacons = me.viewModel.attr("dataModel.beacons") || [];
    state = !!_.find(beacons, (beaconId) => beaconId === item.id);

    if (state === true) {
      me.deleteBeacon(wc, dom, wcEvent, wcList, item);
    } else if (!beacons.length) {
      item.attr('active', true);
      me.viewModel.dataModel.attr('activeBeaconId', item.id);
    }
  },

  onLog: function (ev) {
    let me = this;

    // Do not abort BeaconLog openning for mobile without beacons. It may havec had beacons.
    BeaconLog.findBy({selectBy: "mobile:" + me.data.id}).done((data) => {
      BeaconLogPopup.openPage({
        data: data
      });
    });
  },

  extensionToMimeType(imgExtension) {
    switch (imgExtension) {
      case "apng":
      case "bmp":
      case "gif":
      case "jpeg":
      case "png":
      case "tiff":
      case "webp":
        return imgExtension;
      case "ico":
      case "cur":
        return "x-icon";
      case "tif":
        return "tiff";
      case "jpg":
      case "jfif":
      case "pjpeg":
      case "pjp":
        return "jpeg";
      case "svg":
        return "svg+xml";
      default:
        return "";
    }

  },

  externalImageToSrcUrl: function (extImg) {

    if (!extImg) {
      return;
    }

    let me           = this,
        imgUrl       = "data:image/",
        imgExtension = extImg.type,
        imgData      = extImg.b64Image;
    imgUrl += me.extensionToMimeType(imgExtension);
    imgUrl += ";base64, " + imgData;
    return imgUrl;
  },

  updateExternalImage: function (imageId) {
    let me = this;
    if (_.isNil(imageId) || imageId === '') {
      me.viewModel.attr('imgSrc', "");
      me.viewModel.attr('imgName', '');
    } else {
      me.viewModel.attr('imgSrc', me.externalImageToSrcUrl(APP('externalImages', imageId)));
      me.viewModel.attr('imgName', imageId);
    }
  },

  updateAge: function (dateValue) {
    let me         = this,
        today      = new Date(),
        birthDate  = new Date(dateValue),
        yearToMs   = 365.25 * 24 * 60 * 60 * 1000,
        diffInYear = new Number((today.getTime() - birthDate.getTime()) / yearToMs).toFixed(1);
    me.viewModel.attr("age", diffInYear);
  },

  updateSpecies: function (speciesId) {
    let me = this;

    if (speciesId) {

      me.viewModel.attr("gregarious", APP('species', speciesId).gregarious);

      if (me.viewModel.attr("gregarious") && me.data.fleetId) {
        me.viewModel.attr("domestication", me.msg(APP('fleets', me.data.fleetId).domestication));
      }

    } else {
      me.viewModel.attr("gregarious", undefined);
      me.viewModel.attr("domestication", undefined);
    }
  }

});
export default AnimalInfoPanel;
