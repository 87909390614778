<div id="{{id}}" class="page">
    <ui-list class="L-fit"
             {item-template}="positionTemplate"
             {items}="positionList"
             edit-mode=""
             close-page="true"
             {(active-item)}="position">
        <ui-list-header class="header-group"
                        {search-fields}="searchFields">
            <h3>{{title}}</h3>
        </ui-list-header>
    </ui-list>
</div>
