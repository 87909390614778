import ThmZoneInfoPanel from "thm/ui/common/zone/ThmZoneInfoPanel";

/**
 * @class ZoneLandmarkInfoPanel
 * Landmark form
 */
let ZoneLandmarkInfoPanel = ThmZoneInfoPanel.extend({
  id: 'zoneLandmarkInfoPanel'
});
export default ZoneLandmarkInfoPanel;
