import _ from "core-cmp/lodash";
import {FEATURE_TYPE} from "core-gis/FeatureConstants";
import InfoHandler from "core-gis/service/info/InfoHandler";
import tooltipStache from "thm/service/info/meteoStationRecord/MeteoStationRecord.tooltip.stache";
import infoStache from "thm/service/info/meteoStationRecord/MeteoStationRecord.info.stache";
import MeteoStationRecord from "thm/model/meteoStationRecord/MeteoStationRecord";
import DateFormat from "core-cmp/format/DateFormat";

/**
 * Mobiles positions handler.
 */
let MeteoStationRecordHandler = InfoHandler.extend({
  ID: "meteoStationRecord",
  TYPES: [FEATURE_TYPE.METEO_STATION_AERO],
  RESOURCES: [MeteoStationRecord.itemId]
}, {
  id:          "meteoStationRecord",
  dataId:      "meteoStationRecord",
  type:        "meteoStationRecord",
  tooltipView: tooltipStache,
  infoView:    infoStache,
  titleClickable: false,
  tooltipScrollable: false,
  i18nPrefix:  "thm.service.info.MeteoStationRecordHandler.",

  /**
   * @override
   */
  getTitle: function (index) {
    let me                   = this,
        meteoStationName = _.get(me.model.meteoStationRecordMetar, 'meteoStationName') || _.get(me.model.meteoStationRecordTaf, 'meteoStationName');

    return meteoStationName || "";
  },

  /**
   * @override
   */
  getSubTitle: function (index) {
    let me                   = this,
        recordData = _.get(me.model.meteoStationRecordMetar, 'recordDate') || _.get(me.model.meteoStationRecordTaf, 'recordDate');

    return recordData ? DateFormat.DATE_FORMAT.modelToView(recordData) : "";
  },

  /**
   * @override
   */
  centerOnModel: function () {
    let me = this,
        meteoStationLocation = _.get(me.model.meteoStationRecordMetar, 'meteoStationLocation') || _.get(me.model.meteoStationRecordTaf, 'meteoStationLocation'),
        xy = GIS.locToMap(meteoStationLocation);

    GIS.setCenter(xy);
  },

  /**
   * @override
   */
  getViewModel: function (index) {
    let me = this,
        meteoStationRecordMetar = me.model.meteoStationRecordMetar,
        meteoStationRecordTaf = me.model.meteoStationRecordTaf,
        hideAll = _.isNil(meteoStationRecordMetar) && _.isNil(meteoStationRecordTaf),
        displayMetarSpec = !_.isNil(me.model.meteoStationRecordData.metar) ? !_.isNil(me.model.meteoStationRecordData.metar.temperatureSI) : false,
        displayTafSpec = !_.isNil(me.model.meteoStationRecordData.taf.wind) ? !_.isNil(me.model.meteoStationRecordData.taf.wind.directionDegrees) : false;

    if (!_.isNil(me.model.meteoStationRecordData.taf.clouds)) {
      _.map(me.model.meteoStationRecordData.taf.clouds, (c) => {
        switch (c.quantity) {
          case 'SKC':
            c.cloudsView = 'Sky clear'
            break;
          case 'FEW':
            c.cloudsView = '1-2 oktas'
            break;
          case 'SCT':
            c.cloudsView = '3-4 oktas'
            break;
          case 'BKN':
            c.cloudsView = '5-7 oktas'
            break;
          case 'OVC':
            c.cloudsView = '8 oktas'
            break;
        }
        return c;
      })
    }

    let title = _.get(meteoStationRecordMetar, 'meteoStationName') || _.get(meteoStationRecordTaf, 'meteoStationName'),
        date = _.get(meteoStationRecordMetar, 'recordDate') || _.get(meteoStationRecordTaf, 'recordDate');

    return _.extend(me._super(index), {
      meteoStationRecordMetar: meteoStationRecordMetar,
      meteoStationRecordTaf: meteoStationRecordTaf,
      recordData: me.model.meteoStationRecordData,
      displayMetarSpec: displayMetarSpec,
      displayTafSpec: displayTafSpec,
      title: title || "",
      date: date || "",
      hideAll: hideAll
    });
  },

});

export default MeteoStationRecordHandler;
