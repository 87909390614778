<div id="{{id}}" class="page">
  <div class="header-group">
    <h3>{{title}}</h3>
    <div cls-action="close" class="btn btn-header-right ui-icon-close"></div>
  </div>
  <div class="fields inlineGroup">
    <field-search label="Search Query" {(field)}="searchQuery"></field-search>
    <field-states label="" {(field)}="kind" {options}="kindOptions" format="array" options-col="2"></field-states>
  </div>

  <div class="inline-1btn">
    <a cls-action="clear" class="btn btn-cancel">{{msg "core-uda.ui.ResourceListCmp.clearFilter"}}</a>
  </div>
</div>
