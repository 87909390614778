<div id="{{id}}" class="page L-panel size-xs">
    <div class="L-panel-header">
        <div class="header-page">
            <h1>{{title}}</h1>
            <a cls-action="close" class="btn btn-header-left ui-icon-arrow-l"></a>
            <a cls-action="import" class="btn btn-header-right ui-icon-import" title="{{msg 'updateNafTemplate'}}"></a>
        </div>
        <field-radios {(field)}="type" border="false" label="" {options}="typeOptions" label-align="top"
                      options-col="2"></field-radios>
        <field-select {(field)}="activity" class="l20-f80 w-100" {options}="activityOptions" option-none="true"></field-select>
    </div>

    <field-item item="dataModel" class="L-panel-content">
        <div class="header-group icon-left icon-l ui-icon-annotation">
            <h3>Activity details</h3>
        </div>
        <div class="header-group icon-left {{#unless neededMobile}}hidden{{/unless}}">
            <div class="header-toolbar ">
                <uda-select id="{{id}}-mobileSelect" of="mobiles" name="mobileId" col="6 12"></uda-select>
                <div class="btn ui-icon-position {{#unless neededPosition}}hidden{{/unless}} {{#unless mobileSelected}}disabled{{/unless}}" title="{{msg 'choosePosition'}}" cls-action="position"></div>
            </div>
        </div>
        <div id="{{id}}-generated-form" class="inlineGroup fields {{#unless stacheGenerated}}hidden{{/unless}}"></div>
    </field-item>
    <div class="header-group icon-left icon-l ui-icon-mail2">
        <h3>Raw NAF message</h3>
    </div>
    <div class="inlineGroup fields">
        <field-textarea {(field)}="rawMessage" label=""></field-textarea>
    </div>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" submit-label="{{msg "_Send"}}"></ui-submit-cancel>
</div>
