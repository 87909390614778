import StyleEngine from "core-gis/service/style/StyleEngine";
import MeteoStationRecordStyleBuilder from "thm/service/style/meteoStationRecord/MeteoStationRecordStyleBuilder";

const COLOR_BLUE = "#3366ff";
const COLOR_WHITE = "#FFFFFF";
const COLOR_GREEN = "#008000";
const COLOR_ORANGE = "#ff9900";
const COLOR_RED = "#ff0000";
const COLOR_GREY = "#999999";
/**
 * Search Area Determination Rendering.
 */
let MeteoStationRecordStyleEngine = StyleEngine.extend({
  modelName:     "MeteoStationRecord",
  type:          MeteoStationRecordStyleBuilder,
  typeStyle:     "MeteoStationRecord",
  specificRules: {
    "circle": {
      extend: "getColor",
      fillOpacity:     1,
      strokeOpacity:   0.9,
      strokeDashstyle: "solid",
      strokeWidth:     5,
      pointRadius:     15
    },
    "getColor": function (feature, attributes) {
      let color = COLOR_GREY;

      if (_.isNil(attributes.visibility) ||_.isNil(attributes.visibility.mainVisibility) ) {
        return {
          fillColor:    color,
          strokeColor:  color
        };
      }

      let visibility = attributes.visibility.mainVisibility;

      if (visibility === '>10km') {
        color = COLOR_BLUE;
        return {
          fillColor:    color,
          strokeColor:  color
        };
      }

      let visibilityInt = parseInt(visibility.substring(0, visibility.length - 1));

      switch (true) {
        case visibilityInt >= 5000 && visibilityInt <= 9000:
          color = COLOR_WHITE;
          break;
        case visibilityInt >= 4000 && visibilityInt <= 4900:
          color = COLOR_GREEN;
          break;
        case visibilityInt >= 500 && visibilityInt <= 3900:
          color = COLOR_ORANGE;
          break;
        case visibilityInt < 500:
          color = COLOR_RED;
          break;
      }

      return {
        fillColor:    color,
        strokeColor:  color
      };
    }
  }


});

MeteoStationRecordStyleEngine.INSTANCE = new MeteoStationRecordStyleEngine();
export default MeteoStationRecordStyleEngine;
