<div id="{{id}}" class="page">
    <div class="header-group">
        <h3>
            {{title}}
        </h3>
    </div>

    <field-item  item="dataModel" class="inlineGroup fields">
        <field-date {(field)}="date" format="dateTime" required="true"></field-date>
        {{#unless isDecimal}}
          <field-lat-lon {(field)}="longitude" is-latitude-field="false" required="true"></field-lat-lon>
          <field-lat-lon {(field)}="latitude" required="true"></field-lat-lon>
        {{/unless}}
        {{#if isDecimal}}
          <field-text {(field)}="longitude" name="longitude" format="longitude" label-key="_Longitude" required="true"></field-text>
          <field-text {(field)}="latitude" name="latitude" format="latitude" label-key="_Latitude" required="true"></field-text>
        {{/if}}

        <field-text {(field)}="speed" format="speed" ></field-text>
        <field-text {(field)}="heading" format="heading"></field-text>
    </field-item>

    <div class="inline-2btn btn-footer">
        <a cls-action="send" class="btn btn-ok">{{msg '_Modify'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
