import {canDefineMap} from "core-cmp/can";
import ResourceUI from "core-uda/ui/ResourceUI";
import Beacon from "core-uda/model/beacon/Beacon";
import template from "thm/ui/common/beacon/BeaconTpl.stache";
import {MOBILE_DATA} from "core-uda/model/mobile/MobileData";
import {APP} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";

/**
 * User Interface elements for Beacon
 */
let BeaconUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:     Beacon.itemId,
      i18nPrefix:     "thm.ui.common.beacon.BeaconUI.",
      icon:           "beacon",
      labelField:     "reference",
      searchFields:   ["reference"],
      template:       template,
      isListEditable: true
    })
  },

  /**
   * @override
   */
  getUdaExportColumn: function (field) {
    switch (field) {
      case "mobileId" :
        return MOBILE_DATA.getMobilePrefToData(1);
      default :
        return field;
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "mobileId": {
        format:   (value) => MOBILE_DATA.getMobileLabel(APP("mobile", value)),
        dataType: 'string'
      },
      "inmarsatOcean": I18N_FIELD("uda.beacon.field.oceanRegion.options."),
      "inmarsatOcean2": I18N_FIELD("uda.beacon.field.oceanRegion.options."),
    }
  }
});

const BEACON_UI = new BeaconUI();

export default BeaconUI;
