<div id="{{id}}" class="page">
    <field-item item="exportModel">
        <div class="header-group icon-left icon-m ui-icon-export">
            <h2>{{msg 'exportModeTitle'}}</h2>
        </div>
      <div class="inlineGroup fields">
        <field-radios name="exportMode" options="exportModeOptions" class="l30-f70"></field-radios>
        <uda-list of="species" name="species" {disabled}="speciesDisabled"></uda-list>
      </div>
    </field-item>

  <ui-submit-cancel class="L-scrollY-footer-btn" submit-label-key="btnExport" cancel-label-key="btnClose" {status}="saveStatus" style="bottom: 0px;"></ui-submit-cancel>
</div>
