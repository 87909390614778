import Page from "core-cmp/Page";
import ExportTypePickerPopupStache from "thm/ui/common/popup/ExportTypePickerPopupTpl.stache";

/**
 * @class ExportTypePickerPopup
 *
 * Popup to chose the type of export
 */
let ExportTypePickerPopup = Page.extend({
  id:              "exportTypePickerPopup",
  routerType:      'popup',
  template:        ExportTypePickerPopupStache,
  i18nPrefix:      ['thm.ui.common.popup.ExportTypePickerPopup.'],
  list:            ['pdf', 'csv', 'xlsx'],
  autoBindActions: true,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('formatOptions', me.list);
    me.viewModel.attr('dataFormModel', {});
    me.viewModel.attr('dataFormModel.format');
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me      = this,
        context = me.context;

    me._super();

    if (context.list && context.list.length) {
      me.viewModel.attr('formatOptions', context.list);
      me.viewModel.attr('dataFormModel.format', context.list[0]);
    }
  },

  // fonction appellée via bindActions
  onDownload: function () {
    let me     = this,
        params = me.viewModel.attr('dataFormModel'),
        ctx    = me.context; // The context will be destroy by close, save it to use it after close

    me.close().done(() => {
      ctx.onExport(params.format);
    });
  }
});

export default ExportTypePickerPopup;
