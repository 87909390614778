<div class="header-group icon-left icon-m ui-icon-config">
  <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
  <div class="list-field col-sm-12 col-size-xs-12">
    <label class="annotation inlineGroup">{{msg 'statusAnomalyDescription'}} : </label>
  </div>
  <field-select name="status"
                options="statusOptions"
                option-none="false"
                col="6 12"
                required="true"></field-select>
  <field-text name="maxDuration" format="duration" required="true"></field-text>
</div>
