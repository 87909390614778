import _ from "core-cmp/lodash";
import Page from "core-cmp/Page";
import "core-ure/ui/favorite/AddFavoritePopup.css";
import MobileThemisUpdateCustomersPopupTpl from "thm/ui/common/mobile/MobileThemisUpdateCustomersPopupTpl.stache";
import FixCustomerAssociation from 'core-uda/model/mobile/FixCustomerAssociation';
import Toastr from "core-cmp/Toastr";

/**MobileThemisUpdateCustomersPopup
 * @class MobileThemisUpdateCustomersPopup
 */
let MobileThemisUpdateCustomersPopup = Page.extend({
  id:                  "mobileThemisUpdateCustomersPopup",
  routerType:          'popup',
  popupWidth:          800,
  popupHeight:         280,
  modal:               true,
  template:            MobileThemisUpdateCustomersPopupTpl,
  i18nPrefix:          ['thm.ui.common.mobile.MobileThemisUpdateCustomersPopup.'],
  autoBindActions:     true,
  localValuesLocation: {
    "customers": '#mobileThemisUpdateCustomersPopup-customerList field-list-select'
  },

  initViewModel: function () {
    const me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
    me.viewModel.attr('form', {});
    me.viewModel.attr('tabs', [
      {
        value: "all",
        text:  me.msg('all')
      },
      {
        value: "data",
        text:  me.msg('period')
      },
    ]);

    me.viewModel.attr("loading", false);
    me.viewModel.attr('form').on('currentTab', me.proxy(me._updateTab));
  },

  _updateTab: function () {
    let me            = this,
        value         = me.viewModel.attr('form.currentTab')

    me.viewModel.attr("loading", true);

    switch (value) {
      case 'all':
        me.viewModel.attr('showDate', false);
        break;
      case 'data':
        me.viewModel.attr('showDate', true);
        break;
    }

    me.viewModel.attr("loading", false);
  },

  doBeforeOpen: function () {
    let me            = this

    if (!!me.context) {
      me.viewModel.attr('dataModel.customerId', me.context.customerId);
      me.viewModel.attr('dataModel.srcVessel', me.context.mobileId);
    }
  },

  checkErrors: function (item) {
    const me = this;

    if (me.viewModel.attr('showDate') && (!item.startDate || !item.endDate)) {
      Toastr.showError(me.msg('errorDate'), 5000);
      return true;
    }

    return false;
  },

  onSubmit: function (event) {
    const me             = this,
          item           = me.viewModel.attr('dataModel').attr(),
          mobileIdSource = me.context.mobileId;

    if (me.checkErrors(item)) {
      return;
    }

    let messageSuccesKey = _.camelCase('successFix ' + me.viewModel.attr('form.currentTab')),
      customerName = APP('customers', item.targetCustomer).company,
      vesselName = me.context.mobileName,
      messageErrorKey = _.camelCase('failureFix ' + me.viewModel.attr('form.currentTab'));

    if ((item.startDate === undefined && item.endDate === undefined) || !me.viewModel.attr('showDate')) {
      item.startDate = null;
      item.endDate = null;
    }

    item.changeMode = 'CHANGE_BEACON_CUSTOMER';

    delete item.customerId;
    FixCustomerAssociation.changeCustomer(item).done(() => {
      Toastr.showSuccess(me.msg(messageSuccesKey, customerName, vesselName), 5000);

      // Refresh Mobile Panel
      Page.openDataPanel({
        type:   'mobile',
        data:   {id: mobileIdSource},
        target: 'right'
      });

      // Switch mobileId to new customerId (target)
      APP('mobiles', mobileIdSource).attr('customerId', item.targetCustomer);

      me.close();
    }).fail((error) => {
      Toastr.showError(me.msg(messageErrorKey, customerName, vesselName, error), 5000);
      me.close();
    });

    me.close();
  },

  onCancel: function () {
    const me = this;
    me.close();
  },

  // getSuccessMessage(type, vesselTo, vesselFrom) {
  //   let me = this;
  // }

});

export default MobileThemisUpdateCustomersPopup;
