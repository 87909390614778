<div id="{{id}}" class="page">
    <div class="header-group">
        <h3>{{msg 'titleEvent'}}</h3>
    </div>

    <field-item  item="dataModel" class="inlineGroup fields">
        <field-date name="eventDate" format="dateTime" required="true"></field-date>
        <uda-select of="mobileLifeEventTypes"
                    name="eventTypeId"
                    {update-for}="dataModel.customerId"
                    update-function="filterByCustomerId"
                    creation-function="createForCustomer"
                    label-align="top"></uda-select>
        <field-textarea name="eventComment"></field-textarea>
    </field-item>

    <div class="inline-btn">
        <a cls-action="save" class="btn btn-ok">{{msg '_Save'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
