<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
    <div class="list-field col-sm-12 col-size-xs-12">
        <label class="annotation inlineGroup">{{msg 'invalidMoveDescription'}} : </label>
    </div>

    <!-- distance threshold -->
    <div class="col-xs-8" style="text-align: center;">
        <label style="margin: 0;line-height: 44px;text-align: center;"> {{msg 'invalidMoveDistanceDesc'}} </label>
    </div>
    <div class="col-xs-4" style="padding:0">
        <field-text name="distanceThreshold" label="" format="distance" required="true"></field-text>
    </div>

    <!-- speed threshold -->
    <div class="col-xs-8" style="text-align: center;">
        <label style="margin: 0;line-height: 44px;text-align: center;"> {{msg 'invalidMoveSpeedDesc'}} </label>
    </div>
    <div class="col-xs-4" style="padding:0">
        <field-text name="speedThreshold" label="" format="speed" required="true"></field-text>
    </div>
</div>
