<div id="{{id}}" class="page">
  <div class="header-group">
    <h3>
      {{msg 'titleCatch'}}
    </h3>
  </div>
  <field-item item="catch" class="inlineGroup fields">
    <field-list-select name="speciesId" {items}="speciesItems" {item-template}="speciesTemplate" search-fields="name" required="true">
      <span class="icon-m icon-left ui-icon-fish">{{value.name}}</span>
    </field-list-select>
    <field-select name="speciesCatId" label-key="field.speciesCatI18n" {options}="speciesCatOptions"
                  options-value="id" options-text="code"></field-select>
    <field-text name="weightFished" format="num"></field-text>
    <field-text name="numberFished" format="num"></field-text>
  </field-item>

  <div class="inline-2btn">
    <a cls-action="save" class="btn btn-ok">{{msg '_Save'}}</a>
    <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
  </div>
</div>
