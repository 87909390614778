<div id="{{id}}" class="page L-scrollY has-header has-footer-btn">
  <div class="header-page L-scrollY-header">
    <h1>{{title}}</h1>
    <a cls-action="close" class="btn btn-header-left ui-icon-arrow-l"></a>
  </div>
  <field-item item="dataModel" class="L-scrollY-content">
    <div class="inlineGroup fields">
      <field-select label-align="top" name="kind" {options}="kindOptions"></field-select>
      <uda-select of="flags" name="destination" update-function="findAllWithWebService"></uda-select>
      <field-radios label="" name="exportMode" options="exportModeOptions"></field-radios>
      {{#is dataModel.exportMode "vesselIdentifier"}}
          <field-select name="identifierType" {options}="identifierTypeOptions"></field-select>
          <field-text name="identifierValue" max-length="30"></field-text>
      {{/is}}
      {{#is dataModel.exportMode "tripNumber"}}
          <field-text name="tripNumber" max-length="30"></field-text>
      {{/is}}
      <field-date name="startTime" period="fakePeriod" role="start" updatemax="true" format="dateTime"></field-date>
      <field-date name="endTime" period="fakePeriod" role="end" format="dateTime"></field-date>
      {{#if isFlux}}
          <field-checkbox name="consolidated"></field-checkbox>
      {{/if}}
    </div>
  </field-item>
  <ui-submit-cancel class="L-scrollY-footer-btn" {status}="saveStatus"></ui-submit-cancel>
</div>
