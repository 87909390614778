import _ from "core-cmp/lodash";
import Page from "core-cmp/Page";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";
import "core-ure/ui/favorite/AddFavoritePopup.css";
import MobileMergePopupTplStache from "thm/ui/common/mobile/MobileMergePopupTpl.stache";
import MobileMerge from 'core-uda/model/mobile/MobileMerge';
import Toastr from "core-cmp/Toastr";

/**
 * @class MobileMergePopup
 */
let MobileMergePopup = Page.extend({
  id:              "mobilePopup",
  routerType:      'popup',
  popupWidth:      600,
  modal:           true,
  template:        MobileMergePopupTplStache,
  i18nPrefix:      "thm.ui.common.mobile.MobileMergePopup.",
  authorizedMode:  [],
  autoBindActions: true,
  localValuesLocation : {
    "mobiles" : '#mobilePopup-mobileList field-list-select'
  },

  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
  },

  doBeforeOpen: function () {
    let me = this;

    if (!!me.context) {
      me.viewModel.attr('dataModel.context', me.context.customerId + "/" + me.context.vessel.id);
    }
  },

  onSubmit: function (event) {
    let me = this;
    const obsoleteMobile = me.getLocal('mobiles', me.viewModel.attr('dataModel.obsoleteMobile'));

    ConfirmationPopup.openPage({
      title:   me.msg('alert'),
      text:    me.msg('mergeInfo', obsoleteMobile.name, me.context.vessel.name),
      buttons: [
        {action: "apply"},
        {action: "cancel"}
      ],
      onAction: function (action) {
        if (action === 'cancel') {
          me.close();
        }
        else if (action === 'apply') {
          MobileMerge.createMerge(me.context.vessel.id, obsoleteMobile.id).done(() => {
            Toastr.showSuccess(me.msg('successMerged', me.context.vessel.name, obsoleteMobile.name, 5000));
            me.close();
          }).fail(() => {
            Toastr.showError(me.msg('failureMerged', 5000));
            me.close();
          });
        }
      }
    });
  },

  onCancel: function(){
    let me = this;

    me.close();
  }

});

export default MobileMergePopup;
