import ResourceUI from "core-uda/ui/ResourceUI";
import MobileType from "core-uda/model/mobile/MobileType";
import template from "thm/ui/common/mobileType/ThmMobileTypeTpl.stache";

/**
 * User Interface elements for Mobile Type
 */
let ThmMobileTypeUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   MobileType.itemId,
      i18nPrefix:   "thm.ui.common.mobileType.ThmMobileTypeUI.",
      icon:         "mobileLabel",
      template:     template,
      labelField:   "type",
      searchFields: "type",
      isListEditable: true
    })
  }

});

const THM_MOBILE_TYPE_UI = new ThmMobileTypeUI();

export default ThmMobileTypeUI;
