import ResourceUI from "core-uda/ui/ResourceUI";
import UserZoneType from "core-zone/model/UserZoneType";
import template from "thm/ui/common/userZoneType/UserZoneTypeTpl.stache";

/**
 * User Interface elements for User Zone Type
 */
let UserZoneTypeUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: UserZoneType.itemId,
      i18nPrefix: "thm.ui.common.zone.UserZoneTypeUI.",
      icon:       "zoneLabel",
      template:   template,
      labelField:   "name",
      searchFields: "name",
      isListEditable: true
    })
  }

});

const USER_ZONE_TYPE_UI = new UserZoneTypeUI();

export default UserZoneTypeUI;
