import InfoPanel from "core-uda/ui/InfoPanel";
import StatusCodeInfoPanelTplStache from "thm/ui/common/statusCode/StatusCodeInfoPanelTpl.stache";

/**
 * @class thm.ui.common.StatusCodeInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un code d'état.
 * @param {Object} options
 */
let StatusCodeInfoPanel = InfoPanel.extend({
  template:   StatusCodeInfoPanelTplStache,
  i18nPrefix: ['uda.statusCode.', 'thm.ui.common.statusCode.StatusCodeInfoPanel.'],
  id:         'statusCodeInfoPanel',
  childTpl:   null,
  entityId:   'statusCode',
  allowWidth: true,

  /**
   * @override
   */
  getTitle: function () {
    let me = this;
    return me._super() || "New Status Code";
  }
});

export default StatusCodeInfoPanel;
