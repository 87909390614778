{{pref 'core-uda.ui.view.PrintPage.'}}
<style>
    .bodyNoScrollbodyNoScroll{
        overflow:auto;
    }

    body {
        overflow:auto;
    }

    #logo.themis-logo {
        width: 150px !important;
        height: 60px !important;
        margin: auto !important;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
        display: block;
    }
    .footer {
        bottom : 0px
    }
    @media print {
        html, body {
            height: 100% !important;
            width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden;
        }

        .bodyNoScrollbodyNoScroll{
            overflow:hidden;
        }

        @page {
            size: A4 landscape !important;
            max-height: 100% !important;
            max-width: 100% !important;
        }

        .copyright {
            font-style: italic !important;
            font-size:10px !important;
            bottom:0px;
        }

      .printedView {
        display: block !important;
        width : 85% !important;
        height : auto !important;
        margin-right: auto !important ;
        margin-left: auto !important
      }

        #logo {
            height: 60px;
        }
    }
</style>
<div id="{{id}}-container">
    <div class="header-group">
        <h3>{{user.label}} - {{date}} {{#if user.agency}} - {{user.agency}} {{/if}} </h3>
    </div>

    <div id="logo" class="themis-logo"></div>

    <img id="{{id}}View" src="{{imgSrc}}" class="printedView"/>

    <div id="{{id}}-infos" class="{{#unless hideForm}}hidden{{/unless}} ">
        <h5 id="{{id}}-printTitle" data-ref="print_title">{{print_title}}</h5>
        <hr style="margin:0;">
        <div id="{{id}}-printContents" class="printContents" data-ref="print_contents">
            {{#each print_contents}}
                <p>{{value}}</p>
            {{/each}}
        </div>
        <div class="inline-1btn no-print">
          <a id="print-button" class="btn">{{msg '_Print'}}</a>
        </div>
    </div>
    <field-item id="{{id}}-fields" item="dataModel" class="no-print" {hidden}="hideForm">
        <field-text name="title"></field-text>
        <field-textarea name="content"></field-textarea>
        <div class="inline-2btn no-print">
            <a id="{{id}}-save" cls-action="save" class="btn">{{msg '_Save'}}</a>
            <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
        </div>
    </field-item>
    <div id="footer" class="{{#unless hideForm}}hidden{{/unless}}">
        <div id="copyright" class="copyright">
            Contains public sector information licensed under the Open Government License v3.0; ©UK Hydrographic Office and ©Ordnance Survey. Crown copyright and database right (2020). © ICES and ©  FAO (2020). © CMAP 2020
        </div>
    </div>
</div>
