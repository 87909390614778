import template from "thm/ui/common/beaconType/CommandWarningTpl.stache";
import CommandHandler from "core-mobile/ui/command/CommandHandler";
import Toastr from "core-cmp/Toastr";
import {RIGHTS} from "core-uda/Rights";

/**
 * Handler for periodic polling Command.
 * @class CommandWarning
 */
let CommandWarning = CommandHandler.extend({
  id:            "warning",
  i18nPrefix:    "thm.ui.common.beaconModel.CommandWarning.",
  template:      template,
  channel:       "POLL",
  channelOption: [{
    value: 'POLL',
    text:  'IRIDIUM'
  }, {
    value: 'POLL_GPRS',
    text:  'GPRS'
  }],
  message:       "",
  messageOption: [],
  customMessage: "",
  messageMaxLength: 30,

  /**
   * @override
   */
  onCommandChange: function (commandStatus) {
    let me = this;

    if (commandStatus.name === me.id) {
      let messageOption = RIGHTS("commands.warnMessages.list", "").split("\n"),
          customOption = me.msg("field.customMessage");
      messageOption.push(customOption);
      me.attr("messageOption", messageOption);
      me.attr("message", messageOption[0]);
      me.attr("customOption", customOption);
      me.attr("messageMaxLength", RIGHTS("commands.warnMessages.maxLength", 30));
    }
  },

  /**
   * @override
   */
  checkErrors: function () {
    let me = this;

    if (!me.channel || !me.message) {
      Toastr.showToastError("noValueError", 3000);
      return true;
    }

    return false;
  },

  /**
   * @override
   */
  onSubmit: function (params) {
    let me = this,
        finalMessage = me.message === me.attr("customOption") ? me.customMessage : me.message;

    params.value = `{'channel':'${me.channel}','message':'${finalMessage}'}`;
  }
});

CommandHandler.register(CommandWarning);

export default CommandWarning;
