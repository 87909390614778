import {canConstruct} from "core-cmp/can";
import {ICON_SVG_MANAGER} from "core-cmp/service/IconSVGManager";
import {APP} from "core-uda/model/Resource";
import GIS from "core-gis/Default";
import Utils from "core-cmp/Utils";
import _ from "core-cmp/lodash";
import ZonePreferences from "core-uda/model/userObject/ZonePreferences";
import FavoriteZonePreferences from "core-uda/model/userObject/FavoriteZonePreferences";
import Views from "core-uda/model/userObject/Views";
import Palette from "core-uda/model/userObject/Palette";
import ConcentricRadius from "core-uda/model/userObject/ConcentricRadius";
import AlertDefinition from "core-alert/model/AlertDefinition";
import ApplicationDomain from "core-uda/model/applicationDomain/ApplicationDomain";
import Role from "core-uda/model/role/Role";
import GridPreferences from "core-uda/model/userObject/GridPreferences";
import UmeFavoritePreferences from "core-uda/model/userObject/UmeFavoritePreferences";
import NafTemplate from "thm/model/nafTemplate/NafTemplate";
import FlagOnPosition from "core-uda/model/customerObject/FlagOnPosition";
import FavoriteRequest from "core-uda/model/userObject/FavoriteRequest";
import InfoPanelPreferences from "core-uda/model/userObject/InfoPanelPreferences";
import CustomerParameter from "core-uda/model/parameter/CustomerParameter";
import GridDefinition from "core-uda/model/GridDefinition";
import Flag from "core-uda/model/flag/Flag";
import Fleet from "core-uda/model/fleet/Fleet";
import Mobile from "core-uda/model/mobile/Mobile";
import Animal from "core-uda/model/mobile/Animal";
import SharedVessel from "core-uda/model/sharedVessel/SharedVessel";
import BeaconType from "core-uda/model/beacon/BeaconType";
import Beacon from "core-uda/model/beacon/Beacon";
import ModemAlert from "core-uda/model/beacon/ModemAlert";
import MobileType from "core-uda/model/mobile/MobileType";
import MobileModel from "core-uda/model/mobile/MobileModel";
import Shipowner from "core-uda/model/mobile/Shipowner";
import StatusCode from "core-uda/model/mobile/StatusCode";
import FishingGear from "core-uda/model/mobile/FishingGear";
import Licence from "core-uda/model/mobile/Licence";
import Recipient from "core-uda/model/user/Recipient";
import SsasContact from "core-uda/model/ssas/SsasContact";
import PositionSource from "core-uda/model/position/PositionSource";
import Message from "core-uda/model/message/Message";
import Parameter from "core-uda/model/parameter/Parameter";
import Zone from "core-zone/model/Zone";
import Style from "core-uda/model/customerObject/Style";
import Folder from "core-uda/model/folder/Folder";
import BeaconWithoutFolder from "core-uda/model/beacon/BeaconWithoutFolder";
import Species from "core-uda/model/species/Species";
import UserZoneType from "core-zone/model/UserZoneType";
import GlobalZoneType from "core-zone/model/GlobalZoneType";
import PortType from "core-zone/model/PortType";
import User from "core-uda/model/user/User";
import AlertType from "core-alert/model/AlertType";
import Quota from "core-uda/model/fishing/Quota";
import Customer from "core-uda/model/customer/Customer";
import Trip from "core-uda/model/trip/Trip";
import SpeciesCat from "core-uda/model/species/SpeciesCat";
import Compose from "core-uda/model/compose/Compose";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import {PREF} from "core-cmp/Preferences";
import {THM_PREF} from "thm/ThmPreferences";
import {RIGHTS, DATA_AUTHORIZED} from "core-uda/Rights";
import CustomerChartModel from "core-uda/model/customerObject/CustomerChartModel";
import CustomerPrefObject from "core-uda/model/customerObject/CustomerPrefObject";
import ImportPreset from "core-uda/model/customerObject/ImportPreset";
import {ICON_FACTORY} from "core-cmp/service/IconFactory";
import {GOOGLE_ANALYTICS} from "core-uda/service/google/GoogleAnalytics";
import StyleModel from "core-uda/model/customerObject/StyleModel";
import {MAP_CONFIG} from "thm/ui/umv/ThmUmvMapConfig";
import $ from "thm/thm-jquery";

import Cpc from "core-uda/model/cpc/Cpc";
import CpcCondition from "core-uda/model/cpcCondition/CpcCondition";
import CpcFormat from "core-uda/model/cpcFormat/CpcFormat";
import CpcFrom from "core-uda/model/cpcFrom/CpcFrom";
import CpcProtocol from "core-uda/model/cpcProtocol/CpcProtocol";

import MobidriftObject from "core-drift/model/mobidriftObject/MobidriftObject";

import ICONS from "core-cmp/icons-default";
import ResourcesPreferences from "core-uda/model/customerObject/ResourcesPreferences";
import Preference from "core-uda/model/preference/Preference";
import SensorType from "core-sensor/model/SensorType";
import CriteriaPreferences from "core-uda/model/customerObject/CriteriaPreferences";
import GridCustomerPreferences from "core-uda/model/customerObject/GridCustomerPreferences";
import GridUserPreferences from "core-uda/model/userObject/GridUserPreferences";
import Satellite from "core-uda/model/satellite/Satellite";
import SatellitePreference from "core-uda/model/satellitePreference/SatellitePreference";
import coreUda from "core-uda/uda";
import ExternalImage from "core-uda/model/externalImage/ExternalImage";
import GeneralAlertBroadcast from "core-uda/model/generalAlertBroadcast/GeneralAlertBroadcast";
import UserGroup from "core-uda/model/userGroup/UserGroup";
import GeoAnnotation from "core-uda/model/geoAnnotation/GeoAnnotation";
import GeoAnnotationPreferences from "core-uda/model/userObject/GeoAnnotationPreferences";
import ReportSchedule from "core-uda/model/reportSchedule/ReportSchedule";
import GeoTiff from "core-geotiff/model/GeoTiff";


/**
 * Classe permettant de lancer toutes les requêtes liées à l'utilisateur
 */
let UserRequest = canConstruct.extend({
  //Permet de charger les styles en débug
  user:     null,
  business: '',

  loadIcons: function () {
    APP().families = JSON.parse('[{"id":"Drifter","name":"DRIFTER"},{"id":"TwoWheeledVehicle","name":"TWO_WHEEL"},{"id":"Railcars","name":"RAILCAR"},{"id":"Pedestrians","name":"PEDESTRIAN"},{"id":"MooredBuoy","name":"MOOR_BUOY"},{"id":"UnderwaterVehicle","name":"UNDW_VEH"},{"id":"IceBuoy","name":"ICE_BUOY"},{"id":"LandVehicle","name":"LAND_VEH"},{"id":"SeaVehicle","name":"SEA_VEH"},{"id":"Fish","name":"FISH"},{"id":"LandAnimals","name":"LAND_ANI"},{"id":"Cars","name":"CAR"},{"id":"MarineAnimals","name":"MARIN_ANI"},{"id":"UnderwaterStation","name":"UNDW_STAT"},{"id":"UnknownPlatformType","name":"UNKNOWN"},{"id":"Containers","name":"CONTAINER"},{"id":"Ship","name":"SHIP"},{"id":"FixedStation","name":"FIX_STAT"},{"id":"SubsurfaceFloat","name":"SUB_FLOAT"},{"id":"Birds","name":"BIRD"},{"id":"Aircraft","name":"AIRCRAFT"}]');

    ICON_FACTORY.generateIcons(ICONS);
    ICON_SVG_MANAGER.ICON_FACTORY = ICON_FACTORY;
  },

  loadCustomerGlobalPref: function (callback) {
    let me = this;

    //On fait par name car UDA ne retourne rien avec queryBy=customerId&query=null ...
    CustomerPrefObject.findAll({queryBy: "name", query: '__customerGlobalPref'}).done(function (resp) {
      if (resp._datas.length > 0) {
        THM_PREF.__customerGlobalPref = resp._datas[0];
        THM_PREF.__currentPref = $.extend(true, {}, THM_PREF.__customerGlobalPref.attr());
      }

      me.loadCustomerClientPref(callback);
    });
  },

  loadCustomerClientPref: function (callback) {
    let current,
        currentValue,
        customer,
        customerValue;

    CustomerPrefObject.findAll({queryBy: "customerId", query: APP().user.customerId}).done((resp) => {
      if (resp._datas.length > 0) {
        THM_PREF.__customerClientPref = resp._datas[0];
      }

      current = THM_PREF.__currentPref; // Global preferences
      currentValue = current ? current.value : {};
      customer = THM_PREF.__customerClientPref; // Customer default preferences
      customerValue = customer ? customer.value.attr() : {};

      THM_PREF.__currentPref = {value: _.merge({}, currentValue, customerValue)};

      callback();
    });
  },

  /**
   * Charge les Users Objects
   */
  loadUserObjects: function () {
    FavoriteRequest.findAll();
    InfoPanelPreferences.findAll({});
    Views.findAll();
    Palette.findAll();
    ConcentricRadius.findAll();
    NafTemplate.findAll();
    ZonePreferences.findAll();
    FavoriteZonePreferences.findAll();
    SatellitePreference.findAll();
    GeoAnnotationPreferences.findAll();
  },

  /**
   * Charge les CustomerObject
   */
  loadCustomerObjects: function () {
    var me     = this,
        reqCust, reqUs,
        charts = [];
    //Global
    reqCust = CustomerChartModel.findAll({}).done(function (chartModels) {

      // Patch core-uda car on a un niveau de plus
      if (!!chartModels.datas) {
        chartModels = chartModels.datas;
      }

      _.each(chartModels, function (data) {
        if (!data.value) {
          data.destroy();
        } else {
          data.attr('value', data.value);
          data.attr('type', 'customer');
          charts.push(data);
        }
      });
      APP().chartModels.customer = _.sortBy(charts, function (v) {
        return v.name.toLowerCase();
      });
      Utils.addMapToArray(APP().chartModels.customer);
      APP().trigger('chartModelsLoaded');
    });
  },

  /**
   * Initialise les données METOC en fonction des droits utilisateur.
   */
  initializeMetocWithRights: function () {
    // Construction du modèle des données METOC
    let config = RIGHTS('metoc.layers');

    if (config && RIGHTS("map.layers.metocAuthorized")) {

      let conf;

      try {
        conf = JSON.parse(config)
      } catch (e) {
        return
      }

      APP().metocModel.loadConfig({
        wmsServices: conf,
        token:       () => APP().getLastToken(),
        application: "umv"
      });

      APP().multiModel.loadConfig({
        wmsServices: conf,
        token:       () => APP().getLastToken(),
        application: "umv"
      });

    }
  },

  /**
   * Initialise les modèles de styles en fonction des droits utilisateur.
   */
  initializeStylesWithRights: function () {
    StyleModel.setStyleEngineRights({
      bourbon:  RIGHTS("styleModel.bourbon"),
      fishing:  RIGHTS("styleModel.fishing"),
      tracking: RIGHTS("styleModel.tracking"),
      animal:   RIGHTS("styleModel.animal")
    });
  },

  /**
   * Méthode de chargement des préférences
   */
  loadData: function () {
    let me       = this,
        deferred = new $.Deferred();

    APP().loadUserInfo("umv");

    // =============================================================================================
    // ================= 2 ) Chargement des préférences niveau centre et client ====================
    // =============================================================================================
    me.loadCustomerGlobalPref(function () {

      // =============================================================================================
      // ================= 3 ) Chargement des préférences utilisateurs  ===========================================
      // =============================================================================================

      if (_.isNil(APP().getCapabilities('compose'))) {
        $.when(
          Preference.findAll(),
          ResourcesPreferences.findAll(),
          CriteriaPreferences.findAll(),
          GridCustomerPreferences.findAll(),
          GridUserPreferences.findAll()
        ).done(function (dataArray) {
          me.loadDatas(dataArray[0], deferred);
        });
      } else {
        Compose.findAllCompose([
          "preferences",
          "resourcesPreferences",
          "criteriaPreferences",
          "gridCustomerPreferences",
          "gridUserPreferences"
        ]).done((response) => {
          me.loadDatas(response.data[0].data, deferred);
        });
      }

    });

    return deferred;
  },

  loadDatas: function (preferences, deferred) {
    let me = this,
        i18nRequest,
        styleRequest,
        themeRequest,
        mobileRequest;

    // Modify findAllParams to add fields required by UME
    GridCustomerPreferences.updateFindAllFields();

    //Si pas de pref = nouvel user
    if (_.isEmpty(preferences)) {
      APP().newUser = true;
    }
    PREF().setData(preferences);
    themeRequest = coreUda.applyTheme(PREF().get('theme'));

    //Chargement langue
    i18nRequest = APP().loadI18n();
    APP().metocModel.locale = PREF("locale");
    APP().multiModel.locale = PREF("locale");
    $('body').addClass('cls-' + RIGHTS("user.domain"));

    PREF().trigger("dateFormatChanged");

    //On définit si l'ensemble des mobiles sont récupérables :
    // - pas de mode aisTraffic
    // - nombre de mobiles accessible inférieure au max.
    APP().hasAllMobiles = !APP().hasAisTraffic() && RIGHTS('mobile.loadAll');

    // Insert Googlemap JS
    // IMPORTANT : MAP_CONFIG.getViewLayers() used preferences. Do not move this code outside
    // Preference.findAll().done promise
    GIS.loadJs(MAP_CONFIG.getViewLayers());

    if (APP().hasAllMobiles) {
      mobileRequest = me.loadMobiles();
    }

    if (APP().hasAisTraffic()) {
      PREF('loadAllTrajectories', "last");
    }

    // Les droits sont chargés, on initialise les modules qui en dépendent
    me.initializeMetocWithRights();
    me.initializeStylesWithRights();
    //me.initMetoc();

    // ====================================================================================
    // ===== 4) Tous le reste en parrallèle    ============================================
    // ====================================================================================
    // Application.availableData.message = true;

    // Not supported by compose
    if(DATA_AUTHORIZED("searchAndRescue")){
      //GeoAnnotation.findAll({resource: "sarSad,sarSac"}) ne marche pas, les données renvoyées n'ont pas les champs resource
      GeoAnnotation.findAll({resource: "sarSad"}).done(() => GeoAnnotation.findAll({resource: "sarSac"}));
    }

    CustomerParameter.findAll();
    if (RIGHTS('data.generalAlertBroadcast.authorized'))
      GeneralAlertBroadcast.findAll();

    if (RIGHTS('data.ssas'))
      SsasContact.findAll();

    ReportSchedule.findAll();

    // THM-14590 - on a besoin des recipients au chargement de l'appli -> on ne charge pas les recipients via le compose car le temps d'exécution est trop long
    Recipient.findAll();

    GeoTiff.findAll();

    if (_.isNil(APP().getCapabilities('compose'))) {
      GridPreferences.findAll();
      UmeFavoritePreferences.findAll();
      FlagOnPosition.findAll();
      BeaconType.findAll();
      StatusCode.findAll();
      Fleet.findAll();
      Zone.findAll();
      AlertDefinition.findAll();
      Beacon.findAll();
      MobileType.findAll();
      Shipowner.findAll();
      FishingGear.findAll();
      Flag.findAll();
      PositionSource.findAll();
      SensorType.findAll();
      ImportPreset.findAll();
      Folder.findAll();
      User.findAll();
      UserZoneType.findAll();
      GlobalZoneType.findAll();
      PortType.findAll();
      Quota.findAll();
      ApplicationDomain.findAll();
      Role.findAll();
      Cpc.findAll();
      CpcCondition.findAll();
      CpcFormat.findAll();
      CpcFrom.findAll();
      CpcProtocol.findAll();
      ExternalImage.findAll();
      Species.findAll();
      Customer.findAll();
      UserGroup.findAll();
      Trip.findAll();
      SharedVessel.findAll();
      SpeciesCat.findAll();
    } else {
      Compose.findAllCompose([
        "gridPreferences",
        "umeFavoritePreferences",
        "flagOnPositions",
        "beaconTypes",
        "statusCodes",
        "fleets",
        "zones",
        "alertDefinitions",
        "beacons",
        "mobileTypes",
        "shipowners",
        "fishingGears",
        "flags",
        "positionSources",
        "sensorTypes",
        "importPreset",
        "folders",
        "users",
        "userZoneTypes",
        "globalZoneTypes",
        "portTypes",
        "quotas",
        "applicationDomains",
        "applicationRoles",
        "cpc",
        "cpcCondition",
        "cpcFormat",
        "cpcFrom",
        "cpcProtocol",
        "externalImage",
        "species",
        "customer",
        "userGroup",
        "trips",
        "sharedVessel",
        "speciesCat"
      ]);
    }

    // Specials resources
    GOOGLE_ANALYTICS.sendTrack();
    AlertType.findAll();
    Licence.findAll();
    BeaconWithoutFolder.findAll();
    Satellite.findAll();
    MobidriftObject.findAll(); // Fake resource

    APP().whenData('mobiles').done(() => {
      if (DATA_AUTHORIZED('message')) {
        Message.findAll().done(() => {
          setInterval(() => {
            Message.refreshAll();
          }, 60000);
        });
      }
    });

    //On charge les styles
    me.loadIcons();
    styleRequest = Style.findAll().done(() => {
      me.loadUserObjects();
      me.loadCustomerObjects();

      /* Suppression du timezone s'il y a */
      if (!!PREF('timezoneOffset')) {
        PREF().deleteOne('timezoneOffset');
        PREF().saveAll();
      }
    });

    $.when(styleRequest, i18nRequest, themeRequest, mobileRequest).done(() => {
      deferred.resolve();
    });
  },

  loadMobiles: function () {
    let me       = this,
        deferred = new $.Deferred();

    Mobile.findAll().done(() => Animal.findAll()).fail(() => {
      APP().hasAllMobiles = false;

      APP().setModel(new MobileModel({
        data:   [],
        total:  0,
        hasAll: false
      }));
      console.warn("TOO MANY MOBILES => hasAllMobiles = false");
    }).always(() => deferred.resolve());

    return deferred;
  }

});
// Instance unique
const USER_REQUEST = new UserRequest();

export {USER_REQUEST};
export default UserRequest;
