<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-scrollY-content inlineGroup fields">
    <field-text name="name" icon="label"  max-length="30" required="true" col="12 12"></field-text>
    <uda-select of="satProvider" name="satelliteProviderId" col="12 12"></uda-select>
    {{#each commandGroups}}
      <field-checkbox name="{{.}}" border="false" col="6 12"></field-checkbox>
    {{/each}}
    <field-text name="progValues" max-length="128" col="12 12" label-align="top"></field-text>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
