<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'sensor'}}</h2>
</div>

<div class="inlineGroup fields">

    <field-checkbox name="starboardWinchActivated"></field-checkbox>
    {{#if activatedStarboardWinch}}
      <field-select label-key="_Value" name="starboardOperator" options="sensorOperator" col="7"></field-select>
      <field-text label="" name="starboardValue" format="pnum" required="true" col="5"></field-text>
    {{/if}}

    <field-checkbox name="portWinchActivated"></field-checkbox>
    {{#if activatedPortWinch}}
      <field-select label-key="_Value" name="portOperator" options="sensorOperator" col="7"></field-select>
      <field-text label="" name="portValue" format="pnum" required="true" col="5"></field-text>
    {{/if}}

</div>
