<div id="{{id}}" class="page L-fit L-scrollY has-header has-footer-btn">

    <div class="L-scrollY-header header-page">
        <h3>{{msg 'title'}}</h3>
    </div>

    <div class="L-scrollY-content">
        <field-item  item="dataModel">
            <div class="header-group icon-left icon-m ui-icon-vessel">
                <h2>{{msg 'vesselIdentification'}}</h2>
            </div>
            <div id="{{id}}-vesselIdentification" class="inlineGroup fields">
              <uda-select of="mobiles"
                          label-key="vessel"
                          name="vessel"
                          {update-for}="dataModel.customerId"
                          update-function="filterByCustomerId"
                          class="inlineGroup">
              </uda-select>
                <field-text name="imo" col="12" disabled="true"></field-text>
                <field-text label-key="extraDataGross" format="pnum" name="gt" col="6 12" class="l50-f50"></field-text>
                <field-text format="pnum" name="nt" col="6 12" class="l50-f50"></field-text>
                <field-text format="pnum" name="dwt" col="12" class="l70-f30"></field-text>
                <field-text format="pnum" name="mainEngines" col="6 12"  class="l60-f40"></field-text>
                <field-text format="pnum" name="auxiliaryEngines" col="6 12" class="l60-f40"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-route">
                <h2>{{msg 'voyageInformation'}}</h2>
            </div>
            <div id="{{id}}-voyageInformation" class="inlineGroup fields">
                <field-select name="type" {options}="typeOptions" col="12"></field-select>  {{! THM-8943 }}
                <field-date name="startDate" format="date" col="12"></field-date>
                <field-date name="endDate" format="date" col="12"></field-date>
                <field-text format="pnum" name="distance" max="99999" col="12" class="l60-f40"></field-text>
                <field-text format="pnum" name="speed" max="99999" col="12" class="l60-f40"> </field-text> {{! THM-8943 }}
                <field-text name="emission" col="12" class="l60-f40"></field-text> {{! THM-8943 }}
                <field-text format="pnum" name="hours" max="9999" col="12" class="l60-f40"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-oil">
                <h2>{{msg 'fuelConsumption'}}</h2>
            </div>
            <div id="{{id}}-fuelConsomption" class="inlineGroup fields">
                <field-text format="pnum" name="diesel" max="9999" col="12" class="l60-f40"></field-text>
                <field-text format="pnum" name="lfo" max="9999" col="6" class="l60-f40"></field-text>
                <field-text format="pnum" name="hfo" max="9999" col="6" class="l60-f40"></field-text>
                <field-text format="pnum" name="lpgPropane" max="9999" col="12" class="l60-f40"></field-text>
                <field-text format="pnum" name="lpgButane" max="9999" col="12" class="l60-f40"></field-text>
                <field-text format="pnum" name="lng" max="9999" col="12" class="l60-f40"></field-text>
                <field-text format="pnum" name="methanol" max="9999" col="6" class="l60-f40"></field-text>
                <field-text format="pnum" name="ethanol" max="9999" col="6" class="l60-f40"></field-text>
                <field-text format="pnum" name="other" max="9999" col="12" class="l60-f40"></field-text>
                <field-select name="method" options="methods" col="12" class="l60-f40"></field-select>
                <field-text format="pnum" name="tonnage" max="9999" col="12" class="l60-f40"></field-text> {{! THM-8943 }}
                <field-text format="pnum" name="teuFull" max="9999" col="12" class="l60-f40"></field-text> {{! THM-8943 }}
                <field-text format="pnum" name="teuEmpty" max="9999" col="12" class="l60-f40"></field-text> {{! THM-8943 }}
                <field-text name="otherMethod" {visible}="otherMethodActivate" max-length="30" col="12" class="l60-f40"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-position">
                <h2>{{msg 'location'}}</h2>
            </div>
            <div id="{{id}}-location" class="inlineGroup fields">
                <field-text format="longitude" name="longitude" col="12" required="true"></field-text>
                <field-text format="latitude" name="latitude" col="12" required="true"></field-text>
            </div>
        </field-item>
    </div>

    <div id="{{id}}-footer" class="L-scrollY-footer-btn inline-2btn">
        <a cls-action="validate" class="btn btn-ok">{{msg '_Validate'}}</a>
        <a cls-action="cancel" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
