import {canBatch} from "core-cmp/can";
import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import ZoneInfoPanel from "core-zone/ui/ZoneInfoPanel";

/**
 * @class ThmZoneInfoPanel
 * Themis zone form.
 */
let ThmZoneInfoPanel = ZoneInfoPanel.extend({
  i18nPrefix: [
    'core-zone.ui.ZoneInfoPanel.',
    'uda.zone.',
    'uda.alert.field.kind.',
    'thm.ui.common.zone.ThmZoneUI.'
  ],
  hasStyle:   true,

  /**
   * @override
   */
  initViewModel: function () {
    let me       = this,
        agencies = APP().getCapabilities("zone").agencies;

    me._super();

    // Default value Point
    agencies = _.concat("", agencies);

    me.viewModel.attr({
      agencies: agencies,
      maxDate:  100 * 365 // 100 ans dans le turfu
    });

    //forcer le refresh du header pour modifier l'état du bouton
    APP().on('favoriteZonesChanged', (evt,data) => {
      me.updateHeaderModel()
    });
  },

  /**
   * @override
   */
  cleanForEqual: function (attr) {
    let me = this;

    attr = me._super(attr);

    attr.direction = attr.direction === "" ? undefined : attr.direction;
    attr.typeId = attr.typeId === "-1" ? undefined : attr.typeId;
    delete attr.typeName;

    return attr;
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;

    canBatch.start();

    me._super();

    me.viewModel.attr("showUserGroupList", RIGHTS("userGroup"));
    me.viewModel.attr('hasReference', !["user", "itinerary", "annotation"].includes(me.newData.kind));

    if (!APP().user.userGroups || me.childId === "zone.official") {
      me.viewModel.attr("showUserGroupList", false);
    }

    canBatch.stop();
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me        = this,
        dataModel = me._super();

    delete dataModel.beginDateMillis;
    delete dataModel.endDateMillis;

    return dataModel;
  },

  /**
   * @override
   */
  getToolbarActions: function () {
    let me      = this,
        actions = me._super();

    me.addToolbarAction(actions, {
      action:     "addFavorite",
      icon:       "star",
      label:      me.msg("addFavoriteZone"),
      authorized: !!APP("favoriteZonePreferences") && !APP("favoriteZonePreferences").isFavoriteZone(me.data.id)
    });

    me.addToolbarAction(actions, {
      action:     "removeFavorite",
      icon:       "star2",
      label:      me.msg("removeFavoriteZone"),
      authorized: !!APP("favoriteZonePreferences") && APP("favoriteZonePreferences").isFavoriteZone(me.data.id)
    });

    return actions;

  },

  /**
   *
   */
  onAddFavorite: function () {
    let me = this;
    APP("favoriteZonePreferences").addZoneToFavorites(me.data.id);
  },

  /**
   *
   */
  onRemoveFavorite: function () {
    let me = this;
    APP("favoriteZonePreferences").removeZoneFromFavorites(me.data.id);
  }
});

export default ThmZoneInfoPanel;
