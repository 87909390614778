import InfoHandler from "core-gis/service/info/InfoHandler";
import Page from "core-cmp/Page";
import UdaMainPage from "core-uda/ui/UdaMainPage";
import InfoPanel from "core-uda/ui/InfoPanel";
import GIS from "core-gis/Default";
import MetocLegendsVM from "core-metoc/ui/wc/MetocLegendsVM";
import _ from "core-cmp/lodash";
import PrintPage from "core-uda/ui/view/PrintPage";
import CentricBar from "core-uda/ui/centric/CentricBar";
import { APP } from "core-uda/model/Resource";
import { FEATURE_TYPE } from "core-gis/FeatureConstants";
import ArchivedPositionGranter from "core-mobile/service/ArchivedPositionGranter";
import "core-gis/service/info/DefaultHandler";
import "core-gis/service/info/MetocHandler";
import "core-zone/service/info/ZoneHandler";
import GridPanel from "thm/ui/grid/ThmGridPanel";
import "core-mobile/ui/chart/MobileCreateChartPage"; // Do not remove. Used by Page.get("createChart")
import "thm/ui/umv/ThmUmvMain.css";
import UdaSearchTool from "core-uda/ui/search/UdaSearchTool";
import ThmUmvLeftMenu from "thm/ui/umv/ThmUmvLeftMenu";
import Tooltip from "core-gis/ui/tooltip/Tooltip";
import ZoomBar from "thm/ui/main/ThmZoomBar";
import MobileMeasureListCmp from "core-mobile/ui/measure/MobileMeasureListCmp";
import StarMeasureListCmp from "core-gis/ui/starMeasure/StarMeasureListCmp";
import ThmToolbar from "thm/ui/umv/ThmToolbar";
import RightPanel from "core-uda/ui/rightPanel/RightPanel";
import TabInfoPage from "core-uda/ui/rightPanel/TabInfoPage";
import MobileTagListCmp from "core-mobile/ui/tag/MobileTagListCmp";
import { MAP_CONFIG } from "thm/ui/umv/ThmUmvMapConfig";
import ThmUmvMainTplStache from "thm/ui/umv/ThmUmvMainTpl.stache";
import "core-mobile/ui/MobileList.css";
import PrintCustomBuilderDefaultTplStache from "core-uda/ui/view/PrintCustomBuilderDefaultTpl.stache";
import PrintCustomBuilderMarocTplStache from "thm/ui/common/print/PrintCustomBuilderMarocTpl.stache";
import PrintCustomBuilderPsdkpTplStache from "thm/ui/common/print/PrintCustomBuilderPsdkpTpl.stache";
import PrintCustomBuilderUkTplStache from "thm/ui/common/print/PrintCustomBuilderUkTpl.stache";
import { RIGHTS, DATA_AUTHORIZED } from "core-uda/Rights";
import { MAP_UPDATER } from "core-uda/UdaMapUpdater";

// FIXME : Required for UDAMenu : to delete after migration of UDAMenu entries to ResourceUI
import "core-alert/ui/alert/AddManualAlertPopup";
import "core-mobile/ui/fleet/AddMobilePopup";
import "core-mobile/ui/fleet/CreateFleetPopup";

// Load Resource UI configuration
import "core-uda/ui/GridUI";
import "core-uda/ui/satellite/SatelliteUI";
import "core-radar/ui/RadarEchoUI";
import "core-radar/ui/RadarSlickUI";
import "core-radar/ui/RadarProductUI";
import "core-sar/ui/sarAlert/SarAlertUI";
import "core-sar/ui/sarOperation/SarOperationUI";
import "core-sar/ui/sarSad/SarSadUI";
import "core-sar/ui/sarSac/SarSacUI";
import "core-drift/ui/mobidriftObject/MobidriftObjectUI";

import "core-mobile/ui/shipowner/ShipownerUI";

import "thm/ui/common/point/ThmPointUI";
import "thm/ui/common/alert/ThmAlertUI";
import "thm/ui/common/alertDefinition/ThmAlertDefinitionUI";
import "thm/ui/common/collectedData/ThmCollectedDataUI";
import "thm/ui/common/sensor/ThmSensorUI";
import "thm/ui/common/trajectory/ThmTrajectoryUI";
import "thm/ui/common/zone/ThmZoneUI";

import "thm/ui/common/alertType/AlertTypeUI";
import "thm/ui/common/beacon/BeaconUI";
import "thm/ui/common/beaconType/BeaconTypeUI";
import "thm/ui/common/command/CommandUI";
import "thm/ui/common/command/CommandLogUI";
import "thm/ui/common/exceptionReport/ExceptionReportUI";
import "thm/ui/common/ersActivity/ErsActivityUI";
import "thm/ui/common/ersAlertReport/ErsAlertReportUI";
import "thm/ui/common/ersAuditReport/ErsAuditReportUI";
import "thm/ui/common/ersOperationReport/ErsOperationReportUI";
import "thm/ui/common/ersData/ErsDataUI";
import "thm/ui/common/ersFishingQuotasReport/ErsFishingQuotasReportUI";
import "thm/ui/common/ersMissingDeclarationReport/ErsMissingDeclarationReportUI";
import "thm/ui/common/ersQuery/ErsQueryUI";
import "thm/ui/common/ersStatistic/ErsStatisticUI";
import "thm/ui/common/ersTrip/ErsTripUI";
import "thm/ui/common/catchDeclaration/CatchDeclarationUI";
import "thm/ui/common/flag/FlagUI";
import "thm/ui/common/fleet/FleetUI";
import "thm/ui/common/ssas/SsasContactUI";
import "thm/ui/common/ssas/SsasAuditlogUI";
import "thm/ui/common/herd/HerdUI";
import "thm/ui/common/fmc/FmcUI";
import "thm/ui/common/fishingGear/FishingGearUI";
import "thm/ui/common/fishingProtocol/FishingProtocolUI";
import "thm/ui/common/fishingTime/FishingTimeUI";
import "thm/ui/common/lesInmc/LesInmcUI";
import "thm/ui/common/licence/LicenceUI";
import "thm/ui/common/trip/TripUI";
import "thm/ui/common/sharedVessel/SharedVesselUI";
import "thm/ui/common/userAction/UserActionUI";
import "thm/ui/common/auditLogs/AuditLogUI";
import "thm/ui/common/mobile/MobileMergeUI";
import "thm/ui/common/mobileLifeEvent/MobileLifeEventUI";
import "thm/ui/common/locationStatData/LocationStatDataUI";
import "thm/ui/common/cpcPostData/CpcPostDataUI";
import "thm/ui/common/vesselLog/VesselLogUI";
import "thm/ui/common/mobileLifeEventType/MobileLifeEventTypeUI";
import "thm/ui/common/palette/ThmPaletteUI";
import "core-uda/ui/concentricradius/ConcentricRadiusUI";
import "thm/ui/common/mobile/ThmMobileUI";
import "thm/ui/common/periodicPolling/PeriodicPollingUI";
import "thm/ui/common/position/ThmPositionUI";
import "thm/ui/common/position/HistoricalPositionUI";
import "thm/ui/common/mobileType/ThmMobileTypeUI";
import "thm/ui/common/statusCode/StatusCodeUI";
import "thm/ui/common/portType/PortTypeUI";
import "thm/ui/common/userZoneType/UserZoneTypeUI";
import "thm/ui/common/globalZoneType/GlobalZoneTypeUI";
import "thm/ui/common/quota/QuotaUI";
import "thm/ui/common/recipient/RecipientUI";
import "thm/ui/common/satProvider/SatProviderUI";
import "thm/ui/common/positionSource/PositionSourceUI";
import "thm/ui/common/species/SpeciesUI";
import "thm/ui/common/speciesCat/SpeciesCatUI";
import "core-uda/ui/user/UserUI";
import "core-uda/ui/customer/CustomerUI";
import "thm/ui/common/zoneType/ZoneTypeUI";
import "core-sar/ui/sarSruWork/SarSRUnitUI";
import "thm/ui/common/mobile/AnimalUI";
import "thm/ui/common/externalImage/ExternalImageUI";
import "thm/ui/common/statusDataReport/StatusMessageReportUI";
import "thm/ui/common/illegalVessel/IllegalVesselUI";
import "thm/ui/common/inspectionReport/InspectionReportUI";
import "thm/ui/common/gfcmInspectionReport/GfcmInspectionReportUI";
import "thm/ui/common/missingLocation/MissingLocationUI";
import "thm/ui/common/userGroup/UserGroupUI";
import "thm/ui/common/fishingTrip/FishingTripUI";
import "thm/ui/common/reportSchedule/ReportScheduleUI";
import "thm/ui/common/reportLog/ReportLogUI";
import "thm/ui/common/acquisitionException/AcquisitionExceptionUI";
import "thm/ui/common/beaconLog/BeaconLogUI";

// CPC UI
import "thm/ui/common/cpc/CpcUI";
import "thm/ui/common/cpcCondition/CpcConditionUI";
import "thm/ui/common/cpcFormat/CpcFormatUI";
import "thm/ui/common/cpcFrom/CpcFromUI";
import "thm/ui/common/cpcProtocol/CpcProtocolUI";

// COMMAND HANDLERS
import "thm/ui/common/beaconType/CommandHrdr";
import "thm/ui/common/beaconType/CommandPiratFreeForm";
import "thm/ui/common/beaconType/CommandPiratLogicalOutput";
import "thm/ui/common/beaconType/CommandPiratTimers";
import "thm/ui/common/beaconType/CommandReportingInterval";
import "thm/ui/common/beaconType/CommandPeriodicPolling";
import "thm/ui/common/beaconType/CommandWarning";

/**
 * Open information page for given resource.
 * @param {Object} config - configuration
 * @param {string} config.type - Resource type. Ex : "mobile" / "zone" / "fleet"...
 * @param {Resource} [config.data] - Resource instance. Ex : Mobile / Zone ...
 * @param {Object} [config.target] - Page target
 * @param {Function} [config.callback] - callback function after page loading and opnenning
 * @param {Object} [config.context] - Context object for Page.open method
 * @param {Object} [config.skipAnimation] - skip page openning animation (default false)
 * @return {$.Deferred} - promise
 **/
Page.openDataPanel = function (config) {

  let pageClass = (config.routerType === 'tab') ? TabInfoPage : RightPanel;

  if (config.closeIfOpen && pageClass.get().isOpen) {
    return pageClass.get().close().done(() => {
      delete config.closeIfOpen;
      pageClass.load(config);
    });
  } else {
    delete config.closeIfOpen;
    return pageClass.load(config);
  }

};

/**
 * Open information page for given resource.
 * @param {Object} config - configuration
 * @param {string} config.type - Resource type. Ex : "mobile" / "zone" / "fleet"...
 * @param {Resource} [config.data] - Resource instance. Ex : Mobile / Zone ...
 * @param {Object} [config.target] - Page target
 * @param {Function} [config.callback] - callback function after page loading and opnenning
 * @param {Object} [config.context] - Context object for Page.open method
 * @param {Object} [config.skipAnimation] - skip page openning animation (default false)
 * @return {$.Deferred} - promise
 **/
Page.openInfoPanel = function (config) {

  // Si la demande est de maj le panneau si il est ouvert uniquement
  // on vérifie ces conditions et l'on annule l'ouverture si non remplies
  if (config.updateIfOpen && !RightPanel.get().isOpen) {
    return null;
  }

  config.target = "right";
  return Page.openDataPanel(config);
};

Page.getInfoPanel = function () {
  return RightPanel.get();
};

/**
 * @class thm.ui.umv.MainPage
 * @parent thm.ui
 * @constructor
 * Page principale avec carto.
 * @param {Object} options
 */
let ThmUmvMain = UdaMainPage.extend({
  id: 'mapPage',
  template: ThmUmvMainTplStache,
  metocLegendTimer: null,
  animation: 'scaleDown:left',
  routerMain: true,
  isDropZone: true,

  /**
   * @override
   */
  init: function (config) {
    var me = this,
      lexicon = RIGHTS("user.domain");

    // FIXME : Temporaire pour pouvoir externaliser Infopanel
    InfoPanel.COMMON_VIEW_MODEL = {
      'udaServiceArgos': APP().getUdaService("ARGOS"),
      'udaServiceIridium': APP().getUdaService("IRIDIUM"),
      'udaServiceThemis': APP().getUdaService("THEMIS"),
      'domainTracking': lexicon === "tracking",
      'domainFishing': lexicon === "fishing",
      'domainFishingOrTraking': lexicon === "fishing" || lexicon === "tracking",
      'domainFish': lexicon === "fish"
    };

    ArchivedPositionGranter.register();

    me._super(_.extend({}, config, {
      gridPanelClass: GridPanel,
      toolbarClass: ThmToolbar,
      leftMenuClass: ThmUmvLeftMenu,
      centricBarClass: CentricBar,
      searchToolClass: UdaSearchTool,
      rightPanelClass: RightPanel
    }));

    APP().on("messagesCountChanged", function (ev, count) {
      me.onMessagesCountChanged(ev, count);
    });

    PrintPage.prototype.template = me.getPrintModelTemplate();
    PrintPage.prototype.topBarLogoId = "#topBar-logo";
    PrintPage.prototype.mainCssFile = "dist/bundles/thm/ThmMain.css";

    APP().on("trajectoryPointSelected", (ev, context) => {
      let showInfo = context && context.showInfo,
        select = context && context.select && context.trajectory && !_.isNil(context.index);
      if (showInfo) {
        me.showObjectInfo('mobilePosition', context);
      }
      if (select) {
        MAP_UPDATER("mobile").selectPosition(context.trajectory, context.index);
      }
    });

    RightPanel.get().on('stateChange', (event, eventName, value) => {
      if (eventName === "open" && value === false) {
        APP().trigger("rightPanelClose");
      }
    });

    MetocLegendsVM.PALETTE_EDITABLE = RIGHTS("metoc.paletteEditable", true);

    // Utile dans le cas ou le dashboard est ouvert en premier
    me.renderMap();
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();
    me.viewModel.attr("legends", []);

    APP().getMetocModel().on("legends", me._updateLegends.bind(me))

    APP().on("sarDriftsLoaded", me._updateLegends.bind(me))
  },

  _updateLegends: function () {
    const me = this,
      legends = [];

    legends.push(...APP().getMetocModel().legends);
    legends.push(APP().mobileStatLegend);
    if (DATA_AUTHORIZED("searchAndRescue") && APP("sarDrifts")) {
      legends.push(APP("sarDrifts").legend);
    }
    me.viewModel.attr("legends", legends)
  },

  /**
   * @override
   */
  initChilds: function () {
    let me = this;
    me._super();

    me.zoomBar = me.addChild({
      childId: 'zoomBar',
      cmpClass: ZoomBar
    });

    if (RIGHTS('umv.tools.measure')) {
      me.measureList = me.addChild({
        childId: 'measureList',
        cmpClass: MobileMeasureListCmp,
        listeners: {
          "toolActivate": {
            handler: () => me.hideInfo()
          },
          "toolDeactivate": {
            handler: () => me.toolbar.backToInfoTool('info')
          }
        }
      });
    }

    if (RIGHTS('umv.tools.starMeasure')) {
      me.starMeasureList = me.addChild({
        childId: 'starMeasureList',
        cmpClass: StarMeasureListCmp,
        listeners: {
          "toolActivate": {
            handler: () => me.hideInfo()
          },
          "toolDeactivate": {
            handler: () => me.toolbar.backToInfoTool('info')
          }
        }
      });
    }

    if (RIGHTS('umv.tools.tag')) {
      me.tagList = me.addChild({
        childId: 'tagList',
        cmpClass: MobileTagListCmp,
        listeners: {
          "toolActivate": {
            handler: function () {
              me.hideInfo();
            }
          },
          "close": {
            handler: function () {
              me.toolbar.backToInfoTool();
            }
          }
        }

      });
    }

    me.tooltip = me.addChild({
      childId: 'tooltip',
      cmpClass: Tooltip
    });

  },

  /**
   * Rendu de la carte différé après l'affichage de la page principale.
   */
  renderMap: function () {
    const me = this;

    // Active l'outil "info"
    me.activateTool('info', true);
    me.animationToolbar.hide();

    MAP_CONFIG.centerZoomFactor = RIGHTS('map.layers.zoomFactor', 1);
    me._super(MAP_CONFIG, "umv");

    me.gisInfoTool.setLayers([
      'zones',
      'mobiles.trajectories',
      'mobiles.server',
      'collectedDatas',
      'sensorMessages',
      'alerts',
      'radars',
      'drifts',
      'sarSac',
      'sarSad',
      'sarDrift',
      'sarSruBasePositions',
      'sarAlerts',
      'sarSRUnits',
      'meteoStationRecord'
    ]);
    me.gisInfoTool.setZoneLayers(['zones', 'radars']);

    let featureTypes = []

    _.each(InfoHandler.getAllHandlerClasses(), clazz => {
      featureTypes.push(..._.difference(clazz.TYPES, clazz.LOW_PRIORITY_TYPES));
    });
    featureTypes = _.uniq(featureTypes);

    me.gisInfoTool.setFeatureTypes(featureTypes);

    if (PREF("defaultMapView")) {
      APP().whenData("views").done(() => {
        _.defer(() => {
          me.applyMapView(APP("views", PREF("defaultMapView")));
        })
      });
    }

    me._updateLegends();
  },

  /**
   * @override
   */
  doAfterRender: function () {
    let me = this;

    me._super();

    me.$content = me.$el.find('.content');
  },

  /**
   * Permet d'afficher le MapInfoBox
   */
  showInfo: function (handler) {
    let me = this,
      isGridOpened = me.gridPanel.isVisible(),
      isMeasure = me.toolbar.currentButton.id === "measure";

    if (!!handler.model && !handler.model.keepMapSelection) {
      APP().trigger("clearMapSelection");
    }

    //Cas du clic sur un ETA
    if (!handler || isMeasure) {
      if (me.infoPanel) {
        me.getMapInfoBox().hide();
      }
      return;
    }

    if (handler.selectItem) {
      handler.selectItem({
        map: false,
        info: true,
        infoPanel: {
          updateIfOpen: true // update if already displayed, do not open it if hidden
        }
      });
    } else {

      // Default Handler behaviour
      me.showMapInfoBox(handler);
    }

    if (isGridOpened && me.infoPanel) {
      me.infoPanel.hide();
    }
  },

  /**
   * Affiche le panel de droite
   */
  showRightPanel: function (handler) {
    let page = Page.getChildFromPage(RightPanel.prototype.id, 0);
    if (page) {
      if (page.updatableWithHandler && page.updatableWithHandler(handler)) {
        page.updateWithHandler(handler);
        return;
      }
      if (handler.getMobileId() != null) {
        if (handler.getMobileId() !== page.id) {
          Page.openDataPanel({
            type: 'mobile',
            data: handler.model.trajectory.mobile,
            target: 'right'
          });
        }
      }
    }
  },

  /**
   * Retourne le template utilisé par la printPage
   * @returns {*}
   */
  getPrintModelTemplate: function () {
    let model = RIGHTS('printModels').split(",")[0]; // On se limitte à un modèle pour l'instant

    switch (_.upperFirst(model)) {
      case "Maroc":
        return PrintCustomBuilderMarocTplStache;
      case "Psdkp":
        return PrintCustomBuilderPsdkpTplStache;
      case "Uk":
        return PrintCustomBuilderUkTplStache;
      default:
        return PrintCustomBuilderDefaultTplStache;
    }
  },

  onMessagesCountChanged: function (ev, count) {
    var me = this;

    if (!!me.leftMenu) {
      var msgTooltip = me.leftMenu.getButtonViewModel('message')[0];
      msgTooltip.attr('tooltipIcon', count);
    }
  },

  /**
   * @override
   */
  setFullMap: function (active) {
    let me = this;

    me._super(active);

    me.zoomBar.setVisible(!active);
  }

});


export default ThmUmvMain;
