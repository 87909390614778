<div id="{{id}}" class="page" tabindex="0">
    <div id="map-container" class="L-fit">
        <div id="gmap" style="display: none;"></div>
        <div id="map">
            <div include-child="tagList"/>
            <div include-child="measureList"/>
            <div include-child="starMeasureList"/>
            <div class="cls-logoControl ui-icon-logo"></div>
            <div include-child="tooltip"/>
            <metoc-legends {legends}="legends"></metoc-legends>
            <ui-sadtool {sar-data}="sarX"></ui-sadtool>
        </div>
    </div>


    <div include-child="gridPanel"/>
    <div include-child="animationToolbar"/>
    <div include-child="zoomBar"/>
    <!-- zoombar avant toolbar searchtool et layerDataBar pour smartphone -->
    <div include-child="toolbar"/>
    <div include-child="searchTool"/>
    <div include-child="centricBar"/>
    <div include-child="layerDataBar"/>

    <div include-child="printView"/>

    <div class="ui-loader-background"></div>

    <div id="refreshPositions"></div>
    <div id="newRequestPositions"></div>
</div>
