<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

    <field-item item="dataModel" class="L-panel-content">
        <div class="inlineGroup fields">
            <field-text name="name" required="true" col="12"></field-text>
            <field-textarea name="description" col="12"></field-textarea>
        </div>
        <uda-list of="users" name="users" class="inlineGroup" (beforeitemdelete)="onBeforeDeleteUser"></uda-list>
        <uda-list of="fleets" name="fleets" class="inlineGroup" (beforeitemdelete)="onBeforeDeleteFleet"
                  {update-for}="dataModel.customerId" update-function="filterByCustomerId" ></uda-list>
        <uda-list of="zones" name="zones" class="inlineGroup" (beforeitemdelete)="onBeforeDeleteZone"
                  {update-for}="dataModel.customerId" update-function="findForAlertDefinitions"></uda-list>
    </field-item>

    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
