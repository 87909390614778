<div id="{{id}}" class="page">
  <div class="header-group icon-left icon-l ui-icon-mobile">
    <h3>{{msg 'changeCustomers'}}</h3>
  </div>

  <field-item item="form">
    <field-hcombo name="currentTab" label="" options="tabs" col="12 12"
                  always-editable="true" class="hcombo-tab"></field-hcombo>
  </field-item>

  <field-item item="dataModel" class="inlineGroup fields {{#if loading}}hidden{{/if}}">
    <uda-select id="{{id}}-customerList"
                of="customers"
                label-key="customerId"
                name="targetCustomer"
                {update-for}="dataModel.customerId"
                class="inlineGroup">
    </uda-select>

    {{#if showDate}}
    <field-date name="startDate" label-key="startDate" period="myPeriod" role="start"
                format="dateTime"></field-date>
    <field-date name="endDate" label-key="endDate" period="myPeriod" max-date="now" role="end"
                format="dateTime"></field-date>
    {{/if}}

    <div class="msg inlineGroup">{{msg 'noteFixCustomer'}}</div>

  </field-item>

  <ui-loading {visible}="loading" anchor="center"></ui-loading>

  <ui-submit-cancel style="position: absolute; bottom: 0;" {status}="saveStatus" cancel-action="close"></ui-submit-cancel>

</div>
