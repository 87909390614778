import template from "thm/ui/common/beaconType/CommandHrdr.stache";
import CommandHandler from "core-mobile/ui/command/CommandHandler";

/**
 * Handler for Pirat Free Form Command.
 * @class CommandHrdr
 */
let CommandHrdr = CommandHandler.extend({
  id:            "hrdr",
  i18nPrefix:    "thm.ui.common.beaconModel.CommandHrdr.",
  template:      template,
  endHrdr:       null,
  startHrdr:     null,
  channel:       "",
  channelOption: [{
    value: 'iridium',
    text:  'IRIDIUM'
  }, {
    value: 'gprs',
    text:  'GPRS'
  }],

  /**
   * @override
   */
  onSubmit: function (params) {
    const me = this;

    if ((me.endHrdr.getTime() - me.startHrdr.getTime()) > 86400000) {
      params.value = 'errorTimelapse';
      return;
    }
    let startDate = me.startHrdr.toISOString(),
        endDate   = me.endHrdr.toISOString();
    params.value = `{'channel':'${me.channel}','from':'${startDate}','to':'${endDate}'}`;
  }
});

CommandHandler.register(CommandHrdr);

export default CommandHrdr;
