<div id="{{id}}" class="L-fit L-panel">

  <ui-page-header {cmp-config}="headerConfig">
    <field-item item="form">
      <field-hcombo name="currentTab" label="" options="tabs" col="12 12"
                    always-editable="true" class="hcombo-tab"></field-hcombo>
    </field-item>
  </ui-page-header>


  <field-item item="dataModel" class="L-panel-content">

    {{! TAB : alertInfo }}

    <div id="{{id}}-tab-alertInfo" class="tab readOnly">

      <div class="header-group icon-left icon-m ui-icon-alert">
        {{#if alertDefinitionName}}
          <h4>{{kind}}</h4>
          <h5>{{alertDefinitionName}}</h5>
          <div class="btn btn-header-right ui-icon-info alwaysEnabled" title="{{msg 'uda.alertDefinition.label'}}"
               cls-action="showAlertDefinition"/>
        {{else}}
          <h2>{{kind}}</h2>
        {{/if}}
      </div>
      <div id="{{id}}-alertInfo" class="fields inlineGroup">

      <span class="info icon-l icon-left ui-icon-position col-sm-6 col-size-xs-12 {{#if noLoc}}hidden{{/if}}"
            title="{{msg '_Location'}}" id="{{id}}-loc">{{loc}}</span>
        <span class="info icon-l icon-left ui-icon-calendar col-sm-6 col-size-xs-12 {{#if noLoc}}hidden{{/if}}"
              title="{{msg '_LocDate'}}" id="{{id}}-locDate">{{locDate}}</span>
        <field-textarea name="text" col="12 12"></field-textarea>
        <field-text name="alarmDate" col="12 12"></field-text>
      </div>

      <div class="header-group icon-left icon-m ui-icon-mobile">
        <h2>{{msg '_Mobile'}}</h2>
        <div class="btn btn-header-right ui-icon-info alwaysEnabled" title="{{msg 'showMobile'}}"
             cls-action="showMobile"/>
      </div>
      <div id="{{id}}-general" class="fields inlineGroup">
        <field-text label-key="field.name" name="mobile.name" col="6 12"></field-text>
        <field-text label-key="field.imo" name="mobile.imo" col="6 12"></field-text>
        <field-text label-key="field.mmsi" name="mobile.mmsi" col="6 12"></field-text>
        <field-text label-key="field.activeBeaconRef" name="mobile.activeBeaconRef" col="6 12"></field-text>
        <field-text label-key="field.crewNumber" name="mobile.crewNumber" col="6 12"></field-text>
      </div>
      <div>
        <ui-collapser class="header-group" expanded="true" collapser-for="{{id}}-acknownledge">
          <h2>{{msg 'acknowledgeTitle'}}</h2>
        </ui-collapser>
        <div id="{{id}}-acknownledge" class="fields inlineGroup" style="height:90px">
          <field-text name="ackUser" col="6 12"></field-text>
          <field-text name="ackDate" col="6 12"></field-text>
        </div>
      </div>


      <div>
        <ui-collapser class="header-group" expanded="true" collapser-for="{{id}}-validation">
          <h2>{{msg 'validationTitle'}}</h2>
        </ui-collapser>
        <div id="{{id}}-validation" class="fields inlineGroup" style="height:90px">
          <field-text name="validationUser" col="6 12"></field-text>
          <field-text name="validationDate" col="6 12"></field-text>
        </div>
      </div>

      <field-textarea name="action" col="12 12"></field-textarea>
    </div>


    {{! TAB : Pictures }}
    <div id="{{id}}-tab-pictures" class="tab" style="display:none;">
      <div>
        <div id="{{id}}-pictures" class="fields inlineGroup {{#unless editable}}hidden{{/unless}}"
             style="width: 75%;margin-left: 12.5%;">
          {{>ImageListTpl}}
        </div>
      </div>
    </div>

  </field-item>

  <form id="{{id}}-request-form" class="hidden" action="{{certificateFormModel.url}}" target="_blank" method="post">
    <input type="text" name="token" value="{{certificateFormModel.token}}">
    <input type="text" name="application" value="{{certificateFormModel.application}}">
    <input type="text" name="format" value="{{certificateFormModel.format}}">
    <input type="text" name="template" value="{{certificateFormModel.template}}">
    <input type="text" name="data" value="{{certificateFormModel.data}}">
    <button type='button' id='submitButton'></button>
  </form>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
