import InfoPanel from "core-uda/ui/InfoPanel";
import PositionSource from "core-uda/model/position/PositionSource";
import template from "thm/ui/common/positionSource/PositionSourceInfoPanelTpl.stache";

/**
 * Position Source form.
 * @class PositionSourceInfoPanel
 */
let SourceInfoPanel = InfoPanel.extend({
  template:   template,
  i18nPrefix: ['uda.positionSource.', 'thm.ui.common.positionSource.PositionSourceInfoPanel.'],
  id:         'positionSourceInfoPanel',
  entityId:   PositionSource.itemId,
  allowWidth: true,
  titleKey:   "title",
  allowClone: true,

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this;

    dataModel.attr({
      name: me.msg("_Clone") + " - " + dataModel.name
    });
  }
});

export default SourceInfoPanel;
