import {PREF} from "core-cmp/Preferences";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionCloseToHerdInfoPanelTpl.stache";
import {APP} from "core-uda/model/Resource";

/**
 * @class AlertDefinitionCloseToHerdInfoPanel
 * Alert Definition "Close to Herd" Form.
 */
let AlertDefinitionCloseToHerdInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'AlertDefinitionCloseToHerdInfoPanel',
  childTpl:      ChildStache,
  timingAllowed: true,

  bindEvents: function () {
    let me = this;
    me._super();
    PREF.bind('unitChanged', function (ev, newVal, oldVal) {
      me.updateViewModel();
    });
  },

  //override
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('domesticationOptions', APP("animal").getDomesticationOptions()); //TEMPORAIRE: on utilisera celui de herd une fois la ressource créée
  }
});
export default AlertDefinitionCloseToHerdInfoPanel;
