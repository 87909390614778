import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import Toastr from "core-cmp/Toastr";
import {APP} from "core-uda/model/Resource";
import Page from "core-cmp/Page";
import template from "thm/ui/common/cpcPostData/ImportNafTemplateTpl.stache";
import NafTemplate from "thm/model/nafTemplate/NafTemplate";

/**
 * @class ImportNafTemplate
 */
let ImportNafTemplate = Page.extend({
  routerType:      "popup",
  template:        template,
  i18nPrefix:      ['uda.cpcPostData.', 'thm.ui.common.cpcPostData.ImportNafTemplate.'],
  id:              'importTemplate',
  titleKey:        "thm.ui.common.cpcPostData.ImportNafTemplate.create.label",
  autoBindActions: true,
  popupWidth:      450,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super({
      resourceId: NafTemplate.itemId
    });

  },

  doBeforeOpen: function(){
    let me = this;

    me.viewModel.attr('file', "");
  },

  onCancel: function () {
    let me = this;

    me.close();
  },

  readFileContent: function (file) {
    let lName      = _.toLower(file.name),
        deferred   = new $.Deferred(),
        reader     = new $.Deferred(),
        fileReader = new FileReader();


    fileReader.onload = (evt) => {
      reader.resolve(evt.target.result);
    };
    fileReader.onerror = (evt) => {
      reader.reject();
    };

    if (_.endsWith(lName, ".json")) {
      fileReader.readAsText(file, "UTF-8");
      return deferred.resolve({
        entries: [reader]
      })
    }

    return deferred.reject(true);
  },

  /**
   * Try to read given file.
   * This method can be invoke from other class.
   * @param {File} file - Js native File
   * @returns {$.Deferred}
   */
  handleFile: function (file) {
    let me          = this,
        nafTemplate = new NafTemplate({
          name: "nafTemplate"
        }),
        deferred    = new $.Deferred(),
        rejectFn    = () => {
          deferred.reject(false);
        },
        resolveFn = () => {
          deferred.resolve(true);
        };

    me.readFileContent(file).done((content) => {
      content.entries[0].then((text) => {
        nafTemplate.saveTemplate(JSON.parse(text));

        me.listenStatus(nafTemplate.save(), "saveStatus").done((item) => {
          APP().setById('nafTemplate', item.id, item);
          _.delay(me.close.bind(me), 1000);
          resolveFn();

        }).fail(rejectFn);

      }).catch(rejectFn);

    }).fail(rejectFn);

    return deferred;
  },

  onSubmit: function () {
    let me  = this,
        ctx = me.context;

    if (me.viewModel.file && me.viewModel.file[0]) {
      me.handleFile(me.viewModel.file[0]).done(() => {
          Toastr.showToastSuccess("saveTemplateSuccess", 5000);
          ctx.reloadNaf();
      }).fail(() => {
        Toastr.showToastError("saveTemplateFail", 5000);
      });
    } else {
      Toastr.showToastError("selectFile", 5000);
    }
  }

});

export default ImportNafTemplate;
