import {THM_PREF} from "thm/ThmPreferences";
import Toastr from "core-cmp/Toastr";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import AlertDefinitionSpeedInZoneInfoPanelTplStache from "thm/ui/common/alertDefinition/AlertDefinitionSpeedInZoneInfoPanelTpl.stache";

/**
 * @class AlertDefinitionSpeedInZoneInfoPanel
 * Alert Definition "Speed In Zone" Form.
 */
let AlertDefinitionSpeedInZoneInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'alertDefinitionSpeedInZoneInfoPanel',
  childTpl:      AlertDefinitionSpeedInZoneInfoPanelTplStache,
  timingAllowed: true,

  bindEvents: function () {
    let me = this;
    me._super();
    THM_PREF.bind('unitChanged', function (ev, newVal, oldVal) {
      me.updateViewModel();
    });
  },

  forceDefaultValues: function () {
    let me        = this,
        dataModel = me.getDataModel();

    if (_.isUndefined(dataModel.portsIncluded)) {
      me.viewModel.attr('dataModel.portsIncluded', false);
    }
  },

  checkErrors: function (dataModel) {
    let me = this;

    if (me._super(dataModel)) {
      return true;
    }

    if (!dataModel.zones || dataModel.zones.length === 0) {
      Toastr.showToastr('error', 'alertDefinitionNoZone');
      return true;
    }

    if (dataModel.min > dataModel.max) {
      Toastr.showToastr('error', 'minCantBeGreater');
      return true;
    }
    return false;
  }
});
export default AlertDefinitionSpeedInZoneInfoPanel;
