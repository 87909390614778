import {APP} from "core-uda/model/Resource";
import {PREF} from "core-cmp/Preferences";
import {RIGHTS, DATA_AUTHORIZED} from "core-uda/Rights";
import InfoPanel from "core-uda/ui/InfoPanel";
import LicenceInfoPanelTplStache from "thm/ui/common/licence/LicenceInfoPanelTpl.stache";
import SpeciesTplStache from "thm/ui/common/licence/SpeciesTpl.stache";
import EditSpeciesPopup from "thm/ui/common/licence/EditSpeciesPopup";
import Toastr from "core-cmp/Toastr";

/**
 *
 * @class LicenceInfoPanel
 */
let LicenceInfoPanel = InfoPanel.extend({
  template:   LicenceInfoPanelTplStache,
  i18nPrefix: ['uda.licence.', 'uda.fishingProtocol.', 'thm.ui.common.licence.LicenceInfoPanel.'],
  id:         'licenceInfoPanel',
  childTpl:   null,
  entityId:   'licence',
  allowWidth: true,
  allowClone: true,
  titleKey:   "title",

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function(dataModel){
    dataModel.attr({
      reference:    ""
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr("showClient", RIGHTS("showClient"));
    me.viewModel.attr('tabs', "identification");
    me.viewModel.attr('tabsOptions', me.loadTabsOptions());
    me.viewModel.attr('typeOptions', me.loadTypeOptions());
    me.viewModel.attr('fishingGearDatas', APP('fishingGear').getData());
    me.viewModel.attr('speciesTpl', SpeciesTplStache);
    me.viewModel.attr('editAuthorizedParams', {
      titleKey: "titleAuthorizedSpecies"
    });
    me.viewModel.attr('editForbiddenParams', {
      titleKey: "titleForbiddenSpecies"
    });
    me.viewModel.attr('editAccessoryParams', {
      titleKey: "titleAccessorySpecies"
    });
    me.viewModel.attr('authSpeciesEditPage', EditSpeciesPopup.getId());
  },

  updateViewModel: function () {
    let me = this,
        customerId;

    if (!me.data.authorizedSpecies) {
      me.data.attr('authorizedSpecies', []);
    }
    if (!me.data.forbiddenSpecies) {
      me.data.attr('forbiddenSpecies', []);
    }
    if (!me.data.accessorySpecies) {
      me.data.attr('accessorySpecies', []);
    }

    me._super();

    customerId = me.viewModel.attr("dataModel.customerId");

    me.viewModel.attr("editAuthorizedParams.customerId", customerId);
    me.viewModel.attr("editForbiddenParams.customerId", customerId);
    me.viewModel.attr("editAccessoryParams.customerId", customerId);
  },

  loadTabsOptions: function () {
    let me = this;
    return [{value: 'identification', text: me.msg('identificationOption'), icon: 'info'},
      {value: 'zones', text: me.msg('zonesOption'), icon: 'zones'},
      {value: 'species', text: me.msg('speciesOption'), icon: 'fish'},
      {value: 'fishingGears', text: me.msg('fishingGearsOption'), icon: 'fishHook'}]
  },

  loadTypeOptions: function () {
    let me = this;
    return [me.msg('fishingOption'), me.msg('prospectingOption')];
  },

  /**
   * @override
   */
  checkErrors: function (dataModel) {
    let me = this;

    if (me._super(dataModel)) {
      return true;
    }
    if(dataModel.endDate && dataModel.beginDate){
      if (dataModel.endDate.getTime() <= dataModel.beginDate.getTime()) {
        Toastr.showToastr('error', 'endDateBeforeStartDate');
        return true;
      }
    }

    return false;
  },

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    let me = this;
    return me.data.reference;
  }
});

export default LicenceInfoPanel;
