import Page from "core-cmp/Page";
import Position from "core-uda/model/position/Position";
import template from "thm/ui/common/cpcPostData/CpcMobilePosition.stache";
import positionTemplate from "thm/ui/common/cpcPostData/CpcMobilePositionTpl.stache";

/**
 * @class CpcPostDataCreatePage
 */
let CpcMobilePosition = Page.extend({
  routerType: "tab",
  template:   template,
  i18nPrefix: ['uda.cpcPostData.', 'thm.ui.common.cpcPostData.cpcPostDataCreatePage.'],
  id:         'CpcMobilePosition',
  titleKey:   "thm.ui.common.cpcPostData.cpcPostDataCreatePage.positionTab",


  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('positionList', []);
    me.viewModel.attr('positionTemplate', positionTemplate);

    me.viewModel.on('position', me._selectedPosition.bind(me));

  },

  doBeforeOpen: function () {
    let me = this;

    me._super();

    Position.findBy({
      selectBy: 'mobile:' + me.context.mobileId,
      since:    3600 * 24 * 30,
      orderBy:  'mobileId, locDate ASC',
      dateType: 'location'
    }).done((data) => {
      me.viewModel.attr('positionList', data);
      //me.viewModel.attr('searchFields', Object.keys(data[0].attr()));
    });
  },

  _selectedPosition: function () {
    let me  = this,
        pos = me.viewModel.attr('position'),
        ctx = me.context;

    me.close().done(() => {
      ctx.selectedPosition(pos.attr());
    });

  }

});

export default CpcMobilePosition;
