import {canBatch} from "core-cmp/can";
import FleetInfoPanel from "core-mobile/ui/fleet/FleetInfoPanel";
import AnimalTplStache from "thm/ui/common/mobile/AnimalUITpl.stache";
import ThmFleetInfoPanelTplStache from "thm/ui/common/fleet/ThmFleetInfoPanelTpl.stache";
import {I18N} from "core-cmp/util/I18n";
import ThmFleetEditCriteriaPopup from "thm/ui/common/fleet/ThmFleetEditCriteriaPopup";
import ThmFleetCriteriaTplStache from "thm/ui/common/fleet/ThmFleetCriteriaTpl.stache";
import Capabilities from "core-uda/model/user/Capabilities";
import Fleet from "core-uda/model/fleet/Fleet";
import UnitFormat from "core-cmp/format/UnitFormat";
import {RIGHTS} from "core-uda/Rights";
import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import UserGroupTpl from "core-zone/ui/ZoneUserGroupTpl.stache";
import Toastr from "core-cmp/Toastr";

/**
 * @class ThmFleetInfoPanel
 * Themis fleet form.
 */
let ThmFleetInfoPanel = FleetInfoPanel.extend({
  template: ThmFleetInfoPanelTplStache,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr("criteriaEditPageId", ThmFleetEditCriteriaPopup.getId());
    me.viewModel.criteriaTpl = ThmFleetCriteriaTplStache;
    me.viewModel.criteriaHandler = {
      getItemViewModel: function (item, index) {
        let value      = item.value,
          fieldInfo  = APP().getCapabilities("mobile", "fields", item.field),
          format     = Capabilities.getFormaterFormat(fieldInfo ? fieldInfo.formater || fieldInfo.type : null),
          label      = I18N.msg("uda.mobile.field." + item.field),
          typeFormat = Capabilities.typeToFieldFormat(fieldInfo.type),
          join       = item.join === "NONE" ? "" : me.msg('_' + item.join),
          operator   = Fleet.getOperator(item.operator),
          oldValue   = item.value;

        if (fieldInfo.type === "boolean") {
          operator = Fleet.getOperator(value === "true" ? "isTrue" : "isFalse");
          value = "";
        } else if (item.operator === "isNotNull" || item.operator === "isNull") {
          value = "";
        } else if (format) {
          value = format(value, true);
        } else if (typeFormat === "text") {
          value = '"' + value + '"';
        }

        // Prevent display of UNDEFINED in the criteria preview
        value = _.isNil(value) ? '' : value;
        if(item.field === 'registeredLength' && PREF().othersUnits === "english"){
          // THM-10633 convert m into ft if we use imperial unit
          oldValue = UnitFormat.DISTANCE_FORMAT.to(value, value, 'm', 'ft', 4, false);
        }
        return {
          item:     item,
          join:     join,
          label:    label,
          operator: operator,
          value:    value,
          oldValue: oldValue,
          index:    index,
          editMode: me.editMode ? 'ui-icon-edit' : []._
        };
      },
    };

    me.viewModel.attr('userGroupTemplate', UserGroupTpl);
    me.viewModel.attr('onAnimalsRefresh', me.proxy(me.updateAnimalsViewModel));
    me.viewModel.attr('animalTemplate', AnimalTplStache);
    me.viewModel.attr('animalHandler', {
      getItemViewModel: me.proxy(me.getAnimalItemViewModel)
    });
    me.viewModel.attr('domesticationOptions', [
      {value: 'wild', text: me.msg("field.wild")},
      {value: 'semiDomestic', text: me.msg("field.semiDomestic")},
      {value: 'domestic', text: me.msg("field.domestic")}
    ]);
    me.viewModel.attr('migrationOptions', [
      {value: 'long', text: me.msg("field.long")},
      {value: 'short', text: me.msg("field.short")},
      {value: 'none', text: me.msg("field.none")}
    ]);
    me.viewModel.attr('purposeOptions', [
      {value: 'commercial', text: me.msg("field.commercial")},
      {value: 'ownUsage', text: me.msg("field.ownUsage")}
    ]);
    me.viewModel.attr('environmentOptions', [
      {value: 'tundra', text: me.msg("field.tundra")},
      {value: 'taiga', text: me.msg("field.taiga")},
      {value: 'mountainTaiga', text: me.msg("field.mountainTaiga")},
      {value: 'closedArea', text: me.msg("field.closedArea")}
    ]);
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;
    me._super();
    canBatch.start();
    me.viewModel.attr("hasMobiles", me.newData.kind === "mobileList");
    me.viewModel.attr("hasCriteria", me.newData.kind === "criteriaList");
    me.viewModel.attr("hasAnimals", me.newData.kind === "herd");
    me.viewModel.attr("showUserGroupList", RIGHTS("userGroup"));
    if (!me.data.criteria) {
      me.viewModel.attr("dataModel.criteria", []);
    }

    if (me.newData.kind === "herd") {
      me.updateAnimalsViewModel();
    }

    if (RIGHTS("userGroup") && me.data.userGroups && me.data.userGroups.length){
      let formattedUserGroups = _.map(me.data.userGroups, (value) => {
        let userGroup = APP("userGroups", value);
        return userGroup;
      });
      me.viewModel.attr('dataModel.userGroups', formattedUserGroups);
    }

    if (!APP().user.userGroups) {
      me.viewModel.attr("showUserGroupList", false);
    }

    canBatch.stop();
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me   = this,
        data = me._super();

    switch (data.kind) {
      case "mobileList":
      case "herd":
        delete data.criteria;
        if (data.mobiles) {
          data.mobiles.sort();
        }
        break;
      case "criteriaList":
        delete data.mobiles;
        break;
    }
    return data;
  },

  ///////////ANIMAL LIST
  getAnimals: function () {
    let me    = this,
        array = [];

    _.each(me.data.mobiles, function (v) {
      let animal = APP('animals', v);
      if (!!animal) {
        array.push(animal);
      }
    });

    me.viewModel.attr('animals', array);
  },

  updateAnimalsViewModel: function () {
    let me = this;
    me.getAnimals();

    let animals       = me.viewModel.attr('animals'),
        newList       = [],
        equiped       = 0,
        equipedMale   = 0,
        equipedFemale = 0,
        size          = 0;
    _.each(animals, function (animal) {
      newList.push(animal.id);
      size += animal.representativity;
      equiped++;
      animal.sex == "male" ? equipedMale++ : equipedFemale++;
    });
    me.viewModel.dataModel.attr('mobiles', newList);
    me.viewModel.dataModel.attr('size', size);
    me.viewModel.dataModel.attr('equiped', size > 0 ? 100 * equiped / size : 0);
    me.viewModel.dataModel.attr('maleEquiped', size > 0 ? 100 * equipedMale / size: 0);
    me.viewModel.dataModel.attr('femaleEquiped', size > 0 ? 100 * equipedFemale / size : 0);
  },

  getAnimalItemViewModel: function (item, index) {
    let me      = this,
        species = APP("species", item.speciesId);
    return {
      id:           item.id,
      item:         item,
      typeClass:    "ui-icon-paw",
      sexLabel:     item.sex == "male" ? I18N.msg("uda.animal.field.sex.male") : I18N.msg("uda.animal.field.sex.female"),
      speciesLabel: species ? species.name : me.msg("field.unknownSpecies")
    };
  },

  /**
   *
   * @override
   */
  cleanForEqual: function (item) {
    let me = this;

    if (item.kind === "herd") {
      delete item.size;
      delete item.equiped;
      delete item.maleEquiped;
      delete item.femaleEquiped;
    }

    delete item.userGroups;

    return me._super(item);
  },

  /**
   * @Override
   */
  onSaveError: function (error) {
    let me = this;

    if (error.exception === 'HTTP_NOT_FOUND') {
      APP().setById(me.entityId, me.data.id, null);
      Toastr.showError(me.msg('fleetNotFound'), 5000);
      me.close();
    }
  }


});

export default ThmFleetInfoPanel;
