import ResourceUI from "core-uda/ui/ResourceUI";
import SharedVessel from "core-uda/model/sharedVessel/SharedVessel";


/**
 * User Interface elements for Shared Vessels
 */
let SharedVesselUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:               SharedVessel.itemId,
      icon:                     "vessel",
      i18nPrefix:               ["uda.sharedVessel."]
    })
  },

  getColumnOptions: function () {
    let me       = this;
    return {
      "customerId": {
        format: (value) => {
          let customer = APP('customers').find((c) => c.id === value);
          return customer ? customer.company : value;
        },
        dataType: 'string',
        encodeHtml: false
      }
    }
  }

});

const SHARED_VESSEL_UI = new SharedVesselUI();

export default SharedVesselUI;
