import {USER_REQUEST} from "thm/model/requests/UserRequest";
import Rights from "core-uda/Rights";
import ThmRights from "thm/ThmRights";
import ThmTopBar from "thm/ui/main/ThmTopBar";
import LoginPage from "core-uda/ui/login/LoginPage";
import Right from "core-uda/model/right/Right";
import Toastr from "core-cmp/Toastr";
import {I18N} from "core-cmp/util/I18n";
import LoadingOverlay from "core-uda/ui/loadingOverlay/LoadingOverlay";
import {APP} from "core-uda/model/Resource";
import {THM_ROUTER} from "thm/ui/main/ThmRouter";
import {PREF} from "core-cmp/Preferences";
import {RIGHTS} from "core-uda/Rights";
import {canList} from "core-cmp/can";
import Utils from "core-cmp/Utils";
import _ from "core-cmp/lodash";
import "thm/ui/main/ThmLoginPage.css";
import SarConstants from "core-sar/utilities/SarConstants";

/**
 * Page de login THM.
 * @extends LoginPage
 */
let ThmLoginPage = LoginPage.extend({
  i18nLocale:    'en',
  hasParameters: true,
  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    // Show lost password link only if google captcha key exist
    APP().whenData("publicParameters").done(() => {
      me.viewModel.attr('hasLostLogin', !!APP("publicParameters").value('security.reCaptcha.siteKey')
      && !APP('publicParameters').value('center.ldap.providerUrl'));

      const rgpd = APP("publicParameters").value('security.rgpd.loginText') || "";

      me.viewModel.attr('hasRGPD', !!rgpd);
      if (rgpd) {
        me.setRgpd(rgpd);
      }
    });



    me._super();
  },


  /**
   *  Override
   **/
  onLoginSuccessCallback: function () {

    /* On met les resources disponible depuis le capabilities dans une liste */
    let resourcesAvailable = _.keys(APP().capabilities.resources).sort();

    // SAR: we manually add sarSRUnit in the resource list
    resourcesAvailable.push('sarSRUnit');
    APP().capabilities.resources['sarSRUnit'] = APP().capabilities.resources['mobile'];

    // Animal: we manually add animal in the resource list
    resourcesAvailable.push('animal');
    APP().capabilities.resources['animal'] = APP().capabilities.resources['mobile'];

    // Herds: we manually add herds in the resource list
    resourcesAvailable.push('herd');
    APP().capabilities.resources['herd'] = APP().capabilities.resources['fleet'];

    // LDAP users: we manually add LDAP users in the resource list
    resourcesAvailable.push('ldapUser');
    APP().capabilities.resources['ldapUser'] = APP().capabilities.resources['user'];

    resourcesAvailable.sort();
    APP().resourcesAvailable = new canList(_.map(resourcesAvailable, function (resourceName) {
      return {id: resourceName}
    }));
    Utils.addMapToArray(APP().resourcesAvailable);

    Right.findAll().done(function (data) {
      const  message = _.get(data, "role.data.message");
      if (message && _.isNil("message.edit") ) {
        data.role.data.message.edit = data.role.data.message.authorized;
        data.role.data.message.create = data.role.data.message.authorized;
        data.role.data.message.delete = data.role.data.message.authorized;
      }

      // Pilotage des dashboards mobiles avec un seul paramètre
      if (data.center.dashboard.mobile) {
        if (data.customer.webAppDomain.lexicon === "animal") {
          data.center.dashboard.herdsAlertsReport = true;
        }
        data.center.dashboard.mobileReports = true;
        data.center.dashboard.mobileNoReport = true;
        data.center.dashboard.mobileIntegration = true;
        data.center.dashboard.mobileAssistance = true;
        data.center.dashboard.mobileLowBattery = true;
      }

      // Pilotage des dashboards SAR avec un seul paramètre
      if (data.center.dashboard.sar) {
        data.center.dashboard.sarSRUnits = true;
        data.center.dashboard.sarAlertMonthReports = true;
        data.center.dashboard.unreadMessagesMonth = true;
        data.center.dashboard.unreadMessagesSender = true;
        data.center.dashboard.sarAlertIdentTypeReports = true;
        data.center.dashboard.sarAlertMsgTypeReports = true;
        data.center.dashboard.sarChartNbAlertsMonth = true;
      }

      Rights.register(new ThmRights(data));
      APP().setRights(data);

      APP().onLoginSuccess();

      let msg = I18N.prefix();

      APP().starts = [
        {
          value: "dashboard",
          text:  msg('_Dashboard')
        }, {
          value: "umv",
          text:  msg('_Map')
        }, {
          value: "ume",
          text:  msg("_Management")
        }, {
          value: "ure",
          text:  msg("_Reporting")
        }];

      let loading = new LoadingOverlay();

      USER_REQUEST.loadData().done(() => {
        // ThmUmvMain.openPage();
        if (RIGHTS("RCC")) {
          PREF('sarAlertsActivated', true);
        }

        if (RIGHTS("sar.sweepWidthSource") === "USCG") {
          SarConstants.setSweepWidthSource("USCG");
        }

        THM_ROUTER.initPages().done(() => {
          let mainPage = _.find(THM_ROUTER.pages, {id: PREF().umvStart.toUpperCase()}),
              topbar   = new ThmTopBar();

          topbar.render($("body"));
          topbar.doAfterRender();

          // Change start page with pref
          if (mainPage) {
            THM_ROUTER.switchPageTo(mainPage.id, {}, false);
          } else {
            THM_ROUTER.switchPageTo('UMV', {}, false);
          }

          loading.render($("body"));
          loading.doAfterRender();
        });
      });
    });
  },

  onLoginFail: function (params) {
    let me = this;

    this._super(params);
    if (!!params && !!params.key && params.key == "loginDeactivated")
      Toastr.showToastr('error', 'loginDeactivated', params.args);
  },

  setRgpd(value) {
    let me        = this,
        regex     = new RegExp("<([^>]{1,})>[^\\[]{0,}\\[([^\\]]{1,})]", "g"),
        links     = [],
        finalRgpd = value.replace(regex, (match, text, url) => {
          links.push({text, url});
          return '||||';
        }).split('||||').map((data, index) => {
          return {
            data,
            link: links[index] ? links[index] : null
          };
        });


    me.viewModel.attr("rgpd", finalRgpd);
  }
});

export default ThmLoginPage;
