import Toastr from "core-cmp/Toastr";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionReportingInZoneInfoPanelTpl.stache";

/**
 * @class AlertDefinitionReportingInZoneInfoPanel
 * Alert Definition "Reporting In Zone" Form.
 */
let AlertDefinitionReportingInZoneInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'AlertDefinitionReportingInZoneInfoPanel',
  childTpl:      ChildStache,

  updateViewModel: function () {
    let me = this,
        dataModel;

    me._super();

    dataModel = me.getDataModel();
    if (!!dataModel.triggerThresholds && dataModel.triggerThresholds.length > 0) {
      _.each(dataModel.triggerThresholds, function (val, i) {
        me.viewModel.attr('dataModel.trigger' + (i + 1), val);
      });
    }

    me.viewModel.attr('displayZoneAnnotation', true);
    me.viewModel.attr('zoneAnnotation', me.msg('reportingInZoneNoZoneDescriptor'));   

    me.updateOriginalValue();
  },

  //override
  getDataModelForSave: function () {
    let me        = this;
    let dataModel = me._super();

    //TRIGGER ONE TWO THREE
    dataModel.triggerThresholds = [$.isTrue(dataModel.trigger1) + "", $.isTrue(dataModel.trigger2) + "", $.isTrue(dataModel.trigger3) + ""];
    delete dataModel.trigger1;
    delete dataModel.trigger2;
    delete dataModel.trigger3;

    return dataModel;
  },

  /**
    * @override
    */
  getDataModelForCheckChange: function () {
    let me        = this,
        dataModel = me._super();
  
    dataModel.triggerThresholds = [$.isTrue(dataModel.trigger1) + "", $.isTrue(dataModel.trigger2) + "", $.isTrue(dataModel.trigger3) + ""];
    delete dataModel.trigger1;
    delete dataModel.trigger2;
    delete dataModel.trigger3;
    return dataModel;
  },

  /**
   * @override
   */
  checkErrors: function (dataModel) {
    let me = this;

    if (me._super(dataModel)) {
      return true;
    }

    // Zones are now optional in ua_reportingInZone
    // if (!dataModel.zones || dataModel.zones.length === 0) {
    //   Toastr.showToastr('error', 'alertDefinitionNoZone');
    //   return true;
    // }

    return false;
  }
});
export default AlertDefinitionReportingInZoneInfoPanel;