<div id="{{id}}"
     class="page page-page
     page-active {{_cmp.mode}} {{#if metocAuthorized}}
       {{#if metocModel.hasOneCurrentData}}
                 metocLegend-on
        {{/if}}
     {{/if}}{{#if isNotifAuthorized}} hasNotification{{/if}}"
     style="right:{{right}}px;">
  <div id="{{id}}-content" class="{{#if isNotifAuthorized}}hasNotification{{/if}}">
    <div id="{{id}}-logo"></div>
    {{#if isNotifAuthorized}}
      <div id="{{id}}-notif">
        <div id="{{id}}-msgMenu" class="btn btn-header-l icon-xl ui-icon-mail" cls-action="messages" title="{{msg '_Communication'}}">
          <span class="count msgCount">0</span>
        </div>
        <div id="{{id}}-alertMenu" class="btn btn-header-l icon-xl ui-icon-notification{{#unless hasSarAlerts}} hidden{{/unless}}" cls-action="sarAlerts" title="{{msg '_Alerts'}}">
          <span class="count alertsCount">0</span>
        </div>
      </div>
    {{/if}}
    <div id="{{id}}-user"></div>
    {{#if isIE}}
      <a cls-action="openSwitch" class="btn btn-header-l ui-icon-bars switchMenu" title="{{msg '_Switch'}}"></a>
    {{else}}
      <ui-navigation-menu id="{{id}}-switchMenu" class="switchMenu" {(field)}="page" {effect}="navEffect" {options}="menuOptions" icon="bars"></ui-navigation-menu>
    {{/if}}

    <a id="{{id}}-exit" cls-action="exit" class="btn btn-header-l ui-icon-power" title="{{msg 'exit.title'}}"></a>

    <!-- Boutons pour écrans < 600px -->
    {{#if isMap}}
      <a id="{{id}}-layerData" cls-action="layerData" class="btn btn-header-l ui-icon-layers"
         title="{{msg 'layerData'}}"></a>
      <a id="{{id}}-tools" cls-action="tools" class="btn btn-header-l ui-icon-tools" title="{{msg 'tools'}}"></a>
      <a id="{{id}}-search" cls-action="search" class="btn btn-header-l ui-icon-search"
         title="{{msg 'search'}}"></a>
    {{/if}}

    {{#if isGrid}}
      <a id="{{id}}-resource" cls-action="resource" class="btn btn-header-l ui-icon-search"></a>
    {{/if}}

    <div id="{{id}}-infos">
      <div id="{{id}}-agency"></div>
      <div id="{{id}}-date"></div>
    </div>
  </div>
  <div id="{{id}}-mouse" class="icon-left icon-m ui-icon-position"></div>
  <div id="{{id}}-metoc">
    {{#each families}}
      <div class="metocLegend icon-left icon-m ui-icon-{{icon}} core-metoc-color-{{index}} {{#unless
        hasData}}hidden{{/unless}}">{{mouseValue}}</div>
    {{/each}}
  </div>
  <a id="{{id}}-zoom" cls-action="zoom" class="btn btn-header-l ui-icon-bars"></a>
</div>
