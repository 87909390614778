import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";
import SelectByStache from "thm/ui/common/cpcPostData/ThmSelectCpcProtocolCriteria.stache";

/**
 * @class ThmSelectCpcProtocolCriteria
 */
let ThmSelectCpcProtocolCriteria = ThmSelectByCriteria.extend({
  /**
   * @override
   */
  readParameters: function (resource, criteriaConfig, criteriaName) {
    return {options: ["cpcProtocolKind"]};
  }
}, {
  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;
    me.stache = SelectByStache;
    me._super(config);
    me.typeOptions = [{
      value: 'all',
      text:  I18N.msg("uda.cpcPostData.cpcProtocolKind.all")
    }];

    me.cpcProtocolKinds = [
      {value: "ftp", text: I18N.msg("uda.cpcPostData.cpcProtocolKind.ftp")},
      {value: "ftps", text: I18N.msg("uda.cpcPostData.cpcProtocolKind.ftps")},
      {value: "http", text: I18N.msg("uda.cpcPostData.cpcProtocolKind.http")},
      {value: "https", text: I18N.msg("uda.cpcPostData.cpcProtocolKind.https")},
      {value: "smtp", text: I18N.msg("uda.cpcPostData.cpcProtocolKind.smtp")},
      {value: "flux", text: I18N.msg("uda.cpcPostData.cpcProtocolKind.flux")}
    ];

    me.criteriaFormModel.attr('cpcProtocolKind', "ftp");
  },

  /**
   * @override
   */
  setOptions: function () {
    let me = this;

    me.resourceMap.cpcProtocolKind = true;
    me.states.cpcProtocolKind = false;
    me.typeOptions.push({
      value: "cpcProtocolKind",
      text:  I18N.msg("uda.cpcPostData.cpcProtocolKind.selection")
    });
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this,
        type,
        id;

    me.criteriaFormModel.type = 'all';
    if (params[me.name]) {
      type = me.name;
      id = params[me.name].split(',');

      if (!_.isNil(me.states[type])) {
        me.criteriaFormModel.type = type;
        me.criteriaFormModel[type] = id;
      }
    }
  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me           = this,
        params       = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda    = {},
        type         = params.type;

    if (me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(params);
    } else {
      if (type !== 'all' && !_.isEmpty(me.criteriaFormModel[type])) {
        let ids = me.criteriaFormModel[type];
        paramsUda[resourceName + me.name] = ids;
      }
    }

    return paramsUda;
  },

  /**
   * @override
   */
  getUdaExportData: function () {
    let me         = this,
        params     = me.criteriaFormModel,
        type       = params.type,
        typeOption = _.find(me.typeOptions, {value: type}),
        protocol   = me.criteriaFormModel[type];

    return [{
      key:   typeOption.text,
      value: (type === "all") ? "" : I18N.msg("uda.cpcPostData.cpcProtocolKind." + protocol)
    }]
  }
});

export default ThmSelectCpcProtocolCriteria;
