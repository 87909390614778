import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import InfoPanel from "core-uda/ui/InfoPanel";
import {LOCAL_STORAGE_MANAGER} from "core-cmp/service/LocalStorageManager";
import PeriodicPollingInfoPanelTplStache from "thm/ui/common/periodicPolling/PeriodicPollingInfoPanelTpl.stache";
import PeriodicPolling from "core-uda/model/periodicPolling/PeriodicPolling";

/**
 * @class thm.ui.common.periodicPolling.PeriodicPollingInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un PPP
 * @param {Object} options
 */

let msg = I18N.prefix('core-sar.sap.');

let PeriodicPollingInfoPanel = InfoPanel.extend({
  template:      PeriodicPollingInfoPanelTplStache,
  i18nPrefix:    ['uda.periodicPolling.'],
  id:            'periodicPollingInfoPanel',
  entityId:      'periodicPolling',
  allowClone:    true,
  allowWidth: true,

  //override
  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr('channelOptions', ["POLL","POLL_GPRS"]);
  },

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this;
    dataModel.attr({
      name: me.generateName(),
      enabled: true,
      shared: true
    });
  },

  getTitle: function () {
    let me = this;
    return me.viewModel.attr('dataModel.name') || me.msg('label');
  },

  updateViewModel: function () {
    let me = this;
    me._super();

    if (me.viewModel.attr('dataModel.enabled') === undefined) {
      me.viewModel.attr('dataModel.enabled', true);
    }

    if (me.viewModel.attr('dataModel.shared') === undefined) {
      me.viewModel.attr('dataModel.shared', true);
    }

    if (!me.viewModel.attr('dataModel.name')){
        me.viewModel.attr('dataModel.name', me.generateName());
        me.viewModel.attr('titleInfo', me.getTitle());
    }
  },

  generateName: function() {
    const currentDate = new Date();
    const userId = LOCAL_STORAGE_MANAGER.get('user', 'id');
    const name = `${currentDate.getFullYear()}
${("0" + (currentDate.getMonth() + 1)).slice(-2)}
${("0" + currentDate.getDate()).slice(-2)}
${("0" + currentDate.getHours()).slice(-2)}
${("0" + currentDate.getMinutes()).slice(-2)}
${("0" + currentDate.getSeconds()).slice(-2)}-U-${APP('user', userId).login}`;

    return name.replace(/\n|\r/g, '');
  }

});

export default PeriodicPollingInfoPanel;
