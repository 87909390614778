<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>
    <field-item item="dataModel" class="L-scrollY-content">
        <div class="inlineGroup fields">
            <field-text name="code"></field-text>
            <field-text name="name"></field-text>
            <field-text name="url"></field-text>
            <field-text name="accord"></field-text>
            <field-textarea name="description"></field-textarea>
        </div>
    </field-item>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
