import ResourceUI from "core-uda/ui/ResourceUI";
import CpcFrom from "core-uda/model/cpcFrom/CpcFrom";
import template from "thm/ui/common/cpcFrom/CpcFromUITpl.stache";

/**
 * User Interface elements for CpcFrom
 */
let CpcFromUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;
    me._super({
      resourceId: CpcFrom.itemId,
      i18nPrefix: "thm.ui.common.cpcFrom.CpcFromUI.",
      icon:       "company",
      template:   template
    });
  }
});

const CPC_FROM_UI = new CpcFromUI();

export default CpcFromUI;
