<li data-id="{{item.id}}" data-index="{{index}}" class="list-item btn {{stateClass}}">
    <div>
        <div class="dataCustomDisplay">
            {{#if item.from}}
              <b>{{msg '_restrictionTime'}}:</b>
                {{__format 'dateTime' item.from ''}}
                {{#if item.to}}
                    - {{__format 'dateTime' item.to ''}}
                {{/if}}
            {{/if}}
            {{#if item.lastxdays}}
              <b>{{msg '_restrictionTime'}}:</b>
                {{item.lastxdays}} {{msg 'thm.ui.common.infopanel.customer.CustomerInfoPanel.xlastdays'}}
            {{/if}}
        </div>
        <div class="dataCustomDisplay">
            {{#if item.sources}}
                <b>{{msg 'uda.customer.field.aisSources'}}:</b>
                {{item.sources}}
            {{/if}}
        </div>
        <div class="dataCustomDisplay">
            {{#if item.zones}}
                <b>{{msg 'thm.ui.common.infopanel.customer.CustomerInfoPanel.authorizedZones'}}:</b> {{item.zones}}
            {{/if}}
        </div>
    </div>
</li>
