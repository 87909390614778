import {canDefineMap} from "core-cmp/can";
import ResourceUI from "core-uda/ui/ResourceUI";
import Flag from "core-uda/model/flag/Flag";
import template from "core-mobile/ui/type/FlagTpl.stache";
import {PREF} from "core-cmp/Preferences";
import FlagListSearchPage from "thm/ui/common/flag/FlagListSearchPage";

import {I18N} from "core-cmp/util/I18n";

/**
 * User Interface elements for Flag
 */
let FlagUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   Flag.itemId,
      i18nPrefix:   "thm.ui.common.flag.FlagUI.",
      icon:         "flag",
      searchFields: ["countryName", "countryCode"],
      template:     template,
      searchPageId: FlagListSearchPage.getId(),
      isListEditable: true
    })
  },

  /**
   * @override
   */
  getLabel: function (item) {
    return `${item.countryName} (${item.countryCode})`;
  }

});

const FLAG_UI = new FlagUI();

export default FlagUI;
