import UmeMainPage from "core-ure/ui/UmeMainPage";
import UploadZonePopup from "core-zone/ui/UploadZonePopup";
import {RIGHTS} from "core-uda/Rights";
import {RESOURCE_CLASS} from "core-uda/model/Resource";
import {PAGE_ANIMATION} from "thm/ui/main/ThmRouter";
import ExportLogbookPage from "thm/ui/common/ersTrip/ExportLogbookPage";
import ErsDataComparePopup from "thm/ui/common/ersData/comparePopup/ErsDataComparePopup";
import FilterFavoriteAction from "core-ure/ui/actions/FilterFavoriteAction";

let ThmUmeMain = UmeMainPage.extend({
  id:                               'thm-umeMain',
  i18nPrefix:                       ["ure.ui.GridMainPage.", "thm.ui.ume.ThmUmeMain."],
  compliantResourcesFromMenuConfig: true,

  /**
   * @override
   */
  init: function (config) {
    const me = this;

    me.animation = PAGE_ANIMATION;
    me.menuOptionsStyle = "ui-menu-item";
    me.menuIconAlign = "left";
    me.menuTitleVisible = false;
    me.menuOptionsAlign = "left";
    me.menuAnimateStyle = "airbnb";
    me.menuConfig = [
      {
        label: me.msg("menu.user"),
        items: ["customer", "user", "userGroup"]
      }, {
        label: me.msg("menu.beacons"),
        items: ["beacon", "lesInmc", "beaconModel", "satProvider", "positionSource", "periodicPolling"]
      }, {
        label: me.msg("menu.vessels"),
        items: ["mobile", "animal", "fleet", "shipowner", "flag", "mobileType", "mobileLifeEventType", "statusCode", "trip", "sharedVessel"]
      }, {
        label: me.msg("menu.fishing"),
        items: ["licence", "quota", "fishingGear", "fishingProtocol", "fmc", "species", "speciesCat"]
      }, {
        label: me.msg("menu.alert"),
        items: ["alertDefinition", "recipient"]
      }, {
        label: me.msg("menu.cpc"),
        items: ["cpc", "cpcCondition", "cpcFormat", "cpcFrom", "cpcProtocol"]
      }, {
        label: me.msg("menu.zone"),
        items: ["zone", "zoneType", "portType"]
      }, {
        label: me.msg("_SAR"),
        items: ["sarSRUnit"]
      }
    ];

    if(!RIGHTS('userGroup')){
      // remove entry for userGroup in the menuCOnfig array
      let indexUserGroup = me.menuConfig[0].items.indexOf('userGroup');
      me.menuConfig[0].items.splice(indexUserGroup);
    }

    me._super();
  },

  getActionsByResource: function () {
    return {
      "zone": {
        "import": {
          "icon":     "import",
          "labelKey": "Import",
          action:     () => {
            UploadZonePopup.openPage({
              createTarget: "management"
            });
          }
        }
      }
    }
  },

  /**
   * @override
   */
  isManyToOneContextMenuButtonAuthorized: function (manyToOne, manyToOneId) {

    if (manyToOne === "customer") {
      return RIGHTS("showClient");
    }
    return true;
  },

  /**
   * Action called when the user click on context menu button of type "logbook".
   * @param {object} context - open context
   * @param {object} context.data - row data.
   * @param {UreGridModel} context.model - grid model
   * @param {UdaGrid} context.grid - grid
   * @param {Resource|null} [context.item] - Resource item.
   * @param {number} context.rowIndex - Row index.
   * @param {dom} context.rowElement - Row element.
   * @param {object} button - button model
   */
  onContextMenuLogbook: function (context, button) {
    ExportLogbookPage.openPage({
      tripId: context.data.id
    });
  },

  /**
   * Action called when the user click on context menu button of type "compare".
   * @param {object} context - open context
   * @param {object} context.data - row data.
   * @param {UreGridModel} context.model - grid model
   * @param {UdaGrid} context.grid - grid
   * @param {Resource|null} [context.item] - Resource item.
   * @param {number} context.rowIndex - Row index.
   * @param {dom} context.rowElement - Row element.
   * @param {object} button - button model
   */
  onContextMenuCompare: function (context, button) {
    let me = this;
    RESOURCE_CLASS["ersData"].findCorrected(context.data).done(function (result) {
      ErsDataComparePopup.openPage({
        message:    context.data,
        activities: result.ersActivities
      });
    });
  },

  /**
   * Action called when the user click on context menu button of type "compareAll".
   * @param {object} context - open context
   * @param {object} context.data - row data.
   * @param {UreGridModel} context.model - grid model
   * @param {UdaGrid} context.grid - grid
   * @param {Resource|null} [context.item] - Resource item.
   * @param {number} context.rowIndex - Row index.
   * @param {dom} context.rowElement - Row element.
   * @param {object} button - button model
   */
  onContextMenuCompareAll: function (context, button) {
    let me = this;
    RESOURCE_CLASS["ersData"].findCorrected(context.data).done(function (result) {
      ErsDataComparePopup.openPage({
        message:    context.data,
        activities: result.ersActivities,
        compareAll: true
      });
    });
  },

  /**
   * @override
   * Return left menu config.
   */
  getLeftMenuConfig: function () {
    return {
      allowCriteria:       true, // some application use criterie to load declarations.
      allowCriteriaConfig: true,
      allowFavorite:       true,
      allowColumns:        true,
      superAdminConfig:    true,
      criteriaFavoriteAction : FilterFavoriteAction
    };
  }

});

export default ThmUmeMain;
