<div id="{{id}}" class="L-fit L-panel">

    <ui-loading anchor="center" class="{{#unless loadingScreen}}hidden{{/unless}}"></ui-loading>

    <div class="L-panel-content {{#if loadingScreen}}hidden{{/if}}">
        <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

        <field-item item="dataModel" class="L-panel-content">
            <div class="inlineGroup">
                <div class="header-group icon-left icon-m ui-icon-fish">
                    <h2>{{msg 'species'}}</h2>
                </div>
                <div id="{{id}}-catchesGrid"></div>
            </div>
        </field-item>
    </div>

</div>
