<div id="{{id}}" class="page">
    <div class="header-group">
        <h3>
            {{title}}
        </h3>
    </div>

    <field-item  item="dataModel" class="inlineGroup fields">
        <uda-select of="cpc" label="{{msg 'cpcLabel'}}" name="cpcId"></uda-select>
    </field-item>

    <div class="inline-2btn btn-footer">
        <a cls-action="send" class="btn btn-ok">{{msg '_Forward'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
