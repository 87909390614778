import _ from "core-cmp/lodash";
import Toastr from "core-cmp/Toastr";
import {APP, RESOURCE_CLASS} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import Criteria from "core-criteria/Criteria";
import ResourceUI from "core-uda/ui/ResourceUI";
import SelectByStache from "thm/ui/common/criteria/ThmSelectByCriteria.stache";
import ErsTripStache from "thm/ui/common/criteria/ThmErsTripCriteria.stache";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";
import "core-criteria/selectBy/SelectByCriteria.css";

/**
 * @class ThmErsTripCriteria
 */
let ThmErsTripCriteria = Criteria.extend({}, {

  typeOptions: "array",
  states:      "observable",
  resourceMap: "observable",

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;

    if (!me.stache) {
      me.stache = ErsTripStache;
    }

    me._super(config);

    me.validateMandatory = false;

    me.criteriaFormModel = {
      type:    'all',
      ersTrip: []
    };

    me.typeOptions = [{
      value: "all",
      text:  I18N.msg("uda.ersTrip.allErsTrip")
    },{
      value: "ersTrip",
      text:  I18N.msg("uda.ersTrip.selectionErsTrip")
    }];

    me.states = {};
    me.criteriaFormModel.on("type", me.proxy(me.changeType));
    me.changeType();
  },


  changeType: function () {
    let me   = this,
        type = me.criteriaFormModel.type;

    me.states.set("ersTrip", type === "ersTrip");
  },

  /**
   * @override
   * Au moment de l'envoie de la requete, il faut determiner comment la construire
   */
  formatModelToUDA: function () {
    let me = this,
        params    = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda = {},
        type      = params.type;

    if (me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(params);
    } else {
      if (type !== "all" && !_.isEmpty(me.criteriaFormModel[type])) {
        let ids = me.criteriaFormModel[type].join(",");
        paramsUda[resourceName + me.name] = ids;
      }
    }

    return paramsUda;
  },


  /**
   * @override
   * Si des paramettres ont été rentré precedemment il faut les remettre.
   */
  setParams: function (params) {
    let me = this;

    me.criteriaFormModel.type = 'all';
    if (params[me.name]) {
      if (!_.isNil(me.states[me.name])) {
        me.criteriaFormModel.type = me.name;
        me.criteriaFormModel[me.name] = [params[me.name]];
      }
    }
  }

});

export default ThmErsTripCriteria;
