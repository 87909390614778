<div id="{{id}}" class="page customer-update-confirm-popup">
  <div class="header-group">
    <h3>{{msg 'title'}}</h3>
  </div>

  <div class="body text-danger">
    <div class="vessel-message-1">{{msg 'warningMessage1'}}</div>
    <div class="vessel-errors">
      {{#with dataModel}}
        {{#each vesselErrors}}
          {{#if fleets.length}}
            <div class="vessel-error">
              {{msg 'theVessel'}} <span class="vessel">{{getVesselName(vesselId)}}</span> {{msg 'fleetMessage'}}
              {{#each fleets}}
                <span class="fleet">{{getFleetName(this)}}</span>
              {{/each}}
            </div>
          {{/if}}
          {{#if alertDefinitions.length}}
            <div class="vessel-error">
              {{msg 'theVessel'}} <span class="vessel">{{getVesselName(vesselId)}}</span> {{msg 'alertDefinitionMessage'}}
              {{#each alertDefinitions}}
                <span class="alert-definition">{{getAlertDefinitionName(this)}}</span>
              {{/each}}
            </div>
          {{/if}}
        {{/each}}
      {{/with}}
    </div>
    <div class="vessel-message-2">{{msg 'warningMessage2'}}</div>
  </div>

  <div class="inline-2btn">
    <a cls-action="confirm" class="btn btn-ok">{{msg 'save'}}</a>
    <a cls-action="cancel" class="btn btn-cancel">{{msg 'cancel'}}</a>
  </div>

</div>
