import InfoPanel from "core-uda/ui/InfoPanel";
import template from "thm/ui/common/lesInmc/LesInmcInfoPanelTpl.stache";
import LesInmc from "core-uda/model/beacon/LesInmc";

/**
 * Inmarsat C-LES form.
 * @class LesInmcInfoPanel
 */
let LesInmcInfoPanel = InfoPanel.extend({
  template:   template,
  i18nPrefix: ['uda.lesInmc.', 'thm.ui.common.lesInmc.LesInmcInfoPanel.'],
  id:         'lesInmcInfoPanel',
  entityId:   LesInmc.itemId,
  titleKey:   "title",
  allowWidth: true,
  allowClone: true,

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this;

    dataModel.attr({
      name: me.msg("_Clone") + " - " + dataModel.name
    });
  }
});

export default LesInmcInfoPanel;
