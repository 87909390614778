import $ from "core-cmp/cmp-jquery";
import _ from "core-cmp/lodash";
import {RIGHTS} from "core-uda/Rights";
import SsasContact from "core-uda/model/ssas/SsasContact";
import InfoPanel from "core-uda/ui/InfoPanel";
import SsasContactInfoTpl from "thm/ui/common/ssas/SsasContactInfoPanelTpl.stache";

/**
 * @class SsasContactInfoPanel
 *
 * SSAS Contact  Info panel.
 */
let SsasContactInfoPanel = InfoPanel.extend({
  template:      SsasContactInfoTpl,
  i18nPrefix:    ['uda.ssas.field.', 'thm.ui.common.mobile.SsasMobile.'],
  id:            'ssasContactInfoPanel',
  childTpl:      null,
  data:          null,
  entityId:      'ssasContacts',

  /**
   * @override
   */
  initViewModel: function () {
    let me     = this;
    me._super();
  },

  updateViewModel: function () {
    let me = this;
    me._super();
  },

  /**
   * Méthode qui permet de binder les evènements au viewModel
   */
  bindViewModel: function () {
    let me = this;
    me._super();
  },

  showErrorFields: function () {
    let me        = this,
        allFields = me.$el.find(".core-cmp-wc-field");

    // focus doest not seem to work
    allFields.find('input').keyup();
    allFields.find('textarea').keyup();
  },

  getTitle: function () {
    let me = this;
    return me.data.alias || me.msg('_SsasContacts');
  },

  /**
   *  Remplit la liste des alertes
   */
  fillList: function (data, type) {
    let me = this;
  },

  /**
   * @override
   */
  loadForEdition: function (data) {
    return SsasContact.loadForEdition(data.id, data);
  }

});

export default SsasContactInfoPanel;
