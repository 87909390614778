import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import UdaTopBar from "core-uda/ui/topBar/UdaTopBar";
import {THM_PREF} from "thm/ThmPreferences";
import {I18N} from "core-cmp/util/I18n";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import SettingsPage from "thm/ui/common/settings/SettingsPage"; // Necessary for Page.get in UdaTopBar
import SwitchViewPopup from "core-uda/ui/switchViewPopup/SwitchViewPopup";
import TopBarStache from "thm/ui/main/ThmTopBar.stache";
import {THM_ROUTER} from "thm/ui/main/ThmRouter";
import {RIGHTS} from "core-uda/Rights";
import Toastr from "core-cmp/Toastr";
import RightPanel from "core-uda/ui/rightPanel/RightPanel";
import Message from "core-uda/model/message/Message";
import SarAlert from "core-sar/model/sarAlert/SarAlert";
import {DATA_CONTEXT} from "core-uda/model/DataContext";
import {ROUTER} from "core-uda/ui/UdaRouter";
import GIS from "core-gis/Default";
import {RESOURCE_CLASS} from "core-uda/model/Resource";
import {APP} from "core-uda/model/Resource";
import {PREF} from "core-cmp/Preferences";
import {MAIN_PAGE} from "core-uda/ui/UdaMainPage";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";
import "thm/ui/main/ThmTopBar.css";

/* PANEL*/
import {INFO_PANEL_REPOSITORY} from "core-uda/ui/InfoPanelRepository";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import AlertInfoPanel from "thm/ui/common/alert/AlertInfoPanel";
import CollectedDataInfoPanel from "core-cdata/ui/infopanel/CollectedDataInfoPanel";
import ThmFleetInfoPanel from "thm/ui/common/fleet/ThmFleetInfoPanel";
import RecipientInfoPanel from "core-alert/ui/recipient/RecipientInfoPanel";
import SensorMessageInfoPanel from "core-sensor/ui/infopanel/SensorMessageInfoPanel";
import SpeedStatisticsInfoPanel from "core-mobile/ui/speed/SpeedStatisticsInfoPanel";
import StatusInfoPanel from "thm/ui/common/status/StatusInfoPanel";
import ThmZoneInfoPanel from "thm/ui/common/zone/ThmZoneInfoPanel";
import ConcentricRadiusInfoPanel from "core-uda/ui/concentricradius/ConcentricRadiusInfoPanel";
import AlertDefinitionFishingWithoutLicenceInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionFishingWithoutLicenceInfoPanel";
import AlertDefinitionFishingLicenceExpiredInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionFishingLicenceExpiredInfoPanel";
import AlertDefinitionFarFromFleetInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionFarFromFleetInfoPanel";
import AlertDefinitionTripSpeedInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionTripSpeedInfoPanel";
import AlertDefinitionTripProgressionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionTripProgressionInfoPanel";
import AlertDefinitionExceptionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionExceptionInfoPanel";
import AlertDefinitionInvalidMoveInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInvalidMoveInfoPanel";
import AlertDefinitionManualInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionManualInfoPanel";
import AlertDefinitionPortInOutInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionPortInOutInfoPanel";
import AlertDefinitionPortTimeInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionPortTimeInfoPanel";
import AlertDefinitionReportOverdueInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionReportOverdueInfoPanel";
import AlertDefinitionReportingInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionReportingInfoPanel";
import AlertDefinitionTimeInZoneInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionTimeInZoneInfoPanel";
import AlertDefinitionSensorWinchMonitoringInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionSensorWinchMonitoringInfoPanel";
import AlertDefinitionSensorStateMonitoringInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionSensorStateMonitoringInfoPanel";
import AlertDefinitionSpeedInZoneInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionSpeedInZoneInfoPanel";
import AlertDefinitionStationaryInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionStationaryInfoPanel";
import AlertDefinitionVesselRDVInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionVesselRDVInfoPanel";
import AlertDefinitionZoneInOutInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionZoneInOutInfoPanel";
import AlertDefinitionSpeedAnomalyInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionSpeedAnomalyInfoPanel";
import AlertDefinitionConcentratedDispersedInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionConcentratedDispersedInfoPanel";
import AlertDefinitionDistanceToZoneInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionDistanceToZoneInfoPanel";
import AlertDefinitionCloseToHerdInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionCloseToHerdInfoPanel";
import AlertDefinitionStatusAnomalyInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionStatusAnomalyInfoPanel";
import AlertDefinitionReportingInZoneInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionReportingInZoneInfoPanel";
import MobileThemisInfoPanel from "thm/ui/common/mobile/MobileThemisInfoPanel";
import AnimalInfoPanel from "thm/ui/common/mobile/AnimalInfoPanel";
import ZoneAnnotationInfoPanel from "thm/ui/common/zone/ZoneAnnotationInfoPanel";
import ZoneItineraryInfoPanel from "thm/ui/common/zone/ZoneItineraryInfoPanel";
import ZoneLandmarkInfoPanel from "thm/ui/common/zone/ZoneLandmarkInfoPanel";
import ZoneOfficialInfoPanel from "thm/ui/common/zone/ZoneOfficialInfoPanel";
import ZonePortInfoPanel from "thm/ui/common/zone/ZonePortInfoPanel";
import ZoneUserInfoPanel from "thm/ui/common/zone/ZoneUserInfoPanel";
import ZoneWorkstationInfoPanel from "thm/ui/common/zone/ZoneWorkstationInfoPanel";
import FolderInfoPanel from "core-mobile/ui/folder/FolderInfoPanel";
import BeaconDefaultInfoPanel from "thm/ui/common/beacon/BeaconDefaultInfoPanel";
import BeaconTypeInfoPanel from "thm/ui/common/beaconType/BeaconTypeInfoPanel";
import UserInfoPanel from "thm/ui/common/user/UserInfoPanel";
import FlagInfoPanel from "thm/ui/common/flag/FlagInfoPanel";
import MobileTypeInfoPanel from "thm/ui/common/mobile/MobileTypeInfoPanel";
import PaletteInfoPanel from "core-uda/ui/palette/PaletteInfoPanel";
import PortTypeInfoPanel from "thm/ui/common/portType/PortTypeInfoPanel";
import UserZoneTypeInfoPanel from "thm/ui/common/userZoneType/UserZoneTypeInfoPanel";
import GlobalZoneTypeInfoPanel from "thm/ui/common/globalZoneType/GlobalZoneTypeInfoPanel";
import ShipownerInfoPanel from "thm/ui/common/ship/ShipownerInfoPanel";
import CustomerInfoPanel from "thm/ui/common/customer/CustomerInfoPanel";
import StatusCodeInfoPanel from "thm/ui/common/statusCode/StatusCodeInfoPanel";
import SatProviderInfoPanel from "thm/ui/common/satProvider/SatProviderInfoPanel";
import PositionSourceInfoPanel from "thm/ui/common/positionSource/PositionSourceInfoPanel";
import MobileLifeEventTypeInfoPanel from "thm/ui/common/mobileLifeEventType/MobileLifeEventTypeInfoPanel";
import FmcInfoPanel from "thm/ui/common/fmc/FmcInfoPanel";
import LesInmcInfoPanel from "thm/ui/common/lesInmc/LesInmcInfoPanel";
import SpeciesInfoPanel from "thm/ui/common/species/SpeciesInfoPanel";
import GenericMobileInfoPanel from "thm/ui/common/mobile/GenericMobileInfoPanel";
import PeriodicPollingInfoPanel from "thm/ui/common/periodicPolling/PeriodicPollingInfoPanel";
import UserGroupInfoPanel from "thm/ui/common/userGroup/UserGroupInfoPanel";
import TripInfoPanel from "thm/ui/common/trip/TripInfoPanel";
import SharedVesselInfoPanel from "thm/ui/common/sharedVessel/SharedVesselInfoPanel";
import ReportScheduleInfoPanel from "core-ure/ui/favorite/ReportScheduleInfoPanel";

// CPC
import CpcInfoPanel from "thm/ui/common/cpc/CpcInfoPanel";
import CpcConditionInfoPanel from "thm/ui/common/cpcCondition/CpcConditionInfoPanel";
import CpcFromInfoPanel from "thm/ui/common/cpcFrom/CpcFromInfoPanel";
import CpcFormatInfoPanel from "thm/ui/common/cpcFormat/CpcFormatInfoPanel";
import CpcProtocolInfoPanel from "thm/ui/common/cpcProtocol/CpcProtocolInfoPanel";

// DRIFT
import DriftInfoPanel from "core-drift/ui/infopanel/DriftInfoPanel";

// ERS
import FishingProtocolInfoPanel from "thm/ui/common/fishingProtocol/FishingProtocolInfoPanel";
import LicenceInfoPanel from "thm/ui/common/licence/LicenceInfoPanel";
import FishingGearInfoPanel from "thm/ui/common/fishingGear/FishingGearInfoPanel";
import QuotaInfoPanel from "thm/ui/common/quota/QuotaInfoPanel";
import ErsActivityInfoPanel from "thm/ui/common/ersActivity/ErsActivityInfoPanel";
import ErsTripInfoPanel from "thm/ui/common/ersTrip/ErsTripInfoPanel";
import CatchDeclarationInfoPanel from "thm/ui/common/catchDeclaration/CatchDeclarationInfoPanel";
import ErsOperationReportInfoPanel from "thm/ui/common/ersOperationReport/ErsOperationReportInfoPanel";

// RADAR
import RadarEchoInfoPanel from "core-radar/ui/infopanel/RadarEchoInfoPanel";
import RadarSlickInfoPanel from "core-radar/ui/infopanel/RadarSlickInfoPanel";
import RadarProductInfoPanel from "core-radar/ui/infopanel/RadarProductInfoPanel";

// SAR
import SarDriftInfoPanel from "core-sar/ui/infopanel/sarDrift/SarDriftInfoPanel";
import SRUInfoPanel from "core-sar/ui/infopanel/sru/SRUInfoPanel";
import SarOperationInfoPanel from "core-sar/ui/sarOperation/SarOperationInfoPanel";
import SarSadInfoPanel from "core-sar/ui/sarSad/SarSadInfoPanel";
import SarSacInfoPanel from "core-sar/ui/sarSac/SarSacInfoPanel";
import SarAlertInfoPanel from "core-sar/ui/infopanel/alert/SarAlertInfoPanel";

// SSAS
import SsasContactInfoPanel from "thm/ui/common/ssas/SsasContactInfoPanel";

import UmeFavoritePreferencesInfoPanel from "core-uda/ui/umeFavoritePreferences/UmeFavoritePreferencesInfoPanel";

/**
 * @class TopBar
 * Top bar
 */
let ThmTopBar = UdaTopBar.extend({
  id:                 'topBar',
  i18nPrefix:         ["core-uda.ui.topBar.UdaTopBar.", "thm.ui.main.ThmTopBar."],
  template:           TopBarStache,
  notifRefreshTime:   60,
  hasUpdateRight:     true,
  hasMetocAuthorized: true,

  initViewModel: function () {
    let me = this;
    me._super();

    me.udaRouter = THM_ROUTER;
    me.navEffectList = RIGHTS('navEffects', 'moveleft').split(",");
    me.switchPopup = new SwitchViewPopup(THM_ROUTER.pages);

    if (THM_ROUTER.pages.length > 2) {
      me.switchPopupActivated = true;
      me.viewModel.attr('switchPopupActivated', me.switchPopupActivated);
    }

    me.viewModel.attr("hasSarAlerts", false);
    me.viewModel.attr('metocAuthorized', true);
    me.viewModel.attr('menuOptions', THM_ROUTER.pages);
    me.viewModel.attr('isNotifAuthorized', !!RIGHTS("RCC"));


    me.alertSound = new Audio('sound/alert.mp3');

    // We check if we the browser is IE to disable the navigation menu
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf("MSIE ");

    // If Internet Explorer, return true
    me.viewModel.attr("isIE", msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));

    // Nav effect
    me.updateNavEffect();
    if (PREF("alertNotification")) {
      me.handleSearchNewAlertsInterval();
    }
  },

  updateNavEffect: function () {
    let me        = this,
        navEffect = PREF('navEffects') || "moveleft";

    if (me.navEffectList.indexOf(navEffect) >= 0) {
      me.viewModel.attr('navEffects', navEffect);
    }
  },

  onOpenSwitch: function (ev) {
    let me = this;

    me.switchPopup.open();
  },

  /**
   * @override
   */
  bindEvents: function () {
    let me = this;
    me._super();

    if (RIGHTS("RCC")) {
      me.refreshSarMessages();
      APP().on("sarMessageUpdated", function () {
        me.refreshSarMessages();
      });

      APP().whenMapLoaded().done(function () {
        if (APP().hasData("sarAlerts")) {
          me._sarAlertsCount();
        }

        APP().on("sarAlertsLoaded", function () {
          me.viewModel.attr("hasSarAlerts", true);
          me._sarAlertsCount();
        });
        APP().on("sarAlertsUpdated", function () {
          me._sarAlertsCount();
        });
      });
    }

    PREF().on("navEffects", me.updateNavEffect.bind(me));
    PREF().on("alertNotification", (ev, value) => {
      clearInterval(me.searchNewAlertsInterval);
      if (value) {
        me.handleSearchNewAlertsInterval();
      }
    });
    PREF().on("alertNotificationFilter", () => {
      clearInterval(me.searchNewAlertsInterval);
      me.handleSearchNewAlertsInterval();
    });
  },

  /**
   * @override
   */
  doAfterRender: function () {
    let me        = this,
        startPage = 'UMV';

    me._super();

    if (!_.isUndefined(PREF('umvStart')) && PREF('umvStart') !== "umv") {
      startPage = PREF('umvStart').toUpperCase();
    }
  },

  registerInfoPanels: function () {
    let me = this;

    /* Data Panel */
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_stationary", AlertDefinitionStationaryInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_closeToHerd", AlertDefinitionCloseToHerdInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_statusDuration", AlertDefinitionStatusAnomalyInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_invalidMove", AlertDefinitionInvalidMoveInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_vesselRDV", AlertDefinitionVesselRDVInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_far", AlertDefinitionFarFromFleetInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_concentrated", AlertDefinitionConcentratedDispersedInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_distanceToZone", AlertDefinitionDistanceToZoneInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_manual", AlertDefinitionManualInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_portInOut", AlertDefinitionPortInOutInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_portTime", AlertDefinitionPortTimeInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_zoneInOut", AlertDefinitionZoneInOutInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_speedAnomaly",AlertDefinitionSpeedAnomalyInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_speedInZone", AlertDefinitionSpeedInZoneInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_timeInZone", AlertDefinitionTimeInZoneInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_reporting", AlertDefinitionReportingInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_sensorWinchMonitoring", AlertDefinitionSensorWinchMonitoringInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_sensorStateMonitoring", AlertDefinitionSensorStateMonitoringInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ua_reportingInZone", AlertDefinitionReportingInZoneInfoPanel);  
    INFO_PANEL_REPOSITORY.register("alertDefinition.ga_reportOverdue", AlertDefinitionReportOverdueInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ga_exceptions", AlertDefinitionExceptionInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ga_fishingWithoutLicence", AlertDefinitionFishingWithoutLicenceInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ga_fishingLicenceExpired", AlertDefinitionFishingLicenceExpiredInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ga_tripSpeed", AlertDefinitionTripSpeedInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition.ga_tripProgression", AlertDefinitionTripProgressionInfoPanel);
    INFO_PANEL_REPOSITORY.register("alertDefinition", AlertDefinitionInfoPanel); // Default panel
    INFO_PANEL_REPOSITORY.register("periodicPolling", PeriodicPollingInfoPanel);
    INFO_PANEL_REPOSITORY.register("collectedData", CollectedDataInfoPanel);
    INFO_PANEL_REPOSITORY.register("sensorMessage", SensorMessageInfoPanel);
    INFO_PANEL_REPOSITORY.register("fleet", ThmFleetInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobile", MobileThemisInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobile.generic", GenericMobileInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobile.vessel", MobileThemisInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobile.srunit", MobileThemisInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobile.animal", AnimalInfoPanel);
    INFO_PANEL_REPOSITORY.register("recipient", RecipientInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.annotation", ZoneAnnotationInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.itinerary", ZoneItineraryInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.landmark", ZoneLandmarkInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.official", ZoneOfficialInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.port", ZonePortInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.user", ZoneUserInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone.workstation", ZoneWorkstationInfoPanel);
    INFO_PANEL_REPOSITORY.register("zone", ThmZoneInfoPanel);
    INFO_PANEL_REPOSITORY.register("concentricRadius", ConcentricRadiusInfoPanel);
    INFO_PANEL_REPOSITORY.register("radarEcho", RadarEchoInfoPanel);
    INFO_PANEL_REPOSITORY.register("radarSlick", RadarSlickInfoPanel);
    INFO_PANEL_REPOSITORY.register("radarProduct", RadarProductInfoPanel);
    INFO_PANEL_REPOSITORY.register("alert", AlertInfoPanel);
    INFO_PANEL_REPOSITORY.register("statusData", StatusInfoPanel);
    INFO_PANEL_REPOSITORY.register("speedStatistics", SpeedStatisticsInfoPanel);
    INFO_PANEL_REPOSITORY.register("folder", FolderInfoPanel);
    INFO_PANEL_REPOSITORY.register("beacon", BeaconDefaultInfoPanel);
    INFO_PANEL_REPOSITORY.register("beaconModel", BeaconTypeInfoPanel);
    INFO_PANEL_REPOSITORY.register("user", UserInfoPanel);
    INFO_PANEL_REPOSITORY.register("user.normal", UserInfoPanel);
    INFO_PANEL_REPOSITORY.register("flag", FlagInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobileType", MobileTypeInfoPanel);
    INFO_PANEL_REPOSITORY.register("palette", PaletteInfoPanel);
    INFO_PANEL_REPOSITORY.register("portType", PortTypeInfoPanel);
    INFO_PANEL_REPOSITORY.register("userZoneType", UserZoneTypeInfoPanel);
    INFO_PANEL_REPOSITORY.register("globalZoneType", GlobalZoneTypeInfoPanel);
    INFO_PANEL_REPOSITORY.register("shipowner", ShipownerInfoPanel);
    INFO_PANEL_REPOSITORY.register("customer", CustomerInfoPanel);
    INFO_PANEL_REPOSITORY.register("licence", LicenceInfoPanel);
    INFO_PANEL_REPOSITORY.register("fishingGear", FishingGearInfoPanel);
    INFO_PANEL_REPOSITORY.register("statusCode", StatusCodeInfoPanel);
    INFO_PANEL_REPOSITORY.register("satProvider", SatProviderInfoPanel);
    INFO_PANEL_REPOSITORY.register("positionSource", PositionSourceInfoPanel);
    INFO_PANEL_REPOSITORY.register("mobileLifeEventType", MobileLifeEventTypeInfoPanel);
    INFO_PANEL_REPOSITORY.register("fmc", FmcInfoPanel);
    INFO_PANEL_REPOSITORY.register("lesInmc", LesInmcInfoPanel);
    INFO_PANEL_REPOSITORY.register("fishingProtocol", FishingProtocolInfoPanel);
    INFO_PANEL_REPOSITORY.register("species", SpeciesInfoPanel);
    INFO_PANEL_REPOSITORY.register("quota", QuotaInfoPanel);
    INFO_PANEL_REPOSITORY.register("ersActivity", ErsActivityInfoPanel);
    INFO_PANEL_REPOSITORY.register("ersTrip", ErsTripInfoPanel);
    INFO_PANEL_REPOSITORY.register("catchDeclaration", CatchDeclarationInfoPanel);
    INFO_PANEL_REPOSITORY.register("ersOperationReport", ErsOperationReportInfoPanel);
    INFO_PANEL_REPOSITORY.register("animal", AnimalInfoPanel);
    INFO_PANEL_REPOSITORY.register("userGroup", UserGroupInfoPanel);
    INFO_PANEL_REPOSITORY.register("trip", TripInfoPanel);
    INFO_PANEL_REPOSITORY.register("sharedVessel", SharedVesselInfoPanel);
    INFO_PANEL_REPOSITORY.register("reportSchedule", ReportScheduleInfoPanel);

    // CPC
    INFO_PANEL_REPOSITORY.register("cpc", CpcInfoPanel);
    INFO_PANEL_REPOSITORY.register("cpcCondition", CpcConditionInfoPanel);
    INFO_PANEL_REPOSITORY.register("cpcFrom", CpcFromInfoPanel);
    INFO_PANEL_REPOSITORY.register("cpcFormat", CpcFormatInfoPanel);
    INFO_PANEL_REPOSITORY.register("cpcProtocol", CpcProtocolInfoPanel);

    // DRIFT
    INFO_PANEL_REPOSITORY.register("drift", DriftInfoPanel);

    // SAR
    INFO_PANEL_REPOSITORY.register("sarDrift", SarDriftInfoPanel);
    INFO_PANEL_REPOSITORY.register("sarSRUnit", SRUInfoPanel);
    INFO_PANEL_REPOSITORY.register("sarOperation", SarOperationInfoPanel);
    INFO_PANEL_REPOSITORY.register("sarSad", SarSadInfoPanel);
    INFO_PANEL_REPOSITORY.register("sarSac", SarSacInfoPanel);
    INFO_PANEL_REPOSITORY.register("sarAlert", SarAlertInfoPanel);

    // SSAS
    INFO_PANEL_REPOSITORY.register('ssasContact', SsasContactInfoPanel);

    INFO_PANEL_REPOSITORY.register("umeFavoritePreference", UmeFavoritePreferencesInfoPanel);
  },

  onResource: function () {
    let me = this,
        id = APP().gridMode === "UME" ? 'thm-umeMain' : 'thm-ureMain';
    me._super(id);
  },

  refreshSarMessages: function () {
    let me = this;

    me._sarMessagesCount();
    clearInterval(me.sarMessageInterval);
    me.sarMessageInterval = setInterval(function () {
      me._sarMessagesCount();
    }, me.notifRefreshTime * 1000);
  },

  _sarMessagesCount: function () {
    let me = this;
    if (DATA_AUTHORIZED('message')) {
      return Message.count({
        filter: JSON.stringify({
          type:   "IN",
          status: "NONE"/*,
           "!toId": null*/
        })
      }).done(function (res) {
        let msgIcon = $('#topBar-msgMenu');
        me._setNotifValue(msgIcon, res);
      });
    }
  },

  _sarAlertsCount: function () {
    let me         = this,
        alertIcon  = $('#topBar-alertMenu'),
        openAlerts = APP("sarAlerts").getOpenAlerts()

    me._setNotifValue(alertIcon, openAlerts.length);
    if (APP("sarAlerts").playAlertSound()) {
      APP("sarAlerts").shouldPlayAlertSound = false;
      me.playSoundAlert()
    }
  },

  _setNotifValue: function (el, value) {
    let me = this;
    if (el) {
      // Change span value
      el[0].firstElementChild.textContent = value;
      if (!value) {
        el.removeClass('unread');
      } else {
        el.addClass('unread');
      }
    }
  },

  playSoundAlert: function () {
    let me      = this,
        promise = me.alertSound.play();

    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!
      }).catch(error => {
        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.
      });
    }
  },

  onMessages: function () {
    let me = this;
    THM_ROUTER.switchPageTo('sarMessages', {}, false);
  },

  onSarAlerts: function () {
    let alert = APP('sarAlert').getOpenAlerts()[0];

    // Show map
    THM_ROUTER.switchPageTo('UMV', {}, false);

    if (alert && alert.loc) {
      let mapXY = GIS.locToMap(alert.loc);
      GIS.setCenter(mapXY, 10);
    } else {
      MAIN_PAGE().gridPanel.show();
    }
  },

  //////////////////////////////////////////////////////////////
  //                                                          //
  //               NOTIFICATIONS D'ALERTES                    //
  //                                                          //
  //////////////////////////////////////////////////////////////
  handleSearchNewAlertsInterval: function () {
    let me = this;
    me.searchNewAlerts();
    me.searchNewAlertsInterval = setInterval(function () {
      me.searchNewAlerts();
    }, RIGHTS("alertNotification.refreshRate") * 1000);
  },

  searchNewAlerts: function () {
    let me    = this,
        kinds = me.getKindsUserFilter();

    if (kinds.length) {
      RESOURCE_CLASS["alert"].findNewAlerts({
        kind: kinds.join(',')
      }).done((alerts) => {
        let filteredAlerts = _.filter(alerts, function (alert) {
          return PREF("alertNotificationFilter").split(',').includes('' + alert.priority);
        });

        _.each(filteredAlerts, function (alert) {
          let kind = I18N.msg("uda.alert.field.kind." + alert.kind),
              msg  = me.msg("newAlert", [kind.toLowerCase(), PREF().format("time", new Date(alert.modificationDateMillis))]);
          Toastr.showToastWithClick("info", msg, 8000, true, function () {
            me.zoomOnAlert(alert);
          });
        });
      });
    }
  },

  getKindsUserFilter: function () {
    let levelThreeAlert    = ["ex_test"],
        levelFourAlert     = ["ex_assistance", "ex_assistance.end", "ua_manual"],
        kinds              = RIGHTS('alert.kinds').replace("ua_portInOut", "ua_portIn,ua_portOut").replace("ua_zoneInOut", "ua_zoneIn,ua_zoneOut").split(","),
        kindFilterPriority = !PREF("alertNotificationFilter") ? [] : PREF("alertNotificationFilter").split(','),
        hightPriorityKind  = _.filter(kinds, function (kind) {
          return !levelFourAlert.includes(kind) || !levelThreeAlert.includes(kind);
        }),
        requestedKinds     = [];

    hightPriorityKind.forEach((kind, i) => {
      if (kind === "ua_portInOut") {
        hightPriorityKind[i] = "ua_portIn";
        hightPriorityKind.splice(i + 1, 0, "ua_portOut");
      }

      if (kind === "ua_zoneInOut") {
        hightPriorityKind[i] = "ua_zoneIn";
        hightPriorityKind.splice(i + 1, 0, "ua_zoneOut");
      }
    });

    let priorityObject = {
      "1": _.filter(hightPriorityKind, function (kind) {
        return !kind.indexOf("ex_");
      }),
      "2": _.filter(hightPriorityKind, function (kind) {
        return kind.indexOf("ex_");
      }),
      "3": levelThreeAlert,
      "4": levelFourAlert
    };

    _.each(kindFilterPriority, function (priority) {
      requestedKinds = requestedKinds.concat(priorityObject[priority]);
    });

    return requestedKinds;
  },

  zoomOnAlert: function (alert) {
    let me          = this,
        lastRequest = DATA_CONTEXT.getLastRequest();

    if (!lastRequest) {
      return console.warn("No request set for data context");
    }

    let lastParams        = DATA_CONTEXT.getLastParams(),
        modifyDataContext = !$.isTrue(PREF().alertsActivated) || !!lastParams.to || !!lastParams.mostRecentData,
        badFromValue      = true,
        maxDays           = RIGHTS("alertNotification.maxDays", 10, "int"),
        deferred          = new $.Deferred().resolve(),
        config            = {};

    if (lastParams.from) {
      if (_.isString(lastParams.from)) {
        lastParams.from = new Date(lastParams.from.replace('_', 'T'));
      }
      badFromValue = ((new Date().getTime() - lastParams.fromMillis) / (1000 * 60 * 60 * 24)) > maxDays
    }

    if (modifyDataContext || badFromValue) {
      delete lastParams.to;
      delete lastParams.mostRecentData;
      config = {
        params: _.extend({}, lastParams, {
          from: badFromValue ? new Date().addDays(-maxDays) : lastParams.from
        })
      };

      deferred = new $.Deferred();
      ConfirmationPopup.openSingleton({
        title:    me.msg('changeSearchContextTitle'),
        text:     me.msg('changeSearchContextLabel'),
        buttons:  [
          {action: "yes"},
          {action: "no"}
        ],
        onAction: function (action) {
          if (action === "yes") {
            deferred.resolve();
          } else {
            deferred.reject();
          }
        }
      });
    }

    deferred.done(() => {
      DATA_CONTEXT.refresh(config, true).done(function () {
        GIS.setCenter(GIS.locToMap(alert.loc), 15);
        if (APP().currentPageId !== "UMV") {
          ROUTER().switchPageTo("UMV");
        }
      });
    });
  }
});

export default ThmTopBar;
