import InfoPanel from "core-uda/ui/InfoPanel";
import {APP} from "core-uda/model/Resource";
import TripInfoPanelTplStache from "thm/ui/common/trip/TripInfoPanelTpl.stache";
import {TRIP_CONST} from "core-uda/model/trip/Trip";
import {RIGHTS} from "core-uda/Rights";
import {DATA_AUTHORIZED} from "core-uda/Rights";

/**
 * @class TripInfoPanel
 * Afficher les infos d'un cpcFrom
 */
let TripInfoPanel = InfoPanel.extend({
  template:   TripInfoPanelTplStache,
  i18nPrefix: ['uda.trip.', 'thm.ui.common.trip.TripInfoPanel.'],
  id:         'tripInfoPanel',
  entityId:   'trip',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr('tripStateOptions', TRIP_CONST.tripState);
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this;
    me.viewModel.on("dataModel.itineraryId", (ev, value) => {
      let zone = APP("zones", value);
      if (!!zone && !me.updateViewModelInProgress){
        me.viewModel.attr('dataModel.speed', zone.speed);
        me.viewModel.attr('dataModel.speedTolerance', zone.speedTolerance);
        me.viewModel.attr('dataModel.breadth', zone.breadth);
      }
    });
  },


  /**
   * @override
   */
  updateViewModel: function () {
    let me = this;
    me.updateViewModelInProgress = true;
    me._super();

    if (me.viewModel.attr('dataModel.routeMonitoring') === undefined) {
      me.viewModel.attr('dataModel.routeMonitoring', false);
    }
    if (me.viewModel.attr('dataModel.speedMonitoring') === undefined) {
      me.viewModel.attr('dataModel.speedMonitoring', false);
    }
    if (me.viewModel.attr('dataModel.progressMonitoring') === undefined) {
      me.viewModel.attr('dataModel.progressMonitoring', false);
    }
    if (me.viewModel.attr('dataModel.cycleTrip') === undefined) {
      me.viewModel.attr('dataModel.cycleTrip', false);
    }
    me.updateViewModelInProgress = false;
  }
});

export default TripInfoPanel;
