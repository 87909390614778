import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";

/**
 * @class AlertDefinitionManualInfoPanel
 * Alert Definition "Manual" Form.
 */
let AlertDefinitionManualInfoPanel = AlertDefinitionInfoPanel.extend({
  id: 'alertDefinitionManualInfoPanel'
});
export default AlertDefinitionManualInfoPanel;
