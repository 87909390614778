import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import ResourceUI from "core-uda/ui/ResourceUI";
import template from "thm/ui/common/inspectionReport/InspectionReportUITpl.stache";
import InspectionReport from "thm/model/inspectionReport/InspectionReport";

/**
 * User Interface elements for Fleet
 */
let InspectionReportUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   InspectionReport.itemId,
      i18nPrefix:   "thm.ui.common.inspectionReport.InspectionReportUI.",
      icon:         "annotation",
      template:     template
    });
  },

  /**
   * @override
   */
  onGridRowPrepared: function (dxEvent) {
    let data = dxEvent.data,
        $el  = dxEvent.rowElement;
    $el.on('click', () => {
      InspectionReport.openPdfLink(data.id)
    });
  }

});

const INSPECTION_REPORT_UI = new InspectionReportUI();

export {INSPECTION_REPORT_UI};
export default InspectionReportUI;
