import _ from "core-cmp/lodash";
import { canEach } from "core-cmp/can";
import { APP } from "core-uda/model/Resource";
import { UTILS } from "core-cmp/Utils";

import UnitFormat from "core-cmp/format/UnitFormat";
import Preferences from "core-cmp/Preferences";
import Preference from "core-uda/model/preference/Preference";
import Log from "core-cmp/util/Log"
import GIS from "core-gis/Default";

const log = Log.get("pref");

let ThmPreferences = Preferences.extend({
  "showAllTrajectories": "string",
  "radarProductStylePOTENTIAL": "string",
  "radarProductStyleACQUIRED": "string",
  "radarProductStyleNOT_ACQUIRED": "string",
  "radarProductStylePROCESSED": "string",
  "radarProductStylePLANNED": "string",
  "radarProductStyleUNKNOWN": "string",
  "radarLayer-correlation": "boolean",
  "metocLegendRequest": "boolean",
  "metocRasterLegend": "boolean",
  "longitudeZeroHemisphere": "boolean",
  "displayInactiveMobile": "boolean",
  "homePageUser": "string",
  "localeTimeZone": "boolean",
  "disableAllAlertsNotification": "boolean",
  "latitudeZeroHemisphere": "boolean",
  "webMMIState": "string",
  "metocLayer-isoline": "string",
  "metocLayer-vector": "string",
  "metocLayer-raster": "string",
  "mobileTemplate": "string",
  "mobileTemplate3": "string",
  "mobileTemplate2": "string",
  "mobileTemplate1": "string",
  "flagId": "string",
  "overlayLayer-scaleLine": "boolean",
  "overlayLayer-nightAndDay": "boolean",
  "overlayLayer-grid": "boolean",
  "overlayLayer-eez": "boolean",
  "overlayLayer-metarTaf": "boolean",
  "wmsLayer-eez": "boolean",
  "wmsLayer-fao": "boolean",
  "wmsLayer-ices": "boolean",
  "baseLayer": "string",
  "metocLayer-isoline-legend": "boolean",
  "metocLayer-vector-legend": "boolean",
  "metocLayer-raster-legend": "boolean",
  "alertHistoriesActivated": "boolean",
  "alertHistoriesAsPositions": "boolean",
  "radarsActivated": "boolean",
  "radarLayer-scene": "boolean",
  "radarLayer-footprint": "boolean",
  "radarLayer-echos": "boolean",
  "sarAlertsActivated": "boolean",
  "sarOperationsActivated": "boolean",
  "sarSRUnitsActivated": "boolean",
  "sarSRUTypesFilter": "string",
  "sarPageFilter": "string",
  "locationActivated": "boolean",
  "collectedDatasAsPositions": "boolean",
  "sensorMessagesAsPositions": "boolean",
  "hideOtherRadarEchos": "boolean",
  "measureMode": "string",
  "speedLevels": "string",
  "csvSeparator": "string",
  "criteriaSettings": "string",
  "newModemAlert": "string",
  "alertVal": "boolean",
  "alertAck": "boolean",
  "alertOn": "boolean",
  "zoomAndCenter": "boolean",
  "loadAllTrajectories": "string",
  "zoneLabel": "boolean",
  "cmapLayers": "string",
  "scaleLine": "boolean",
  "alertsActivated": "boolean",
  "sensorMessagesActivated": "boolean",
  "collectedDatasActivated": "boolean",
  "metocVectorLegend": "boolean",
  "metocLegend": "boolean",
  "userVisible": "boolean",
  "dateVisible": "boolean",
  "mousePositionVisible": "boolean",
  "radarLayer-echo": "boolean",
  "zoneStyleItinerary": "string",
  "zoneStyleWorkstation": "string",
  "zoneStyleRadar": "string",
  "zoneStyleLandmark": "string",
  "zoneStyleUser": "string",
  "zoneStylePort": "string",
  "zoneStyleOfficial": "string",
  "zoneStyleAnnotation": "string",
  "sarSRUColor": "string",
  "sarDriftStyle": "string",
  "sarAerialDriftStyle": "string",
  "trajectoryStyleV2": "string",
  "stationarySpeedValue": "number",
  "stationarySpeed": "boolean",
  "refreshRate": "*",
  "MOBILE_DATA.getMobileLabel": "string",
  "labelPositionsValue": "string",
  "labelPositions": "boolean",
  "lastLabelPositionsValue": "string",
  "lastLabelPositions": "boolean",
  "estimatedPositionsValue": "number",
  "estimatedPositions": "boolean",
  "breakRouteValue": "number",
  "breakRoute": "boolean",
  "aisTraffic": "boolean",
  "statisticOptions": "string",
  "infiniteTimeout": "boolean",
  "sarSunRendering": "boolean",
  "sarUnitsRendering": "boolean",
  "sarShowAckAlert": "boolean",
  "sarAlertDopplerLines": "boolean",
  "sarDriftStart": "boolean",
  "sarDriftingObject": "boolean",
  "sarDriftTrajectory": "boolean",
  "sarDriftResultant": "boolean",
  "sarErrorRadius": "boolean",
  "sarSearchArea": "boolean",
  "sarSruIcon": "boolean",
  "sarSruSearch": "boolean",
  "sarLeadingEdge": "boolean",
  "sarSruVoyage": "boolean",
  "sarAnnotations": "boolean",
  "sarSymbology": "boolean",
  "sarSacSubAreasUnlock": "string",
  "sarSacPocGrid": "boolean",
  "sarSacPocGridDetails": "boolean",
  "satelliteLabel": "boolean",
  "satellitePath": "boolean",
  "satelliteFootprint": "boolean",
  "flagsOnPosition": "string",
  "navEffect": "string",
  "positionStatisticOptions": "string",
  "dashboard": "*",
  "dashboardRequestDayLimit": "number",
  "sound": "boolean",
  "alertNotification": "boolean",
  "alertNotificationFilter": "*",
  "sarSadPreferences": "string",
  "sarSacPreferences": "string",
  "showInactiveMobile": "boolean",
  "historicVesselDetails": "boolean",
  "cmapLoadMode": "string",
  "trimOnPaste": "boolean",
  "sarOperationExpanded": "string",
  "sarSadExpanded": "string",
  "umvLeftMenuLocked": "boolean",
  "admiraltyLayers": "string",
  "isNightMode": "boolean",
  "showSeconds": "boolean",

  // Mobidrift
  "mobidriftInitialParticules": "boolean",
  "mobidriftDrifts": "boolean",
  "mobidriftParticules": "boolean",
  "mobidriftDisplayMode": "string",
  "mobidriftGridMapPrecision": "string",
  "mobidriftGridMapPrecisionAuto": "boolean",
  "mobidriftGridMapGeometry": "string",

  "__loaded": "boolean",
  __customerGlobalPref: "*",
  __customerClientPref: "*",
  __currentPref: "*",
  __prefData: "*",

  init: function () {
    let me = this;

    me._super();

    me.set("__loaded", false);

    // Valeur par defaut
    me.set("umvLeftMenuLocked", false);
    me.set("radarLayer-scene", true);
    me.set("radarLayer-echo", true);
    me.set('radarLayer-correlation', false);
    me.set('aisTraffic', "false");
    me.set('breakRoute', "true");
    me.set('breakRouteValue', 180);
    me.set('estimatedPositions', "false");
    me.set('estimatedPositionsValue', 60);
    me.set('lastLabelPositions', 'true');
    me.set('lastLabelPositionsValue', 'name');
    me.set('labelPositions', 'true');
    me.set('labelPositionsValue', 'date');
    me.set('mobileTemplate', 'name');
    me.set('refreshRate', 15);
    me.set('stationarySpeed', "true");
    me.set('stationarySpeedValue', .25);
    me.set('sarSRUColor', "#000000");
    me.set('sarDriftStyle', "Drifting");
    me.set('sarAerialDriftStyle', "AerialDrifting");
    me.set('trajectoryStyleV2', "Tracking");
    me.set('zoneStyleAnnotation', "Zone");
    me.set('zoneStyleOfficial', "Zone");
    me.set('zoneStylePort', "Zone");
    me.set('zoneStyleUser', "Zone");
    me.set('zoneStyleLandmark', "Zone");
    me.set('zoneStyleRadar', "Zone");
    me.set('zoneStyleWorkstation', "Zone");
    me.set('zoneStyleItinerary', "Zone");
    me.set('mousePositionVisible', "true");
    me.set('dateVisible', "false");
    me.set('userVisible', "true");
    me.set('metocLegend', "true");
    me.set('metocVectorLegend', "true");
    me.set('collectedDatasActivated', "false");
    me.set('sensorMessagesActivated', "false");
    me.set('sarOperationsActivated', "true");
    me.set('alertsActivated', "false");
    me.set('scaleLine', "true");
    me.set("cmapLayers", "");
    me.set("zoneLabel", "false");
    me.set("umvStart", "umv");
    me.set("loadAllTrajectories", "last");
    me.set("zoomAndCenter", "false");
    me.set("alertOn", "true");
    me.set("alertAck", "true");
    me.set("alertVal", "false");
    me.set("newModemAlert", "popupModemAlert");
    me.set("criteriaSettings", JSON.stringify({
      "time": true,
      "mobile": true,
      "zone": true,
      "speed": true,
      "nature": true,
      "source": true,
      "excludePorts": false
    }));
    me.set("positionStatisticOptions", JSON.stringify({ gridPrecision: 5 }));
    me.set("csvSeparator", ",");
    me.set("speedLevels", "1,3,5");
    me.set("measureMode", "distance");
    me.set("hideOtherRadarEchos", false);
    me.set('collectedDatasAsPositions', "false");
    me.set('sensorMessagesAsPositions', "false");
    me.set('locationActivated', "true");
    me.set('radarsActivated', "false");
    me.set('radarLayer-scene', "true");
    me.set('radarLayer-footprint', "true");
    me.set('radarLayer-echos', "true");
    me.set('sarAlertsActivated', "false");
    me.set('sarSRUnitsActivated', "false");
    me.set('sarSRUTypesFilter', "0,1,2,3");
    me.set('sarPageFilter', JSON.stringify({ closed: "true,false", exercise: "true,false", searchQuery: "" }));
    me.set("metocLayer-raster-legend", "true");
    me.set("metocLayer-vector-legend", "true");
    me.set("metocLayer-isoline-legend", "true");
    me.set("baseLayer", "marine");
    me.set("overlayLayer-grid", "false");
    me.set("overlayLayer-eez", "false");
    me.set("overlayLayer-metarTaf", "false");
    me.set("overlayLayer-nightAndDay", "false");
    me.set("overlayLayer-scaleLine", "false");
    me.set("wmsLayer-eez", "false");
    me.set("wmsLayer-fao", "false");
    me.set("wmsLayer-ices", "false");
    //additions
    me.set("flagId", "");
    me.set("mobileTemplate1", "name");
    me.set("mobileTemplate2", "activeBeaconRef");
    me.set("mobileTemplate3", "customerCompany");
    me.set("metocLayer-raster", "");
    me.set("metocLayer-vector", "");
    me.set("metocLayer-isoline", "");
    me.set("webMMIState", "");
    me.set("latitudeZeroHemisphere", false);
    me.set("disableAllAlertsNotification", false);
    me.set("localeTimeZone", false);
    me.set("homePageUser", "index");
    me.set("displayInactiveMobile", false);
    me.set("longitudeZeroHemisphere", false);
    me.set("metocRasterLegend", true);
    me.set("metocLegendRequest", false);
    me.set("radarProductStyleUNKNOWN", "Radar");
    me.set("radarProductStylePLANNED", "Radar");
    me.set("radarProductStylePROCESSED", "Radar");
    me.set("radarProductStyleNOT_ACQUIRED", "Radar");
    me.set("radarProductStyleACQUIRED", "Radar");
    me.set("radarProductStylePOTENTIAL", "Radar");
    me.set("showAllTrajectories", "");
    me.set("infiniteTimeout", "false");
    me.set("sarSunRendering", true);
    me.set("sarUnitsRendering", true);
    me.set("sarShowAckAlert", false);
    me.set("sarAlertDopplerLines", false);
    me.set("sarDriftStart", true);
    me.set("sarDriftingObject", true);
    me.set("sarDriftTrajectory", true);
    me.set("sarErrorRadius", true);
    me.set("sarSearchArea", true);
    me.set("sarSruIcon", true);
    me.set("sarSruSearch", true);
    me.set("sarLeadingEdge", false);
    me.set("sarSruVoyage", true);
    me.set("sarDriftResultant", true);
    me.set("sarAnnotations", true);
    me.set("sarSymbology", true);
    me.set("sarSacSubAreasUnlock", "endurance");
    me.set("sarSacPocGrid", true);
    me.set("sarSacPocGridDetails", true);
    me.set("satelliteLabel", true);
    me.set("satellitePath", true);
    me.set("satelliteFootprint", true);
    me.set("dashboard", "{\"dashbordLimit\":\"7\",\"dashboardWidgets\":[\"collectedDatas\",\"beaconEvents\",\"mobileNoReport\",\"alertReports\",\"unreadMessages\"]}");
    me.set("dashboardRequestDayLimit", "7");
    me.set("flagsOnPosition", "");
    me.set("navEffect", "moveleft");
    me.set("sound", true);
    me.set("alertNotification", false);
    me.set("alertNotificationFilter", "");
    me.set("sarOperationExpanded", "{}");
    me.set("sarSadExpanded", "{}");
    me.set("sarSadPreferences", "");
    me.set("sarSacPreferences", "");
    me.set("showInactiveMobile", false);
    me.set("historicVesselDetails", false);
    me.set("cmapLoadMode", false);
    me.set("trimOnPaste", false);
    me.set("admiraltyLayers", "");
    me.set("isNightMode", false)
    me.set("showSeconds", true)

    // Mobidrift
    me.set("mobidriftInitialParticules", true);
    me.set("mobidriftDrifts", true);
    me.set("mobidriftParticules", true);
    me.set("mobidriftDisplayMode", "none");
    me.set("mobidriftGridMapPrecision", "0.5");
    me.set("mobidriftGridMapPrecisionAuto", true);
    me.set("mobidriftGridMapGeometry", "square");

    // Evénement zoneStyle
    var triggerZoneStyle = function () {
      me.trigger('zoneStyleChanged');
    };

    me.bind('zoneStyleAnnotation', triggerZoneStyle);
    me.bind('zoneStyleOfficial', triggerZoneStyle);
    me.bind('zoneStylePort', triggerZoneStyle);
    me.bind('zoneStyleUser', triggerZoneStyle);
    me.bind('zoneStyleLandmark', triggerZoneStyle);
    me.bind('zoneStyleRadar', triggerZoneStyle);
    me.bind('zoneStyleWorkstation', triggerZoneStyle);
    me.bind('zoneStyleItinerary', triggerZoneStyle);
    me.bind("zoneLabel", triggerZoneStyle);

    // Evénement radarProductStyle
    var triggerRadarProductStyle = function () {
      me.trigger('radarProductStyleChanged');
    };

    me.bind("radarProductStyleUNKNOWN", triggerRadarProductStyle);
    me.bind("radarProductStylePLANNED", triggerRadarProductStyle);
    me.bind("radarProductStylePROCESSED", triggerRadarProductStyle);
    me.bind("radarProductStyleNOT_ACQUIRED", triggerRadarProductStyle);
    me.bind("radarProductStyleACQUIRED", triggerRadarProductStyle);
    me.bind("radarProductStylePOTENTIAL", triggerRadarProductStyle);
    me.bind("radarProductStyle", triggerRadarProductStyle);

    me.bind('isNightMode', function (ev, newVal, oldVal) {
      if (_.isNil(oldVal)) {
        return;
      }
      me._updateTheme();
    });

    me.__prefData = {};
  },

  _updateTheme: function () {
    const me = this;

    $('body').toggleClass('night-mode', me.isNightMode);

    APP().whenMapLoaded().done(() => {
      UTILS.applyTheme(me.isNightMode ? 'dark' : me.theme);
      $('#map .ol-overlaycontainer-stopevent').css("opacity", me.isNightMode ? RIGHTS('opacityNight') || '30%' : '100%');
    });
  },

  saveAll: function () {
    let me = this,
      data = me.objectToArray(),
      entries = {};

    // On :
    // - garantie l'unicité de chaque préférence
    // - ajoute l'id si il existe.
    // - transforme la valeur de la préférence en string systématiquement
    _.each(data, function (pref) {
      if (me.__prefData[pref.name] != undefined) {
        pref.id = me.__prefData[pref.name].id;
      }
      pref.value = "" + pref.value;
      entries[pref.name] = pref;
    });

    return Preference.saveAll(_.values(entries)).done((response) => {
      me.setAll(response.data, true);
    });
  },

  saveList: function (names) {
    let me = this,
      data = me.objectToArray(),
      entries = {};

    // On :
    // - garantie l'unicité de chaque préférence
    // - ajoute l'id si il existe.
    // - transforme la valeur de la préférence en string systématiquement
    _.each(data, (pref) => {
      if (me.__prefData[pref.name] !== undefined) {
        pref.id = me.__prefData[pref.name].id;
      }
      if (names.indexOf(pref.name) < 0) {
        return;
      }
      pref.value = "" + pref.value;
      entries[pref.name] = pref;
    });

    return Preference.saveAll(_.values(entries)).done((response) => {
      me.setAll(response.data, false);
    });
  },

  saveOne: function (name) {
    let me = this,
      value = "" + me.get(name),
      pref = me.__prefData[name],
      prefValue = pref ? pref.value || "" : "",
      preference;

    // pref : Valeur json retournée via le loadAll
    if (prefValue == value) {
      return new $.Deferred().resolve();
    }

    // Instanciation d'un objet unique pour la sauvegarde d'une unique pref.
    preference = new Preference({
      name: name
    });

    if (pref) {
      preference.attr("id", pref.id);
      pref.value = value;
    }

    preference.attr("value", value);
    return preference.save().done((savedPref) => {
      // Si la pref n'existait pas on la sauvegarde dans __prefData
      if (!pref) {
        me.__prefData[name] = savedPref.attr();
      }
    });
  },

  deleteOne: function (name) {
    var me = this,
      pref = me.__prefData[name],
      preference;

    // Instanciation d'un objet unique pour la sauvegarde d'une unique pref.
    if (!!pref) {
      preference = new Preference({
        id: pref.id,
        name: name
      });

      return preference.destroy();
    }
  },

  patchPreferences: function () {
    var me = this,
      loadAll = me.get('loadAllTrajectories');

    //PATCH V2.0
    if (loadAll.toString() === "true") {
      me.set('loadAllTrajectories', "all");
    } else if (loadAll.toString() === "false") {
      me.set('loadAllTrajectories', "last");
    }

    //PATCH V2.3 DES BEACONS PAR LA FAUTE DE MONSIEUR BERTIN QUI FAIT DES CHANGEMENTS INTEMPESTIFS SANS NOTIFIER PERSONNE
    if (me.get('mobileTemplate1') === 'beaconId') {
      me.set('mobileTemplate1', 'activeBeaconId');
    }
    if (me.get('mobileTemplate').indexOf('beaconId') > 1) {
      me.set('mobileTemplate', me.get('mobileTemplate').replace('beaconId', 'activeBeaconId'));
    }

    // Rights
    me.set("trimOnPaste", RIGHTS('trimOnPaste'));

    if (!me.get('cmapLoadMode') || me.get('cmapLoadMode') === "false") {
      me.set("cmapLoadMode", "tileWMS");
    }

    if (!RIGHTS('rendering.vesselLabel')) {
      me.set('lastLabelPositions', false);
    }
    if (!RIGHTS('rendering.labelOnTrack')) {
      me.set('labelPositions', false);
    }
    if (!RIGHTS('rendering.pauseSymbol')) {
      me.set('stationarySpeed', false);
    }
    if (!RIGHTS('rendering.missingPositions')) {
      me.set('breakRoute', false);
    }
    if (!RIGHTS('rendering.estimatedPositions')) {
      me.set('estimatedPositions', false);
    }

    if (RIGHTS('loadAll')) {
      me.set("collectedDatasActivated", DATA_AUTHORIZED('collectedData'));
      me.set("collectedDatasAsPositions", false);
      me.set("alertsActivated", DATA_AUTHORIZED('alert'));
      me.set("aisTraffic", false);
      me.set('alertOn', true);
      me.set('alertAck', false);
      me.set('alertVal', false);
      me.set('loadAllTrajectories', 'all');
      me.set("sensorMessagesActivated", DATA_AUTHORIZED('sensorMessage'));
      me.set("historicVesselDetails", false);
    } else {
      if (!APP().hasAisTraffic()) {
        me.set("aisTraffic", false);
      } else if (APP().isAisTrafficModeForced()) {
        me.set("aisTraffic", APP().getAISTrafficMode() === "traffic");
      }
    }

    // V8.11 THM-13774
    if (RIGHTS("dashboard.sar")) {
      UnitFormat.TEMPERATURE_FORMAT.viewUnit = 'C';
    }
  },

  setData: function (data) {
    let me = this;

    me.setAll(data, true);
    me.patchPreferences();
  },

  setAll: function (data, resetPref) {
    var me = this,
      obj = {};

    if (resetPref) {
      me.__prefData = {};
    } else {
      obj = me.getEditableValues();
    }

    canEach(data, function (pref, i) {
      obj[pref.name] = pref.value;
      me.__prefData[pref.name] = pref;
    });

    if (!!me.__currentPref.value.preferences) {
      for (var field in me.__currentPref.value.preferences) {
        var pref = me.__currentPref.value.preferences[field];
        if (_.isUndefined(obj[field]) && typeof pref != "function") {
          obj[field] = pref;
        }
      }
    }

    // Migration CanJS3: pour empecher l'erreur object not extensible, on enleve toute propriete
    // qui ne serait pas definie explicitement dans les preferences
    for (var field in obj) {
      if (me.get(field) === undefined) {
        delete obj[field];
        log.info("Preference '" + field + "' is deleted as it is not defined in ThmPreferences");
      }
    }

    // Pour un nouvel utilisateur sans préférences, il peut arriver que certaines préférences soient positionnées à undefined
    // ce qui fait planté UMV. Pour éviter cela on positionne les péréférences à "undefined" avec les valeur avant modification.
    me.set(_.defaults(obj, me.get()));

    me.__loaded = true;
    me.trigger("preferencesLoaded");
  },

  isLoaded: function () {
    const me = this;
    return me.__loaded;
  }
});

const THM_PREF = new ThmPreferences();
Preferences.PREF = THM_PREF;

export { THM_PREF };
export default ThmPreferences;
