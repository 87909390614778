import _ from "core-cmp/lodash";
import MetocModel from "core-metoc/model/MetocModel";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import {APP} from "core-uda/model/Resource";
import FamilyModelPrefDAO from "core-metoc/model/FamilyModelPrefDAO";

const STATIC_CONFIG = {
  metocLayers: [{
    id:     "image",
    index:  1,
    family: "image",
    icon:   "griddedData"
  }, {
    id:     "vector",
    index:  2,
    family: "vector",
    icon:   "vectorData"
  }, {
    id:     "isoline",
    index:  3,
    family: "isoline",
    icon:   "isoline"
  }]
};

/**
 * Modèle de données Metoc gérant 3 couches de données :
 * - image : Donnée représentée sous la forme d'une image en dégardé de couleur.
 * - vector : Donnée vestorielle (vent, courant,...)
 * - isoligne : Donnée représentée sous la forme d'isolignes
 */
let UMVMetocModel = MetocModel.extend({
  familyDao: null,

  /**
   * @override
   */
  init: function () {
    let me = this;
    me.familyDao = new FamilyModelPrefDAO();
    me._super();
  },

  loadConfig: function (context) {
    let me = this;

    me.staticConfig = STATIC_CONFIG;

    // Generate uda proxy url
    _.each(context.wmsServices, (wmsService) => {
      wmsService.url = UDA_URL + 'map/metoc/' + wmsService.id;
      wmsService.httpHeaders = {
        jwt:         context.token,
        application: context.application
      }
    });

    me.setConfig({
      language:    me.locale,
      wmsServices: context.wmsServices,
      formatValue: true
    });
  }
});

export default UMVMetocModel;
