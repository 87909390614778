<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-state {(field)}="tabs" options="tabsOptions" options-col="auto" options-sort="false" class="tab"></field-state>
    <div class="{{#if endDateMustBeAfterTodayDate}}msgErrorEndDate {{else}} hidden{{/if}}">{{msg 'endDateMustBeAfterTodayDate'}}</div>
  </ui-page-header>
  <field-item item="dataModel" class="L-panel-content">

    {{! TAB : Information }}

    <div class="L-panel L-fit {{#is tabs "information"}}{{else}}hidden{{/is}}">
      <div class="header-group icon-left icon-m ui-icon-info">
        <h2>{{msg 'generalesInfos'}}</h2>
      </div>
      <div class="inlineGroup fields">
        <field-text name="name" required="true" format="uppercase" max-length="255" col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
        {{#if showClient}}
          <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
        {{/if}}
        <field-date name="startDate" role="start" period="quotaValidity"
                    required="true" format="dateTime" col="6 12"></field-date>
        <field-date id="{{id}}-endDate" name="endDate" role="end" period="quotaValidity"
                    required="true" format="dateTime" col="6 12" always-editable="true"></field-date>
        <field-text name="quantity" format="pnum" required="true" col="6 7"></field-text>
        <field-radios name="unit" label="" options="unitOptions" col="6 5" options-col="2"></field-radios>
        <field-radios {(field)}="alarmType" options="alarmTypeOptions" class="l30-f70" col="6 12"></field-radios>
        <field-text name="percAlarm" required="true" format='num' {visible}="isQuotaAlmost" class="l60-f40"
                    col="6 12"></field-text>
      </div>

      <uda-list of="species"
                name="speciesIds"
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                create-function="createForCustomer"
                class="L-panel-content inlineGroup"></uda-list>
    </div>

    {{! TAB : Vessels }}

    <div class="L-panel L-fit {{#is tabs "vesselsFleets"}}{{else}}hidden{{/is}}">
      <div class="L-panel-header inlineGroup fields">
        <field-switch label-key="filter" {(field)}="isMobile" text-on-key="mobiles" text-off-key="fleets"></field-switch>
      </div>

      <uda-list of="mobiles"
                name="vesselIds"
                {update-for}="dataModel.customerId"
                update-function="filterVesselByCustomerId"
                toolbar-size="4"
                {visible}="isMobile"
                class="L-panel-content">
      </uda-list>

      <uda-list of="fleets"
                name="fleetIds"
                {item-template}="fleetQuotaTpl"
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                {hidden}="isMobile"
                class="L-panel-content">
      </uda-list>

    </div>

    {{! TAB : Zones }}

    <div class="L-panel L-fit {{#is tabs "zones"}}{{else}}hidden{{/is}}">
      <uda-list of="zones"
                name="zoneZEEIds"
                label-key="zoneZEE"
                {item-template}="zoneQuotaTpl"
                {update-for}="dataModel.customerId"
                update-function="findCustomerZeeZones"
                class="L-panel-content">
      </uda-list>
      <uda-list of="zones"
                name="zoneFAOIds"
                label-key="zoneFAO"
                {item-template}="zoneQuotaTpl"
                {update-for}="dataModel.customerId"
                update-function="findCustomerFaoZones"
                class="L-panel-content">
      </uda-list>
    </div>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
