<div id="{{id}}" class="page">
  <div class="header-group icon-left icon-l ui-icon-beacon">
    <h3>{{msg 'fixBeaconAssociation'}}</h3>
  </div>

  <field-item item="form">
    <field-hcombo name="currentTab" label="" options="tabs" col="12 12"
                  always-editable="true" class="hcombo-tab"></field-hcombo>
  </field-item>

  <field-item item="dataModel" class="inlineGroup fields {{#if loading}}hidden{{/if}}">
      <field-select name="beaconId"
                    label="{{#switch exportType}}
                                {{#case "all"}}
                                    {{msg 'allId'}}
                                {{/case}}
                                {{#case "data"}}
                                    {{msg 'dataBeaconId'}}
                                {{/case}}
                                {{#case "beacon"}}
                                    {{msg 'beaconId'}}
                                {{/case}}
                                {{#default}}
                                    {{msg 'beaconId'}}
                                {{/default}}
                            {{/switch}}"
                    {options}="beacons" options-value="id" options-text="reference"></field-select>
      <uda-select id="{{id}}-mobileList"
                  of="mobiles"
                  label-key="mobileId"
                  name="mobileId"
                  {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId"
                  class="inlineGroup">
      </uda-select>

    {{#if showDate}}
      <field-date name="startDate" label-key="startDate" period="myPeriod" role="start" format="dateTime"></field-date>
      <field-date name="endDate" label-key="endDate" period="myPeriod" max-date="now" role="end"
                  format="dateTime"></field-date>
    {{/if}}
    {{#if showActive}}
      <field-checkbox name="activate" label-key="activate"></field-checkbox>
    {{/if}}
    <div class="msg inlineGroup">{{noteText}}</div>
  </field-item>
  <ui-loading {visible}="loading" anchor="center"></ui-loading>

  <ui-submit-cancel style="position: absolute; bottom: 0;" {status}="saveStatus"
                    cancel-action="close"></ui-submit-cancel>

</div>
