import ResourceUI from "core-uda/ui/ResourceUI";
import PortType from "core-zone/model/PortType";
import template from "thm/ui/common/portType/PortTypeTpl.stache";

/**
 * User Interface elements for Port Type
 */
let PortTypeUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: PortType.itemId,
      i18nPrefix: "thm.ui.common.zone.PortTypeUI.",
      icon:       "portLabel",
      template:   template,
      labelField:   "name",
      searchFields: "name",
      isListEditable: true
    })
  }

});

const PORT_TYPE_UI = new PortTypeUI();

export default PortTypeUI;
