<div id="{{id}}" class="page">
  <div class="header-group">
    <h3>{{title}}</h3>
  </div>
  <field-item item="dataModel" class="inlineGroup fields">
    <uda-select of="species"
                name="id"
                label=""
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                create-function="createForCustomer"
                col="12"></uda-select>
    <field-text name="name" disabled="true"></field-text>
    <field-text name="family" disabled="true"></field-text>
    <field-text name="amount" format="pint" max-length="9"></field-text>
    <field-text name="unit" max-length="200"></field-text>
  </field-item>

  <div class="inline-2btn">
    <a cls-action="save" class="btn btn-ok">{{msg '_Save'}}</a>
    <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
  </div>
</div>
