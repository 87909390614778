<div id="{{id}}" class="page">
  <div class="header-group">
    <h3>{{msg 'editComment'}}</h3>
  </div>
  <field-item item="dataFormModel" class="inlineGroup">
    <div class="fields">
      <field-text label="" name="comment"  options-col="2"></field-text>
    </div>
  </field-item>
  <div id="{{id}}-footer" class="inline-2btn">
    <a cls-action="save" class="btn btn-ok">{{msg '_Save'}}</a>
    <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
  </div>
</div>
