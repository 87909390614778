import ResourceUI from "core-uda/ui/ResourceUI";
import GlobalZoneType from "core-zone/model/GlobalZoneType";
import template from "thm/ui/common/globalZoneType/GlobalZoneTypeTpl.stache";

/**
 * User Interface elements for Global Zone Type
 */
let GlobalZoneTypeUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:     GlobalZoneType.itemId,
      i18nPrefix:     "thm.ui.common.zone.GlobalZoneTypeUI.",
      icon:           "zoneLabel",
      template:       template,
      labelField:     "name",
      searchFields:   "name",
      isListEditable: true
    });
  }

});

const GLOBAL_ZONE_TYPE_UI = new GlobalZoneTypeUI();

export default GlobalZoneTypeUI;
