import ResourceUI from "core-uda/ui/ResourceUI";
import MobileLifeEventType from "core-uda/model/mobile/MobileLifeEventType";
import template from "thm/ui/common/mobileLifeEventType/MobileLifeEventTypeUITpl.stache";

/**
 * User Interface elements for "Mobile life event type"
 */
let MobileLifeEventTypeUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:     MobileLifeEventType.itemId,
      i18nPrefix:     "thm.ui.common.mobileLifeEventType.MobileLifeEventTypeUI.",
      icon:           "mobileEvent",
      template:       template,
      isListEditable: true
    })
  }
});

const MOBILE_LIFE_EVENT_TYPE_UI = new MobileLifeEventTypeUI();

export {MOBILE_LIFE_EVENT_TYPE_UI};
export default MobileLifeEventTypeUI;
