<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
    <div class="list-field col-sm-12 col-size-xs-12">
        <label class="annotation inlineGroup">{{msg 'reportingInZoneDescription'}} : </label>
    </div>
    <field-text name="period" required="true" col="6 12" format="int"></field-text>
    <field-text name="gracePeriod" required="true" col="6 12" format="int"></field-text>
    <field-checkbox label-key="triggerAfter1" name="trigger1" col="12 12"></field-checkbox>
    <field-checkbox label-key="triggerAfter2" name="trigger2" col="12 12"></field-checkbox>
    <field-checkbox label-key="triggerAfter3" name="trigger3" col="12 12"></field-checkbox>
</div>
