import InfoPanel from "core-uda/ui/InfoPanel";
import {APP} from "core-uda/model/Resource";
import CpcConditionInfoPanelTplStache from "thm/ui/common/cpcCondition/CpcConditionInfoPanelTpl.stache";
import "thm/ui/common/cpcCondition/CpcConditionInfoPanel.css";
import _ from "core-cmp/lodash";

/**
 * @class CpcConditionInfoPanel
 * Afficher les infos d'une cpcCondition
 */
let CpcConditionInfoPanel = InfoPanel.extend({
  template:   CpcConditionInfoPanelTplStache,
  i18nPrefix: ['uda.cpcCondition.', 'thm.ui.common.cpcCondition.CpcConditionInfoPanel.'],
  id:         'cpcConditionInfoPanel',
  entityId:   'cpcCondition',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
        availableSources = [],
        sourcesOptions = [];

    me._super();
    me.viewModel.attr("mobileOrFleet", false);
    // me.viewModel.on("dataModel.mobileOrFleet", function(ev, newValue){
    //   me.viewModel.attr("dataModel.mobileOrFleet", newValue);
    // });

    me.viewModel.attr('restrictionSourceOptions', [
      {
        value: 'all',
        text:  I18N.msg('core.criteria.source.SourceCriteria.field.sourceType.options.all'),
        icon:  'check'
      },
      {
        value: 'source',
        text:  I18N.msg('core.criteria.source.SourceCriteria.field.sourceType.options.selSources'),
        icon:  'source'
      }
    ]);


    if (APP('positionSources')) {
      availableSources = APP('positionSources').getData();
    } else {
      availableSources = APP().getCapabilities('position', 'sources');
    }

    if (!_.isNil(availableSources) && availableSources.length) {
      _.each(availableSources, function (source) {
        sourcesOptions.push({value: source.code, text: source.name});
      });

      sourcesOptions.sort(function (a, b) {
        return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1
      });
    }
    me.viewModel.attr('sourcesOptions', sourcesOptions);
    me.viewModel.attr("restrictionSourceFlag", "all");
    me.viewModel.on("restrictionSourceFlag", me.updateSourceRestriction.bind(me));
    me.viewModel.attr('sourcesList', false);
  },

  updateSourceRestriction: function () {
    let me   = this,
        flag = me.viewModel.attr("restrictionSourceFlag");
    me.viewModel.attr('sourcesList', flag == "source");

    // on évite de modifier le dataModel pendant le updateViewModel pour éviter la popup "saveChange"
    if(!me.updateViewModelInProgress){
      me.viewModel.attr('dataModel').attr('useAllSources', flag == "all");
    }
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this,
        sourceFlag  = me.data.sources ? "source" : "all";
    me._super();
    me.viewModel.attr("mobileOrFleet", !me.data.mobiles);
    me.updateViewModelInProgress = true;
    me.viewModel.attr("restrictionSourceFlag", sourceFlag);
    me.updateViewModelInProgress = false;
  },

  getDataModel: function () {
    let me = this,
        dataModel = me.viewModel.attr('dataModel').attr();

    if(me.viewModel.attr("mobileOrFleet") === false){
      delete dataModel.fleets;
    } else {
      delete dataModel.mobiles;
    }

    return dataModel;
  },

});

export default CpcConditionInfoPanel;
