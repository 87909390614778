<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
    <field-text name="name" max-length="100" required="true"></field-text>
    {{#if showClient}}
      <uda-select of="customers" name="customerId" col="12" {enabled}="_cmp.creation" {hidden}="isListEditPage"></uda-select>
    {{/if}}
    <field-text name="faoCode" max-length="40" required="true"></field-text>
    <field-textarea name="description" max-length="240"></field-textarea>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
