import {PREF} from "core-cmp/Preferences";
import ResourceUI from "core-uda/ui/ResourceUI";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import MobileLifeEvent from "core-uda/model/mobileLifeEvent/MobileLifeEvent";
import ThmSelectMobileLifeEventTypeCriteria from "thm/ui/common/mobileLifeEventType/ThmSelectMobileLifeEventTypeCriteria";


/**
 * User Interface elements for MobileLifeEvent
 */
let MobileLifeEventUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: MobileLifeEvent.itemId,
      i18nPrefix: "uda.mobileLifeEvent.",
      icon: "mobileEvent"
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      "mobileLifeEventType": {
        cmpClass: ThmSelectMobileLifeEventTypeCriteria
      }
    };
  }
});

const MOBILE_LIFE_EVENT_UI = new MobileLifeEventUI();

export default MobileLifeEventUI;
