import EditCriteriaPopup from "core-uda/ui/fleet/EditCriteriaPopup";
import {canList} from "core-cmp/can";
import template from "core-uda/ui/fleet/EditCriteriaPopupTpl.stache";
import UnitFormat from "core-cmp/format/UnitFormat";
import "thm/ui/common/fleet/ThmFleetEditCriteriaPopup.css";

/**
 * Fleet criteria popup.
 */
let ThmFleetEditCriteriaPopup = EditCriteriaPopup.extend({
  id:              "thmFleetEditCriteriaPopup",
  routerType:      'popup',
  template:        template,
  criteriaList:    null,
  i18nPrefix:      ['core-uda.ui.fleet.EditCriteriaPopup.', 'uda.criteria.', 'uda.mobile.'],
  autoBindActions: true,

  /**
   * @override
   */
  init: function (config) {
    let me = this;
    me.criteriaList = new canList([]);
    me._super(config);
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me    = this,
      criteria,
      joins = me.joins.slice(),
      item = me.context.item,
      isCreation =  me.context.action === "create";

    me._super();

    if (isCreation) {
      me.context.index = me.context.model.length;
    }

    if (!_.isNil(me.context.index) && "" + me.context.index !== "0") {
      joins.splice(0, 1);
    } else {
      joins.splice(1, 2);
    }

    if (isCreation) {

      // Id is simulated using index, generate it using max id +1 (or 1 for empty criteria list)
      item = {
        id : _.last(me.context.model) ? ""+(parseInt(_.last(me.context.model).id)+1) : 1,
        join : joins[0].value,
        field: "name",
        operator: "equal",
        value: ""
      }

      // Update list if no NPFC
      if (!RIGHTS("mobile.specificSectionAllowed") || RIGHTS("mobile.specificSectionCenter") != "NPFC") {
        me.viewModel.attr('criteriaList', me.criteriaList.filter((criteria) => criteria.value != 'vesselStatus'));
      } 
    }

    criteria = {
      join:     item.join || joins[0].value,
      field:    item.field || 'name',
      operator: item.operator || 'equal',
      value:    '' // set value after show. It's a fix to set format on field-text before set value (prevent bad format)
    };

    me.fakeCriteriaValue = true;
    _.delay(() => {

      // Default value : 0 or empty string
      let defaultValue = ["float","double","integer","long"].indexOf(me.field.type) >=0 ? "0" : "",
        value = _.isNil(item.value) ? defaultValue : item.value;
      if(item.field === 'registeredLength' && PREF().othersUnits === "english"){
        value = UnitFormat.DISTANCE_FORMAT.to(value, value, 'm', 'ft', 4, false);
      }
        me.viewModel.attr("criteria.value", value);
      if (me.field.type === "boolean") {

        // Special boolean behaviour :
        // - model : operator is "equal" and value is "true" or "false"
        // - view : operator is "isTrue" or "isFalse", value is hidden
        me.viewModel.attr('criteria.operator', value === "true" ? "isTrue" : "isFalse");
      }
      me.fakeCriteriaValue = false
    }, 100);

    me.viewModel.attr('joinVisibility', joins.length === 1 ? "hidden" : "visible");
    me.viewModel.attr('joinList', joins);
    me.viewModel.attr('criteria').attr(criteria);
  },

  /**
   * @override
   */
  onSave: function () {
    let me = this,
      criteria = me.viewModel.attr('criteria').attr();

    if (me.field.type === "boolean") {

      // Special boolean behaviour :
      // - model : operator is "equal" and value is "true" or "false"
      // - view : operator is "isTrue" or "isFalse", value is hidden
      criteria.value = criteria.operator === "isTrue" ? "true" : "false";
      criteria.operator = "equal";
    }
    if (["isNull","isNotNull"].indexOf(criteria.operator)>=0) {
      criteria.value = criteria.value || " "; // Not empty
    }
    criteria.name = criteria.field;
    criteria.oldValue = criteria.value;

    // THM-10633 - use distance pref then if we use imperial unit we must convert into metric
    if(criteria.field === 'registeredLength' && PREF().othersUnits === "english"){
      // convert imperial (ft) into metric (m)
      criteria.value = UnitFormat.DISTANCE_FORMAT.to(criteria.value, criteria.value, 'ft', 'm', UnitFormat.DISTANCE_FORMAT.fixed, false);
    }

    // Update me.context.item with edited values (do not create a new object !)
    if (me.context.action === "update") {
      criteria.id = me.context.item.id;
      me.context.item.attr(criteria,true);
    }
    me.context.onSave(me.context.action, criteria);

    me.close();
  }
});

export default ThmFleetEditCriteriaPopup;
