import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import SelectStache from "thm/ui/common/locationStatData/ThmDateWeeklyMonthlyCriteria.stache";
import Criteria from "core-criteria/Criteria";
import DateCriteria from "core-criteria/date/DateCriteria";

/**
 * @class ThmDateWeeklyMonthlyCriteria
 */
let ThmDateWeeklyMonthlyCriteria = DateCriteria.extend({
  /**
   * @override
   */
  readParameters: function (resource, criteriaConfig, criteriaName) {
    return Criteria.readDefaultParameters(resource, criteriaConfig, criteriaName);
  }
}, {

  dateParamsWeekly:       "boolean",
  dateParamsMonthly:      "boolean",
  weeklyAuthorized:       "boolean",
  monthlyAuthorized:      "boolean",
  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;
    me.stache = SelectStache;
    me._super(config);

    me.dateParams.push({value: 'weekly', text: I18N.msg('uda.locationStatData.date.weeklyOptions')});
    me.dateParams.push({value: 'monthly', text: I18N.msg('uda.locationStatData.date.monthlyOptions')});

    me.criteriaFormModel.weekly = "";
    me.criteriaFormModel.monthly = "";

    me.months = [
      {value: 0, text: I18N.msg("uda.locationStatData.date.JAN")},
      {value: 1, text: I18N.msg("uda.locationStatData.date.FEB")},
      {value: 2, text: I18N.msg("uda.locationStatData.date.MAR")},
      {value: 3, text: I18N.msg("uda.locationStatData.date.APR")},
      {value: 4, text: I18N.msg("uda.locationStatData.date.MAY")},
      {value: 5, text: I18N.msg("uda.locationStatData.date.JUN")},
      {value: 6, text: I18N.msg("uda.locationStatData.date.JUL")},
      {value: 7, text: I18N.msg("uda.locationStatData.date.AUG")},
      {value: 8, text: I18N.msg("uda.locationStatData.date.SEP")},
      {value: 9, text: I18N.msg("uda.locationStatData.date.OCT")},
      {value: 10, text: I18N.msg("uda.locationStatData.date.NOV")},
      {value: 11, text: I18N.msg("uda.locationStatData.date.DEC")},
    ];

    me.criteriaFormModel.on("dateParams", me.proxy(me.changeDateParams));
    me.on("lastDaysAuthorized", me.proxy(me.changeLastDaysDateParams));
    me.on("mostRecentDataAuthorized", me.proxy(me.changeLastDaysDateParams));
    me.changeDateParams();
  },

  /**
   * @override
   */
  setOptions: function () {
    let me = this;
    me._super();
    me.weeklyAuthorized = me.options.weekly;
    me.monthlyAuthorized = me.options.monthly;
  },

  /**
   * @override
   */
  changeLastDaysDateParams: function () {
    let me             = this,
        lastDaysAuth   = me.lastDaysAuthorized,
        mostRecentAuth = me.mostRecentDataAuthorized,
        weeklyAuth     = me.weeklyAuthorized,
        monthlyAuth    = me.monthlyAuthorized,
        choices        = ["between"];

    choices = lastDaysAuth ? ["lastDays"].concat(choices) : choices;
    choices = mostRecentAuth ? choices.concat(["mostRecentData"]) : choices;
    choices = weeklyAuth ? choices.concat(["weekly"]) : choices;
    choices = monthlyAuth ? choices.concat(["monthly"]) : choices;
    me.dateParams = choices;
    me.criteriaFormModel.dateParams = lastDaysAuth ? "lastDays" : "between";
  },

  /**
   * @override
   */
  changeDateParams: function () {
    let me        = this,
        from      = me.criteriaFormModel.from,
        to        = me.criteriaFormModel.to,
        dateParam = me.criteriaFormModel.dateParams;


    switch (dateParam) {
      case "lastDays":
        me.dateParamsLastDays = true;
        me.dateParamsBetween = false;
        me.dateParamsWeekly = false;
        me.dateParamsMonthly = false;
        me.lastType = ["positions", "fishing"];
        break;
      case "between":
        if (from == " ") {
          from = new Date();
        }
        if (to == " ") {
          to = new Date();
        }
        me.criteriaFormModel.from = from;
        me.criteriaFormModel.to = to;
        me.dateParamsLastDays = false;
        me.dateParamsBetween = true;
        me.dateParamsWeekly = false;
        me.dateParamsMonthly = false;
        me.lastType = ["positions"];
        me.criteriaFormModel.lastType = "positions";
        break;
      case "mostRecentData":
        me.dateParamsLastDays = false;
        me.dateParamsBetween = false;
        me.dateParamsWeekly = false;
        me.dateParamsMonthly = false;
        me.lastType = ["positions"];
        me.criteriaFormModel.lastType = "positions";
        break;
      case "weekly":
        me.dateParamsLastDays = false;
        me.dateParamsBetween = false;
        me.dateParamsWeekly = true;
        me.dateParamsMonthly = false;
        me.lastType = ["positions"];
        me.criteriaFormModel.lastType = "positions";
        break;
      case "monthly":
        me.dateParamsLastDays = false;
        me.dateParamsBetween = false;
        me.dateParamsWeekly = false;
        me.dateParamsMonthly = true;
        me.lastType = ["positions"];
        me.criteriaFormModel.lastType = "positions";
        break;
    }
  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me        = this,
        params    = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda = {};

    switch (params.dateParams) {
      case "weekly":
        paramsUda[resourceName + "weekly"] = params.weekly;
        break;
      case "monthly":
        paramsUda[resourceName + "monthly"] = params.monthly;
        break;
      default:
        paramsUda = me._super();
        break;
    }
    return paramsUda;
  },

  /**
   * @override
   */
  getUDAParamsNames: function () {
    let me    = this,
        names = ["since","alwaysLastPos","lastFishingTrips","lastPositions","from","to","lastFishingTrips","lastPositions","weekly","monthly"];

    if (me.getUDAParamsNamesCustom) {
      names = me.getUDAParamsNamesCustom(names);
    }
    return names;
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this;

    if (params.since) {
      me.dateParamsLastDays = true;
      me.dateParamsBetween = false;
      me.dateParamsWeekly = false;
      me.dateParamsMonthly = false;

      me.criteriaFormModel.dateParams = "lastDays";

      let hours = (params.since / 3600);
      params.lastDays = hours / 24;
      params.lastHours = hours - params.lastDays * 24;

      me.criteriaFormModel.lastDays = params.lastDays || '';
      me.criteriaFormModel.lastHours = params.lastHours || '';
    } else if (params.from && params.to) {
      me.dateParamsLastDays = false;
      me.dateParamsBetween = true;
      me.dateParamsWeekly = false;
      me.dateParamsMonthly = false;
      me.criteriaFormModel.dateParams = "between";

      me.criteriaFormModel.from = PREF().fromServerDate(params.from);
      me.criteriaFormModel.to = PREF().fromServerDate(params.to);
    } else if (params.weekly){
      me.dateParamsLastDays = false;
      me.dateParamsBetween = false;
      me.dateParamsWeekly = true;
      me.dateParamsMonthly = false;
      me.criteriaFormModel.dateParams = "weekly";

      me.criteriaFormModel.weekly = params.weekly;
    } else if (params.monthly){
      me.dateParamsLastDays = false;
      me.dateParamsBetween = false;
      me.dateParamsWeekly = false;
      me.dateParamsMonthly = true;
      me.criteriaFormModel.dateParams = "monthly";

      me.criteriaFormModel.monthly = params.monthly;
    } else {
      me.dateParamsLastDays = false;
      me.dateParamsBetween = false;
      me.dateParamsWeekly = false;
      me.dateParamsMonthly = false;
      me.criteriaFormModel.dateParams = "mostRecentData";
    }
  },
});

export default ThmDateWeeklyMonthlyCriteria;
