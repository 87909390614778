<div class="header-group icon-left icon-m ui-icon-trajectory">
    <h2>{{msg 'trajectory'}}</h2>
</div>
<div class="inlineGroup fields">
    <field-range label-key="field.line.thickness" name="lineDefault.strokeWidth" min="1" max="10"></field-range>
    <field-select label-key="field.lineLevel.number" name="lineLevel.number"
                  options="lineLevelNumbers" format="pint"></field-select>

    <field-color label-key="field.stroke.color" name="lineDefault.strokeColor" format="hex"
                 click="openMenu"></field-color>

    <field-text format="speed" label-key="field.stroke.speedLevel1" name="lineLevel1.speed"
                col="9 9"></field-text>
    <field-color label="" name="lineLevel1.strokeColor" format="hex" click="openMenu" col="3 3"></field-color>
    <field-text format="speed" label-key="field.stroke.speedLevel2" name="lineLevel2.speed"
                col="9 9"></field-text>
    <field-color label="" name="lineLevel2.strokeColor" format="hex" click="openMenu"
                 col="3 3"></field-color>

  {{#if number3}}
      <field-text format="speed" label-key="field.stroke.speedLevel3" name="lineLevel3.speed"
                  col="9 9"></field-text>
      <field-color label="" name="lineLevel3.strokeColor" format="hex" click="openMenu"
                   col="3 3"></field-color>
  {{/if}}
</div>
<div class="header-group" style="padding-left:38px">
    <a cls-action="addAnimalDynamicPoint" class="btn btn-header-left ui-icon-plus" title="{{msg 'speciesList'}}"></a>
    <h2>{{msg 'positionSpecies'}}</h2>
</div>
<div class="inlineGroup fields">
    <field-icon label-key="default" name="animalDynamicTypes.default" {categories-to-display}="categories"
                {icon-manager}="iconSet"
                force-color="true" format="ol3"></field-icon>
  {{#each dataModel.animalDynamicTypes.values}}
      <field-icon {(field)}="dataModel.animalDynamicTypes.values.{{id}}"
                  label="{{UI resource id "getLabel"}}"
                  {categories-to-display}="categories" {icon-manager}="iconSet" force-color="true"
                  format="ol3"></field-icon>
  {{/each}}
</div>
<div class="header-group" style="padding-left:38px">
    <a cls-action="addAnimalDynamicColor" class="btn btn-header-left ui-icon-plus"
       title="{{msg 'mobileColorList'}}"></a>
    <h2>{{msg 'mobileTypeColorManager'}}</h2>
</div>
<div class="inlineGroup fields">
    <field-select label-key="field.mobile.type" name="animalDynamicColors.select"
                  options="animalFamilies"></field-select>
    <field-color label-key="default" {(field)}="dataModel.animalDynamicColors.default.fillColor"
                 format="hex"></field-color>

  {{#each dataModel.animalDynamicColors.values}}
      <field-color {(field)}="dataModel.animalDynamicColors.values.{{id}}.fillColor"
                   label="{{animalStyleInfoPanelUI resource id}}" format="hex"></field-color>
  {{/each}}
</div>
<div class="header-group" style="padding-left:38px">
    <a cls-action="addAnimalAlertDynamicTypes" class="btn btn-header-left ui-icon-plus" title="{{msg 'alertsList'}}"></a>
    <h2>{{msg 'positionAlerts'}}</h2>
</div>
<div class="inlineGroup fields">
    <field-icon label-key="default" name="animalAlertDynamicTypes.default" {categories-to-display}="alertCategories"
                {icon-manager}="iconSet"
                force-color="true" format="ol3"></field-icon>
  {{#each dataModel.animalAlertDynamicTypes.values}}
      <field-icon {(field)}="dataModel.animalAlertDynamicTypes.values.{{id}}"
                  label="{{animalStyleInfoPanelUI resource id}}"
                  {categories-to-display}="alertCategories" {icon-manager}="iconSet" force-color="true"
                  format="ol3"></field-icon>
  {{/each}}
</div>
