import {PREF} from "core-cmp/Preferences";
import ResourceUI from "core-uda/ui/ResourceUI";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import ErsActivity from "core-uda/model/ersActivity/ErsActivity";
import SelectCriteria from "core-criteria/select/SelectCriteria";

/**
 * User Interface elements for ErsQuery
 */
let ErsActivityUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: ErsActivity.itemId,
      i18nPrefix: "thm.ui.common.ersActivity.ErsActivityUI.",
      icon:       "fishing"
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'kind':   {
        cmpClass:         SelectCriteria,
        optionsKeyPrefix: 'uda.ersActivity.field.kind.'
      },
      'origin': {
        cmpClass:         SelectCriteria,
        optionsKeyPrefix: 'uda.ersActivity.field.origin.',
        labelKey:         'uda.ersActivity.field.origin'
      },
      'status': {
        cmpClass:         SelectCriteria,
        optionsKeyPrefix: 'uda.ersActivity.field.status.',
        labelAlign:       "top"
      }
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "kind":   I18N_FIELD("uda.ersActivity.field.kind."),
      "status": I18N_FIELD("uda.ersActivity.field.status."),
      "origin": I18N_FIELD("uda.ersActivity.field.origin.")
    }
  },

  /**
   * @override
   */
  onGridRowPrepared: function (dxEvent) {
    let data = dxEvent.data,
        $el  = dxEvent.rowElement;
    if (data.reportType === "MANUAL") {
      $el.css("font-style", "italic").css("font-weight", "bold");
    }
  },

  /**
   * @override
   */
  getUreMasterDetailResources: function () {
    return [
      "ersActivity"
    ]
  },

  /**
   * @override
   */
  formatMasterDetails: function (data, masterResource) {
    let me = this;

    if (masterResource == 'ersActivity') {
      return data && data.length && data[0].subActivities ? data[0].subActivities.attr() : {};
    }
    return data;
  },

  /**
   * @override
   */
  hideMasterDetails: function () {
    return true;
  }
});

const ERS_ACTIVITY_UI = new ErsActivityUI();

export default ErsActivityUI;
