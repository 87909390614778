import ResourceUI from "core-uda/ui/ResourceUI";
import {I18N_OBJECT_FIELD} from "core-uda/ui/ResourceUI";
import ErsAlertReport from "core-uda/model/ersAlertReport/ErsAlertReport";
import Alert from "core-alert/model/Alert";
import ThmListCriteria from "thm/ui/common/criteria/ThmListCriteria";
import BooleanCriteria from "core-criteria/boolean/BooleanCriteria";

/**
 * User Interface elements for ErsQuery
 */
let ErsAlertReportUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:               ErsAlertReport.itemId,
      icon:                     "alert",
      i18nPrefix:               "thm.ui.common.alert.ErsAlertReportUI.",
      onlyDisplayResultColumns: [
        'tripId',
        'tripNumber',
        'tripOpenDate',
        'tripOpenDateMillis',
        'tripCloseDate',
        'tripCloseDateMillis'
      ]
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'kind':          {
        cmpClass:              ThmListCriteria,
        i18nPrefix:            "uda.alertType.",
        resourceName:          "alertTypes",
        kindOptions:           APP("alertTypes").getErsKindOptions(),
        alertTypeKinds:        APP("alertTypes").ersKindList.join(","),
        optionsUpdateFunction: "findByErsKinds"
      },
      'addTripDetail': {
        cmpClass: BooleanCriteria,
        labelKey: "thm.ui.common.ers.field.addTripDetail"
      }
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "kind": {
        format:   (value) => {
          return Alert.getKindLongLabel(value);
        },
        dataType: 'string'
      },
      "text": I18N_OBJECT_FIELD("uda.ersAlertReport.field.text.")
    }
  }

});

const ALERT_UI = new ErsAlertReportUI();

export default ErsAlertReportUI;
