<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
      <field-text name="name" max-length="60" required="true" col="12"></field-text>
      <field-text name="provType" max-length="30" required="true" col="12"></field-text>
      <field-text name="provDelay" max="999999" format="int" col="12" class="l80-f20"></field-text>
      <field-text name="checkDelay" max="999999" format="int" col="12" class="l80-f20"></field-text>
      <field-text name="driftDelay" max="999999" format="int" col="12" class="l80-f20"></field-text>
      <field-text name="provMaxWait" max="999999" format="int" col="12" class="l80-f20"></field-text>
      <field-checkbox name="enabled" col="12"></field-checkbox>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
