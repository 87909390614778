<div id="{{id}}" class="page">
    <div class="header-group">
        <h3>{{msg '_Export'}}</h3>
    </div>
    <field-item item="dataFormModel" class="inlineGroup">
        <div class="fields">
            <field-radios label="" name="format" {options}="formatOptions" options-col="2"></field-radios>
        </div>
    </field-item>
    <div id="{{id}}-footer" class="inline-2btn">
        <a cls-action="download" class="btn btn-ok">{{msg '_Download'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
