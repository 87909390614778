import InfoPanel from "core-uda/ui/InfoPanel";
import template from "thm/ui/common/mobileLifeEventType/MobileLifeEventTypeInfoPanelTpl.stache";
import MobileLifeEventType from "core-uda/model/mobile/MobileLifeEventType";
import Toastr from "core-cmp/Toastr";
import {RIGHTS} from "core-uda/Rights";

/**
 * Mobile life event type form
 * @class MobileLifeEventTypeInfoPanel
 */
let MobileLifeEventTypeInfoPanel = InfoPanel.extend({
  template:                template,
  i18nPrefix:              ['uda.mobileType.', 'thm.ui.common.mobileLifeEventType.MobileLifeEventTypeInfoPanel.'],
  id:                      'mobileLifeEventTypeInfoPanel',
  entityId:                MobileLifeEventType.itemId,
  allowWidth:              true,
  linkErrorCodeByResource: null,
  titleKey:                "title",

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr("showClient", RIGHTS("showClient"));
    me.linkErrorCodeByResource = {
      MLE_MLET_FK: me.msg('errorConstraintMle')
    }
  },

  doAfterDeleteOneFailed: function (error) {
    let me             = this,
        constraintCode = error.args[0].match(/\(([^)]+)\)/)[1].split('.')[1];

    Toastr.showError(me.linkErrorCodeByResource[constraintCode], 5000);
  }
});

export default MobileLifeEventTypeInfoPanel;
