import _ from "core-cmp/lodash";
import Page from "core-cmp/Page";
import "core-ure/ui/favorite/AddFavoritePopup.css";
import MobileFixBeaconAssociationPopupTpl from "thm/ui/common/mobile/MobileFixBeaconAssociationPopupTpl.stache";
import FixBeaconAssociation from 'core-uda/model/beacon/FixBeaconAssociation';
import Beacon from 'core-uda/model/beacon/Beacon';
import Mobile from "core-uda/model/mobile/Mobile";

import Toastr from "core-cmp/Toastr";

/**
 * @class MobileFixBeaconAssociationPopup
 */
let MobileFixBeaconAssociationPopup = Page.extend({
  id:                  "mobileFixBeaconAssociationPopup",
  routerType:          'popup',
  popupWidth:          800,
  popupHeight:         380,
  modal:               true,
  template:            MobileFixBeaconAssociationPopupTpl,
  i18nPrefix:          ['thm.ui.common.mobile.MobileFixBeaconAssociation.'],
  autoBindActions:     true,
  localValuesLocation: {
    "mobiles": '#mobileFixBeaconAssociationPopup-mobileList field-list-select'
  },

  initViewModel: function () {
    const me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
    me.viewModel.attr('form', {});
    me.viewModel.attr('tabs', [
      {
        value: "all",
        text:  me.msg('all')
      },
      {
        value: "beacon",
        text:  me.msg('beacon')
      },
      {
        value: "data",
        text:  me.msg('data')
      }
    ]);

    me.viewModel.attr("loading", false);
    me.viewModel.attr('form').on('currentTab', me.proxy(me._updateTab));
  },

  _updateTab: function () {
    let me            = this,
        value         = me.viewModel.attr('form.currentTab'),
        positionsType = 'false';

    me.viewModel.attr("loading", true);
    me.viewModel.attr("exportType", value);

    switch (value) {
      case 'data':
        me.viewModel.attr('showDate', true);
        me.viewModel.attr('showActive', false);
        me.viewModel.attr('dataModel.type', 'DATA_ONLY');
        me.viewModel.removeAttr('dataModel.activate');
        me.viewModel.attr('dataModel.startDate', "");
        me.viewModel.attr('dataModel.endDate', "");
        me.viewModel.attr('noteText', me.msg('noteFixData'));
        positionsType = 'only';
        break;
      case 'beacon':
        me.viewModel.attr('showDate', false);
        me.viewModel.attr('showActive', true);
        me.viewModel.attr('dataModel.type', 'BEACON_ONLY');
        me.viewModel.removeAttr('dataModel.startDate');
        me.viewModel.removeAttr('dataModel.endDate');
        me.viewModel.attr('dataModel.activate', false);
        me.viewModel.attr('noteText', me.msg('noteFixBeacon'));
        positionsType = 'true';
        break;
      case 'all':
      default:
        me.viewModel.attr('showDate', true);
        me.viewModel.attr('showActive', true);
        me.viewModel.attr('dataModel.type', 'BEACON_AND_DATA');
        me.viewModel.attr('dataModel.activate', false);
        me.viewModel.attr('dataModel.startDate', "");
        me.viewModel.attr('dataModel.endDate', "");
        me.viewModel.attr('noteText', me.msg('noteFix'));
        break;
    }

    Beacon.selectBeaconByMobilesAndPositions(me.context.mobileIdSource, positionsType).done((beacons) => {
      me.viewModel.attr('beacons', beacons);
      me.viewModel.attr("loading", false);
    });
  },

  doBeforeOpen: function () {
    let me            = this,
        beaconsObject = [];


    if (!!me.context) {
      me.viewModel.attr('dataModel.customerId', me.context.customerId);
      me.viewModel.attr('dataModel.mobileId', '');
      me.viewModel.attr('dataModel.startDate', '');
      me.viewModel.attr('dataModel.endDate', '');

      _.forEach(me.context.beacons, (beaconId) => {
        beaconsObject.push(APP('beacons', beaconId));
      });

      me.viewModel.attr('beacons', beaconsObject);
      me.viewModel.attr('dataModel.activate', true);
      me.viewModel.attr('dataModel.beaconId', '');
    }

    me.viewModel.attr('form').attr({
      currentTab: 'all'
    });

  },

  checkErrors: function (item) {
    const me = this;

    if (me.viewModel.attr('showDate') && (!item.startDate || !item.endDate)) {
      Toastr.showError(me.msg('errorDate'), 5000);
      return true;
    }

    if (!item.mobileId || !item.mobileId.length) {
      Toastr.showError(me.msg('errorVessel'), 5000);
      return true;
    }

    if (!item.beaconId || !item.beaconId.length) {
      Toastr.showError(me.msg('errorBeacon'), 5000);
      return true;
    }

    return false;
  },

  onSubmit: function (event) {
    const me             = this;
    const item           = me.viewModel.attr('dataModel').attr();

    if (me.checkErrors(item)) {
      return;
    }

    const {beaconId, mobileId, ...cleanedItem} = item;
    const createFixParams = {
      ...cleanedItem,
      src:      beaconId,
      target:   mobileId,
    };

    const vesselName = me.getLocal('mobiles', mobileId).name;
    const beaconRef  = APP('beacons', beaconId).reference;
    FixBeaconAssociation.createFix(createFixParams).done(() => {
      const messageSuccesKey = _.camelCase('successFix ' + me.viewModel.attr('form.currentTab'));
      Toastr.showSuccess(me.msg(messageSuccesKey, vesselName, beaconRef), 5000);
      me.refreshUI();
      me.close();
    }).fail((error) => {
      const messageErrorKey = _.camelCase('failureFix ' + me.viewModel.attr('form.currentTab'));
      Toastr.showError(me.msg(messageErrorKey, vesselName, beaconRef, error), 5000);
      me.close();
    });
  },

  /**
   * Rafraichit l'UI apres appel de la route createFix
   */
  refreshUI: function(){
    const me = this;
    const {mobileId, beaconId} = me.viewModel.attr('dataModel').attr();

    // refresh des 2 mobiles source et dest
    Mobile.reloadByIds([me.context.mobileIdSource, mobileId]);

    // refresh de la balise
    Beacon.reloadById(beaconId);

    // Refresh Mobile Panel en cours d'affichage
    Page.openDataPanel({
      type:   'mobile',
      data:   {id: me.context.mobileIdSource},
      target: 'right'
    });
  },

  onCancel: function () {
    const me = this;
    me.close();
  },

  getSuccessMessage(type, vesselTo, vesselFrom) {
    let me = this;


  }

});

export default MobileFixBeaconAssociationPopup;
