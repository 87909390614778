import {canDefineMap} from "core-cmp/can";
import ResourceUI from "core-uda/ui/ResourceUI";
import ErsAuditReport from "core-uda/model/ersAuditReport/ErsAuditReport";
import {DATA_AUTHORIZED} from "core-uda/Rights";

import SelectCriteria from "core-criteria/select/SelectCriteria";
import ThmListCriteria from "thm/ui/common/criteria/ThmListCriteria";
import BooleanCriteria from "core-criteria/boolean/BooleanCriteria";

import {I18N} from "core-cmp/util/I18n";

/**
 * User Interface elements for ErsQuery
 */
let ErsAuditReportUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: ErsAuditReport.itemId,
      icon: "annotation",
      i18nPrefix: "thm.ui.common.ersActivity.ErsAuditReportUI.",
      onlyDisplayResultColumns:   [
        'tripId',
        'tripNumber',
        'tripOpenDate',
        'tripOpenDateMillis',
        'tripCloseDate',
        'tripCloseDateMillis',
        'auditRTPKG',
        'auditRTPNB',
        'auditLanNB',
        'auditLanKG',
        'auditTraKG',
        'auditTraNB',
        'auditCoxKG',
        'auditCoxNB',
        'auditPnoKG',
        'auditPnoNB'
      ]
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'species':       {
        cmpClass:      ThmListCriteria,
        i18nPrefix:    "uda.species.",
        customOptions: [{
          value:      'speciesCats',
          udaName:    'speciesCats',
          i18nPrefix: 'uda.speciesCat.',
          text:       I18N.msg("uda.speciesCat.selection")
        }]
      },
      'kind':          {
        cmpClass:         SelectCriteria,
        mandatory:        true,
        optionsKeyPrefix: 'uda.ersActivity.field.kind.',
        labelAlign:       "top"
      },
      'addTripDetail': {
        cmpClass: BooleanCriteria,
        labelKey: "thm.ui.common.ers.field.addTripDetail"
      }
    }
  }

});

const ERS_ACTIVITY_UI = new ErsAuditReportUI();

export default ErsAuditReportUI;
