import UserObjectModel from "core-uda/model/userObject/UserObjectModel";

/**
 * Modèle de NafTemplate.
 */
let NafTemplateModel = UserObjectModel.extend({
  modelId : "nafTemplates",
  itemId:   "nafTemplate",
});

export default NafTemplateModel;
