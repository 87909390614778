import Page from "core-cmp/Page";
import _ from "core-cmp/lodash";
import {canMap} from "core-cmp/can";
import {APP} from "core-uda/model/Resource";
import {DATA_CONTEXT} from "core-uda/model/DataContext";
import Toastr from "core-cmp/Toastr";
import Position from "core-uda/model/position/Position";
import Mobile from "core-uda/model/mobile/Mobile";
import template from "thm/ui/common/position/ThmPositionModifyPopupTpl.stache";

let msg = I18N.prefix('thm.ui.common.position.ThmPositionModifyPopup.');

/**
 * Edit mobile life event.
 */
let ThmPositionModifyPopup = Page.extend({
  id:              "thmPositionModifyPopup",
  routerType:      'popup',
  template:        template,
  i18nPrefix:      ['thm.ui.common.position.ThmPositionModifyPopup.', 'uda.position.'],
  autoBindActions: true,
  popupWidth:      400,
  popupHeight:     280,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('dataModel', {});
  },

  getMobileName: function (item) {
    let me       = this,
        deferred = new $.Deferred();

    if (item && item.mobileName) {
      deferred.resolve(item.mobileName);
    } else if (APP().hasAllMobiles) {
      deferred.resolve(APP('mobile').getById(item.mobileId).name);
    } else {
      Mobile.findOne(item.mobileId).done((mobile) => {
        deferred.resolve(mobile.name);
      });
    }

    return deferred;
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me   = this,
        item = me.context.data.attr();

    me.getMobileName(me.context.data.attr()).done((name) => {

      me.viewModel.attr('title', me.msg('modifyTitle', name));
      me.viewModel.attr('date', item.locDate);
      me.viewModel.attr('longitude', item.loc[0]);
      me.viewModel.attr('latitude', item.loc[1]);
      me.viewModel.attr('speed', !_.isNil(item.speed) ? item.speed : null);
      me.viewModel.attr('heading', !_.isNil(item.heading) ? item.heading : null);

      // THM-11024 : add a view model attribut to check the lat/lon preference and display the right form
      me.viewModel.attr('isDecimal', PREF().locationFormat === 'd');

    });

  },

  /**
   * @override
   */
  checkErrors: function() {
    let me = this;

    let errors = me.$find('.error').not('.field-hidden');
    if (errors.length > 0) {
      Toastr.showToastr('error', 'componentHasErrors');
      return true;
    }
    return false;
  },

  onSend: function (evt) {
    let me          = this,
        item        = me.context.data.attr(),
        initialDate = new Date(me.viewModel.attr('date')).toISOString().substring(0, 19).replace('T', '_');

    if (me.checkErrors()) {
      return;
    }

    if (isNaN(me.viewModel.attr('longitude')) || isNaN(me.viewModel.attr('latitude'))) {
      Toastr.showError(me.msg('latLonEmpty'), 3000);
      return;
    }

    // THM-12163 check if -90 < latitude < 90 && -180 < longitude < 180
    if(me.viewModel.attr('latitude') < -90 || me.viewModel.attr('latitude') > 90){
      Toastr.showError(me.msg('latLonTooHigh'), 3000);
      return;
    }

    if(me.viewModel.attr('longitude') < -180 || me.viewModel.attr('longitude') > 180){
      Toastr.showError(me.msg('latLonTooHigh'), 3000);
      return;
    }
    Position.modifyManualPosition({
      longitude: me.viewModel.attr('longitude'),
      latitude:  me.viewModel.attr('latitude'),
      speed:     me.viewModel.attr('speed'),
      heading:   me.viewModel.attr('heading'),
      locDate:   initialDate
    }, item.id).done(function (data) {
      // Mise à jour de la position
      data.locDate = me.viewModel.attr('date');
      me._success(data);
    });
    me.grid = me.context.grid;
    me.close(); // THM-11575 : close the popup even if the UDA request is not finished
    Toastr.showToastSuccess('positionUpdateSent', 3000);
  },

  _success: function (position) {
    let me = this;
    let initialPosition = APP("positions", position.id);

    if (me.grid && me.grid.model.mode === 'URE') {
      me.grid.updateRowData('position', position, true);
      me.grid.gridinstance.refresh();
    }

    if (initialPosition) {
      _.extend(initialPosition, position);
      APP("positions").set(initialPosition);
    }

    Toastr.showToastSuccess('positionUpdated', 3000);
    DATA_CONTEXT.refresh();
    delete me.grid;
  }

});

export default ThmPositionModifyPopup;
