import AlertDefinitionUI from "core-alert/ui/alertdefinition/AlertDefinitionUI";

/**
 * User Interface elements for AlertDefinition
 */
let ThmAlertDefinitionUI = AlertDefinitionUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;
    me._super({
      i18nPrefix: "thm.ui.common.alertDefinition.AlertDefinitionUI."
    });
  }

});

const THM_ALERT_DEFINITION_UI = new ThmAlertDefinitionUI();

export {THM_ALERT_DEFINITION_UI};
export default ThmAlertDefinitionUI;
