import ResourceUI from "core-uda/ui/ResourceUI";
import {CELL_TPL_KIND_ICON,I18N_FIELD} from "core-uda/ui/ResourceUI";
import Fleet from "core-uda/model/fleet/Fleet";
import template from "thm/ui/common/fleet/FleetUITpl.stache";
import FleetListSearchPage from "thm/ui/common/fleet/FleetListSearchPage";
import {I18N} from "core-cmp/util/I18n";
import {RIGHTS} from "core-uda/Rights";
import FleetMergeSubtractPopup from "thm/ui/common/fleet/FleetMergeSubtractPopup";


/**
 * User Interface elements for Fleet
 */
let FleetUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   Fleet.itemId,
      i18nPrefix:   "thm.ui.common.fleet.FleetUI.",
      icon:         "fleet",
      template:     template,
      searchPageId: FleetListSearchPage.getId()
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "name": {
        dataType:     'string',
        cellTemplate: function (container, options) {
          CELL_TPL_KIND_ICON(container, options, Fleet);
        }
      },
      "kind": {
        dataType:     'string',
        cellTemplate: function (container, options) {
          CELL_TPL_KIND_ICON(container, options, Fleet);
        },
        format : I18N_FIELD("uda.fleet.field.kind.").format
      },
      "userGroups": {
        dataType:     'string',
        format:       (value) => {
          let names = "";
          if (value !== undefined) {
            names = value.map(id => APP("userGroups", id) && APP("userGroups", id).name || '').join(', ');
          }
          return names;
        }
      }
    }
  },

  /**
   * @override
   */
  getUreTopActionButtons: function () {
    const me = this;

    return [{
      id:         'mergeSubtract',
      title:      me.msg("mergeSubtract"),
      icon:       'vesselMeeting',
      authorized: RIGHTS("data.fleet.create"), //TODO ajouter vérification du nouveau droit data.mergeFleet
      handler:    (gridMainPage, ev) => {
        FleetMergeSubtractPopup.openPage({
          customerId: APP().user.customerId
        });
      }
    }];
  },

});

const FLEET_UI = new FleetUI();

export {FLEET_UI};
export default FleetUI;
