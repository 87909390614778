<div id="{{id}}" class="page">
  <div class="header-group icon-left icon-l ui-icon-star">
    <h3>{{msg 'mergeTitle'}}</h3>
  </div>

  <field-item item="dataModel" class="inlineGroup fields">

    <uda-select id="{{id}}-mobileList"
                of="mobiles"
                name="obsoleteMobile"
                label-key="_Mobiles"
                {update-for}="dataModel.context"
                update-function="filterMobileByCustomerId"
                class="inlineGroup"></uda-select>

  </field-item>


  <ui-submit-cancel {status}="saveStatus" cancel-action="close"></ui-submit-cancel>

</div>
