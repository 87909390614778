import ResourceUI from "core-uda/ui/ResourceUI";
import StatusMessage from "core-uda/model/status/StatusMessage";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";


/**
 * User Interface elements for Status Messages
 */
let StatusMessageReportUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:               StatusMessage.itemId,
      icon:                     "status",
      i18nPrefix:               ["uda.statusMessage."]
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'selectBy':   {
        cmpClass:       ThmSelectByCriteria,
        options: ['mobile', 'beacon']
      }
    };
  },

  getColumnOptions: function () {
    let me = this;

    return {
      'latitudeInterp': {
        datatype: 'string',
        format: function (value) {
          return value || 'NotANumber';
        }
      },
      'longitudeInterp': {
        datatype: 'string',
        format: function (value) {
          return value || 'NotANumber';
        }
      }
    }
  }

});

const STATUS_MESSAGE_REPORT_UI = new StatusMessageReportUI();

export default StatusMessageReportUI;
