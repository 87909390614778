import Page from "core-cmp/Page";
import FleetMergeSubtractPopupTplStache from "thm/ui/common/fleet/FleetMergeSubtractPopupTpl.stache";
import Fleet from "core-uda/model/fleet/Fleet";
import Toastr from "core-cmp/Toastr";


let FleetMergeSubtractPopup = Page.extend({
  id:              "fleetMergeSubtractPopup",
  routerType:      'popup',
  popupWidth:      600,
  modal:           true,
  template:        FleetMergeSubtractPopupTplStache,
  i18nPrefix:      "thm.ui.common.fleet.FleetMergeSubtractPopup.",
  authorizedMode:  [],
  autoBindActions: true,

  initViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
    me.viewModel.on('dataModel.type', (ev, value, oldValue) => {
      me.viewModel.attr('secondFleet', me.msg(value ? 'fleetToMerge' : 'fleetToSubtract'));
    });
  },

  doBeforeOpen: function () {
    let me = this;
    me.viewModel.attr('dataModel.type', false);
    if (!!me.context) {
      me.viewModel.attr('dataModel.customerId', me.context.customerId);
    }
  },

  onSubmit: function () {
    let me                           = this,
        initFleet                    = me.viewModel.attr("dataModel.fleet1"),
        initFleetObject              = APP('fleets').find((f) => f.id === initFleet),
        fleetToMergeOrSubtract       = me.viewModel.attr("dataModel.fleet2"),
        fleetToMergeOrSubtractObject = APP('fleets').find((f) => f.id === fleetToMergeOrSubtract),
        fleetName                    = me.viewModel.attr("dataModel.name"),
        operationType                = me.viewModel.attr("dataModel.type") ? 'merge' : 'subtract';

    if (!initFleet || !fleetToMergeOrSubtract) {
      Toastr.showError(me.msg('missingFleet'), 3000);
      return;
    }

    if (!fleetName || !fleetName.length) {
      Toastr.showError(me.msg('missingName'), 3000);
      return;
    }

    if (APP("fleet").find({name: fleetName})) {
      Toastr.showError(me.msg('fleetNameAlreadyExists', fleetName), 3000);
      return;
    }

    Fleet.mergeOrSubtract(fleetName, initFleet, fleetToMergeOrSubtract, operationType).done(data => {
      data = new Fleet(data);
      APP('fleets').createOrReplaceById(data);
      APP().trigger('refreshGrid');

      if (operationType === 'subtract' && data.mobiles && initFleetObject.mobiles.length === data.mobiles.length) {
        Toastr.showWarn(me.msg('subtractWarn', initFleetObject.name), 5000);
      }

      if (operationType === 'subtract' && (!data.mobiles || data.mobiles.length === 0)) {
        Toastr.showWarn(me.msg('subtractWarnEmpty', data.name), 5000);
      }

      if (operationType === 'merge' && data.mobiles &&
        (data.mobiles.length === initFleetObject.mobiles.length ||
          data.mobiles.length === fleetToMergeOrSubtractObject.mobiles.length)) {

        let identicalFleetName = data.mobiles.length === initFleetObject.mobiles.length ?
          initFleetObject.name : fleetToMergeOrSubtractObject.name;
        Toastr.showWarn(me.msg('mergeWarnIdentical', data.name, identicalFleetName), 5000);

      }

      Toastr.showSuccess(me.msg('mergeSuccess', fleetName), 5000);
    }).fail(err => {
      console.error('Fleet merge/subtract failed ::: ' + err);
      Toastr.showError(me.msg('mergeFail'), 5000);
    });

    me.close();
  },

  onCancel: function () {
    let me = this;
    me.close();
  }
});

export default FleetMergeSubtractPopup;
