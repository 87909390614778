<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-scrollY-content inlineGroup fields">
    <field-text name="name" disabled="true" col="6 12"></field-text>
    <field-date name="startDate" period="myPeriod" role="start" format="dateTime" col="6 12" required="true"></field-date>
    <field-date name="endDate"  period="myPeriod" role="end" format="dateTime" col="6 12" required="true"></field-date>
    <field-text name="frequency" format="int" min="0" max="60" col="6 12" required="true"></field-text>
    <field-text name="maxNbOfRetry" format="int" min="0" max="60" col="6 12" required="true"></field-text>
    <field-switch name="enabled"></field-switch>
    <field-switch name="shared"></field-switch>
    <field-text name="status" disabled="true" col="6 12"></field-text>
    <uda-select of="mobiles"
                name="mobileId"
                required="true"
                class="inlineGroup"></uda-select>
    <field-select name="channel" options="channelOptions"></field-select>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
