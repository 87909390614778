<div id="{{id}}"  class="{{#if hideCriteriaFirstTime}}hidden{{/if}}">
    <field-item  item="criteriaFormModel">
      {{! Critère temporel }}

        <field-hcombo name="dateParams" label="" {options}="dateParams"></field-hcombo>

      {{! Critère between dates }}

        <field-date name="from" min="{{minDate}}" max="{{maxDate}}" updatemax="true"  period="fakePeriod" role="start"
                    format="dateTime" {visible}="dateParamsBetween"></field-date>
        <field-date name="to" min="{{minDate}}" max="{{maxDate}}" updatemax="true"  period="fakePeriod" role="end"
                    format="dateTime" {visible}="dateParamsBetween"></field-date>

      {{! Critère last days }}

        <field-text name="lastDays" format="pnum" min="0" col="6 6"
                    label-align="right" {visible}="dateParamsLastDays"></field-text>
        <field-text name="lastHours" format="pnum" min="0" max="24" col="6 6"
                    label-align="right" {visible}="dateParamsLastDays"></field-text>

      {{! Critère weekly }}
        <field-text name="weekly" label="" format="pnum" min="1" max="53" {visible}="dateParamsWeekly"></field-text>

      {{! Critère monthly }}
        <field-radios name="monthly" label="" {options}="months" {visible}="dateParamsMonthly"></field-radios>

    </field-item>
</div>
