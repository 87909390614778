import {canDefineMap} from "core-cmp/can";
import ResourceUI from "core-uda/ui/ResourceUI";
import AlertType from "core-alert/model/AlertType";
import template from "thm/ui/common/alertDefinition/AlertTypeTpl.stache";

import {I18N} from "core-cmp/util/I18n";

/**
 * User Interface elements for Alert
 */
let AlertTypeUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   AlertType.itemId,
      i18nPrefix:   "thm.ui.common.alertType.AlertTypeUI.",
      icon:         "label",
      template:     template,
      labelField:   "shortLabel",
      searchFields: ["shortLabel"]
    })
  }

});

const ALERT_TYPE_UI = new AlertTypeUI();

export default AlertTypeUI;
