<div id="{{id}}" class="page">
  <div class="header-group">
    <h3>{{msg 'title'}}</h3>
    <a cls-action="export" class="btn btn-header-right-2 ui-icon-export" title="{{msg '_Export'}}"></a>
    <a cls-action="close" class="btn btn-header-right ui-icon-close" title="{{msg '_Close'}}"></a>
  </div>
  <div id="{{id}}-grid-container">
    <div id="{{id}}-activities-container"></div>
  </div>
</div>
