<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content">

    <div class="inlineGroup fields">
  <field-text name="name" max-length="60" col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
      {{#if showClient}}
        <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
      {{/if}}
      <field-date name="beginDate" updatemax="true" role="start" format="dateTime" col="6 12"></field-date>
      <field-date name="endDate" role="end" format="dateTime" col="6 12"></field-date>
      <field-text name="mobileNumber" max="999999999999" format="int" col="6 12" class="l60-f40"></field-text>
      <field-textarea name="agentContact" max-length="240" col="12 12"></field-textarea>
    </div>

    <uda-list of="species"
              name="speciesIds"
              {update-for}="dataModel.customerId"
              update-function="filterByCustomerId"
              create-function="createForCustomer"
              class="inlineGroup"></uda-list>
    <uda-list of="zones" name="zoneIds" {update-for}="dataModel.customerId"
              update-function="findForFishingProtocol" class="inlineGroup"></uda-list>
    <uda-list of="fishingGears" name="fishingGearIds" class="inlineGroup"
              {update-for}="dataModel.customerId"
              update-function="filterByCustomerId"
              create-function="createForCustomer"></uda-list>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
