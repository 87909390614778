import Page from "core-cmp/Page";
import "core-ure/ui/favorite/AddFavoritePopup.css";
import CustomerUpdateConfirmPopupTpl from "thm/ui/common/customer/CustomerUpdateConfirmPopupTpl.stache";
import 'thm/ui/common/customer/CustomerUpdateConfirmPopup.scss';
import {APP} from "core-uda/model/Resource";

export default Page.extend({
  id:              "customerUpdateConfirmPopup",
  routerType:      'popup',
  popupWidth:      800,
  modal:           true,
  template:        CustomerUpdateConfirmPopupTpl,
  i18nPrefix:      ['uda.customer.', 'thm.ui.common.customer.CustomerUpdateConfirmPopup.'],
  autoBindActions: true,

  initViewModel: function () {
    const me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
  },

  doBeforeOpen: function () {
    const me = this;
    const {vesselErrors} = me.context;
    me.viewModel.attr('dataModel.vesselErrors', vesselErrors);
    me.viewModel.attr('getVesselName', id => APP('mobiles', id)?.name || '');
    me.viewModel.attr('getFleetName', id => APP('fleets', id)?.name || '');
    me.viewModel.attr('getAlertDefinitionName', id => APP('alertDefinitions', id)?.name || '');
  },

  onConfirm: function () {
    const me = this;
    const {confirmHandler} = me.context;
    confirmHandler();
    me.close();
  },

  onCancel: function () {
    const me = this;
    me.close();
  }
});
