import InfoPanel from "core-uda/ui/InfoPanel";
import Flag from "core-uda/model/flag/Flag";
import FlagInfoPanelTplStache from "thm/ui/common/flag/FlagInfoPanelTpl.stache";

/**
 * Flag form.
 * @class FlagInfoPanel
 */
let FlagInfoPanel = InfoPanel.extend({
  template:   FlagInfoPanelTplStache,
  i18nPrefix: ['uda.flag.', 'thm.ui.common.flag.FlagInfoPanel.'],
  id:         'flagInfoPanel',
  entityId:   Flag.itemId,
  allowWidth: true,
  allowClone: true,
  titleKey:   "title",

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function(dataModel){
    dataModel.attr({
      countryCode: ""
    });
  },

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    let me = this;
    return me.data.countryCode;
  }
});

export default FlagInfoPanel;
