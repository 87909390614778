import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";
import SelectByStache from "thm/ui/common/userAction/ThmSelectActionCriteria.stache";

/**
 * @class ThmSelectActionCriteria
 */
let ThmSelectActionCriteria = ThmSelectByCriteria.extend({
  /**
   * @override
   */
  readParameters: function (resource, criteriaConfig, criteriaName) {
    return {options: ["action"]};
  }
}, {
  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;
    me.stache = SelectByStache;
    me._super(config);
    me.typeOptions = [{
      value: 'all',
      text:  I18N.msg("uda.userAction.action.all")
    }];

    me.actions = [
      {value: "LOGIN_UDA", text: I18N.msg("uda.userAction.action.loginUDA")},
      {value: "LOGOUT_UDA", text: I18N.msg("uda.userAction.action.logoutUDA")},
      {value: "LOGIN_WEB", text: I18N.msg("uda.userAction.action.loginWEB")},
      {value: "LOGOUT_WEB", text: I18N.msg("uda.userAction.action.logoutWEB")}
    ];
  },

  /**
   * @override
   */
  setOptions: function () {
    let me = this;

    me.resourceMap.action = true;
    me.states.action = false;
    me.typeOptions.push({
      value: "action",
      text:  I18N.msg("uda.userAction.action.selection")
    });
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this,
        type,
        id;

    me.criteriaFormModel.type = 'all';
    if (params[me.name]) {
      type = me.name;
      id = params[me.name];

      if (!_.isNil(me.states[type])) {
        me.criteriaFormModel.type = type;
        me.criteriaFormModel[type] = id;
      }
    }
  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me        = this,
        params    = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda = {},
        type      = params.type;

    if (me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(params);
    } else {
      if (type !== 'all' && !_.isEmpty(me.criteriaFormModel[type])) {
        let id = me.criteriaFormModel[type];
        paramsUda[resourceName + me.name] = id;
      }
    }

    return paramsUda;
  }
});

export default ThmSelectActionCriteria;
