<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <div class="L-panel-content">
    {{#each statusData}}
      <div class="statusPage" id={{id}}>
        <div class="header-group">
          <h3>{{date}}</h3>
          <div class="btn btn-header-left ui-icon-center"></div>
        </div>
        <div class="inlineGroup statusScrollable">
          {{#each data}}
            <div class="info col-sm-4 col-size-xs-8">{{typeLib}}</div>
            <div class="{{#if isBehaviour}} UnsetDivStatusScrollable{{/if}} info col-sm-2 col-size-xs-4">{{value}}</div>
          {{/each}}
        </div>
      </div>
    {{/each}}
    {{#unless statusData}}
      <h3 style="text-align: center;">{{msg '_NoData' }}</h3>
    {{/unless}}
  </div>
</div>
