<div>
  <field-file label="" {(field)}="images" multiple="true" accept-extentions="png,jpg,jpeg,svg" max-size="6Mo"
              class="l30-f70" style="margin-bottom: 5%; display: contents"></field-file>
</div>
<div style="margin-top: 10%">
  {{#each imagesToDisplay}}
    <div id="{{id}}-image">
      <a id="{{id}}-image-a" class="image-view" href="{{b64Image}}"
         data-lightbox="mobileImage">
        <img id="{{id}}-image" src="{{b64Image}}" alt=""/>
      </a>
      <div id="{{id}}-image-delete"
           cls-action="deleteAlertImage"
           title="{{msg 'deleteImage'}}"
           class="btn ui-icon-delete btn-header-right btn-cancel {{#unless b64Image}}hidden{{/unless}}"></div>
    </div>
  {{/each}}
</div>

