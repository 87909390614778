import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionReportingInfoPanelTpl.stache";

/**
 * @class AlertDefinitionReportingInfoPanel
 * Alert Definition "Reporting" Form.
 */
let AlertDefinitionReportingInfoPanel = AlertDefinitionInfoPanel.extend({
  id:       'alertDefinitionReportingInfoPanel',
  childTpl: ChildStache,

  //override
  getTitle: function () {
    let me = this;
    return me.msg("reporting.title");
  },

  forceDefaultValues: function () {
    let me        = this,
        dataModel = me.getDataModel();

    if (_.isUndefined(dataModel.backToNormalReportingNotify)) me.viewModel.attr('dataModel.backToNormalReportingNotify', false);
    if (_.isUndefined(dataModel.noReportingNotify)) me.viewModel.attr('dataModel.noReportingNotify', false);
    if (_.isUndefined(dataModel.overReportingNotify)) me.viewModel.attr('dataModel.overReportingNotify', false);
    if (_.isUndefined(dataModel.underReportingNotify)) me.viewModel.attr('dataModel.underReportingNotify', false);
  }

});
export default AlertDefinitionReportingInfoPanel;
