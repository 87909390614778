<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>
    <field-item item="dataModel" class="L-scrollY-content">
        <div class="inlineGroup fields">
            <field-text name="name"></field-text>
        </div>
        {{#if isFTP}}
            <div id="ftp">
                <div id="params">
                    <div class="header-group icon-left icon-m ui-icon-config">
                        <h2>{{msg 'ftpConnexionParam'}}</h2>
                    </div>
                    <div class="inlineGroup fields">
                        <field-text name="hostname" required="true"></field-text>
                        <field-text name="path"></field-text>
                        <field-text name="username"></field-text>
                        <field-text name="password"></field-text>
                    </div>
                </div>
            </div>
        {{/if}}
        {{#if isHTTP}}
            <div id="http">
                <div id="connection" class="inlineGroup fields">
                    <field-text name="method"></field-text>
                    <field-text name="url" required="true"></field-text>
                </div>
                <div id="certs">
                    <div class="header-group icon-left icon-m ui-icon-check">
                        <h2>{{msg 'checkCerts'}}</h2>
                    </div>
                    <div class="inlineGroup fields">
                        <field-checkbox name="secured"></field-checkbox>
                    {{#if dataModel.secured}}
                        <field-checkbox name="checkHostName"></field-checkbox>
                        <field-checkbox name="checkStartValidity"></field-checkbox>
                        <field-checkbox name="checkEndValidity"></field-checkbox>
                    {{/if}}
                    </div>
                </div>
                <div id="reception">
                    <div class="header-group icon-left icon-m ui-icon-notification">
                        <h2>{{msg 'reception'}}</h2>
                    </div>
                    <div class="inlineGroup fields">
                        <field-select name="ackType" col="6 12" options="ackTypeOptions" required="true"></field-select>
                    </div>
                </div>
            </div>
        {{/if}}
        {{#if isSMTP}}
            <div id="smtp">
                <div id="mail">
                    <div class="header-group icon-left icon-m ui-icon-mail">
                        <h2>{{msg 'mailParams'}}</h2>
                    </div>
                    <div class="inlineGroup fields">
                        <field-text label-key="from" name="fromAddress" required="true"></field-text>
                        <field-text label-key="to" name="toAddress" required="true"></field-text>
                        <field-text name="subject"></field-text>
                        <field-switch label="" name="attached" text-on-key="attached"
                                    text-off-key="detached"></field-switch>
                    </div>
                </div>
            </div>
        {{/if}}
        {{#if isFLUX}}
            <div id="flux">
                <div id="mail">
                    <div class="header-group icon-left icon-m ui-icon-mail">
                        <h2>{{msg 'fluxParams'}}</h2>
                    </div>
                    <div class="inlineGroup fields">
                        <field-text name="url" required="true"></field-text>
                        <field-text name="from" required="true"></field-text>
                        <field-text name="addressee" placeholder="FRA:CLS:TEST" required="true"></field-text>
                        <field-text name="dataflow" required="true"></field-text>
                        <field-text name="clientCert" required="true"></field-text>
                    </div>
                </div>
            </div>
        {{/if}}
    </field-item>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
