import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";

/**
 * @class AlertDefinitionTripSpeedInfoPanel
 * Alert Definition "TripSpeed" Form.
 */
let AlertDefinitionTripSpeedInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'alertDefinitionTripSpeedInfoPanel',
  timingAllowed: true
});
export default AlertDefinitionTripSpeedInfoPanel;
