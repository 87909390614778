import CommandHandler from "core-mobile/ui/command/CommandHandler";

/**
 * Parent class for all pirat command
 * @class CommandPirat
 */
let CommandPirat = CommandHandler.extend({
  code:        "",

  /**
   * @override
   */
  init : function() {
    const me = this;

    me._super();

    // backup code to shre it between pirat commands
    me.on("code", () => CommandHandler.piratCode = me.code);
  },

  /**
   * @override
   */
  onCommandChange : function(commandStatus) {
    const me = this;
    if (me.id === commandStatus.name) {
      me.attr("code",CommandHandler.piratCode);
    }
  }
});

export default CommandPirat;
