<div id="{{id}}" class="page">
    <div class="header-group">
        <h3>
            {{msg 'titleRestriction'}}
        </h3>
    </div>
    <field-item  item="restriction" class="inlineGroup fields">
        <field-radios {(field)}="dateType" label='' options="dateTypeOptions" class="dateTypeValueBox"></field-radios>
        <div class="dateTypeValueBox">
            <field-date name="from" role="start" format="dateTime" {visible}="hasBetweenDate"></field-date>
            <field-date name="to" role="end" format="dateTime" {visible}="hasBetweenDate"></field-date>
            <field-text name="lastxdays" label-key="xLastDaysOption" format="int" {visible}="hasLastDaysDate"></field-text>
        </div>
        <field-list name="sources"
                    label-key="field.aisSources"
                    {items}="sourceDatas"
                    format="string"
                    {item-template}="sourceTpl"
                    header-icon="satellite"
                    edit-icon="add"
                    id-field="code"
                    {edit-page-params}="sourcesEditParams"></field-list>
        <field-textarea name="zones" format="restrictionZone"></field-textarea>
    </field-item>

    <div class="inline-2btn btn-footer">
        <a cls-action="save" class="btn btn-ok">{{msg '_Save'}}</a>
        <a cls-action="close" class="btn btn-cancel">{{msg '_Cancel'}}</a>
    </div>
</div>
