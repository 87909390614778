import ResourceUI from "core-uda/ui/ResourceUI";
import AcquisitionException from "core-uda/model/acquisitionException/AcquisitionException";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import ThmFilterCriteria from "thm/ui/common/criteria/ThmFilterCriteria";
import Toastr from "core-cmp/Toastr";
import {RIGHTS} from "core-uda/Rights";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";
import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";

/**
 * User Interface elements for AcquisitionException
 */
let AcquisitionExceptionUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: AcquisitionException.itemId,
      i18nPrefix: "thm.ui.common.acquisitionException.AcquisitionExceptionUI.",
      icon: "annotation"
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "exceptionType": I18N_FIELD("uda.acquisitionException.field.exceptionType.options.")
    }
  },

  /**
   * @override
   */
   isMultipleRowSelectionAuthorized: function () {
    return RIGHTS("data.acquisitionException.delete");
  },

  /**
   * @override
   */
  getUreTopActionButtons: function (mainPage) {
    let me = this;
    return [{
      id:         'delete',
      title:      I18N.msg("_Delete"),
      icon:       'delete',
      authorized: RIGHTS("data.acquisitionException.delete"),
      handler:    (gridMainPage, ev) => {
        let selectedRowsData = gridMainPage.gridElement.getSelectedRowsData();
        if (selectedRowsData.length) {
          ConfirmationPopup.openSingleton({
            title:    me.msg('deleteExceptionTitle'),
            text:     me.msg('deleteExceptionText'),
            buttons:  [
              {action: "yes"},
              {action: "no"}
            ],
            onAction: function (action) {
              if (action === "yes") {

                let requestDeferred = _.map(selectedRowsData, row => {
                  return row.destroy();
                });

                $.when(...requestDeferred).done(() => {
                  gridMainPage.refresh();
                  Toastr.showInfo(me.msg('deleteSuccess'), 5000);
                }).fail(() => {
                  Toastr.showError(me.msg('deleteError'), 5000);
                })
                
              }
            }
          });
        } else {
          Toastr.showError(me.msg("noLineSelectedError"), 5000);
        }
      }
    }];
  },

  /**
   * @override
   */
   getUreCriteria: function () {
    return {
      'exceptionType':   {
        cmpClass: ThmFilterCriteria,
        operator: "",
        isSelect: true,
        i18nPrefix: "uda.acquisitionException."
      },
      'emittingCenter': {
        cmpClass: ThmFilterCriteria,
        operator: "",
        i18nPrefix: "uda.acquisitionException."
      }
    };
  }
});

const ACQUISITION_EXCEPTION_UI = new AcquisitionExceptionUI();

export default AcquisitionExceptionUI;
