<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content">
    <div class="header-group icon-left icon-m ui-icon-info">
      <h2>{{msg 'info'}}</h2>
    </div>
    <div class="inlineGroup fields">
      <field-text col="6 12" name="dcNumber" {disabled}="disabled"></field-text>
      <field-text col="6 12" label-key="field.dcDate" name="declareDate" {disabled}="disabled"></field-text>
      <field-text col="6 12" class="l50-f50" name="dcDelegation" {disabled}="disabled"></field-text>
      <field-text col="6 12" name="detail.shipownerName" {disabled}="disabled"></field-text>
    </div>

    <div class="header-group icon-left icon-m ui-icon-vessel">
      <h2>{{msg 'vesselFishingActivity'}}</h2>
    </div>
    <div class="inlineGroup">
      <div id="{{id}}-vesselFishingGrid"></div>
      <div id="{{id}}-vesselActivityGrid"></div>
    </div>

    <div class="header-group icon-left icon-m ui-icon-fish">
      <h2>{{msg 'captures'}}</h2>
    </div>
    <div class="inlineGroup">
      <div id="{{id}}-capturesGrid"></div>
    </div>

    <div class="header-group icon-left icon-m ui-icon-captain">
      <h2>{{msg 'captain'}}</h2>
    </div>
    <div class="inlineGroup">
      <div id="{{id}}-captainGrid"></div>
    </div>

    <div class="header-group icon-left icon-m ui-icon-licence">
      <h2>{{msg 'visa'}}</h2>
    </div>
    <div class="inlineGroup fields">
      <field-text label="" name="detail.visa" {disabled}="disabled"></field-text>
    </div>
  </field-item>
</div>
