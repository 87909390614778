import ResourceUI from "core-uda/ui/ResourceUI";
import PositionSource from "core-uda/model/position/PositionSource";
import template from "thm/ui/common/positionSource/PositionSourceUITpl.stache";

/**
 * User Interface elements for Source
 */
let PositionSourceUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:     PositionSource.itemId,
      i18nPrefix:     "thm.ui.common.source.SourceUI.",
      icon:           "satellite",
      template:       template,
      isListEditable: true
    })
  }
});

const POSITION_SOURCE_UI = new PositionSourceUI();

export {POSITION_SOURCE_UI};
export default PositionSourceUI;
