import _ from "core-cmp/lodash";
import Page from "core-cmp/Page";
import "core-ure/ui/favorite/AddFavoritePopup.css";
import BeaconThemisUpdateCustomersPopupTpl from "thm/ui/common/beacon/BeaconThemisUpdateCustomersPopupTpl.stache";
import FixCustomerAssociation from 'core-uda/model/mobile/FixCustomerAssociation';
import Toastr from "core-cmp/Toastr";

/**BeaconThemisUpdateCustomersPopup
 * @class BeaconThemisUpdateCustomersPopup
 */
let BeaconThemisUpdateCustomersPopup = Page.extend({
  id:                  "mobileBeaconThemisUpdateCustomersPopup",
  routerType:          'popup',
  popupWidth:          800,
  popupHeight:         320,
  modal:               true,
  template:            BeaconThemisUpdateCustomersPopupTpl,
  i18nPrefix:          ['thm.ui.common.mobile.BeaconThemisUpdateCustomersPopup.'],
  autoBindActions:     true,
  localValuesLocation: {
    "customers": '#mobileBeaconThemisUpdateCustomersPopup-customerList field-list-select',
    "mobiles": '#mobileBeaconThemisUpdateCustomersPopup-mobileList field-list'
  },

  initViewModel: function () {
    const me = this;
    me._super();
    me.viewModel.attr('dataModel', {});
    me.viewModel.attr('form', {});
    me.viewModel.attr('tabs', [
      {
        value: "all",
        text:  me.msg('all')
      },
      {
        value: "data",
        text:  me.msg('period')
      },
      {
        value: "beacon",
        text:  me.msg('beacon')
      },
    ]);

    me.viewModel.attr("loading", false);
    me.viewModel.attr('form').on('currentTab', me.proxy(me._updateTab));
  },

  _updateTab: function () {
    let me            = this,
        value         = me.viewModel.attr('form.currentTab')

    me.viewModel.attr("loading", true);

    switch (value) {
      case 'all':
        me.viewModel.attr('showDate', false);
        me.viewModel.attr('showActive', false);
        me.viewModel.attr('onlyBeacon', false);
        me.viewModel.attr('dataModel.requestType', 'BEACON_AND_DATA');
        break;
      case 'data':
        me.viewModel.attr('showDate', true);
        me.viewModel.attr('showActive', false);
        me.viewModel.attr('onlyBeacon', false);
        me.viewModel.attr('dataModel.requestType', 'DATA_ONLY');
        break;
      case 'beacon':
        me.viewModel.attr('showDate', false);
        me.viewModel.attr('showActive', true);
        me.viewModel.attr('onlyBeacon', true);
        me.viewModel.attr('dataModel.requestType', 'BEACON_ONLY');
        break;
    }

    me.viewModel.attr("loading", false);
  },

  doBeforeOpen: function () {
    let me            = this

    if (!!me.context) {
      me.viewModel.attr('dataModel.customerId', me.context.customerId);
      me.viewModel.attr('dataModel.srcBeacon', me.context.beaconId);
      me.viewModel.attr('displayVessel', me.context.displayVessel);
    }
  },

  checkErrors: function (item) {
    const me = this;

    if (me.viewModel.attr('showDate') && (!item.startDate || !item.endDate)) {
      Toastr.showError(me.msg('errorDate'), 5000);
      return true;
    }

    return false;
  },

  onSubmit: function (event) {
    const me             = this,
          item           = me.viewModel.attr('dataModel').attr(),
          id             = me.context.beaconId;

    if (me.checkErrors(item)) {
      return;
    }

    let messageSuccesKey = _.camelCase('successFix ' + me.viewModel.attr('form.currentTab')),
        customerName = APP('customers', item.targetCustomer).company,
        vesselName = '',
        beaconRef  = APP('beacons', item.srcBeacon).reference,
        messageErrorKey = _.camelCase('failureFix ' + me.viewModel.attr('form.currentTab'));

    if ((item.startDate === undefined && item.endDate === undefined) || !me.viewModel.attr('showDate')) {
      item.startDate = null;
      item.endDate = null;
    }

    if(!me.viewModel.attr('onlyBeacon')){
      item.targetVessel = null;
      item.activate = null;
    }

    if (item.activate === undefined) {
      item.activate = false;
    }

    if(item.targetVessel){
      item.changeMode = 'CHANGE_BEACON_VESSEL';
      vesselName = APP('mobiles', item.targetVessel).name;
    }

    if(me.viewModel.attr('onlyBeacon')){
      if(item.targetVessel){
        messageSuccesKey = 'successFixBeaconWithVessel';
      }else{
        messageSuccesKey = 'successFixBeaconWithoutVessel';
      }
    }

    delete item.customerId;

    FixCustomerAssociation.changeCustomer(item).done(() => {
      Toastr.showSuccess(me.msg(messageSuccesKey, customerName, beaconRef, vesselName), 5000);

      // Refresh Mobile Panel
      Page.openDataPanel({
        type:   'beacon',
        data:   {id: id},
        target: 'right'
      });

      // Switch mobileId to new customerId (target)
      APP('beacons', id).attr('customerId', item.targetCustomer);

      me.close();
    }).fail((error) => {
      Toastr.showError(me.msg(messageErrorKey, customerName, beaconRef, error), 5000);
      me.close();
    });
    me.close();
  },

  onCancel: function () {
    const me = this;
    me.close();
  },

  getSuccessMessage(type, vesselTo, vesselFrom) {
    let me = this;
  }

});

export default BeaconThemisUpdateCustomersPopup;
