<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-state {(field)}="tabs" options="tabsOptions" options-col="3" options-sort="false" class="tab"></field-state>
  </ui-page-header>

  <field-item item="dataModel" class="L-panel-content">
    <div class="{{#is tabs "identification"}}{{else}}hidden{{/is}}">
      <field-text name="name" max-length="60" format="uppercase" required="true" col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
      {{#if showClient}}
        <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
      {{/if}}
      <field-select name="mobileTypeId" {options}="mobileTypeOptions" option-none="true" col="12 12"></field-select>
      <field-textarea name="description" max-length="240" col="12 12"></field-textarea>
      <field-text name="telephone" max-length="20" format="phone-inter" col="6 12"></field-text>
      <field-text name="fax" max-length="20" col="6 12"></field-text>
      <field-text name="email" max-length="100" format="email" col="6 12"></field-text>
      <field-select name="broadcastMode" options="broadcastModeOptions" required="true" col="6 12"></field-select>
      <field-list name="beaconIds"
                  {items}="beaconDatas"
                  format="array"
                  {item-template}="beaconTpl"
                  header-icon="beacon"
                  edit-icon="add"
                  {edit-page-params}="beaconEditParams"></field-list>
    </div>
    <div class="{{#is tabs "image"}}{{else}}hidden{{/is}}">
      {{>ImageTabTpl}}
    </div>
    <div class="L-fit L-panel {{#is tabs "events"}}{{else}}hidden{{/is}}">
      {{>EventsTabTpl}}
    </div>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
