import ResourceUI from "core-uda/ui/ResourceUI";
import StatusCode from "core-uda/model/mobile/StatusCode";
import template from "thm/ui/common/statusCode/StatusCodeTpl.stache";
import {APP} from "core-uda/model/Resource";

/**
 * Indique si une ressource est visible
 * = le SUPERADMIN a configuré cette ressource comme étant visible = il y a un item dans le menu bleu pour y accéder
 * A remonter dans une classe Utils ?
 * @param resourceId
 * @returns {boolean}
 */
const isResourceVisible = resourceId => APP('resourcesPreferences').getForMode('UME')?.value[resourceId] === 'true';

/**
 * User Interface elements for Mobile Type
 */
let StatusCodeUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:   StatusCode.itemId,
      i18nPrefix:   "thm.ui.common.statusCode.StatusCodeUI.",
      icon:         "status",
      template:     template,
      labelField:   "name",
      // THM-14619 - les options de la liste status code ne sont pas éditables si l'utilisateur n'a pas accès à Management -> Status codes
      // Et ce même s'il a les droits sur la data statusCode
      isListEditableFn: () =>isResourceVisible('statusCode'),
    })
  },



});

const STATUS_CODE_UI = new StatusCodeUI();

export default StatusCodeUI;
