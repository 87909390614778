import ResourceDAO from "core-uda/model/ResourceDAO";
import {APP} from "core-uda/model/Resource";
import {RIGHTS} from "core-uda/Rights";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import ReportTemplate from "thm/model/report/ReportTemplate";

/**
 * Identity Change DAO.
 */

let ReportTemplateDAO = ResourceDAO.extend({
  resource:      "reportTemplate",
  resourceClass: ReportTemplate,
  findAllParams: {
    "fields": "all"
  }

});

// Instance unique
let REPORT_TEMPLATE_DAO = new ReportTemplateDAO();

export {REPORT_TEMPLATE_DAO};
export default ReportTemplateDAO;
