<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig" toolbar-size="1" large-toolbar-size="1"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
      <field-text name="tripNumber" {disabled}="disabled"></field-text>
      <field-date name="openDate" {disabled}="disabled"></field-date>
      <field-date name="closeDate" {disabled}="disabled"></field-date>
      <field-text name="fishingDays" {disabled}="disabled"></field-text>
      <field-text name="tripDays" {disabled}="disabled"></field-text>
  </field-item>
</div>
