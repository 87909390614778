<field-item item=".">
    <div id="msg-{{id}}" class="msg-block">
        <field-checkbox name="deleteStatus" label=""></field-checkbox>
        <div data-id="{{id}}" class="msg-content msg-{{classSender}}">
            <h5>{{title}}</h5>
            <hr>
            <p>{{content}}</p>
            <div class="msg-date {{classError}}">
                <span class="msg-type-icon icon-right icon-s {{type}}"></span>
                <p>{{dateTime}}</p>
            </div>
        </div>
    </div>
</field-item>
