import {canDefineMap} from "core-cmp/can";
import ErsQueryCreatePage from "thm/ui/common/ersQuery/ErsQueryCreatePage";
import ResourceUI from "core-uda/ui/ResourceUI";
import ErsQuery from "core-uda/model/ersQuery/ErsQuery";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import {APP} from "core-uda/model/Resource";

import SelectCriteria from "core-criteria/select/SelectCriteria";
import TextCriteria from "core-criteria/text/TextCriteria";

import ThmListSelectCriteria from "thm/ui/common/criteria/ThmListSelectCriteria";
import {I18N} from "core-cmp/util/I18n";

/**
 * User Interface elements for ErsQuery
 */
let ErsQueryUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: ErsQuery.itemId,
      icon:       "command",
      i18nPrefix: "thm.ui.common.ersQuery.ErsQueryUI."
    })
  },

  /**
   * @override
   */
  getUreLeftButtons: function () {
    let me = this;
    return [{
      actionId:   "ersQuery-create",
      title:      me.msg("create.label"),
      label:      me.msg("create.description"),
      icon:       "add",
      style:      "active",
      isDisplay:  () => {return true},
      authorized: DATA_AUTHORIZED("ersQuery.create"),
      handler:    () => {
        ErsQueryCreatePage.openPage();
      }
    }]
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      'kind':            {
        cmpClass:         SelectCriteria,
        options:          ErsQuery.getTypeList(),
        optionsKeyPrefix: 'uda.ersQuery.field.kind.options.',
        initValue:        'get_vessel_data',
        labelKey:         'uda.ersQuery.field.kind',
        labelAlign:       "top"
      },
      'destination':     {
        cmpClass:     ThmListSelectCriteria,
        labelKey:     'uda.ersQuery.field.destination',
        labelAlign:   "top",
        resourceName: "flags",
        formatModelToUDACustom : function(data) {
          let flag = APP("flags",data.destination);
          if (!flag) {
            return {};
          }
          return {destination:flag.countryCode};
        },
        formatUDAToModelCustom : function(params) {
          let flag = params.destination ? APP("flags").find({countryCode:params.destination}) : null;

          if (!flag) {
            return {}
          }
          return {destination:flag.id};
        }
      },
      'identifierType':  {
        cmpClass:         SelectCriteria,
        optionsKeyPrefix: 'uda.ersQuery.field.identifierType.options.',
        labelKey:         'uda.ersQuery.field.identifierType',
        options:          ErsQuery.getIdentifierTypeList(),
        labelAlign:       "top"
      },
      'identifierValue': {
        cmpClass: TextCriteria,
        labelKey: 'uda.ersQuery.field.identifierValue'
      }

    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      "kind": I18N_FIELD("uda.ersQuery.field.kind.options.")
    }
  }

});

const ERS_QUERY_UI = new ErsQueryUI();

export default ErsQueryUI;
