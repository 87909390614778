<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
    <div class="list-field col-sm-12 col-size-xs-12">
        <label class="annotation inlineGroup">{{msg 'reportingDescription'}} : </label>
    </div>

    <field-checkbox name="overReportingNotify"></field-checkbox>
    <field-checkbox name="underReportingNotify"></field-checkbox>
    <field-checkbox name="noReportingNotify"></field-checkbox>
    <field-checkbox name="backToNormalReportingNotify"></field-checkbox>
</div>
