<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-item item="form">
      <field-hcombo name="currentTab" label="" options="tabs" col="12 12"
                    always-editable="true" class="hcombo-tab"></field-hcombo>
    </field-item>
  </ui-page-header>

  <field-item item="dataModel" class="L-panel-content">

    {{! TAB : Identification }}

    <div id="{{id}}-tab-identification" class="tab">

      {{! TAB : Identification / General information}}

      <div id="{{id}}-general" class="inlineGroup fields">
        <field-text name="name" {max-length}="maxLengthName" format="uppercase" required="false"
                    col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
        {{#if showClient}}
          {{#if isNew}}
            <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
          {{else}}
            {{#if isSharedVessel}}
                <field-text name="customerCompany" {visible}="showClient" col="6 12" disabled="true"></field-text>
            {{else}}
                <field-text name="customerCompany" {visible}="showClient" col="6 12" editor-icon="edit" read-only="true"
                            editor-title-key="customer" (editor)="_cmp.onEditionCustomer"></field-text>
            {{/if}}
          {{/if}}
        {{/if}}

        {{#unless domainFish}}
          <field-checkbox name="active" required="false" col="6 6"></field-checkbox>
          <field-checkbox name="unknown" required="false" col="6 6"></field-checkbox>
        {{/unless}}

        {{#if dataModel.isAisMobile}}
          <field-text name="mobileTypeName" required="false" col="6 12"></field-text>
        {{else}}
          <uda-select of="mobileTypes"
                      name="mobileTypeId"
                      option-none="true"
                      {hidden}="dataModel.isAisMobile"
                      {update-for}="dataModel.customerId"
                      update-function="filterByCustomerId"
                      create-function="createForCustomer"
                      col="6 12"
                      class="l40-f60"></uda-select>
        {{/if}}
        <field-list-select label-key="field.typeCode"
                           {(field)}="dataModel.typeCode"
                           {item-template}="codeOptions"
                           {items}="typeCodeOptions"
                           id-field="value"
                           class="l30-f70"
                           col="6 12">
          <span>{{value.text}}</span>
        </field-list-select>

        <field-select name="styleId" options-value="id" options-text="name" option-none="true"
                      {options}="styleOptions"></field-select>
        <div class="inlineGroup">
          <uda-select of="flags"
                      name="flagId"
                      col="6 12"
                      option-none="true"
                      class="l30-f70"></uda-select>
          <field-like label="" col="6 12">{{dataModel.flagCountryCode}}
            - {{dataModel.flagCountryName}}</field-like>
        </div>
        {{#if gfcmAllowed}}
          <field-text name="vms" required="false" col="6 12"></field-text>
          <field-text name="fishingRestrictedArea" required="false" col="6 12"></field-text>
          <field-text name="nbOfFishingDays" format="pint" required="false" col="6 12"></field-text>
        {{/if}}
      </div>

      {{! TAB : Identification / Image}}

      {{>MobileInfoPanelImageTpl}}
      {{#if dataModel.imageUrl}} {{! TAB : Identification / Image}}
        <div id="{{id}}-imageUrl-a-div">
          <a id="{{id}}-imageUrl-a" target="_blank" href="{{dataModel.imageUrl}}">{{msg 'imageUrl'}}</a>
        </div>
      {{/if}}

      {{! TAB : Identification / Identification}}

      <div class="header-group inline icon-left icon-m ui-icon-help">
        <h2>{{msg 'identification'}}</h2>
      </div>

      <div id="{{id}}-identification" class="inlineGroup fields l50-f50">
        {{#if domainTracking}}
          <field-select name="ownership" options="ownershipOptions" col="6 12"></field-select>
        {{/if}}

        {{#if domainFishing}}
          <field-text name="internalRef" max-length="105" required="false" col="6 12"></field-text>
          <field-text name="beneficialOwner" required="false" col="6 12"></field-text>
          <field-text name="registeredOwner" required="false" col="6 12"></field-text>
          <field-text name="callSign" required="false" col="6 12"></field-text>
          <field-text name="ref" required="false" col="6 12"></field-text>
          <field-text name="registry" {max-length}="maxLengthRegistry" required="false" col="6 12"></field-text>
          <field-text name="imo" format="pnum" required="false" col="6 12"></field-text>
          <field-text name="mmsi" format="pnum" required="false" col="6 12" max="999999999"></field-text>
          <field-text name="iccat" required="false" col="6 12"></field-text>
          <field-text name="poCode" format="int" required="false" col="6 12"></field-text>
          <field-text name="poName" max-length="50" required="false" col="6 12"></field-text>
        {{else}}
          <field-text name="callSign" required="false" col="6 12"></field-text>
          <field-text name="ref" required="false" col="6 12"></field-text>
          <field-text name="registry" {max-length}="maxLengthRegistry" required="false" col="6 12"></field-text>
          <field-text name="mmsi" format="pnum" required="false" col="6 12" max="999999999"></field-text>

                {{#unless domainFish}}
                    <field-text name="internalRef" max-length="105" required="false" col="6 12"></field-text>
                    <field-text name="beneficialOwner" required="false" col="6 12"></field-text>
                    <field-text name="registeredOwner" required="false" col="6 12"></field-text>
                    <field-text name="imo" format="pint" required="false" col="6 12"></field-text>
                  {{#if isTrackingDomain}}
                      <field-text name="mobileManager" required="false" col="6 12"></field-text>
                      <field-text name="mobileClass" required="false" col="6 12"></field-text>
                      <field-text name="mobilePreviousName" required="false" col="6 12"></field-text>
                  {{/if}}
                {{/unless}}
              {{/if}}

        {{#unless domainFish}}
          <field-text format="pnum" name="crewNumber" required="false" col="6 12"></field-text>
          <field-text name="registrationQuarter" required="false" col="6 12"></field-text>
          <field-text name="thirdPartOperator" required="false" col="6 12"></field-text>
          <field-text name="commercialOperator" required="false" col="6 12"></field-text>
          <field-text name="technicalManager" required="false" col="6 12"></field-text>
          <uda-select of="zones"
                      name="registrationPortId"
                      label-key="field.registrationPortName"
                      item-none="true"
                      col="12 12"
                      {update-for}="dataModel.customerId"
                      update-function="findCustomerPorts"
                      icon="port">
          </uda-select>

          <uda-select of="zones"
                      name="adminPortId"
                      label-key="field.adminPortName"
                      item-none="true"
                      col="12 12"
                      {update-for}="dataModel.customerId"
                      update-function="findCustomerPorts"
                      icon="port">
          </uda-select>
        {{/unless}}
        <field-textarea name="description" label-align="top" required="false" col="12 12"></field-textarea>
      </div>

      {{#if gfcmAllowed}}
        <div class="header-group inline icon-left icon-m ui-icon-help">
          <h2>{{msg 'gfcmInformation'}}</h2>
        </div>

        <div id="{{id}}-identification-gfcm" class="inlineGroup fields l50-f50">
          <field-date name="gfcmTransmission" format="date" required="false" col="6 12"></field-date>
          <field-date name="gfcmModification" format="date" required="false" col="6 12"></field-date>
          <field-text name="referenceYear" format="pint" required="false" col="6 12"></field-text>
          <field-text name="gsa1" required="false" col="6 12"></field-text>
          <field-text name="gsa2" required="false" col="6 12"></field-text>
          <field-text name="gsa3" required="false" col="6 12"></field-text>
          <field-text name="yearEntryActivity" format="pint" required="false" col="6 12"></field-text>
          <field-switch name="licenceIndicator" text-model="yesNo" col="6 12"></field-switch>
          <field-text name="constructionYear" format="pint" required="false" col="6 12"></field-text>
          <field-text name="vesselNamePrevious" required="false" col="6 12"></field-text>
          <field-text name="flagPrevious" required="false" col="6 12"></field-text>
          <field-text name="previousDeletion" required="false" col="6 12"></field-text>
          <field-date name="authorizedPeriodFrom" format="date" required="false" col="6 12"></field-date>
          <field-date name="authorizedPeriodTo" format="date" required="false" col="6 12"></field-date>
          <field-text name="enginePowerAuxiliary" required="false" col="6 12"></field-text>
          <field-text name="hullMaterial" required="false" col="6 12"></field-text>
          <field-text name="poweredMotorized" required="false" col="6 12"></field-text>
          <field-text name="lights" required="false" col="6 12"></field-text>
          <field-text name="navigation" required="false" col="6 12"></field-text>
          <field-text name="communication" required="false" col="6 12"></field-text>
          <field-text name="fishFinder" required="false" col="6 12"></field-text>
          <field-text name="deckMachinery" required="false" col="6 12"></field-text>
          <field-text name="refrigeration" required="false" col="6 12"></field-text>
          <field-text name="fishProcessing" required="false" col="6 12"></field-text>
          <field-text name="safety" required="false" col="6 12"></field-text>
          <field-text name="mainTargetSpecies" required="false" col="6 12"></field-text>
          <field-text name="fleetSegment" required="false" col="6 12"></field-text>
          <field-text name="timePeriodAuthorized" required="false" col="6 12"></field-text>
        </div>
      {{/if}}

      {{#if npfcAllowed}}
          <div class="header-group inline icon-left icon-m ui-icon-help">
              <h2>{{msg 'npfcInformation'}}</h2>
          </div>

              <div id="{{id}}-identification-npfc" class="inlineGroup fields">
                  <field-text name="mobileType" required="false" col="6 12"></field-text>
                  <field-select name="vesselStatus" options="vesselStatusOptions" required="false"
                              col="6 12"></field-select>
                  <field-text name="mobileType" required="false" col="12 12"></field-text>
                  <field-text name="captainName" required="true" col="6 12"></field-text>
                  <field-text name="captainNationality" required="false" col="6 12"></field-text>
                  <field-text name="countryBuiltin" required="true" col="6 12"></field-text>
                  <field-text name="builtinYear" format="pint" required="true" col="6 12"></field-text>
                  <field-date name="versionDate" format="date" required="false" col="6 12"></field-date>
                  <field-text name="countryAuthorized" required="false" col="6 12"></field-text>
                  <field-text name="beam" format="pnum" required="true" col="6 12"></field-text>
                  <field-select name="beamType" options="beamTypeOptions" col="6 12"></field-select>
                  <field-select name="beamUnit" options="npfcUnitOptions" col="6 12"></field-select>
                  <field-text name="depth" format="pnum" required="true" col="6 12"></field-text>
                  <field-select name="depthType" options="depthTypeOptions" col="6 12"></field-select>
                  <field-select name="depthUnit" options="npfcUnitOptions" col="6 12"></field-select>
                  <field-text format="pnum" name="tonnage" required="true" col="6 12"></field-text>
                  <field-select name="tonnageType" options="tonnageTypeOptions" col="6 12"></field-select>
                  <field-text format="pnum" name="length" required="true" col="6 12"></field-text>
                  <field-select name="lengthType" options="lengthTypeOptions" col="6 12"></field-select>
                  <field-select name="lengthUnit" options="npfcUnitOptions" col="6 12"></field-select>
                  <field-text name="power" label-key="field.powerNoUnit" format="pnum" required="false"
                              col="6 12"></field-text>
                  <field-select name="powerUnit" options="powerUnitOptions" col="6 12"></field-select>
                  <field-text name="deletionReason" required="false" col="6 12"></field-text>
                  <field-text name="deletionOtherReason" required="false" col="6 12"></field-text>
                  <field-text name="commDetails" required="false" col="6 12"></field-text>
                  <field-text name="freezerTypeCount" format="pint" required="false" col="6 12"></field-text>
                  <field-select name="freezerType" options="freezerTypeOptions" required="false"
                                col="6 12"></field-select>
                  <field-text name="freezerCapacity" format="pint" required="false" col="6 12"></field-text>
                  <field-select name="freezerCapacityUnit" options="freezerCapacityUnitOptions" required="false"
                                col="6 12"></field-select>
                  <uda-list of="licences" name="licences"
                            {update-for}="dataModel.id"
                            update-function="findAvailableForMobile"
                            scrollable="false"
                            class="inlineGroup">
                  </uda-list>
              </div>
          {{/if}}

      {{! TAB : Identification / ShipOwners}}

      <field-list name="shipowners"
                  format="items"
                  scrollable="false"
                  counter="true"
                  {item-template}="shipownerTemplate"
                  edit-mode="CUD"
                  {edit-page}="shipownerEditPage"
                  {edit-page-params}="shipownerEditParams"
                  header-icon="boatAnchor">
      </field-list>

      {{! TAB : Identification / Codification}}
      {{#unless domainFish}}
        <div class="header-group inline icon-left icon-m ui-icon-label">
          <h2>{{msg 'vesselCodif'}}</h2>
        </div>
        <div id="{{id}}-codification" class="inlineGroup fields">
          <field-text format="pnum" label="#1" name="type1" class="l30-f70" max="999" col="2 4"></field-text>
          <field-text format="pnum" label="#2" name="type2" class="l30-f70" max="999" col="2 4"></field-text>
          <field-text format="pnum" label="#3" name="type3" class="l30-f70" max="999" col="2 4"></field-text>
          <field-text format="pnum" label="#4" name="type4" class="l30-f70" max="999" col="2 4"></field-text>
          <field-text format="pnum" label="#5" name="type5" class="l30-f70" max="999" col="2 4"></field-text>
        </div>
      {{/unless}}

      {{! TAB : Identification / Additinal services}}
      {{#if rightAdditional}}
        <div class="header-group inline icon-left icon-m ui-icon-tools">
          <h2>{{msg 'additionnalsServices'}}</h2>
        </div>
        <div id="{{id}}-additionnals" class="inlineGroup fields">
          <field-checkbox name="ssasTerminal" required="true" col="6 12"></field-checkbox>
          <field-hcombo name="terminalLevel" options="terminalLevelOptions" {visible}="showTerminalLevel"
                        col="6 12"></field-hcombo>
          <field-checkbox name="aisVessel" required="true"></field-checkbox>
        </div>
      {{/if}}

      {{! TAB : Identification / Beacons}}

      <uda-list of="beacons"
                name="beacons"
                scrollable="false"
                counter="true"
                {update-for}="dataModel.id"
                update-function="findAvailableForMobile"
                create-function="createForCustomer"
                {item-template}="beaconsTemplate"
                {edit-page-item-template}="beaconEditTemplate"
                {toolbar-actions}="beaconListToolbarActions"
                toolbar-size="3"
                (itemclick)="onChangeActiveBeacon"
                (itemdelete)="onDeleteBeacon"
                (itemsupdated)="onBeaconUpdated"
                scrollable="false"
                {editable}="beaconEditable"
                edit-icon="add">
      </uda-list>

      {{#if isTrackingDomain}}
        <field-switch name="beaconReplacementRequired" required="false" col="12 12"></field-switch>
        <field-textarea name="additionalInformation" label-align="top" required="false"
            col="12 12" {hidden}="noReplacementRequired">
        </field-textarea>
      {{/if}}

    </div>

    {{! TAB : Specifications}}

    <div id="{{id}}-tab-specifications" class="tab" style="display:none;">

      {{! TAB : Specifications / Physical}}

      <div class="header-group inline icon-left icon-m ui-icon-info">
        <h2>{{msg 'physicalSpec'}}</h2>
      </div>
      <div id="{{id}}-specifications" class="inlineGroup fields l50-f50">
        <field-text format="length" name="lengthOverall" required="false" col="6 12"></field-text>

        {{#if domainFishing}}
          <field-text format="length" name="registeredLength" required="false" col="6 12"></field-text>
        {{/if}}

        {{#unless domainFish}}
          <field-text format="length" name="lengthPp" required="false" col="6 12"></field-text>
          {{#unless npfcAllowed}}
              <field-text format="length" name="beam" required="false" col="6 12"></field-text>
          {{/unless}}
          <field-text format="length" name="draught" required="false" col="6 12"></field-text>
        {{/unless}}

        <field-text format="pnum" name="holdCapacity" required="false" col="6 12"></field-text>
      {{#unless npfcAllowed}}
          <field-text format="pnum" name="power" required="false" col="6 12"></field-text>
      {{/unless}}

        {{#if domainTracking}}
          <field-text format="pnum" name="eedi" required="false" col="6 12"></field-text>
        {{/if}}

        {{#unless domainFish}}
          <field-text format="pnum" name="umsTonnage" required="false" col="6 12"></field-text>
          <field-text name="vesselColor" required="false" col="6 12"></field-text>
        {{/unless}}

              {{#unless npfcAllowed}}
                  <field-text format="pnum" name="tonnage" required="false" col="6 12"></field-text>
              {{/unless}}

        {{#unless domainFishing}}
          {{#unless domainTracking}}
            <field-text name="fishingGear1" required="false" col="6 12"></field-text>
            <field-text name="fishingGear2" required="false" col="6 12"></field-text>
          {{/unless}}
        {{/unless}}

          <field-select name="hullType" options="hullTypeOptions" option-none="true" col="6 12"></field-select>

      </div>

      {{! TAB : Specifications / Speed}}

      {{#unless domainFish}}
        <div class="header-group inline icon-left icon-m ui-icon-speed">
          <h2>{{msg 'speedSpec'}}</h2>
        </div>
        <div id="{{id}}-speed-specifications" class="inlineGroup fields l50-f50">
          {{#if domainFishing}}
            <field-text format="speed" name="minSpeed" required="false" col="6 12"></field-text>
          {{/if}}
          <field-text format="speed" name="normalSpeed" required="false" col="6 12"></field-text>
          <field-text format="speed" name="maxSpeed" required="false" col="6 12"></field-text>
        </div>
      {{/unless}}

      {{! TAB : Specifications / Contacts}}

      {{#unless domainFishingOrTraking}}
        {{>MobileThemisInfoPanelContactTpl}}
      {{/unless}}

      {{! TAB : Specifications / Reporting}}

      <div class="header-group inline icon-left icon-m ui-icon-dataToPosition">
        <h2>{{msg 'monitoring'}}</h2>
      </div>

      <div id="{{id}}-monitor-specifications" class="inlineGroup fields">
        <field-checkbox name="monitorActivated" col="6 12"></field-checkbox>
        <field-hcombo label="" name="monitorActivatedTarget" options="monitors" option-none="true"
                      col="6 12"></field-hcombo>
        <field-checkbox name="resumeAuto" col="6 12" {hidden}="domainFish"></field-checkbox>
        {{#unless domainFish}}
          <field-date format="date" name="resumeDate" required="false" col="6 12"></field-date>
        {{/unless}}
        <div class="inlineGroup">

          <field-like class="l80-f20" name="expectedReportingPeriod"></field-like>
          {{#if showCloseMonitoring}}
              <field-checkbox name="apwCloseMonitoring" col="6 12"></field-checkbox>
          {{/if}}
          {{#if normalReportingAllowed}}
            {{#unless domainTracking}}
              <uda-select of="statusCodes"
                          name="normalStatusCodeId"
                          option-none="true"
                          {update-for}="dataModel.id"
                          col="6 12"
                          label-key=""
              ></uda-select>
            {{/unless}}
          {{/if}}

          <uda-select of="statusCodes"
                      name="currentStatusCodeId"
                      option-none="true"
                      {update-for}="dataModel.id"
                      col="6 12"
                      label-key=""
                      required="true"
          ></uda-select>

          <uda-select of="statusCodes"
                      name="currentAtPortStatusCodeId"
                      option-none="true"
                      {update-for}="dataModel.id"
                      col="6 12"
                      label-key=""
          ></uda-select>

        </div>
        <div class="inlineGroup">
          <field-text {disabled}="disabledField" name="lastAlertCounter" required="false" col="6 12"></field-text>
        </div>
      </div>

      {{! TAB : Specifications / ManOnBoard}}
      {{#if normalReport}}
        <div class="header-group inline icon-left icon-m ui-icon-dataToPosition">
          <h2>{{msg 'manOnBoard'}}</h2>
        </div>

        <div id="{{id}}-monitor-specifications" class="inlineGroup fields">
          <field-checkbox name="monitorMob" col="6 12"></field-checkbox>
          <div class="inlineGroup">
            <field-date format="date" name="lastMobDate" required="false" {disabled}="disabledField"
                        col="6 12"></field-date>
          </div>
        </div>
      {{/if}}
      {{! TAB : Specifications / Automatic polling}}

      {{#if isApwAuthorized}}
        <div class="header-group inline icon-left icon-m ui-icon-config">
          <h2>{{msg 'autoPolling'}}</h2>
        </div>

        <div id="{{id}}-auto-polling" class="inlineGroup fields">
          <field-select name="apwDelay" options="apwDelays" col="6 12"></field-select>
          {{#if isApwStatusAutorized}}
            <field-select name="lastApwStatus" col="6 12" options="lastApwStatusOptions"
                          {disabled}="disabledField"></field-select>
          {{/if}}
          <field-date name="lastApwActivationDate" col="6 12" {disabled}="disabledField"></field-date>
          <field-text name="lastApwPollCount" col="6 12" {disabled}="disabledField"></field-text>
          <field-text name="lastApwPollFailureCount" col="6 12" {disabled}="disabledField"></field-text>
        </div>
      {{/if}}

      {{! TAB : Specifications / Fishing characteristics}}
      {{#if domainFishing}}
        <div class="header-group inline icon-left icon-m ui-icon-fishing">
          <h2>{{msg 'fishingCarac'}}</h2>
        </div>
        <div id="{{id}}-fishingCarac" class="inlineGroup fields">
          <field-text name="conventionType" required="false" col="6 12"></field-text>
          <field-select name="authorizedProcessing" options="authorizedProcessOptions" option-none="true"
                        col="6 12"></field-select>
          <field-checkbox name="transfertParticipatingVessel" required="false" col="12 12"></field-checkbox>
        </div>

        {{#if domainFishing}}
          <field-list name="fishingGears"
                      format="items"
                      counter="true"
                      {item-template}="fishingGearTemplate"
                      edit-mode="CUD"
                      {edit-page}="fishingGearEditPage"
                      {edit-page-params}="fishingGearEditParams"
                      scrollable="false"
                      header-icon="config">
          </field-list>
        {{#unless npfcAllowed}}
          <uda-list of="licences" name="licences"
                    {update-for}="dataModel.id"
                    update-function="findAvailableForMobile"
                    scrollable="false"
                    class="inlineGroup">
          </uda-list>
        {{/unless}}
          <field-checkbox name="monitorLicence"></field-checkbox>
          <field-checkbox name="licRequiredOutZee" {enabled}="dataModel.monitorLicence"></field-checkbox>

          {{#if gfcmAllowed}}
            <field-date name="periodDemersalTrawlFrom" format="date" required="false" col="6 12"></field-date>
            <field-date name="periodDemersalTrawlTo" format="date" required="false" col="6 12"></field-date>
          {{/if}}
        {{/if}}
      {{/if}}
    </div>

    {{! TAB : Contact}}

    {{#if domainFishingOrTraking}}
      <div id="{{id}}-tab-contacts" class="tab" style="display:none;">

        {{! TAB : Contact / Mobile}}

        {{>MobileThemisInfoPanelContactTpl}}

        {{! TAB : Contact / Shipowners}}

        {{#if shipowners}}
          {{#each shipowners}}
            <field-item item=".">
              <ui-collapser expanded="false" class="header-group contact-shipowner">
                <h2>{{msg 'ownerContact'}} {{name}}</h2>
                <span class="count">{{part}}%</span>
              </ui-collapser>
              <div id="{{id}}-owner-contact" class="inlineGroup fields disabled">
                <span class="info col-xs-12 icon-l icon-left ui-icon-nature">{{personIdentityType}}
                  / {{personIdentityNumber}}</span>
                {{#if address}}
                  <span class="info col-xs-12 icon-l icon-left ui-icon-measureStart">{{address}}</span>{{/if}}
                {{#if telephone}}
                  <span class="info col-xs-6 icon-l icon-left ui-icon-phone">{{telephone}}</span>{{/if}}
                {{#if fax}}<span class="info col-xs-6 icon-l icon-left ui-icon-print">{{fax}}</span>{{/if}}
                {{#if email}}<span class="info col-xs-12 icon-l icon-left ui-icon-mail">{{email}}</span>{{/if}}
              </div>
            </field-item>
          {{/each}}
        {{/if}}

      </div>
    {{/if}}

    {{! TAB : Status}}

    <div id="{{id}}-tab-status" class="tab disabled" style="display:none;">

      {{! TAB : Status / Position}}

      <div class="header-group icon-left icon-m ui-icon-position">
        <h2>{{msg 'lastLoc'}}</h2>
      </div>
      <div class="inlineGroup fields">
          <span class="info icon-l icon-left ui-icon-position col-sm-6 col-size-xs-12"
                title="{{msg '_Location'}}" id="{{id}}-mobileLastLoc">{{__format "position" lastPos.loc
                                                                                 ""}}</span>

        <span class="info icon-l icon-left ui-icon-speed col-sm-6 col-size-xs-12"
              title="{{msg '_Speed'}}" id="{{id}}-mobileLastSpeed">{{__format "speed" lastPos.speed true
                                                                              "" null null}}</span>

        <span class="info icon-l icon-left ui-icon-calendar col-sm-6 col-size-xs-12"
              title="{{msg '_LocDate'}}" id="{{id}}-mobileLastLocDate">{{__format "dateTime"
                                                                                  lastPos.locDate
                                                                                  ""}}</span>

        <span class="info icon-l icon-left ui-icon-heading col-sm-6 col-size-xs-12"
              title="{{msg '_Heading'}}" id="{{id}}-mobileLastHeading">{{__format "heading"
                                                                                  lastPos.heading
                                                                                  true "" null null}}</span>

        {{#if natureAvailable}}
          <span class="info icon-l icon-left ui-icon-nature col-sm-6 col-size-xs-4"
                title="{{msg 'lastNature'}}" id="{{id}}-mobileLastNature">{{lastPos.nature}}</span>
        {{/if}}

        {{#if sourceAvailable}}
          <span class="info icon-l icon-left ui-icon-satellite col-sm-6 col-size-xs-8"
                title="{{msg 'lastSource'}}" id="{{id}}-mobileLastSource">{{lastPos.source}}</span>
        {{/if}}

        <span class="info icon-l icon-left ui-icon-measureEnd col-sm-6 col-size-xs-4"
              title="{{msg 'lastLocOriginEvent'}}"
              id="{{id}}-mobileLastLocOriginEvent">{{msg dataModel.lastLocOriginEvent}}</span>
      </div>

      {{! TAB : Status / Beacon}}

            <div class="header-group inline icon-left icon-m ui-icon-beacon">
                <h2>{{msg 'status'}}</h2>
            </div>
            <div id="{{id}}-status" class="inlineGroup fields readOnly">
                <field-select class="l40-f60" name="integrationStatus" options="integrationStatusOptions"
                              required="false"></field-select>
                <field-switch name="lastAssistance" text-model="yesNo" col="6 12"></field-switch>
                <field-switch name="lastAlimentation" text-model="yesNo" col="6 12"></field-switch>
                <field-switch name="lastGpsConnected" text-model="onOff" col="6 12"></field-switch>
                <field-switch name="lastIntrusion" text-model="yesNo" col="6 12"></field-switch>

              {{! TAB : Status / ChargeLevel}}
              {{#if showChargeLevel}}
                  <field-text name="chargeLevel" col="6 10" readonly="true" value="dataModel.chargeLevel"></field-text>
                  <span class="info icon-l icon-left ui-icon-battery-{{levelBattery}} col-sm-6 col-size-xs-2"
                        title="{{msg 'chargeLevel'}}" id="{{id}}-mobileChargeLevel"
                        style="color: {{colorBattery}}"></span>
              {{/if}}
            </div>

      {{! TAB : Status / AIS}}

      {{#if aisAvailable}}
        <div class="header-group icon-left icon-m ui-icon-satellite">
          <h2>{{msg 'AISStatus'}}</h2>
        </div>
        <div id="{{id}}-AISStatus" class="inlineGroup fields readOnly">
          <field-text name="lastDestination" required="false" col="6 12"></field-text>
          <field-date format="dateTime" name="lastETA" required="false" col="6 12"></field-date>
          <field-text name="lastRateOfTurn" required="false" col="6 12"></field-text>
          <field-text name="lastDte" required="false" col="6 12"></field-text>
          <field-text name="lastFixingDevice" required="false" col="6 12"></field-text>
          <field-text name="lastNavigationStatus" required="false" col="6 12"></field-text>
        </div>
      {{/if}}

      {{! TAB : Status / Zones}}

      {{#unless hasZoneClassic}}
        <div class="header-group cls-fishing-field icon-left icon-m ui-icon-zones">
          <h2>{{msg 'zoneStatus'}}</h2>
        </div>
        <div id="{{id}}-zone-status" class="inlineGroup cls-fishing-field fields readOnly">
          <field-text name="lastPort" required="false" col="6 12"></field-text>
          {{#each zonesInfos}}
            <field-text name="{{name}}" label="{{label}}" required="false" col="6 12"></field-text>
          {{/each}}
        </div>
      {{/unless}}

      {{#unless domainTracking}}
      {{! TAB : Status / Licences}}

        <div class="header-group icon-left icon-m ui-icon-licence">
          <h2>{{msg 'stateLicence'}}</h2>
        </div>
        <div id="{{id}}-state-licence" class="inlineGroup fields readOnly">
          <field-switch name="hasValidLicence" text-model="yesNo" required="false" col="6 12"></field-switch>
        </div>
      {{/unless}}

      {{! TAB : Status / Fleet}}

      <field-list id="fleetList"
                  class="alwaysEnabled"
                  help-template="{{msg 'noData'}}"
                  counter="true"
                  search-fields="name"
                  {(field)}="dataModel.fleet"
                  format="array"
                  {item-template}="fleetTemplate"
                  {item-handler}="fleetHandler"
                  {items}="fleets"
                  {label}="fleetLabel"
                  (itemsrefresh)="onFleetRefresh"
                  edit-mode="R"
                  header-icon="fleet"></field-list>

      {{! TAB : Status / sharedCustomers}}
      {{#if showSharedCustomers}}
        <field-list id="sharedCustomerList"
                    class="alwaysEnabled"
                    help-template="{{msg 'noData'}}"
                    counter="true"
                    {(field)}="sharedCustomerIds"
                    format="array"
                    {item-template}="sharedCustomerTemplate"
                    {items}="sharedCustomers"
                    {label}="sharedCustomersLabel"
                    edit-mode="R"
                    header-icon="company"></field-list>
      {{/if}}


    </div>

    {{! TAB : Events}}

    <div id="{{id}}-tab-events" class="tab L-fit L-panel" style="display:none;">
      {{>EventsTabTpl}} {{! TAB : Identification / Image}}
    </div>

      {{#if ssasAllowed}}
          <div id="{{id}}-tab-ssas" class="tab" style="display:none;">
              <span class="annotation" style="color:red; font-weight: bold;">{{msg 'ssasWarning'}}</span>
              <div class="header-group inline icon-left icon-m ui-icon-user">
                  <h2>
                    {{msg 'ssasContact'}}
                      <div style="float: right">
                          <a cls-action="duplicateSsasContact" class="btn icon-center icon-xs ui-icon-duplicate"
                             title="{{msg 'duplicateVIS'}}"></a>
                          <a cls-action="exportSsasContact" class="btn ui-icon-export icon-m icon-center"
                             title="{{msg "ssasContactExport"}}"></a>
                      </div>
                  </h2>
              </div>
              <div id="{{id}}-ssas-contact" class="inlineGroup fields">

            {{>MobileThemisInfoPanelSsasContactTpl}}

          </div>

            {{#if showSsasTestDeclaration}}
                <div class="header-group inline icon-left icon-m ui-icon-licence">
                    <h2>{{msg 'ssasTestDeclaration'}}</h2>
                </div>
                <div id="{{id}}-ssas-test-declaration" class="inlineGroup fields">
                    <field-switch {disabled}="disableSsasTestDeclaration" name="argosTestEnabled" text-model="onOff"
                                  col="6 12" style="margin-bottom: 10px;">
                    </field-switch>
                    <field-date {disabled}="disableSsasTestDeclaration" name="argosTestStartDate" format="dateTime"
                                col="6 12"></field-date>
                </div>
            {{/if}}

              <div class="header-group inline icon-left icon-m ui-icon-licence">
                  <h2>{{msg 'ssasTestHistory'}}</h2>
              </div>
              <div id="{{id}}-ssas-test-history" class="inlineGroup fields">
                {{#each testLogs}}
                    <div class="test-log-line">
                        <a cls-action="editSsasTestLogComment" class="btn icon-l icon-right ui-icon-edit"
                           title="Edit" data-id="{{id}}"></a>
                        <span class="info col-xs-12 test-log-span"> {{__format "dateTime" date ""}} - {{beaconRef}}
                            - {{msg event}} - {{comment}}</span>
                    </div>
                {{/each}}
              </div>
          </div>
      {{/if}}

  </field-item>
  <form id="{{id}}-request-form" class="hidden" action="{{certificateFormModel.url}}" target="_blank" method="post">
    <input type="text" name="jwt" value="{{certificateFormModel.token}}">
    <input type="text" name="application" value="{{certificateFormModel.application}}">
    <input type="text" name="format" value="{{certificateFormModel.format}}">
    <input type="text" name="template" value="{{certificateFormModel.template}}">
    <input type="text" name="data" value="{{certificateFormModel.data}}">
    <button type='button' id='submitButton'></button>
  </form>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>

