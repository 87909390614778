<div id="{{id}}" class="page">

  <div class="header-group">
    <h3>{{msg 'title'}}</h3>
    <a cls-action="close" class="btn btn-header-right ui-icon-close" title="{{msg '_Close'}}"></a>
  </div>

  <field-file {(field)}="idFile" {status}="idFileStatus" label="" max-size="2Mo"
              accept-extentions="csv" class="fieldFile"></field-file>

  <div class="importContent">

    <div id="{{id}}-grid-content">
      <div id="mobiles-grid"></div>
    </div>
    <div id="{{id}}-edit-content">
      <div id="edit-grid"></div>
    </div>

  </div>


  <div id="{{id}}-footer" class="inline-3btn">
    <a cls-action="validate" class="btn btn-ok">{{msg '_Save'}}</a>
    <a cls-action="apply" class="btn btn-ok">{{msg '_Apply'}}</a>
    <a cls-action="cancel" class="btn btn-cancel">{{msg '_Cancel'}}</a>
  </div>

</div>
