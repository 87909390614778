import ResourceUI from "core-uda/ui/ResourceUI";
import BeaconLog from "core-uda/model/beacon/BeaconLog";
import CheckboxesCriteria from "core-criteria/listCheckbox/CheckboxesCriteria";

/**
 * User Interface elements for BeaconLog
 */
let BeaconLogUI = ResourceUI.extend({
  i18nPrefix: "",

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId: BeaconLog.itemId,
      i18nPrefix: "thm.ui.common.beaconLog.BeaconLogUI.",
      icon:       "annotation"
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      "action": {
        cmpClass:          CheckboxesCriteria,
        resourceName:      "beaconLog",
        // optionsKeyPrefix:  "uda.beaconLog.field.action.",
        validateMandatory: false
      }
    };
  }
});

const BEACON_LOG_UI = new BeaconLogUI();

export default BeaconLogUI;
