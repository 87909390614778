<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-top-content">
    <div id="{{id}}-general" class="fields">
        <field-text label-key="name" name="name" placeholder-key="name" required="true" col="12"></field-text>
        <field-text label-key="function" name="function" placeholder-key="function" required="true" col="12"></field-text>
        <field-text label-key="email" name="email" title-key="multipleMails" placeholder-key="email" required="true" col="12"></field-text>
        <field-text label-key="dayPhone" name="phoneDay" format="phone-inter"  placeholder-key="dayPhone" required="true" col="12"></field-text>
        <field-text label-key="eveningPhone" name="phoneNight" format="phone-inter" placeholder-key="eveningPhone" col="12"></field-text>
        <field-text label-key="mobilePhone" name="phoneMobile" format="phone-inter" placeholder-key="mobilePhone" required="true" col="12"></field-text>
        <field-text label-key="fax" name="fax" format="phone-inter" placeholder-key="fax" required="true" col="12"></field-text>
    </div>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="allowEdit"></ui-submit-cancel>
</div>
