<div class="header-group icon-left icon-m ui-icon-trajectory">
    <h2>{{msg 'selectedTrajectory'}}</h2>
</div>
<div class="inlineGroup fields">

    <field-color label-key="field.stroke.colorMobileSelected" name="selected.strokeColor" format="hex" click="openMenu" ></field-color>


    <field-color label-key="field.stroke.colorLineSelected" name="backLine.strokeColor" format="hex" click="openMenu" ></field-color>
    <field-range label-key="field.line.thicknessLineSelected" name="backLine.strokeWidth" min="1" max="10"></field-range>

</div>
