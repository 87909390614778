{{#each groups}}
  <div class="header-group">
    <h2>{{title}}</h2>
  </div>
  <div class="inlineGroup fields">
    {{#each fields}}
      {{#if isSwitch}}
        <field-switch {(field)}="parameters.{{name}}" label="{{fieldLabel}}" title="{{tooltip}}" {{visible parentCondition}}></field-switch>
      {{/if}}
      {{#if isText}}
        <field-text {(field)}="parameters.{{name}}" label="{{fieldLabel}}" format="{{format}}"
                    placeholder="{{placeholder}}" title="{{tooltip}}" {{visible parentCondition}}></field-text>
      {{/if}}
      {{#if isDate}}
        <field-date {(field)}="parameters.{{name}}" label="{{fieldLabel}}" format="{{format}}"
                    placeholder="{{placeholder}}"
                    title="{{tooltip}}" {{visible showClearFilter}}></field-date>
      {{/if}}
      {{#if isCheckboxes}}
        <field-checkboxes {(field)}="parameters.{{name}}" label="{{fieldLabel}}" options="{{options}}"
                          options-col="{{col}}"
                          label-align="top" format="string" title="{{tooltip}}" {{visible parentCondition}}></field-checkboxes>
      {{/if}}
      {{#if isSelect}}
        <field-select {(field)}="parameters.{{name}}" label="{{fieldLabel}}" options="{{options}}"
                      title="{{tooltip}}" {{visible parentCondition}}></field-select>
      {{/if}}
      {{#if isTextarea}}
        <field-textarea {(field)}="parameters.{{name}}" label="{{fieldLabel}}" label-align="top"
                        placeholder="{{placeholder}}" title="{{tooltip}}" {{visible parentCondition}}></field-textarea>
      {{/if}}
    {{/each}}
  </div>
{{/each}}

