import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import ThmZoneInfoPanel from "thm/ui/common/zone/ThmZoneInfoPanel";
import ZoneOfficialInfoPanelTplStache from "thm/ui/common/zone/ZoneOfficialInfoPanelTpl.stache";

/**
 * @class ZoneOfficialInfoPanel
 * Official Zone form
 */
let ZoneOfficialInfoPanel = ThmZoneInfoPanel.extend({
  id:         'zoneOfficialInfoPanel',
  childTpl:   ZoneOfficialInfoPanelTplStache,

  /**
   * @override
   */
  initViewModel: function () {
    const me = this;

    me._super();

    me.viewModel.attr("typeOptions", APP().getCapabilities("zone", "officialCategories"));

    me.viewModel.compute("dataModel.seasonal").bind("change", function () {
      _.defer(function () { // On timeout pour laisser le temps à canjs de regénérer le dom
        me.setTopFieldHeight();
      });

      if (me.viewModel.attr('dataModel.seasonal')) {
        if (!me.viewModel.attr('dataModel.beginDate')) {
          me.viewModel.attr('dataModel.beginDate', new Date().addDays(-1));
        }
        if (!me.viewModel.attr('dataModel.endDate')) {
          me.viewModel.attr('dataModel.endDate', new Date());
        }
      } else {
        me.viewModel.removeAttr('dataModel.beginDate');
        me.viewModel.removeAttr('dataModel.endDate');
      }

    });

  },

  /**
   * @override
   */
  updateViewModel: function () {
    const me = this;
    me._super();
    me.viewModel.attr('dataModel.seasonal', me.data.seasonal);
  },

  /**
   * @override
   */
  cleanForEqual: function (attr) {
    let me = this;
    let typeName = attr.typeName;

    attr = me._super(attr);
    // Le typeName est supprimé dans cleanForEqual de ThmZoneInfoPanel.js mais les zones officielles en ont besoin
    attr.typeName = typeName;

    return attr;
  }
});
export default ZoneOfficialInfoPanel;
