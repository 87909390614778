import Page from "core-cmp/Page"
import _ from "core-cmp/lodash";
import {APP} from "core-uda/model/Resource";
import {MOBILE_DATA} from "core-uda/model/mobile/MobileData";
import ResourceListCmp from "core-uda/ui/ResourceListCmp";
import "thm/ui/common/message/MessagePage";
import MessageMobileListHeaderTplStache from "thm/ui/common/message/MessageMobileListHeaderTpl.stache";
import MessageMobileTplStache from "thm/ui/common/message/MessageMobileTpl.stache";

/**
 * @class thm.ui.commonmessage.MessageMobileListCmp
 * @parent thm.ui
 * @constructor
 * Liste des mobiles.
 * @param {Object} options
 */
let MessageMobileListCmp = ResourceListCmp.extend({
  /** ======== CLASSE ======== **/

  /**
   * Génère le modéle pour la vue d'un mobile dans une liste.
   * @param {jqmc.ListSelection} selection : modèle de sélection
   * @param {Object} mobile : mobile
   * @param {integer} index : index de l'item dans la liste
   **/
  getMessageViewModel: function (selection, mobile, index) {

    return {
      item:           mobile,
      hasNewMessages: mobile.counter > 0,
      counter:        mobile.counter,
      field0:         MOBILE_DATA.getMobileLabel(mobile),
      subName:        MOBILE_DATA.getMobileAdditionalLabels(mobile)
    };
  }


}, {

  itemClickAction:         'open',
  allowSelect:             true,
  allowSearch:             true,
  allowClose:              true,
  allowRefresh:            false,
  allowSelectAll:          false,
  messageCounter:          0,
  checkableClass:          '',
  previousHeaderExtendTpl: MessageMobileListHeaderTplStache,
  i18nPrefix:              ['thm.ui.common.message.MessageMobileListCmp.', 'core-uda.ui.ResourceListCmp'],
  layoutClass:             'has-header3',

  /** ======== INSTANCE ======== **/

  itemTemplate: MessageMobileTplStache,
  entityId:     'messages',
  searchFields: ['name'],
  ulClass:      '',

  // override
  initModel: function (data) {
    var me = this;
    me._super();
  },

  initViewModel: function () {
    var me = this;
    me._super();

    me.viewModel.attr('headerHeight', 120);
    me.viewModel.attr('periods', [{
      text:  me.msg('week'),
      value: 'week'
    }, {
      text:  me.msg('month'),
      value: 'month'
    }, {
      text:  me.msg('months'),
      value: 'months'
    }]);

    var dataModel = {period: 'week'};
    me.viewModel.attr('dataModel', dataModel);
    me.viewModel.attr('dataModel').on('period', function (ev, val, oldVal) {
      me.parent.setCurrentPeriod(val);
    });
  },

  onItemClickOpen: function (id, ev) {
    let me             = this,
        mobile         = me.model.map[id],
        mobileMessages = !!APP().models['messages'] ? APP().models['messages'].getMobileMessages(mobile.id) : undefined;

    if (mobileMessages) {
      mobileMessages.setAllMessagesRead();
    }

    //MAJ du compteur de message
    me.parent.updateMessageCounter(id, 0);

    Page.get('messagePage').open({
      mobile:         mobile,
      mobileMessages: mobileMessages,
      period:         me.parent.getTimeForPeriod(),
      listPage:       me.parent,
      mode:           'read'
    });
  },


  // override
  updateSearchFields: function () {
    var me     = this,
        fields = [].concat(MOBILE_DATA.getMobileTemplateFields());

    _.each(fields, function (item, index) {
      fields[index] = item;
    });
    me.setSearchFields(fields);
  },

  //override
  bindEvents: function () {
    var me = this;

    me._super();
    me.updateSearchFields();

  },

  //override for stache
  getItemViewModel: function (item, index) {
    return MessageMobileListCmp.getMessageViewModel(this.selection, item, index);
  },

  setHeaderScroll: function () {
    var me = this;
    me.viewModel.attr('pageHeader', 'has-header3');
    me.viewModel.attr('scrollHeader', 'L-scrollY-header');
  }
});

export default MessageMobileListCmp;
