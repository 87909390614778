<div id="{{id}}-ssas-contact-1">

  <uda-select of="ssasContact"
              class="icon-left icon-m contact-field"
              label-key="cso"
              name="contact1Id"
              item-none="true"
              {update-for}="dataModel.contactChange"
              update-function="filterByContactNotCall"
              required="true"></uda-select>


  {{#if contact1}}
      <span class="info icon-l icon-left ui-icon-nature col-sm-12 col-size-xs-12"
            title="{{msg 'function'}}"> {{msg 'function'}} : {{contact1.function}}</span>

      <span class="info icon-l icon-left ui-icon-mail col-sm-12 col-size-xs-12"
            title="{{msg 'email'}}">{{msg 'email'}} : {{contact1.email}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'dayPhone'}}">{{msg 'dayPhone'}} : {{contact1.phoneDay}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'eveningPhone'}}">{{msg 'eveningPhone'}} : {{contact1.phoneNight}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'mobilePhone'}}">{{msg 'mobilePhone'}} : {{contact1.phoneMobile}}</span>

      <span class="info icon-l icon-left ui-icon-print col-sm-6 col-size-xs-12"
            title="{{msg 'fax'}}">{{msg 'fax'}} : {{contact1.fax}}</span>
  {{/if}}
</div>

<div id="{{id}}-ssas-contact-2">
  <uda-select of="ssasContact"
              class="icon-left icon-m contact-field"
              label-key="contact2"
              name="contact2Id"
              {update-for}="dataModel.contactChange"
              update-function="filterByContactNotCall"
              item-none="true"
              disabled="false"></uda-select>

  {{#if contact2}}
      <span class="info icon-l icon-left ui-icon-nature col-sm-12 col-size-xs-12"
            title="{{msg 'function'}}"> {{msg 'function'}} : {{contact2.function}}</span>

      <span class="info icon-l icon-left ui-icon-mail col-sm-12 col-size-xs-12"
            title="{{msg 'email'}}">{{msg 'email'}} : {{contact2.email}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'dayPhone'}}">{{msg 'dayPhone'}} : {{contact2.phoneDay}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'eveningPhone'}}">{{msg 'eveningPhone'}} : {{contact2.phoneNight}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'mobilePhone'}}">{{msg 'mobilePhone'}} : {{contact2.phoneMobile}}</span>

      <span class="info icon-l icon-left ui-icon-print col-sm-6 col-size-xs-12"
            title="{{msg 'fax'}}">{{msg 'fax'}} : {{contact2.fax}}</span>
  {{/if}}
</div>

<div id="{{id}}-ssas-contact-3">
  <uda-select of="ssasContact"
              class="icon-left icon-m contact-field"
              label-key="contact3"
              name="contact3Id"
              {update-for}="dataModel.contactChange"
              update-function="filterByContactNotCall"
              item-none="true"
              disabled="false"></uda-select>

  {{#if contact3}}
      <span class="info icon-l icon-left ui-icon-nature col-sm-12 col-size-xs-12"
            title="{{msg 'function'}}"> {{msg 'function'}} : {{contact3.function}}</span>

      <span class="info icon-l icon-left ui-icon-mail col-sm-12 col-size-xs-12"
            title="{{msg 'email'}}">{{msg 'email'}} : {{contact3.email}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'dayPhone'}}">{{msg 'dayPhone'}} : {{contact3.phoneDay}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'eveningPhone'}}">{{msg 'eveningPhone'}} : {{contact3.phoneNight}}</span>

      <span class="info icon-l icon-left ui-icon-phone col-sm-6 col-size-xs-12"
            title="{{msg 'mobilePhone'}}">{{msg 'mobilePhone'}} : {{contact3.phoneMobile}}</span>

      <span class="info icon-l icon-left ui-icon-print col-sm-6 col-size-xs-12"
            title="{{msg 'fax'}}">{{msg 'fax'}} : {{contact3.fax}}</span>
  {{/if}}
</div>
