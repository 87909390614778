import DataModel from "core-uda/model/DataModel";

/**
 * Repport model.
 */
let InspectionReportModel = DataModel.extend({
  modelId:  "inspectionReports",
  itemId:   "inspectionReport"
});

export default InspectionReportModel;
