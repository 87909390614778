import {PREF} from "core-cmp/Preferences";
import {ICON_FACTORY} from "core-cmp/service/IconFactory";
import {APP} from "core-uda/model/Resource";
import {TRAJECTORY_STYLE_HELPER} from "core-mobile/service/style/TrajectoryStyleHelper";
import {ALERT_OVER, ALERT_SIZE, BAD_AIS_QUALITY_OPACITY} from "core-mobile/service/style/TrajectoryStyleEngine";
import FishingStyleEngine from "core-mobile/service/style/FishingStyleEngine";

/**
 * Modèle de style "Animal"
 */
let AnimalStyleEngine = FishingStyleEngine.extend({
  modelName:     "Animal",
  specificRules: null,
  alertDraw:     true,

  /**
   * Constructeur.
   */
  init: function () {
    let me = this;
    me._super({
      specificRules: {
        estimationLine:        null,
        estimationPoint:       null,
        "animalDynamicTypes":  {
          select:    "species",
          "default": {
            svgCategory: "animals",
            svgName:     "bird",
            svgColor:    "black",
            svgBorder:   "white"
          }
        },
        "animalDynamicColors": {
          select:    "importances",
          "default": {
            fillColor: "#000000"
          }
        },
        "getAnimalPoint":      function (properties, attributes) {
          let multi                   = properties.isMultiSelected ? " selected" : "",
              lateForAnalyseParameter = RIGHTS('map.styles.position.lateForAnalyse'),
              toBeAnalysedParameter   = RIGHTS('map.styles.position.toBeAnalysed'),
              customAttributes        = properties.customAttributes,
              animal                  = APP("animals", attributes.boId),
              sex                     = APP("animals").getGenderOptions().find((g) => g.value === animal.sex),
              importance              = APP("animals").getImportanceOptions().find((i) => i.value === animal.importance);

          properties = Object.assign(properties, {
            species:          animal.speciesId,
            sexs:             sex ? '' + sex.id : null,
            importances:      importance ? '' + importance.id : null,
            customAttributes: {
              pointRadius: 18
            },
            dynamicPointType: this["animalDynamicTypes"].select,
            dynamicColorType: this["animalDynamicColors"].select
          });

          let point = this.getAnimalSVG(properties);
          point.extend = point.extend || "";
          // Add transparency to bad quality position
          if (properties.hasQuality && !properties.quality) {
            point.svgOpacity = BAD_AIS_QUALITY_OPACITY;
          }

          if (properties.isEstimated && !properties.isMobileData) {
            point.svgColor = this.estimationPoint.fillColor;
          }

          if (properties.isLast) {
            if (PREF().lastLabelPositions) {
              point.extend = "lastPointLabel " + point.extend;
              point.fontColor = this["pointLabel"].fontColor;
            }
          } else {
            if (PREF().labelPositions) {
              point.extend = "pointLabel " + point.extend;
            }
          }

          // THM : Late for analysis colors
          if (properties.lateForAnalyse && !!lateForAnalyseParameter && lateForAnalyseParameter.length > 0) {
            point.svgBorder = '#' + lateForAnalyseParameter;
          } else if (properties.toBeAnalysed && !!toBeAnalysedParameter && toBeAnalysedParameter.length > 0) {
            point.svgBorder = '#' + toBeAnalysedParameter;
          }

          point.extend += multi;

          if (customAttributes) {
            return _.assign(point, customAttributes);
          }

          return point;
        },
        "getAnimalSVG":        function (properties) {
          let pointRadius  = properties.customAttributes && properties.customAttributes.pointRadius ? parseInt(properties.customAttributes.pointRadius) : parseInt(this.defaultPoint.pointRadius),
              radius       = pointRadius <= 0 ? 1 : pointRadius,
              symbolRadius = (4 + radius) * 1.6,
              icon         = this.getAnimalIcon(properties),
              point;

          if (properties.isLast) {
            symbolRadius *= 1.5;
          }

          icon.svgSize = [Math.floor(symbolRadius), Math.floor(symbolRadius)];
          point = icon;

          if (properties.hasHeading) {
            if (properties.heading !== 0 && properties.heading !== 360) {
              if (properties.heading === 180) {
                point.rotation = -0.000001;
              } else {
                point.rotation = ((properties.heading) % 360) - 180;
              }
            }
          } else {
            point.extend = (properties.isLast ? "lastPoint" : "point") + "#noHeading";
          }

          point.iconSrc = icon.svgSrc;
          point.iconSize = [64, 64]; // JC util ?
          point.iconSnapToPixel = false;
          return point;
        },
        "getAnimalIcon":       function (properties) {
          let definition = TRAJECTORY_STYLE_HELPER.getValue({
                type:   "animalDynamicTypes",
                model:  properties.instance.definitions,
                select: properties.dynamicPointType,
                id:     properties[properties.dynamicPointType]
              }),
              colors     = this.getAnimalColor(properties, true),
              icon       = ICON_FACTORY.getIcon(definition.svgCategory, definition.svgName, colors.fillColor, colors.strokeColor) || ICON_FACTORY.getDefaultIcon();
          return icon;
        },
        "getAnimalColor":      function (properties, useSVG) {
          let definition       = TRAJECTORY_STYLE_HELPER.getValue({
                type:   "animalDynamicColors",
                model:  properties.instance.definitions,
                select: properties.dynamicColorType,
                id:     properties[properties.dynamicColorType]
              }),
              strokeValue      = "",
              strokeColor,
              color,
              customAttributes = properties.customAttributes;

          if (properties.isEstimated) {
            strokeValue = "estimationPoint";
          }

          if (properties.isSelected || properties.isMultiSelected) {
            strokeValue = "selected";
          }

          if (strokeValue === "") {
            strokeValue = "defaultPoint";
          }
          strokeColor = properties.instance.definitions[strokeValue].strokeColor;

          color = definition;

          if (!useSVG) {
            return _.assign({
              fillColor:   color.fillColor,
              strokeColor: strokeColor
            }, customAttributes);
          }
          return _.assign({
            fillColor:   color.fillColor,
            strokeColor: strokeColor
          }, customAttributes);
        },

        /******************* ALERT PART ***************************/

        "alertLife":               function (feature, attributes) {
          return this.definitions.getAlertIcon(feature, attributes);
        },
        "alertLife:select":        {
          extend: "alertLife"
        },
        "alertTest":               function (feature, attributes) {
          return this.definitions.getAlertIcon(feature, attributes);
        },
        "alertTest:select":        {
          extend: "alertTest"
        },
        "alertException":          function (feature, attributes) {
          return this.definitions.getAlertIcon(feature, attributes);
        },
        "alertException:select":   {
          extend: "alertException"
        },
        "alertUser":               function (feature, attributes) {
          return this.definitions.getAlertIcon(feature, attributes);
        },
        "alertUser:select":        {
          extend: "alertUser"
        },
        "animalAlertDynamicTypes": {
          select:    "alertDefinitions",
          "default": {
            svgCategory: "arrows",
            svgName:     "arrow1",
            svgColor:    "black",
            svgBorder:   "white"
          }
        },
        "getAlertIcon":            function (feature, attributes) {
          let properties       = {
                dynamicAlertType: this.animalAlertDynamicTypes.select,
                alertDefinitions: attributes.alert.kind
              },
              definition       = TRAJECTORY_STYLE_HELPER.getValue({
                type:   "animalAlertDynamicTypes",
                model:  this,
                select: properties.dynamicAlertType,
                id:     properties[properties.dynamicAlertType]
              }),
              validationStatus = attributes.alert.validationStatus,
              anchor           = -(this.defaultPoint.pointRadius - ALERT_OVER),
              color            = APP().getColorAlertValidationStatus(validationStatus),
              size             = ALERT_SIZE,
              colors           = {};

          switch (color) {
            case "black":
              colors.fillColor = '#000';
              break;
            case "red":
              colors.fillColor = '#FF4C25';
              break;
            case "yellow":
              colors.fillColor = '#FFEE25';
              break;
          }

          return Object.assign(ICON_FACTORY.getIcon(definition.svgCategory, definition.svgName, colors.fillColor) || ICON_FACTORY.getDefaultIcon(), {
            svgSize:          [size, size],
            iconSrcAlias:     "animal-alert" + definition.svgCategory + "-" + definition.svgName + ".png",
            iconAnchorOrigin: "bottom-right",
            iconAnchorXUnits: "pixels",
            iconAnchorYUnits: "pixels",
            iconAnchor:       [anchor, anchor]
          });
        }
      }
    });
  }
});

AnimalStyleEngine.INSTANCE = new AnimalStyleEngine();
export default AnimalStyleEngine;
