<field-list {(field)}="dataModel.events"
            format="items"
            edit-page="{{eventEditPage}}"
            {edit-page-params}="dataModel"
            {item-template}="eventTpl"
            header-icon="mobileEvent"
            edit-icon="add"
            item-size="xl"
            toolbar-size="4"
            export-fields="eventDate,eventTypeName,eventComment"
            {export-options}="eventExportOptions"
            class="L-panel-content"></field-list>
