<field-item id="{{id}}Form" item="formModel">
    <div class="col-7">
        <field-text label="" name="title"></field-text>
        <field-textarea label="" name="content"></field-textarea>
    </div>
    <div class="col-3">
        <a id="{{id}}Form-send" cls-action="send" class="btn btn-ok icon-l ui-icon-mail" title="{{msg '_Send'}}"></a>
        <a id="{{id}}Form-back" cls-action="cancel" class="btn btn-cancel icon-l ui-icon-close" title="{{msg '_Reset'}}"></a>
    </div>
</field-item>
