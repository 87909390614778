<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

    <field-item item="dataModel" class="L-panel-content">
        <div class="inlineGroup fields">
            <field-text name="name" max-length="60" col="12"></field-text>
          {{#if showClient}}
              <uda-select of="customers" name="customerId" {enabled}="_cmp.creation" col="12"
                          {hidden}="isListEditPage"></uda-select>
          {{/if}}
            <field-text name="sciName" max-length="60" col="12 12"></field-text>
            <field-text name="code" max-length="10" required="true" col="12 12"></field-text>
            <field-text name="family" max-length="240" col="12 12"></field-text>
            <field-switch name="gregarious" text-model="yesNo"></field-switch>
        </div>
        <div class="header-group icon-left icon-m ui-icon-flag">
            <h2>{{msg 'uda.preferences.field.locale'}}</h2>
        </div>
        <div class="inlineGroup fields">
          {{#each locales}}
              <field-text label="{{text}}" {(field)}="dataModel.i18n.{{value}}" max-length="240" col="12 12"
                          class="l20-f80"></field-text>
          {{/each}}
        </div>
    </field-item>

    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
