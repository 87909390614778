import _ from "core-cmp/lodash";
import $ from "core-cmp/cmp-jquery";
import Utils from "core-cmp/Utils";
import Grid from "core-cmp/Grid";
import XlsxFormat from "core-cmp/util/XlsxFormat";
import {PREF} from "core-cmp/Preferences";
import InfoPanel from "core-uda/ui/InfoPanel";
import {APP} from "core-uda/model/Resource";
import UdaGrid from "core-uda/ui/UdaGrid";
import GridDefinition from "core-uda/model/GridDefinition";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import CatchDeclarationInfoPanelTplStache from "thm/ui/common/catchDeclaration/CatchDeclarationInfoPanelTpl.stache";
import "thm/ui/common/catchDeclaration/CatchDeclarationInfoPanel.css";

/**
 * @class CatchDeclarationInfoPanel
 * Afficher les infos d'un catchDeclaration
 */
let CatchDeclarationInfoPanel = InfoPanel.extend({
  template:   CatchDeclarationInfoPanelTplStache,
  i18nPrefix: ['uda.catchDeclaration.', 'thm.ui.common.catchDeclaration.CatchDeclarationInfoPanel.'],
  id:         'catchDeclarationInfoPanel',
  entityId:   'catchDeclaration',
  grids:      {
    vesselFishingGridConfig:  {
      width:              '100%',
      filterRow:          null,
      sorting:            {
        mode: "single"
      },
      export:             {
        enabled: false
      },
      columnResizingMode: "widget",
      columns:            [
        {
          id:           "vesselName",
          dataField:    "vesselName",
          width:        130,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "vesselRef",
          dataField:    "vesselRef",
          width:        150,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:                 "tripLandTime",
          dataField:          "tripLandTimeMillis",
          width:              160,
          dataType:           "datetime",
          alignment:          "center",
          cellTemplate:       function (cellElement, cellInfo) {
            let data = cellInfo.data;
            cellElement.append(data.tripLandTimeMillis ? PREF().format("dateTime", new Date(data.tripLandTimeMillis)) : "?");
          },
          exportCellTemplate: function (row) {
            return row.tripLandTimeMillis ? PREF().format("dateTime", new Date(row.tripLandTimeMillis)) : "?";
          },
          allowSorting:       false
        },
        {
          id:           "vesselFishType",
          dataField:    "vesselFishType",
          width:        120,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "vesselImmatPort",
          dataField:    "vesselImmatPort",
          width:        160,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "tripLandPort",
          dataField:    "tripLandPort",
          width:        160,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        }
      ]
    },
    vesselActivityGridConfig: {
      width:              '100%',
      filterRow:          null,
      sorting:            {
        mode: "single"
      },
      export:             {
        enabled: false
      },
      columnResizingMode: "widget",
      columns:            [
        {
          id:           "licenceRef",
          dataField:    "licenceRef",
          width:        80,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:                 "licenceEndDate",
          dataField:          "licenceEndDateMillis",
          width:              180,
          dataType:           "datetime",
          alignment:          "center",
          cellTemplate:       function (cellElement, cellInfo) {
            let data = cellInfo.data;
            cellElement.append(data.licenceEndDateMillis ? PREF().format("dateTime", new Date(data.licenceEndDateMillis)) : "?");
          },
          exportCellTemplate: function (row) {
            return row.licenceEndDateMillis ? PREF().format("dateTime", new Date(row.licenceEndDateMillis)) : "?";
          },
          allowSorting:       false
        },
        {
          id:                 "mareeFromTo",
          dataField:          "mareeFromTo",
          width:              300,
          dataType:           "string",
          alignment:          "center",
          cellTemplate:       function (cellElement, cellInfo) {
            let data    = cellInfo.data,
                depDate = data.tripDepTimeMillis ? PREF().format("dateTime", new Date(data.tripDepTimeMillis)) : "?",
                rtpDate = data.tripRtpTimeMillis ? PREF().format("dateTime", new Date(data.tripRtpTimeMillis)) : "?";
            cellElement.append(depDate + " - " + rtpDate);
          },
          exportCellTemplate: function (row) {
            let depDate = row.tripDepTimeMillis ? PREF().format("dateTime", new Date(row.tripDepTimeMillis)) : "?",
                rtpDate = row.tripRtpTimeMillis ? PREF().format("dateTime", new Date(row.tripRtpTimeMillis)) : "?";
            return depDate + " - " + rtpDate;
          },
          allowSorting:       false
        },
        {
          id:           "authorizedProcessing",
          dataField:    "authorizedProcessing",
          width:        200,
          dataType:     "string",
          alignment:    "center",
          format:       I18N_FIELD("uda.mobile.field.authorizedProcessing.options.").format,
          allowSorting: false
        },
        {
          id:           "vesselImo",
          dataField:    "vesselImo",
          width:        230,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "contact",
          dataField:    "contact",
          width:        280,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        }
      ]
    },
    capturesGridConfig:       {
      width:              '100%',
      filterRow:          null,
      sorting:            {
        mode: "single"
      },
      export:             {
        enabled: false
      },
      columnResizingMode: "widget",
      columns:            [
        {
          id:           "fao",
          dataField:    "speciesCode",
          width:        80,
          dataType:     "string",
          alignment:    "center",
          allowSorting: true
        },
        {
          id:           "species",
          dataField:    "speciesName",
          width:        90,
          dataType:     "string",
          alignment:    "center",
          allowSorting: true
        },
        {
          id:           "zoneInfo",
          dataField:    "zoneInfo",
          width:        120,
          dataType:     "string",
          alignment:    "center",
          format:       function (zones) {
            let value;
            if (zones) {
              if (zones.FAO) {
                let zee = zones.ZEE ? zones.ZEE.ref + '-' : '';
                value = "FAO " + zee + zones.FAO.ref;
              } else if (zones.ZEE) {
                value = "ZEE " + zones.ZEE.ref;
              } else if (zones.FSH) {
                value = zones.FSH.ref;
              }
            }
            return value;
          },
          allowSorting: true
        },
        {
          id:           "fishedWeight",
          dataField:    "fishedWeight",
          width:        140,
          dataType:     "number",
          alignment:    "center",
          allowSorting: true
        },
        {
          id:           "estimWeight",
          dataField:    "estimWeight",
          width:        200,
          dataType:     "number",
          alignment:    "center",
          allowSorting: true
        },
        {
          id:           "landedWeight",
          dataField:    "landedWeight",
          width:        180,
          dataType:     "number",
          alignment:    "center",
          allowSorting: true
        }
      ]
    },
    captainGridConfig:        {
      width:              '100%',
      filterRow:          null,
      sorting:            {
        mode: "single"
      },
      export:             {
        enabled: false
      },
      columnResizingMode: "widget",
      columns:            [
        {
          id:           "captainName",
          dataField:    "captainName",
          width:        80,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "captainDocIdentNum",
          dataField:    "captainDocIdentNum",
          width:        160,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "captainInscriptionNumber",
          dataField:    "captainInscriptionNumber",
          width:        120,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "captainNationality",
          dataField:    "captainNationality",
          width:        110,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        },
        {
          id:           "captainSign",
          dataField:    "captainSign",
          width:        80,
          dataType:     "string",
          alignment:    "center",
          allowSorting: false
        }
      ]
    }
  },

  init: function (config) {
    let me = this;
    me._super(config);
    me.gridDefinition = new GridDefinition();
  },

  /**
   * @override
   */
  doAfterRender: function () {
    let me = this;

    me._super();

    _.each(me.grids.attr(), (config, gridConfName) => {
      let gridName = gridConfName.replace("Config", ''),
          el       = me.$findById(gridName);
      if (el.length) {
        _.each(config.columns, function (col) {
          me.updateColumnOption(gridName, col);
        });
        me[gridName] = new UdaGrid(me.$findById(gridName), config);
      }
    });
  },

  /**
   * @override
   */
  getToolbarActions: function () {
    let me      = this,
        actions = me._super();

    me.addToolbarAction(actions, {
      action:     "catchDownload",
      icon:       "export",
      label:      me.msg("_Download")
    });

    return actions;
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me   = this,
        item = me.data.attr ? me.data.attr() : me.data;

    me._super();

    me.format = new XlsxFormat({
      encodeType: "blob",
      ordered:    true,
      name:       item.dcNumber ? item.dcNumber.replace(/\//g, ' ') : ''
    });
    me.infopanelTitleStyle = me.format.stylesheet.createFormat({
      font: {
        bold:  true,
        color: 'ffffffff'
      },
      fill: {
        type:        'pattern',
        patternType: 'solid',
        fgColor:     'ff555555'
      }
    }).id;

    me.setTitle(me.msg('one'));
    me.setSubTitle(PREF().format("dateTime", new Date(item.dcDateMillis)));

    me.viewModel.dataModel.attr('disabled', !me.managementMode);
    me.viewModel.dataModel.attr('declareDate', PREF().format('dateTime', new Date(item.dcDateMillis)));

    me['vesselFishingGrid'].beginUpdate();
    me['vesselFishingGrid'].option('dataSource', [item.detail] || []);
    me['vesselFishingGrid'].endUpdate();

    me['vesselActivityGrid'].beginUpdate();
    me['vesselActivityGrid'].option('dataSource', [item.detail] || []);
    me['vesselActivityGrid'].endUpdate();

    me['capturesGrid'].beginUpdate();
    me['capturesGrid'].option('dataSource', item.speciesDetail || []);
    me['capturesGrid'].endUpdate();

    me['captainGrid'].beginUpdate();
    me['captainGrid'].option('dataSource', [item.detail] || []);
    me['captainGrid'].endUpdate();
  },

  updateColumnOption: function (gridName, col) {
    let me = this;
    col.caption = me.msg(gridName + '.' + col.id);
    col.id = gridName + "_" + col.id;
    Grid.updateColumnOptionFormat(col);
  },

  onCatchDownload: function (ev) {
    let me    = this,
        datas = [],
        file  = {
          fileName: "CapturesDeclarationUMV"
        };

    me._addInfo(datas);
    me._addGrids(datas);

    // Add VISA
    datas.push([{
      metadata: {style: me.infopanelTitleStyle},
      value:    me.msg("visa")
    }]);
    datas.push([me.data.detail.visa]);

    me.format.headersWidth = [{
      width: 40
    }, {
      width: 25
    }, {
      width: 50
    }, {
      width: 30
    }, {
      width: 35
    }, {
      width: 40
    }];

    file = _.extend(file, me.format.encode(datas, true));
    $.when(file.promise).then(function (content) {
      $.when(Utils.saveAs({
        content:  content,
        type:     file.type,
        fileName: file.fileName + "." + file.extension,
        timeout:  100
      }));
    });
  },

  _addInfo: function (datas) {
    let me = this;
    datas.push([{
      metadata: {style: me.infopanelTitleStyle},
      value:    me.msg("info")
    }]);

    datas.push([{
      metadata: {style: 1},
      value:    me.msg("field.dcNumber")
    }, me.data.dcNumber, {
      metadata: {style: 1},
      value:    me.msg("field.dcDate")
    }, PREF().format("dateTime", new Date(me.data.dcDateMillis))]);

    datas.push([{
      metadata: {style: 1},
      value:    me.msg("field.dcDelegation")
    }, me.data.dcDelegation, {
      metadata: {style: 1},
      value:    me.msg("field.detail.shipownerName")
    }, me.data.detail.shipownerName]);

    datas.push([]);
  },

  _addGrids: function (datas) {
    let me = this;

    _.each(me.grids.attr(), function (grid, gridConfName) {
      let gridName = gridConfName.replace("Config", ''),
          realGrid = me[gridName],
          msg;

      switch (gridName) {
        case "vesselFishingGrid":
          msg = "vesselFishingActivity";
          break;
        case "capturesGrid":
          msg = "captures";
          break;
        case "captainGrid":
          msg = "captain";
          break;
      }

      if (gridName !== "vesselActivityGrid") {
        datas.push([{
          metadata: {style: me.infopanelTitleStyle},
          value:    me.msg(msg)
        }]);
      }

      if (realGrid) {
        let columns = realGrid.gridinstance.getVisibleColumns(),
            headers = me._getHeaderDatas(columns),
            rows    = me._getRows(me[gridName]);

        // Add headers
        datas.push(headers);

        _.each(rows, function (row) {
          let formattedRow = [];
          _.each(columns, function (col) {
            let value = row[col.dataField];

            if (col.calculateCellValue && (typeof col.calculateCellValue === "function")) {
              value = col.calculateCellValue(row);
            }

            if (col.exportCellTemplate && (typeof col.exportCellTemplate === "function")) {
              value = col.exportCellTemplate(row);
            }

            value = me.format.encodeValue(value);
            formattedRow.push(value);
          });
          datas.push(formattedRow);
        });

        datas.push([]);
      }
    });
  },

  _getHeaderDatas: function (columns) {
    let me   = this,
        data = [];
    _.each(columns, function (col) {
      data.push(me.format.getHeaderData(col));
    });

    return data;
  },

  _getRows: function (grid) {
    let me                = this,
        dxDataGrid        = grid.gridinstance,
        isFiltered        = !!me.rowsFilter,
        dataSource        = dxDataGrid.getDataSource(),
        filterExpr        = dxDataGrid.getCombinedFilter(),
        sortExpr          = _.cloneDeep(dataSource.sort()),
        store             = dataSource.store(),
        rows              = [],
        toGenerateColumns = [],

        // Generate a clone of grid dataSource without virtual scrolling and paging, using the same store (data)
        exportDataSource  = new DevExpress.data.DataSource({
          paginate: false,
          store:    store
        });

    // Sort
    if (!_.isEmpty(sortExpr)) {
      me.addIfVirtual(toGenerateColumns, sortExpr, "sort");
      exportDataSource.sort(sortExpr);
    }

    // Filter if requested
    if (isFiltered && filterExpr) {
      exportDataSource.filter(filterExpr);
    }

    // Generate text value for virtual columns used by sort or group
    if (!_.isEmpty(toGenerateColumns)) {
      _.each(store._array, (row) => {
        _.each(toGenerateColumns, (config) => {
          row[config.textDataField] = me.gridDefinition.formatExportCellValue(row, config.column, config.getter);
        })
      })
    }

    // Generate rows
    exportDataSource.load().done((rowsOrGroups) => {
      me._addRows(rows, rowsOrGroups);
    });

    return rows;
  },

  _addRows: function (rows, rowsOrGroups) {
    let me = this;
    rows.push(...me.gridDefinition.formatExportRows(rowsOrGroups));
  }
});

export default CatchDeclarationInfoPanel;
