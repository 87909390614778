<div class="header-group icon-left icon-m ui-icon-config">
    <h2>{{msg 'behavior'}}</h2>
</div>
<div class="inlineGroup fields">
    <field-checkbox name="monitorIn" col="6 12"></field-checkbox>
    <field-checkbox name="monitorOut" col="6 12"></field-checkbox>
    {{#unless domainFish}}
        <field-checkbox name="unauthorizedPortsOnly" col="12 12"></field-checkbox>
    {{/unless}}
</div>
