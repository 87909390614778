import {PREF} from "core-cmp/Preferences";
import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";
import ChildStache from "thm/ui/common/alertDefinition/AlertDefinitionTimeInZoneInfoPanelTpl.stache";

/**
 * @class AlertDefinitionTimeInZoneInfoPanel
 * Alert Definition "Time In Zone" Form.
 */
let AlertDefinitionTimeInZoneInfoPanel = AlertDefinitionInfoPanel.extend({
  id:       'AlertDefinitionTimeInZoneInfoPanel',
  childTpl: ChildStache,
  timingAllowed: true,

  /**
   * @override
   */
  bindEvents: function () {
    let me = this;
    me._super();
    PREF.bind('unitChanged', function (ev, newVal, oldVal) {
      me.updateViewModel();
    });
  },

  /**
   * @override
   */
  updateDataModel: function () {
    let me = this,
        thresholdInSeconds;

    me._super();
    thresholdInSeconds = me.viewModel.attr('dataModel.threshold');
    me.viewModel.attr('dataModel.threshold', thresholdInSeconds/3600);
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me   = this,
        data = me._super();

    data.threshold = data.threshold * 3600;
    return data;
  }
});
export default AlertDefinitionTimeInZoneInfoPanel;
