<div id="{{id}}" class="page">
    <div class="header-group icon-left icon-l ui-icon-vesselMeeting">
        <h3>{{msg 'mergeSubtractTitle'}}</h3>
    </div>

    <field-item item="dataModel" class="inlineGroup fields">
        <field-switch label="" name="type" text-on-key="merge" text-off-key="subtract"></field-switch>
        <field-text name="name" label-align="top" required="true"></field-text>
        <span class="type-signature inlineGroup">{{msg 'startFleet'}}</span>
        <uda-select of="fleets"
                    name="fleet1"
                    label=""
                    {update-for}="dataModel.customerId"
                    required="true"
                    update-function="filterByCustomerId"></uda-select>
        <span class="type-signature inlineGroup">{{secondFleet}}</span>
        <uda-select of="fleets"
                    name="fleet2"
                    label=""
                    {update-for}="dataModel.customerId"
                    required="true"
                    update-function="filterByCustomerId"></uda-select>
    </field-item>

    <ui-submit-cancel {status}="saveStatus" cancel-action="close"></ui-submit-cancel>
</div>
