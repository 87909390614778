import SensorUI from "core-sensor/ui/SensorUI";
import {THM_ALERT_DEFINITION_UI} from "thm/ui/common/alertDefinition/ThmAlertDefinitionUI";

let ThmSensorUI = SensorUI.extend({

  /**
   * @override
   */
  getContextMenuActions: function (context) {
    let me = this;
    return me._super(context).concat(
      THM_ALERT_DEFINITION_UI.getContextMenuActionsForType("trajectories", context.data.mobile.customerId, context)
    );
  }
});

const THM_SENSOR_UI = new ThmSensorUI();

export {THM_SENSOR_UI};
export default ThmSensorUI;
