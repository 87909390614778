import AlertDefinitionInfoPanel from "thm/ui/common/alertDefinition/AlertDefinitionInfoPanel";

/**
 * @class AlertDefinitionFishingWithoutLicenceInfoPanel
 * Alert Definition "FishingWithoutLicence" Form.
 */
let AlertDefinitionFishingWithoutLicenceInfoPanel = AlertDefinitionInfoPanel.extend({
  id:            'alertDefinitionFishingWithoutLicenceInfoPanel',
  timingAllowed: true
});
export default AlertDefinitionFishingWithoutLicenceInfoPanel;
