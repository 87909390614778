import {APP} from "core-uda/model/Resource";
import {PREF} from "core-cmp/Preferences";
import {RIGHTS, DATA_AUTHORIZED} from "core-uda/Rights";
import InfoPanel from "core-uda/ui/InfoPanel";
import QuotaInfoPanelTplStache from "thm/ui/common/quota/QuotaInfoPanelTpl.stache";
import _ from "core-cmp/lodash";
import "thm/ui/common/quota/Quota.css";
import Toastr from "core-cmp/Toastr";

/**
 * Fishing quota form.
 * @class QuotaInfoPanel
 */
let QuotaInfoPanel = InfoPanel.extend({
  template:   QuotaInfoPanelTplStache,
  i18nPrefix: ['uda.quota.', 'thm.ui.common.quota.QuotaInfoPanel.'],
  id:         'quotaInfoPanel',
  childTpl:   null,
  entityId:   'quota',
  allowWidth: true,
  allowClone: true,
  titleKey:   "title",

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this;

    dataModel.attr({
      name: me.msg("Clone") + " - " + dataModel.name
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr("showClient", RIGHTS("showClient"));
    me.viewModel.attr('tabs', "information");
    me.viewModel.attr('isMobile', true);
    me.viewModel.attr('tabsOptions', me.loadTabsOptions());
    me.viewModel.attr('unitOptions', me.loadUnitOptions());
    me.viewModel.attr('alarmTypeOptions', me.loadAlarmTypeOptions());
    me.viewModel.attr('alarmType', 'quotaAchieves');
    me.viewModel.attr('isQuotaAlmost', false);
    me.viewModel.attr('endDateMustBeAfterTodayDate', false);
    me.viewModel.on('alarmType', function (vm, newValue) {
      this.attr('isQuotaAlmost', newValue === 'quotaAlmost');
    });
    me.viewModel.on('dataModel.endDate', function (field, newValue) {
      let today = new Date(new Date().setHours(0, 0, 0, 0)),
          endDate;

      if (_.isUndefined(newValue) /*|| me.creation*/) {
        me.setEditable(me.allowEdit);
        me.viewModel.attr('endDateMustBeAfterTodayDate', false);
      } else {
        // Compare only the day without hours to allow edition
        endDate = new Date(newValue.getTime());
        endDate.setHours(0, 0, 0, 0);

        if (endDate >= today) {

          // valid licence : editable (end date in the future)
          me.setEditable(me.allowEdit);
          me.viewModel.attr('endDateMustBeAfterTodayDate', false);
        } else {

          // invalid licence : not editable (end date in the past)
          me.setEditable(false);
          me.viewModel.attr('endDateMustBeAfterTodayDate', true);
        }
      }
    });
  },

  /**
   * @Override
   */
  updateViewModel: function () {
    let me   = this,
        item = me.data.attr ? me.data.attr() : me.data;

    me._super();

    // me._super() :
    //  - set "editable" flag to me.allowEdit
    //  - AND set "endDateMustBeAfterTodayDate" to true if quota not editable because his end date is in the past
    // => quota editable if allowEdit and end date in the future
    me.setEditable(!me.viewModel.attr('endDateMustBeAfterTodayDate') && me.allowEdit);

    me.viewModel.attr('isMobile', !!item.vesselIds);
    me.viewModel.attr('alarmType', me.viewModel.attr('dataModel.percAlarm') === 100 ? 'quotaAchieves' : 'quotaAlmost');

    // UDA has only one zone list "zoneIds".
    // In form these zones must be dispalyed inside 2 lists : FAO and ZEE.
    me.viewModel.attr("dataModel.zoneZEEIds", _.filter(item.zoneIds, (id) => APP("zone", id) && APP("zone", id).isZee()));
    me.viewModel.attr("dataModel.zoneFAOIds", _.filter(item.zoneIds, (id) => APP("zone", id) && APP("zone", id).isFao()));
  },

  /**
   * @Override
   */
  onSaveError: function (error) {
    let me = this;

    if (error.exception === 'OUT_OF_RANGE') {
      Toastr.showError(me.msg('endDateException'), 5000);
    }
  },

  _zeeFaoToZone : function(data) {
    // UDA has only one zone list "zoneIds".
    // In form these zones must be dispalyed inside 2 lists : FAO and ZEE.
    data.zoneZEEIds = data.zoneZEEIds || [];
    data.zoneFAOIds = data.zoneFAOIds || [];
    data.zoneIds = data.zoneZEEIds.concat(data.zoneFAOIds);
    data.zoneIds.sort();
    delete data.zoneFAOIds;
    delete data.zoneZEEIds;
  },

  /**
   * @Override
   */
  getDataModelForSave: function () {
    let me   = this,
        data = me._super();

    if (me.viewModel.attr('isMobile')) {
      delete data.fleetIds;
    } else {
      delete data.vesselIds;
    }

    me._zeeFaoToZone(data);

    return data;
  },

  /**
   * @Override
   */
  cleanForEqual: function (item) {
    let me = this;

    if (item.zoneZEEIds) {
      me._zeeFaoToZone(item);
    }
    item.zoneIds = item.zoneIds || [];

    return item;
  },

  /**
   * @Override
   */
  checkChanges: function () {
    let me       = this,
        fromData = me.cleanForEqual(me.cloneData()),
        diff     = me._super(),
        isNew    = !me.data.id;

    if ((!diff || isNew) && me.viewModel.attr('alarmType') === 'quotaAchieves' && fromData.percAlarm !== 100) {
      me.viewModel.attr('dataModel.percAlarm', 100);
      if (!isNew) {
        diff = true;
      }
    }

    return diff;
  },

  loadTabsOptions: function () {
    let me = this;
    return [{value: 'information', text: me.msg('informationOption'), icon: 'info'},
      {value: 'vesselsFleets', text: me.msg('vesselsFleetsOption'), icon: 'fleet'},
      {value: 'zones', text: me.msg('_Zones'), icon: 'zones'}];
  },

  loadUnitOptions: function () {
    let me = this;

    return [{value: 'T', text: me.msg("tonneOption")},
      {value: 'nb', text: me.msg("nbOption")}];
  },

  loadAlarmTypeOptions: function () {
    let me = this;

    return [{value: 'quotaAlmost', text: me.msg("quotaAlmostOption")},
      {value: 'quotaAchieves', text: me.msg("quotaAchievesOption")}];
  }
});

export default QuotaInfoPanel;
