<div id="{{id}}" class="page L-fit L-scrollY has-header has-footer-btn">

  <div class="L-scrollY-header header-page">
    <h3>{{msg 'title'}}</h3>
  </div>

  <div class="L-scrollY-content">
    <field-item item="dataModel">

    {{#each alertTypes}}
      <div class="header-group {{icon}}">
        <h3>{{label}}</h3>
      </div>

      <div class="inlineGroup fields">
        <field-checkboxes name="{{name}}" label="" {options}="{{name}}"
                          border="false"
                          options-col="2"
                          format="array" visible="true"></field-checkboxes>
      </div>
    {{/each}}

    </field-item>
  </div>

  <div id="{{id}}-footer" class="L-scrollY-footer-btn inline-2btn">
    <a cls-action="validate" class="btn btn-ok">{{msg '_Validate'}}</a>
    <a cls-action="cancel" class="btn btn-cancel">{{msg '_Cancel'}}</a>
  </div>
</div>
